import React from 'react'

import { Flex } from '@src/common/components/BaseComponent'

export default function DropDownMenuList({
  children
}: {
  children
}): JSX.Element {
  return (
    <Flex flexDirection="column" padding="4px 0">
      {children}
    </Flex>
  )
}
