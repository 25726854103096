import React from 'react'

import { Flex, Box } from '@src/common/components/BaseComponent'

import theme from '../../../styles/theme'
import { isSmScreen } from '../../hooks'
import Divider from './Divider'

type IProps = {
  title?: string
  subtitle?: string
  addonAfter?: React.ReactNode
  divider?: boolean
}

const Header: React.FC<IProps> = ({
  title,
  subtitle,
  addonAfter = null,
  divider = null,
  ...props
}) => {
  if (isSmScreen()) {
    return (
      <>
        <Flex flexDirection="row" {...props}>
          <Box flex="1">
            <Box fontSize="16" fontWeight="bold" color={theme.text}>
              {title}
            </Box>
            <Box fontSize="14" color="rgba(0, 0, 0, 0.45)" mb="xsmall">
              {subtitle}
            </Box>
          </Box>
          <Flex
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            {addonAfter}
          </Flex>
        </Flex>
        {divider && <Divider />}
      </>
    )
  }

  return (
    <>
      <Flex flexDirection="row" {...props}>
        <Box flex="1">
          <Box fontSize="16" fontWeight="bold">
            {title}
          </Box>
          <Box fontSize="14" color="rgba(0, 0, 0, 0.45)" mb="xsmall">
            {subtitle}
          </Box>
        </Box>
      </Flex>
      <Flex
        mt="small"
        flexDirection="row"
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        {addonAfter}
      </Flex>
      {divider && <Divider />}
    </>
  )
}

export default Header
