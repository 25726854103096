import useOAuthApp from '@src/common/hooks/useOAuthApp'

export const useChannelStoreList = (
  stores: globalLib.BusinessStore[]
): {
  key: string
  value: string
}[] => {
  const { isOAuthAppEmbed, oauthDefaultBusinessStoreId } = useOAuthApp()

  let channelStoreList = Object.values(stores).map(
    (b: globalLib.BusinessStore) => ({
      key: b.id,
      value: b.name
    })
  )
  if (isOAuthAppEmbed && oauthDefaultBusinessStoreId) {
    channelStoreList = channelStoreList.filter(
      (option) => oauthDefaultBusinessStoreId === option.key
    )
  }

  return channelStoreList
}
