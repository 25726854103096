import React from 'react'

import { Box, BoxProps } from 'fwego'

interface IProps extends BoxProps {
  children?: any
  elevated?: boolean
}

const BoxCard: React.FC<IProps> = ({
  children,
  elevated = false,
  ...props
}) => {
  return (
    <Box
      p={['small', 'large']}
      m={['small', 'large']}
      borderRadius="12"
      bg="white"
      boxShadow={
        elevated ? '0 1px 2px rgba(0,0,0,0.16), 0 1px 2px rgba(0,0,0,0.23)' : ''
      }
      {...props}
    >
      {children}
    </Box>
  )
}

export default BoxCard
