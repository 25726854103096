import React from 'react'

import { Flex } from '@src/common/components/BaseComponent'
import { Empty } from 'antd'

const EmptyInsightsContent: React.FC = () => {
  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      width="100%"
    >
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </Flex>
  )
}

export default EmptyInsightsContent
