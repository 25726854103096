import React, { ChangeEvent, useCallback } from 'react'

import { Poll } from '@src/common/components/Poll'

type BatchUploadPollWrapperProps = {
  addPoll: (value: { question: string; options: string[] }) => void
  poll: {
    question: string
    options: string[]
  }
}

export const BatchUploadPollWrapper: React.FC<BatchUploadPollWrapperProps> = ({
  addPoll,
  poll
}) => {
  const handleAddPoll = useCallback(
    (poll: { question: string; options: string[] }) => {
      addPoll(poll)
    },
    [addPoll]
  )

  const updateOptionAtIndex = (
    index,
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    const options = [...poll.options]
    options[index] = e.target.value
    handleAddPoll({
      ...poll,
      options: options
    })
  }

  const addOption = () => {
    const options = [...poll.options]
    options.push('')
    handleAddPoll({ ...poll, options })
  }

  const removeOptionAtIndex = (index) => {
    const options = [...poll.options]
    options.splice(index, 1)
    handleAddPoll({
      ...poll,
      options
    })
  }

  const handleTextAreaChange = (e) => {
    handleAddPoll({
      ...poll,
      question: e.target.value
    })
  }

  const handleInputChange = (e) => {
    const index = e.target.getAttribute('data-index')
    updateOptionAtIndex(index, e)
  }

  return (
    <Poll
      handleTextAreaChange={handleTextAreaChange}
      removeOptionAtIndex={removeOptionAtIndex}
      addOption={addOption}
      handleInputChange={handleInputChange}
      pollObj={poll}
    />
  )
}
