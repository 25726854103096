import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '@src/app/redux/store'

export const getBusinessStores = createSelector(
  (state: RootState) => state.businessStore.businessStores,
  (state: RootState) => state.businessStore.ids,
  (businessStores, storeIds) => {
    return storeIds.reduce((acc, storeId) => {
      acc.push(businessStores[storeId])

      return acc
    }, [])
  }
)

export const getShopifyStores = createSelector(
  (state: RootState) => state.businessStore.businessStores,
  (businessStores) => {
    return Object.values(businessStores).reduce((acc, store) => {
      if (store?.provider === 'shopify') {
        acc.push(store)
      }

      return acc
    }, [])
  }
)

export const createShopifyStoreSelector = (businessId: string) =>
  createSelector(getShopifyStores, (stores) => {
    return stores.find(({ business_id }) => business_id === businessId)
  })
