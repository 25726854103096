import React from 'react'

import { css } from '@emotion/react'
import { Box, Flex } from '@src/common/components/BaseComponent'
import { SubTitle, Title } from '@src/common/components/BaseComponent'
import theme from '@src/styles/theme'
import { useTranslation } from 'react-i18next'

interface FeatureTileProps {
  imageSrc: string
  title: string
  description: string | React.ReactNode
  onClick: () => void
  footerControls?: React.ReactNode[]
  disabled?: boolean
  noCodeEmbed?: boolean
}

export function FeatureTile({
  imageSrc,
  title,
  description,
  onClick,
  footerControls,
  disabled,
  noCodeEmbed = false
}: FeatureTileProps): JSX.Element {
  const { t } = useTranslation()

  return (
    <Flex
      flexDirection="column"
      width="300px"
      borderRadius={6}
      border={`solid 1px ${theme.grayScale300}`}
      bg={theme.white}
      onClick={onClick}
      cursor="pointer"
      alignItems="flex-start"
      position="relative"
      overflow="hidden"
      minHeight={280}
    >
      <Box opacity={disabled ? 0.5 : 1}>
        <img
          src={imageSrc}
          alt={title}
          loading="lazy"
          style={{
            width: '100%',
            minHeight: '160px',
            objectFit: 'cover',
            display: 'block'
          }}
        />
      </Box>
      {!!noCodeEmbed && (
        <Box
          p="8"
          borderRadius="6px"
          boxShadow="0px 2px 10px 0px rgba(0, 0, 0, 0.25)"
          justifyContent="center"
          position="absolute"
          top="12px"
          right="12px"
          bg="white"
        >
          <Title noMargin fontSize={14} weight={500}>
            {t('No Code Embed')}
          </Title>
        </Box>
      )}

      <Flex
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
        alignItems="flex-start"
        p="18"
      >
        <Flex
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Title
            noMargin
            color={disabled ? theme.textTertiary : theme.textPrimary}
            fontSize={20}
            weight={600}
          >
            {title}
          </Title>

          {typeof description === 'string' ? (
            <SubTitle
              color={disabled ? theme.textTertiary : theme.textSecondary}
              fontSize={14}
              weight={500}
              css={css`
                text-transform: capitalize;
                align-items: center;
                display: flex;
              `}
            >
              {description}
            </SubTitle>
          ) : (
            description
          )}
        </Flex>
        {footerControls && footerControls}
      </Flex>
    </Flex>
  )
}
