import React from 'react'

import { Styles, Flex, Title } from '@src/common/components/BaseComponent'

interface Props extends Styles {
  label: string
  bg?: string
  color?: string
}

export const StatusTag: React.FC<Props> = ({
  label,
  bg = '#000',
  color = '#fff',
  ...rest
}): JSX.Element => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      color="white"
      fontSize="10px"
      padding="1px 8px"
      borderRadius="12px"
      pointerEvents="none"
      whiteSpace="nowrap"
      bg={bg}
      {...rest}
    >
      <Title noMargin fontSize={12} weight={500} color={color}>
        {label}
      </Title>
    </Flex>
  )
}
