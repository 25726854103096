import { PLAYLIST } from '@src/constants'
import {
  PLAYLIST_COLLECTION,
  TAB_CAROUSEL,
  TAB_DYNAMIC_CAROUSEL,
  TAB_FLOATING_BUTTON,
  TAB_FLOATING_VIDEO,
  TAB_GRID,
  TAB_HERO_UNIT,
  TAB_HORIZONTAL_CAROUSEL,
  TAB_HORIZONTAL_PLAYER,
  TAB_PLAYER_DECK,
  TAB_STORY_BLOCK
} from '@src/modules/channel/constants'
import { createHeroUnitHTML } from '@src/modules/channel/utils/createHeroUnitHTML'

const HOST = 'HOST_PLACEHOLDER'

export default function buildEmbedCode(settings: Record<string, any>): string {
  const { type, setting } = settings
  const {
    max_videos,
    loop,
    open_in,
    placement,
    vertical,
    horizontal,
    channel,
    context,
    id,
    player_placement,
    ui_button_color,
    ui_button_font_color,
    ui_border_style,
    category,
    country_code,
    customer_segment
  } = setting
  const embedCode = {
    [TAB_GRID]: `<script async type="text/javascript" src="//${HOST}/js/embed-feed.js"></script>
<fw-embed-feed
      channel="${channel}"
      ${
        context === PLAYLIST
          ? `playlist="${id}"`
          : context === PLAYLIST_COLLECTION
          ? `playlist_collection="${id}"`
          : ''
      }
      mode="grid"
      open_in="default"
      max_videos="${max_videos}"
      placement="middle"
      player_placement="${player_placement}" \
      ${
        category
          ? '\n\xa0\xa0\xa0\xa0\xa0\xa0cohort_category=' + category + ''
          : ''
      }\
      ${
        country_code
          ? '\n\xa0\xa0\xa0\xa0\xa0\xa0cohort_country_code=' + country_code + ''
          : ''
      }\
      ${
        customer_segment
          ? '\n\xa0\xa0\xa0\xa0\xa0\xa0cohort_segment=' + customer_segment + ''
          : ''
      }\
      ${
        ui_button_color
          ? `\n\xa0\xa0\xa0\xa0\xa0\xa0ui_button_color="${ui_button_color}"`
          : ''
      }\
      ${
        ui_button_font_color
          ? `\n\xa0\xa0\xa0\xa0\xa0\xa0ui_button_font_color="${ui_button_font_color}"`
          : ''
      }\
      ${
        ui_border_style
          ? `\n\xa0\xa0\xa0\xa0\xa0\xa0ui_border_style="${ui_border_style}"`
          : ''
      }\
    \n\xa0\xa0\xa0\xa0>
</fw-embed-feed>
    `,
    [TAB_CAROUSEL]: `<script async type="text/javascript" src="//${HOST}/js/embed-feed.js"></script>
<fw-embed-feed
      channel="${channel}"
      ${
        context === PLAYLIST
          ? `playlist="${id}"`
          : context === PLAYLIST_COLLECTION
          ? `playlist_collection="${id}"`
          : ''
      }
      mode="row"
      open_in="default"
      max_videos="0"
      placement="middle"
      player_placement="${player_placement}" \
      ${
        category
          ? '\n\xa0\xa0\xa0\xa0\xa0\xa0cohort_category=' + category + ''
          : ''
      }\
      ${
        country_code
          ? '\n\xa0\xa0\xa0\xa0\xa0\xa0cohort_country_code=' + country_code + ''
          : ''
      }\
      ${
        customer_segment
          ? '\n\xa0\xa0\xa0\xa0\xa0\xa0cohort_segment=' + customer_segment + ''
          : ''
      }\
      ${
        ui_button_color
          ? `\n\xa0\xa0\xa0\xa0\xa0\xa0ui_button_color="${ui_button_color}"`
          : ''
      }\
      ${
        ui_button_font_color
          ? `\n\xa0\xa0\xa0\xa0\xa0\xa0ui_button_font_color="${ui_button_font_color}"`
          : ''
      }\
      ${
        ui_border_style
          ? `\n\xa0\xa0\xa0\xa0\xa0\xa0ui_border_style="${ui_border_style}"`
          : ''
      }\
    \n\xa0\xa0\xa0\xa0>
</fw-embed-feed>
    `,
    [TAB_FLOATING_BUTTON]: `<script async src="//${HOST}/js/channel-button.js"></script>
<fw-channel-button
      channel="${channel}"
      ${
        context === PLAYLIST
          ? `playlist="${id}"`
          : context === PLAYLIST_COLLECTION
          ? `playlist_collection="${id}"`
          : ''
      }
      open_in="${open_in}"
      placement="${placement}"
      vertical="${vertical ?? ''}"
      horizontal="${horizontal ?? ''}"\
      ${
        category
          ? '\n\xa0\xa0\xa0\xa0\xa0\xa0cohort_category=' + category + ''
          : ''
      }\
      ${
        country_code
          ? '\n\xa0\xa0\xa0\xa0\xa0\xa0cohort_country_code=' + country_code + ''
          : ''
      }\
      ${
        customer_segment
          ? '\n\xa0\xa0\xa0\xa0\xa0\xa0cohort_segment=' + customer_segment + ''
          : ''
      }\
    \n\xa0\xa0\xa0\xa0>
      <a href="https://fw.tv/${channel}">${channel}'s Stories</a>
</fw-channel-button>
    `,
    [TAB_STORY_BLOCK]: `<script async src='//${HOST}/js/storyblock.js'></script>
<fw-storyblock
      channel="${channel}"${loop ? '' : '\nloop="false"'}
      ${
        context === PLAYLIST
          ? `playlist="${id}"`
          : context === PLAYLIST_COLLECTION
          ? `playlist_collection="${id}"`
          : ''
      }
      autoplay="true" \
      ${
        category
          ? '\n\xa0\xa0\xa0\xa0\xa0\xa0cohort_category=' + category + ''
          : ''
      }\
      ${
        country_code
          ? '\n\xa0\xa0\xa0\xa0\xa0\xa0cohort_country_code=' + country_code + ''
          : ''
      }\
      ${
        customer_segment
          ? '\n\xa0\xa0\xa0\xa0\xa0\xa0cohort_segment=' + customer_segment + ''
          : ''
      }\
    \n\xa0\xa0\xa0\xa0>
</fw-storyblock>
    `,
    [TAB_FLOATING_VIDEO]: `<script async src='//${HOST}/js/storyblock.js'></script>
<fw-storyblock
      channel="${channel}"${loop ? '' : '\nloop="false"'}
      ${
        context === PLAYLIST
          ? `playlist="${id}"`
          : context === PLAYLIST_COLLECTION
          ? `playlist_collection="${id}"`
          : ''
      }
      mode="pinned"
      autoplay="true" \
      ${
        category
          ? '\n\xa0\xa0\xa0\xa0\xa0\xa0cohort_category=' + category + ''
          : ''
      }\
      ${
        country_code
          ? '\n\xa0\xa0\xa0\xa0\xa0\xa0cohort_country_code=' + country_code + ''
          : ''
      }\
      ${
        customer_segment
          ? '\n\xa0\xa0\xa0\xa0\xa0\xa0cohort_segment=' + customer_segment + ''
          : ''
      }\
    \n\xa0\xa0\xa0\xa0>
</fw-storyblock>
    `,
    [TAB_HERO_UNIT]: createHeroUnitHTML({
      playlistId: id,
      channel,
      category,
      country_code,
      customer_segment
    }),
    [TAB_PLAYER_DECK]: `<script async src='//${HOST}/js/embed-feed.js'></script>
<fw-player-deck
        channel="${channel}"
        ${`playlist="${id}"`}
>
</fw-player-deck>`,
    [TAB_HORIZONTAL_PLAYER]: `<script async src='//${HOST}/js/embed-feed.js'></script>
<fw-player
      style="width:100%;
              aspect-ratio: 16 / 9 "
      channel="${channel}"
      ${`playlist="${id}"`}
      autoplay="true"
      player_captions="false"
>
</fw-player>`,
    [TAB_DYNAMIC_CAROUSEL]: `<script async src='//${HOST}/js/embed-feed.js'></script>
<fw-embed-feed
      channel="${channel}"
      ${`playlist="${id}"`}
      thumbnail_style="dynamic"
>
</fw-embed-feed>`,
    [TAB_HORIZONTAL_CAROUSEL]: `<script async src='//${HOST}/js/embed-feed.js'></script>
<fw-embed-feed
    style= "
              --fw-thumbnail-aspect-ratio: 16/9;
              --fw-thumbnail-width: auto;
              --fw-thumbnail-height: 240px;"
    channel="${channel}"
    ${`playlist="${id}"`}
>
</fw-embed-feed>`
  }

  return embedCode[type]
}
