import React from 'react'

import { languageRegions } from '@src/common/utils/languages'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'

export type LanguageRegionSelectProps = {
  value?: string
  onChange?: (value: string) => void
}
export const LanguageRegionSelect: React.FC<LanguageRegionSelectProps> = ({
  value = null,
  onChange
}) => {
  const { t } = useTranslation()

  return (
    <Select
      style={{ width: 222 }}
      value={value}
      onChange={onChange}
      showSearch
      placeholder={t('Select a language...')}
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '')
          .toLowerCase()
          .localeCompare((optionB?.label ?? '').toLowerCase())
      }
      options={
        languageRegions?.map((languageRegion) => ({
          value: languageRegion.key,
          label: languageRegion.value
        })) ?? null
      }
    />
  )
}
