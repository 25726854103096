import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import { useAppSelector } from '@src/app/hooks'
import { DiscardChangeModal } from '@src/common/components/DiscardChangeModal'
import { usePixelAmpTracking } from '@src/common/hooks'
import { useCustomNavigate } from '@src/common/hooks/useCustomNavigate'
import {
  fetchChannelAllVideos,
  fetchChannelCreatedVideos,
  fetchChannelPendingVideos,
  fetchChannelPlaylists,
  fetchChannelVideoCount
} from '@src/common/redux/channel'
import { getCaptionHashtags } from '@src/common/utils/caption'
import { TRACKING_EVENTS } from '@src/common/utils/tracking'
import { MAX_CAPTION_LENGTH } from '@src/constants'
import {
  BatchImporterEditMedia,
  BatchImporterMedia,
  BatchImporterMediaType,
  BatchImporterResult,
  ImporterSource,
  ImporterSourceFrom,
  TrackingDetails
} from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import { BatchImporterSelectMedia } from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterSelect/BatchImporterSelectMedia'
import {
  addAsyncImporterTasks,
  resetAsyncImporterTasks
} from '@src/modules/channel/redux/Library/Importer/asyncSourceImporter'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import BatchImporterCaption from './BatchImporterFillCaption/BatchImporterCaption'
import BatchImporterImporting from './BatchImporterImporting/BatchImporterImporting'
import BatchImporterLoading from './BatchImporterLoading/BatchImporterLoading'

export enum BatchImporterSteps {
  Loading,
  Select,
  FillCaption,
  SetPlaylist,
  Importing
}

type IProps = {
  businessId: string
  channelId: string
  steps: BatchImporterSteps[]
  mediaList: BatchImporterMedia[]
  // you have to set this parameter if you have the BatchImporterSteps.Loading step.
  loadingParams?: {
    title?: string
    subtitle?: string | ReactNode
    onStart: () => Promise<BatchImporterResult>
  }
  selectParams?: {
    title?: string
    subtitle?: string | ReactNode
    onLoadMore?: () => Promise<BatchImporterResult>
    hasMore?: boolean
    onLogout?: () => void
  }
  fillCaptionParams?: {
    title?: string
    subtitle?: string | ReactNode
  }
  addPlaylistParams?: {
    title?: string
    subtitle?: string | ReactNode
  }
  onBack?: () => void
  onClose: (done?: boolean) => void
  tracking: TrackingDetails
}

export const BatchImporterContainer: React.FC<IProps> = (props) => {
  const { pixelAmpTracking } = usePixelAmpTracking()
  const dispatch = useDispatch()
  const { navigateToHome } = useCustomNavigate()
  const {
    businessId,
    channelId,
    steps,
    mediaList,
    loadingParams,
    selectParams,
    fillCaptionParams,
    onBack,
    onClose,
    tracking
  } = props
  const { t } = useTranslation()
  const [curStep, setCurStep] = useState<BatchImporterSteps>(steps[0])
  const [selectedMediaList, setSelectedMediaList] = useState<
    BatchImporterMedia[]
  >([])
  const [editMediaList, setEditMediaList] = useState<BatchImporterEditMedia[]>(
    []
  )

  const [showDiscardModal, setShowDiscardModal] = useState<boolean>(false)

  const user = useAppSelector((state) => state.profile.user)

  useEffect(() => {
    if (!steps.includes(BatchImporterSteps.Select)) {
      setSelectedMediaList(mediaList)
    }
  }, [steps, mediaList])

  useEffect(() => {
    setEditMediaList(
      selectedMediaList?.map((item) => {
        const caption = Array.from(item.caption || '')
          .slice(0, MAX_CAPTION_LENGTH)
          .join('')

        return {
          ...item,
          editCaption: caption,
          editHashtags: [
            ...new Set(
              getCaptionHashtags(caption)
                .concat(item.hashtags || [])
                .slice(0, 10)
            )
          ],
          playlists: [],
          visibility: 'public',
          agreeTerms: true
        }
      })
    )
  }, [selectedMediaList, tracking.source])

  const handleClose = useCallback(() => {
    if (curStep === BatchImporterSteps.Importing) {
      dispatch(fetchChannelPendingVideos(businessId, channelId))
      dispatch(fetchChannelAllVideos(businessId, channelId))
      dispatch(fetchChannelCreatedVideos(businessId, channelId))
      dispatch(fetchChannelPlaylists(businessId, channelId))
      dispatch(fetchChannelVideoCount(businessId, channelId))
      onClose(true)
      navigateToHome({ businessId, channelId })
    } else {
      if (selectedMediaList.length > 0) {
        setShowDiscardModal(true)

        return
      }
      onClose(false)
    }
  }, [
    businessId,
    channelId,
    curStep,
    dispatch,
    navigateToHome,
    onClose,
    selectedMediaList.length
  ])

  const handleClickNextTracking = useCallback(
    (step: BatchImporterSteps) => {
      if (step === BatchImporterSteps.FillCaption) {
        pixelAmpTracking(
          TRACKING_EVENTS.BUSINESS_BATCH_IMPORTER_EVENT
            .CLICK_CAPTION_EDITING_NEXT_BUTTON,
          {
            source: tracking.source,
            import_number: editMediaList.length || 0,
            customize_captions:
              editMediaList.findIndex((item) => {
                return item.editCaption !== item.caption
              }) >= 0,
            customize_hashtag:
              editMediaList.findIndex((item) => {
                return (item.editHashtags?.length || 0) > 0
              }) >= 0
          }
        )
      } else if (step === BatchImporterSteps.SetPlaylist) {
        pixelAmpTracking(
          TRACKING_EVENTS.BUSINESS_BATCH_IMPORTER_EVENT
            .CLICK_PLAYLIST_EDITING_NEXT_BUTTON,
          {
            source: tracking.source,
            import_number: editMediaList.length || 0,
            customize_playlist: !!editMediaList?.[0]?.playlists?.length,
            repostable: true,
            visibility: !!editMediaList?.[0]?.visibility
          }
        )
      }
    },
    [editMediaList, pixelAmpTracking, tracking.source]
  )

  const handleViewTracking = useCallback(() => {
    const step = curStep
    if (step === BatchImporterSteps.Select) {
      pixelAmpTracking(
        TRACKING_EVENTS.BUSINESS_BATCH_IMPORTER_EVENT.VIEW_CHOOSE_VIDEOS_PAGE,
        {
          source: tracking.source
        }
      )
    } else if (step === BatchImporterSteps.FillCaption) {
      pixelAmpTracking(
        TRACKING_EVENTS.BUSINESS_BATCH_IMPORTER_EVENT.VIEW_CAPTION_EDITING_PAGE,
        {
          source: tracking.source,
          import_number: editMediaList.length || 0
        }
      )
    } else if (step === BatchImporterSteps.SetPlaylist) {
      pixelAmpTracking(
        TRACKING_EVENTS.BUSINESS_BATCH_IMPORTER_EVENT
          .VIEW_PLAYLIST_EDITING_PAGE,
        {
          source: tracking.source,
          import_number: editMediaList.length || 0
        }
      )
    } else if (step === BatchImporterSteps.Importing) {
      pixelAmpTracking(
        TRACKING_EVENTS.BUSINESS_BATCH_IMPORTER_EVENT.VIEW_IMPORTING_PAGE,
        {
          source: tracking.source,
          import_number: editMediaList.length || 0,
          customize_captions:
            editMediaList.findIndex((item) => {
              return item.editCaption !== item.caption
            }) >= 0,
          customize_hashtag:
            editMediaList.findIndex((item) => {
              return (item.editHashtags?.length || 0) > 0
            }) >= 0,
          customize_playlist: !!editMediaList?.[0]?.playlists?.length,
          repostable: true,
          visibility: !!editMediaList?.[0]?.visibility
        }
      )

      editMediaList?.forEach((item) => {
        if (item.mediaType === BatchImporterMediaType.IMAGE) {
          pixelAmpTracking(
            TRACKING_EVENTS.BUSINESS_BATCH_IMPORTER_EVENT.DO_IMAGE_TO_VIDEO,
            {
              source: tracking.source,
              customize_captions: item.editCaption !== item.caption,
              customize_hashtag: (item.editHashtags?.length || 0) > 0,
              customize_playlist: !!editMediaList?.[0]?.playlists?.length,
              repostable: true,
              visibility: !!editMediaList?.[0]?.visibility
            }
          )
        }
      })
    }
    // only trigger when the curStep changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curStep])

  useEffect(() => {
    handleViewTracking()
  }, [handleViewTracking])

  const syncSelectedMediaWithEditMedia = useCallback(() => {
    const updatedSelectedMediaList = editMediaList.reduce((acc, curr) => {
      const selectedMedia = selectedMediaList.find((item) => {
        return item.id === curr.id
      })
      if (selectedMedia) {
        acc.push({
          ...selectedMedia,
          caption: curr.editCaption,
          hashtags: curr.editHashtags,
          playlists: curr.playlists,
          visibility: curr.visibility
        })
      }

      return acc
    }, [])
    setSelectedMediaList(updatedSelectedMediaList)
  }, [editMediaList, selectedMediaList])

  const handleNextButtonClick = useCallback(() => {
    // async flow
    if (
      tracking?.sourceFrom === ImporterSourceFrom.YouTube &&
      curStep === BatchImporterSteps.FillCaption
    ) {
      dispatch(
        addAsyncImporterTasks({
          businessId,
          channelId,
          userId: user?.encoded_id,
          mediaList: editMediaList
        })
      )
      onClose(true)
      navigateToHome({ businessId, channelId })

      return
    } else if (
      tracking?.source === ImporterSource.ASYNC_MODAL &&
      curStep === BatchImporterSteps.FillCaption
    ) {
      dispatch(
        resetAsyncImporterTasks({
          businessId,
          channelId,
          userId: user?.encoded_id,
          mediaList: editMediaList
        })
      )
      onClose(true)
      navigateToHome({ businessId, channelId })

      return
    }

    const curStepIndex = steps.findIndex((item) => {
      return item === curStep
    })
    if (curStepIndex === steps.length - 1) {
      // last step
      return
    }

    const nextStep = steps[curStepIndex + 1]

    handleClickNextTracking(curStep)
    setCurStep(nextStep)
  }, [
    curStep,
    handleClickNextTracking,
    steps,
    dispatch,
    businessId,
    channelId,
    user,
    editMediaList,
    navigateToHome,
    onClose,
    tracking
  ])

  const handleBackButtonClick = useCallback(() => {
    const curStepIndex = steps.findIndex((item) => {
      return item === curStep
    })

    if (curStepIndex === 0) {
      // first step
      if (onBack) {
        onBack()
      }

      return
    }

    // If current step is FillCaption, and there are changes between editMediaList and selectedMediaList, update selectedMediaList to reflect the changes before going back
    if (curStepIndex === BatchImporterSteps.FillCaption) {
      syncSelectedMediaWithEditMedia()
    }

    const preStep = steps[curStepIndex - 1]
    setCurStep(preStep)
  }, [curStep, onBack, steps, syncSelectedMediaWithEditMedia])

  const title = useMemo(() => {
    const sourceFrom = tracking?.sourceFrom
    if (sourceFrom === ImporterSourceFrom.Tiktok) {
      return `Tiktok ${t('Importer')}`
    } else if (sourceFrom === ImporterSourceFrom.Instagram) {
      return `Instagram ${t('Importer')}`
    } else {
      return t('Upload Videos')
    }
  }, [t, tracking])

  return (
    <>
      {curStep === BatchImporterSteps.Loading && (
        <BatchImporterLoading
          title={loadingParams?.title || title}
          subtitle={loadingParams?.subtitle}
          onClose={handleClose}
          onStart={loadingParams.onStart}
          onSuccess={() => {
            handleNextButtonClick()
          }}
        />
      )}
      {curStep === BatchImporterSteps.Select && (
        <BatchImporterSelectMedia
          businessId={businessId}
          channelId={channelId}
          title={selectParams?.title || title}
          subtitle={selectParams?.subtitle}
          onClose={handleClose}
          mediaList={mediaList}
          selectedMediaList={selectedMediaList}
          onChangeSelectedMediaList={setSelectedMediaList}
          onLoadMore={selectParams.onLoadMore}
          hasMore={selectParams.hasMore}
          onNextButtonClick={handleNextButtonClick}
          username={tracking.creationDetails.creator_name}
          onLogout={() => {
            pixelAmpTracking(
              TRACKING_EVENTS.BUSINESS_BATCH_IMPORTER_EVENT.CLICK_LOGOUT_BUTTON,
              {
                source: tracking.source
              }
            )

            if (selectParams.onLogout) {
              selectParams.onLogout()
            }
          }}
          tracking={tracking}
        />
      )}
      {curStep === BatchImporterSteps.FillCaption && (
        <BatchImporterCaption
          title={fillCaptionParams?.title || title}
          onClose={handleClose}
          mediaList={editMediaList}
          onChangeMediaList={setEditMediaList}
          onBackButtonClick={handleBackButtonClick}
          onNextButtonClick={handleNextButtonClick}
          tracking={tracking}
        />
      )}
      {curStep === BatchImporterSteps.Importing && (
        <BatchImporterImporting
          title={title}
          onClose={handleClose}
          mediaList={editMediaList}
          tracking={tracking}
        />
      )}
      {showDiscardModal && (
        <DiscardChangeModal
          showDiscardModal={showDiscardModal}
          setShowDiscardModal={setShowDiscardModal}
          onClose={() => {
            pixelAmpTracking(
              TRACKING_EVENTS.BUSINESS_SOURCE_IMPORTER_EVENT
                .CLICK_CHOOSE_PAGE_BACK_BUTTON
            )
            onClose(false)
          }}
        />
      )}
    </>
  )
}
