import React from 'react'

import { Flex } from '@src/common/components/BaseComponent'
import { SubTitle, Title } from '@src/common/components/BaseComponent'

import { Container } from './Banner.styles'

interface BannerProps {
  title: string
  description?: string
  action: React.ReactNode
  disabled?: boolean
}

export const Banner: React.FC<BannerProps> = ({
  title,
  description,
  action,
  disabled
}) => {
  return (
    <Container disabled={disabled}>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
        alignSelf="stretch"
      >
        <Title color="#27292C" fontSize={20} weight={600} noMargin>
          {title}
        </Title>
        {!!description && (
          <SubTitle color="#3F4145" fontSize={14} weight={500} noMargin>
            {description}
          </SubTitle>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="center">
        {action}
      </Flex>
    </Container>
  )
}
