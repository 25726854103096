import React from 'react'

import { useAppSelector } from '@src/app/hooks'
import {
  isPLGBusiness,
  shouldAskForReview
} from '@src/common/redux/selectors/subscription'
import { localStorageProvider } from '@src/common/utils/storageProvider'

import ReviewModalV2 from './ReviewModalV2'

type ReviewModalContainerProps = {
  businessId: string
}

const localStorageKey = 'fw_review_timestamp'

export const ReviewModalContainer: React.FC<ReviewModalContainerProps> = ({
  businessId
}) => {
  // Get the subscription start date for the business
  const showReviewModal = useAppSelector((state) =>
    shouldAskForReview(state, businessId)
  )

  const isPLGCustomer = useAppSelector((state) =>
    isPLGBusiness(state, businessId)
  )

  const alreadyReviewed = localStorageProvider.getItem(localStorageKey)

  const saveReviewTimestamp = () => {
    localStorageProvider.setItem(localStorageKey, new Date().toISOString())
  }

  if (!isPLGCustomer || alreadyReviewed) {
    return null
  }

  return (
    <ReviewModalV2
      open={!alreadyReviewed && showReviewModal && isPLGCustomer}
      onClose={saveReviewTimestamp}
    />
  )
}
