import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const Integrations = loadable(() => import('../pages/Integrations'), {
  resolveComponent: (component) => component.Integrations
})

const Embed = loadable(() => import('../pages/Embed'), {
  resolveComponent: (component) => component.EmbedPage
})

const WPRedirect = loadable(() => import('../pages/WPRedirect'))

export const Routes = (
  <>
    <Route path="/embed" element={<Embed />} />
    <Route path="/integration" element={<Integrations />} />
    <Route path="/wp_callback" element={<WPRedirect />} />
  </>
)

export const UnauthenticatedRoutes = (
  <>
    <Route path="/integration" element={<Integrations />} />
    <Route path="/wp_callback" element={<WPRedirect />} />
  </>
)
