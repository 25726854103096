import React from 'react'

import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { css } from '@emotion/core'

import { useSpace } from '../../hooks'
import FWButton from './FWButton/FWButton'

type IProps = {
  paging?: Record<string, any>
  space?: string
  onClickPrev?: () => void
  onClickNext?: () => void
  buttonCss?: any
}

const Pager: React.FC<IProps> = ({
  paging = {},
  space = 'small',
  onClickPrev,
  onClickNext,
  buttonCss
}) => {
  const spacing = useSpace(space)

  if (!Object.keys(paging).length) {
    return null
  }

  return (
    <div
      css={css`
        > *:not(:last-child) {
          margin-right: ${spacing};
        }
      `}
    >
      <FWButton
        icon={<LeftOutlined />}
        onClick={onClickPrev}
        disabled={!paging.prev}
        css={buttonCss}
      />
      <FWButton
        icon={<RightOutlined />}
        onClick={onClickNext}
        disabled={!paging.next}
        css={buttonCss}
      />
    </div>
  )
}

export default Pager
