import { useCallback } from 'react'

import { useAppDispatch } from '@src/app/hooks'
import { fetchBusinessStores } from '@src/common/redux/businessStore'

export default function useFetchAllBusinessStores(): {
  fetchAllBusinessStores: (businessId: string, page?: string) => Promise<void>
} {
  const dispatch = useAppDispatch()

  const fetchAllBusinessStores = useCallback(
    async (businessId: string, page = null) => {
      const response = await dispatch(
        fetchBusinessStores(businessId, page, 100)
      )
      const paging = response.data?.paging
      if (paging && paging.next) {
        await fetchAllBusinessStores(businessId, paging.next)
      }
    },
    [dispatch]
  )

  return {
    fetchAllBusinessStores
  }
}
