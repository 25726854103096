import React from 'react'

import Title from '../Title'
import { Container } from './LinkButton.styles'

interface IProps {
  title?: string
  onClick?: () => void
}

export const LinkButton: React.FC<IProps> = (props) => {
  const { title, onClick } = props

  return (
    <Container
      onClick={(e) => {
        e.stopPropagation()
        if (onClick) {
          onClick()
        }
      }}
    >
      <Title fontSize={14} weight={500} noMargin color="#006AFF">
        {title}
      </Title>
    </Container>
  )
}
