import React, { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@src/app/hooks'
import { Flex } from '@src/common/components/BaseComponent'
import { Title } from '@src/common/components/BaseComponent'
import { Select } from '@src/common/components/Select/Select'
import { useStoreData } from '@src/common/hooks/useStoreData'
import { setSelectedStoreId } from '@src/modules/channel/redux/YourSite'
import { useTranslation } from 'react-i18next'

interface StoreSelectorProps {
  businessId: string
  storeFilterCondition?: (store: globalLib.BusinessStore) => boolean
}

export const StoreSelector: React.FC<StoreSelectorProps> = ({
  businessId,
  storeFilterCondition = () => true
}) => {
  const {
    getStoreList,
    getStoreCount: storeCount,
    getShopifyStoreCount: shopifyStoreCount
  } = useStoreData({
    businessId
  })
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const storeList = getStoreList()

  const selectedStoreId = useAppSelector(
    (state) => state.yourSite.selectedStoreIdByBusinessId[businessId]
  )

  // Set the first store as selected if there is only one store
  useEffect(() => {
    if (storeCount === 1) {
      const store = storeList[0]
      dispatch(setSelectedStoreId({ businessId, storeId: store?.id }))
    }
  }, [storeCount, storeList, dispatch, businessId])

  const handleStoreChange = (storeId: string) => {
    dispatch(setSelectedStoreId({ businessId, storeId }))
  }

  if (!storeList.length || !shopifyStoreCount) {
    return null
  }

  if (storeCount === 1) {
    return (
      <Title weight={600} fontSize={25}>
        {t('Your Site {{storeName}}', { storeName: storeList[0].name })}
      </Title>
    )
  }

  return (
    <Flex justifyContent="flex-start" gap="8" alignItems="center" width="100%">
      <Title weight={600} fontSize={25}>
        {t('Your Site')}
      </Title>
      <Select
        showSearch
        allowClear={false}
        optionFilterProp="label"
        defaultValue={selectedStoreId}
        value={selectedStoreId}
        onChange={(value) => handleStoreChange(value)}
        style={{ width: '100%', flex: '1 1 0' }}
        options={
          storeList.filter(storeFilterCondition).map((item) => {
            return {
              label: item.name,
              value: item.id
            }
          }) || []
        }
      />
    </Flex>
  )
}
