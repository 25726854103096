import React from 'react'

import { API_URL, PIXEL_URL } from '@src/common/utils/api'
import { formChannelUrl } from '@src/common/utils/formChannelUrl'

const livestreamConnectionOverlay =
  process.env.REACT_APP_ENV === 'production' ? 'true' : 'false'

const FWStoryblockBase: React.FC<IAnyWidgetParams> = ({
  channel,
  channel_id,
  video,
  max_videos = 1,
  ...rest
}) => {
  return (
    <fw-storyblock
      api_host={API_URL}
      api_host_tracking={PIXEL_URL}
      autoplay="true"
      channel={channel}
      channel_id={channel_id}
      loop="true"
      max_videos={max_videos}
      share_template={`${formChannelUrl(
        channel
      )}?utm_source=business.firework.com&utm_medium=share`}
      video={video}
      livestream_connection_overlay={livestreamConnectionOverlay}
      width="100%"
      {...rest}
    />
  )
}

export const FWStoryblock = React.memo(FWStoryblockBase)
