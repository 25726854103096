import React, { useContext, useLayoutEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@src/app/hooks'
import { BusinessContext } from '@src/common/contexts/BusinessContext'
import { useNabooFeatureFlag } from '@src/common/hooks'
import { useFeatureEnablement } from '@src/common/hooks/useFeatureEnablement'
import { useHandleOpenCommandPalette } from '@src/modules/search/hooks/useHandleOpenCommandPallet'
import {
  setCmdBarIsClosed,
  setCmdBarIsOpen
} from '@src/modules/search/redux/search'
import CommandPalette, {
  filterItems,
  getItemIndex,
  IconName,
  JsonStructureItem
} from 'react-cmdk'
import { useLocation, useNavigate } from 'react-router-dom'

enum CommandPalettePage {
  ROOT = 'root'
}

export default function CommandBar(): JSX.Element {
  const dispatch = useAppDispatch()
  const businessId = useContext(BusinessContext)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [search, setSearch] = React.useState('')
  const [page] = React.useState<CommandPalettePage>(CommandPalettePage.ROOT)
  const [navigated, setNavigated] = React.useState(false)

  const nff = useNabooFeatureFlag()
  const featureEnablement = useFeatureEnablement(businessId)
  const hasWebhooks = nff.cms_business_webhooks

  const channelId = /^\/business/.test(pathname) && pathname.split('/')[4]

  const isOpen = useAppSelector((state) => state.search.cmdBarIsOpen)

  useHandleOpenCommandPalette()

  const createItem = (
    id: string,
    children: string,
    icon: React.FC | IconName,
    path: string
  ) => ({
    id,
    children,
    icon,
    showType: false,
    closeOnSelect: true,
    onClick: () => {
      clearResults()
      setNavigated(true)
      navigate(path)
    }
  })

  const baseNavigationItems = [
    createItem('pages.home', 'Home', 'HomeIcon', '/'),
    createItem(
      'pages.teamMembers',
      'Team Members',
      'UserGroupIcon',
      `/business/${businessId}/settings/team-members`
    ),
    createItem(
      'pages.channelSearch',
      'Channel Search',
      'MagnifyingGlassIcon',
      `/business/${businessId}/channels`
    ),
    createItem(
      'pages.storeSearch',
      'Store Search',
      'MagnifyingGlassIcon',
      `/business/${businessId}/business_store_list`
    ),
    createItem(
      'pages.businessSearch',
      'Business Search',
      'MagnifyingGlassIcon',
      `/businesses`
    )
  ]

  const NavigationItems: JsonStructureItem[] = [...baseNavigationItems]

  const commandItems = [
    { heading: 'Navigation', id: 'pages', items: NavigationItems }
  ]

  if (channelId) {
    if (featureEnablement.short_videos) {
      NavigationItems.push(
        createItem(
          'pages.uploadVideo',
          'Upload Video',
          'ArrowUpTrayIcon',
          `/business/${businessId}/channel/${channelId}/video/create`
        )
      )
    }

    commandItems.push({
      heading: 'Automation',
      id: 'automator',
      items: [
        createItem(
          'automator.manage',
          'Manage Automations',
          'BoltIcon',
          `/business/${businessId}/channel/${channelId}/automation`
        ),
        createItem(
          'automator.creates',
          'Create Automation',
          'BoltIcon',
          `/business/${businessId}/channel/${channelId}/automation/create`
        )
      ]
    })
  }

  if (hasWebhooks) {
    commandItems.push({
      heading: 'Webhooks',
      id: 'webhooks',
      items: [
        createItem(
          'webhooks.manage',
          'Manage Webhooks',
          'LinkIcon',
          `/business/${businessId}/webhooksv2`
        ),
        createItem(
          'webhooks.creates',
          'Create Webhooks',
          'LinkIcon',
          `/business/${businessId}/webhooksv2/create`
        )
      ]
    })
  }

  const clearResults = () => {
    dispatch(setCmdBarIsClosed())
    setSearch('')
  }

  const rootItems = filterItems(commandItems, search)

  useLayoutEffect(() => {
    const observerCallback = (mutationsList, observer) => {
      let foundInputElement = false
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const inputElement = document.getElementById(
            'command-palette-search-input'
          ) as HTMLInputElement | null
          if (inputElement) {
            inputElement.autocomplete = 'off'
            observer.disconnect()
            foundInputElement = true
          }
          if (!foundInputElement && navigated) {
            const antInputElement = document.querySelector(
              '.ant-input'
            ) as HTMLInputElement | null
            if (antInputElement) {
              antInputElement.focus()
              setNavigated(false)
            }
          }
        }
      }
    }

    const observer = new MutationObserver(observerCallback)
    const config = { childList: true, subtree: true }
    const targetNode = document.body
    observer.observe(targetNode, config)

    return () => {
      observer.disconnect()
    }
  }, [navigated])

  const handleOnChangeOpen = () => {
    if (!isOpen) {
      dispatch(setCmdBarIsOpen())
    } else {
      dispatch(setCmdBarIsClosed())
    }
  }

  return (
    <>
      <CommandPalette
        search={search}
        isOpen={isOpen}
        onChangeOpen={handleOnChangeOpen}
        onChangeSearch={setSearch}
        page={page}
      >
        <CommandPalette.Page id={CommandPalettePage.ROOT}>
          {rootItems.length
            ? rootItems.map((list) => (
                <CommandPalette.List key={list.id} heading={list.heading}>
                  {list.items.map(({ id, ...rest }) => (
                    <CommandPalette.ListItem
                      key={id}
                      index={getItemIndex(rootItems, id)}
                      closeOnSelect={true}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          dispatch(setCmdBarIsClosed())
                        }
                      }}
                      {...rest}
                    />
                  ))}
                </CommandPalette.List>
              ))
            : null}
        </CommandPalette.Page>
      </CommandPalette>
    </>
  )
}
