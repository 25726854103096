import React from 'react'

import { Routes as AppRoutes } from '@src/app/routes'
import { Routes as AutomationRoutes } from '@src/modules/automation/routes'
import { AvaRoutes } from '@src/modules/ava/routes'
import { Routes as BusinessAppRoutes } from '@src/modules/business-app/routes'
import { Routes as ChannelRoutes } from '@src/modules/channel/routes'
import { Routes as IntegrationRoutes } from '@src/modules/integration/routes'
import { Routes as InvitationRoutes } from '@src/modules/invitation/routes'
import { Routes as OAuthAppRoutes } from '@src/modules/oauth-app/routes'
import { OneToOneRoutes } from '@src/modules/one-to-one/routes'
import { Routes as PricingPlanRoutes } from '@src/modules/pricing-plan/routes'
import { Routes as ProfileRoutes } from '@src/modules/profile/routes'
import { Routes as SettingRoutes } from '@src/modules/settings/routes'
import { Routes as StoreRoutes } from '@src/modules/store/routes'
import { Routes as SupportRoutes } from '@src/modules/support/routes'
import { Routes as WebhookRoutes } from '@src/modules/webhook/routes'
import { ErrorBoundary } from 'react-error-boundary'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route
} from 'react-router-dom'

import Root from '../layouts/Root'
import FallbackComponent from './FallbackComponent'

export default function createRouter() {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route
        element={
          <ErrorBoundary FallbackComponent={FallbackComponent}>
            <Root />
          </ErrorBoundary>
        }
      >
        <Route path="/overview" element={<Navigate to="/" />} />
        <Route
          path="/business/:businessId/overview"
          element={<Navigate to="/" />}
        />
        <Route path="/advertiser" element={<Navigate to="/" />} />
        <Route path="/creator" element={<Navigate to="/" />} />
        <Route path="/publisher" element={<Navigate to="/" />} />
        <Route path="/pricing" element={<Navigate to="/" />} />
        <Route path="/layout_studio" element={<Navigate to="/" />} />
        {OneToOneRoutes}
        {AvaRoutes}
        {ChannelRoutes}
        {StoreRoutes}
        {OAuthAppRoutes}
        {BusinessAppRoutes}
        {PricingPlanRoutes}
        {SettingRoutes}
        {ProfileRoutes}
        {InvitationRoutes}
        {IntegrationRoutes}
        {SupportRoutes}
        {WebhookRoutes}
        {AutomationRoutes}
        {AppRoutes}
      </Route>
    )
  )
}
