interface Props {
  isAdblockEnabled?: boolean
  AdBlockText?: string
  playlistId?: string
  videoId?: string
  channel: string
  category?: string
  country_code?: string
  customer_segment?: string
  isPublisher?: boolean
}

export const createHeroUnitHTML = ({
  isAdblockEnabled,
  AdBlockText,
  playlistId,
  videoId,
  channel,
  customer_segment,
  country_code,
  category,
  isPublisher
}: Props): string => {
  const head = isPublisher
    ? `<script async src='//${process.env.REACT_APP_PUBLISHER_EMBED_CDN_HOST}/js/fwn.js'></script>`
    : `<script async src='//${process.env.REACT_APP_EMBED_CDN_HOST}/js/fwn.js'></script>`

  const AdBlockSection = `
  <a href="https://fw-tv.github.io/allowlist/" target="_blank">${AdBlockText}</a>
`

  return `<!-- This belongs to <head/> -->
${head}

<!-- This belongs to <body/> -->
<fw-herounit
  channel="${channel}"
${
  playlistId
    ? `  playlist="${playlistId}"`
    : videoId
    ? `  video="${videoId}"`
    : ''
}${category ? `\n  cohort_category=${category}` : ''}${
    country_code ? `\n  cohort_country_code=${country_code}` : ''
  }${customer_segment ? `\n  cohort_segment=${customer_segment}` : ''}
>${isAdblockEnabled ? AdBlockSection : ''}</fw-herounit>`
}
