import { useCallback } from 'react'

export default function useFacebookTracking(): {
  fbTrack: Window['fbq']
} {
  const fbTrack = useCallback((event, eventName) => {
    const fbq = window?.fbq
    if (typeof fbq === 'function') fbq(event, eventName)
  }, [])

  return {
    fbTrack
  }
}
