import React, { FC, useEffect, useState } from 'react'

import { css } from '@emotion/core'
import { useAppSelector } from '@src/app/hooks'
import { Box, Flex } from '@src/common/components/BaseComponent'
import { FWButton } from '@src/common/components/BaseComponent'
import { useBusinessData } from '@src/common/hooks/useBusinessData'
import { openExternalUrl } from '@src/common/utils/routing'
import { url } from '@src/config'
import CopiedModal from '@src/modules/channel/components/EmbedVideoModal/CopiedModal'
import { Input, Modal } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

type CopyCodeLineProps = {
  onCopy: () => void
  tab: string
}

const CopyCodeLine: FC<CopyCodeLineProps> = ({ onCopy, tab }) => {
  const { t } = useTranslation()
  const { businessId } = useParams()
  const embedCode = useAppSelector((state) => state.embed.offlineEmbedCode[tab])
  const [pubEmbedCode, setPubEmbedCode] = useState(embedCode)
  const { isPublisher } = useBusinessData({ businessId })

  useEffect(() => {
    if (embedCode) {
      setPubEmbedCode(
        embedCode.replace(
          /HOST_PLACEHOLDER/g,
          isPublisher
            ? process.env.REACT_APP_PUBLISHER_EMBED_CDN_HOST
            : process.env.REACT_APP_EMBED_CDN_HOST
        )
      )
    }
  }, [isPublisher, embedCode])
  const handleCopyCode = () => {
    onCopy()
    Modal.info({
      width: 484,
      centered: true,
      icon: null,
      content: (
        <CopiedModal
          type="wordpress"
          onHelpBtnClick={() => openExternalUrl(url.helpCenterEmbedTopic)}
        />
      )
    })
  }

  return (
    <Flex justifyContent="flex-start">
      <Box
        css={css`
          textarea.ant-input {
            width: 300px;
            min-height: 36px;
          }
          @media (max-width: 576px) {
            textarea.ant-input {
              width: 100%;
            }
          }
        `}
      >
        <Input.TextArea rows={5} value={pubEmbedCode} />
      </Box>
      <Flex
        ml="10"
        flexDirection="row"
        justifyContent="space-between"
        css={css`
          .ant-btn-primary {
            height: 36px;
            font-size: 16px;
            font-weight: 700;
          }
        `}
      >
        <CopyToClipboard text={pubEmbedCode} onCopy={handleCopyCode}>
          <FWButton type="primary">{t('Copy code')}</FWButton>
        </CopyToClipboard>
      </Flex>
    </Flex>
  )
}

export default CopyCodeLine
