import React from 'react'

import { UserOutlined, CameraFilled } from '@ant-design/icons'
import { css } from '@emotion/core'
import { Box } from '@src/common/components/BaseComponent'
import { Upload, Progress, Avatar as AntdAvatar } from 'antd'

import { isSmScreen } from '../../hooks'

const ACCEPTED_IMAGE_TYPES = 'image/*'

type AvatarIProps = {
  src: string
  uploadProgress: number
  onFileSelected: (file: Blob | string) => void
  disabled?: boolean
  noShadow?: boolean
  onAvatarClick?: () => void
  allowUploading?: boolean
}

const Wrapper = ({ children, ...props }: { children: JSX.Element }) => (
  <Box {...props}>{children}</Box>
)

const Icon = ({
  src,
  disabled,
  noShadow
}: {
  src: string
  disabled?: boolean
  noShadow?: boolean
}): JSX.Element => (
  <AntdAvatar
    size={isSmScreen() ? 80 : 60}
    icon={<UserOutlined />}
    src={src}
    css={css`
      border: 1px solid #ffffff;
      box-shadow: ${noShadow ? 'unset' : '0 0 10px 1px rgba(0, 21, 41, 0.3)'};
      cursor: ${disabled ? 'default' : 'pointer'};
      z-index: 1;
    `}
  />
)

const HoverIcon = () => (
  <AntdAvatar
    size={isSmScreen() ? 80 : 60}
    icon={<CameraFilled />}
    css={css`
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0;
      z-index: 2;
      :hover {
        opacity: 0.65;
      }
    `}
  />
)

const ProgressBar = ({ progress }: { progress: number }): JSX.Element => (
  <Progress
    type="circle"
    width={isSmScreen() ? 82 : 62}
    percent={progress}
    showInfo={false}
    css={css`
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 3;
    `}
  />
)

export default function Avatar({
  src,
  uploadProgress,
  onFileSelected,
  disabled,
  noShadow,
  onAvatarClick,
  allowUploading = true,
  ...props
}: AvatarIProps): JSX.Element {
  if (disabled) {
    return (
      <Wrapper {...props}>
        <Icon src={src} noShadow={noShadow} disabled={disabled} />
      </Wrapper>
    )
  }

  return allowUploading ? (
    <Wrapper {...props}>
      <Upload
        accept={ACCEPTED_IMAGE_TYPES}
        showUploadList={false}
        customRequest={({ file }) => onFileSelected(file)}
      >
        <Box position="relative">
          <Icon src={src} noShadow={noShadow} disabled={disabled} />
          <HoverIcon />
          {uploadProgress > 0 && uploadProgress < 100 && (
            <ProgressBar progress={uploadProgress} />
          )}
        </Box>
      </Upload>
    </Wrapper>
  ) : (
    <Box onClick={onAvatarClick}>
      <Icon src={src} />
    </Box>
  )
}
