import React from 'react'

import { css } from '@emotion/react'
import { useAppSelector } from '@src/app/hooks'
import { Box, Flex } from '@src/common/components/BaseComponent'
import { usePixelAmpTracking } from '@src/common/hooks'
import { TRACKING_EVENTS } from '@src/common/utils/tracking'
import CopyCodeLine from '@src/modules/channel/components/DynamicContent/EmbedModal/EmbedChannelModalInstruction/CopyCodeLine'
import { DCS } from '@src/modules/channel/constants'
import { Checkbox, Col, Row, Typography } from 'antd'
import Link from 'antd/lib/typography/Link'
import { useTranslation } from 'react-i18next'

type IProps = {
  tab: string
  data?: {
    steps: any[]
    detailLink?: string
  }
  context?: string | null
  dcsParameterOptions?: any[]
  updateDCSParameterOptions?: any
}

export const EmbedChannelModalInstruction: React.FC<IProps> = ({
  data,
  tab,
  context = null,
  dcsParameterOptions,
  updateDCSParameterOptions
}) => {
  const { steps, detailLink } = data
  const { t } = useTranslation()
  const { pixelAmpTracking } = usePixelAmpTracking()
  const embedType = useAppSelector((state) => state.embed.embedType)
  const onCopy = () => {
    pixelAmpTracking(TRACKING_EVENTS.BUSINESS_CLICK_COPY_CODE, {
      embed_code_param: {
        trigger_context: context || 'onboarding',
        embed_type: embedType,
        dcs_hydration_categories: dcsParameterOptions
      }
    })

    return null
  }

  const codeStep = context === DCS ? 2 : 1
  const showParameterStep = context === DCS

  return (
    <Flex flexDirection="column" gap="10">
      {steps.map((step, index) => (
        <Box key={index}>
          <Box>
            <Typography.Text
              css={css`
                display: inline;
              `}
              strong
            >
              {step[0]}
            </Typography.Text>
            <Typography.Text
              css={css`
                display: inline;
              `}
              strong
            >
              &nbsp;{step[1]}
            </Typography.Text>
          </Box>
          {index === codeStep && (
            <Box mt="xxsmall">
              <CopyCodeLine onCopy={onCopy} tab={tab} />
            </Box>
          )}
          {showParameterStep && index === 1 ? (
            <Flex justifyContent="flex-start" py="small">
              <Checkbox.Group
                style={{ width: '100%' }}
                value={dcsParameterOptions}
                onChange={updateDCSParameterOptions}
              >
                <Row>
                  <Col span={8}>
                    <Checkbox value="category">{t('Category')}</Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="country_code">
                      {t('Country Code')}
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="customer_segment">
                      {t('Customer Segment')}
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Flex>
          ) : null}
        </Box>
      ))}
      {detailLink && (
        <Link href={detailLink} target="_blank">
          {t('For more detailed instructions click here')}
        </Link>
      )}
    </Flex>
  )
}
