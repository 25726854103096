import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Typography } from 'antd'

export const ActivationStepLink = styled(Typography.Text)<{
  fill?: string
  opacity?: string
}>`
  cursor: pointer;
  color: inherit;
  text-decoration: underline;
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 0px;
  width: 100%;
  height: 100%;

  /* Elevation */
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  /* display: none for mobile devices */
  @media (max-width: 768px) {
    display: none;
  }

  position: relative;
`

export const CardHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0 24px 16px 24px;
  font-size: 18px;
  color: #0060e5;
`
export const MinimizedCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 48px;
  padding: 12px 24px 12px 24px;
  background-color: #0050bf;
  border-radius: 12px 12px 0px 0px;
`

export const StepList = styled.ol`
  padding: 0 24px;
  margin-bottom: 22px;
  list-style-type: decimal;

  li {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 0 5px;
    margin-bottom: 16px;
  }
`

const BorderAnimation = keyframes`
 0% {transform: translate(50%, -50%) rotate(0deg);}
  50% {transform: translate(50%, -50%) rotate(180deg);}
  100% {transform: translate(50%, -50%) rotate(359deg);}

`
export const ModuleBorderWrap = styled.div`
  position: relative;
  width: 320px;
  padding: 3px;
  border-radius: 14px;
  overflow: hidden;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
}
&:after {
  position: absolute;
  content: '';
  top: 50%;
  right: 50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(0deg, #BFDAFF 0%, #006AFF 100%);
  border-radius: 14px;
  transform-origin: center center;
  transform: translate(50%, -50%) rotate(90deg);
  z-index: -1;
  animation: ${BorderAnimation} 5s ease infinite;
}
`
