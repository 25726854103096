import isEmpty from 'lodash/isEmpty'

import { PIXEL_URL } from './api'
import { getOS, getBrowser, getLocale } from './tracking'
import { stringifyQueryParams } from './url'

const nowAndNowLocal = () => {
  const now = new Date()
  const nowLocal = new Date(+now - now.getTimezoneOffset() * 60 * 1000)

  return [now, nowLocal]
}

const nowAndNowLocalToString = () => {
  const [now, nowLocal] = nowAndNowLocal()

  return [
    now.toISOString().replace('Z', ''),
    nowLocal.toISOString().replace('Z', '')
  ]
}

const getPixelClientMeta = () => {
  const [now] = nowAndNowLocalToString()

  return {
    browser: getBrowser(),
    locale: getLocale(),
    platform: 'web',
    product: 'business.web.mandalore',
    product_version: '1.0',
    track_version: '2.0',
    client_event_time: now,
    client_upload_time: now,
    os_name: getOS()
  }
}

const sendBeaconPixel = ({ host, path, data }) => {
  if (navigator.sendBeacon) {
    try {
      // multipart/form-data
      const formData = new FormData()
      formData.append('_json', encodeURI(JSON.stringify(data)))

      if (isEmpty(formData.get('_json'))) {
        throw Error('Empty payload')
      }

      const url = `${host}${path}`
      const success = navigator.sendBeacon(url, formData)

      return Promise.resolve(success)
    } catch (err) {
      return Promise.reject()
    }
  }

  return Promise.reject()
}

const sendGetPixel = ({ host, path, data }) => {
  const url = `${host}${path}`
  const fetchPromise = fetch(url + stringifyQueryParams(data))
  Promise.resolve(fetchPromise)
}

const sendPixelTracking = ({ url: path, data = {} }) => {
  const pixelData = {
    referer: document.referrer,
    ...getPixelClientMeta(),
    ...data
  }

  try {
    return sendBeaconPixel({ host: PIXEL_URL, path, data: pixelData })
  } catch (err) {
    return sendGetPixel({ host: PIXEL_URL, path, data: pixelData })
  }
}

export default function sendVisitorEventPixelTracking({
  event,
  data = {}
}: {
  event: string
  data?: Record<string, unknown>
}): void {
  sendPixelTracking({
    data,
    url: `/trk/${event}`
  })
}
