import React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex } from './EmotionLayout'

export const BetaTag: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Flex
      bg="#E7F0FF"
      color="#0F6BFF"
      borderRadius="2"
      alignItems="center"
      padding="0 4px"
      fontSize="12px"
    >
      {t('Beta')}
    </Flex>
  )
}
