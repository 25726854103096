import React from 'react'

import {
  Flex,
  FullScreenLayout,
  FWButton
} from '@src/common/components/BaseComponent'
import { VideoVisibility } from '@src/constants'
import BatchImporterCaptionBody from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterFillCaption/BatchImporterCaptionBody'
import { BatchImportCaptionHeaderControls } from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterFillCaption/BatchImporterCaptionHeaderControls'
import BatchImporterHeader from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterHeader'
import {
  BatchImporterEditMedia,
  ImporterSource,
  TrackingDetails
} from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import { useTranslation } from 'react-i18next'

import BatchImporterAddPlaylistBody from '../BatchImporterAddPlaylist/BatchImporterAddPlaylistBody'

interface IProps {
  title: string
  onClose: () => void
  mediaList?: BatchImporterEditMedia[]
  onChangeMediaList?: (value: BatchImporterEditMedia[]) => void
  onBackButtonClick?: () => void
  onNextButtonClick?: () => void
  tracking: TrackingDetails
}

const BatchImporterCaption: React.FC<IProps> = (props) => {
  const {
    title,
    onClose,
    mediaList,
    onChangeMediaList,
    onBackButtonClick,
    onNextButtonClick,
    tracking
  } = props

  const { t } = useTranslation()

  const playlists = mediaList?.[0]?.playlists
  const visibility = mediaList?.[0]?.visibility
  const agreeTerms = mediaList?.[0]?.agreeTerms
  const publishTime = mediaList?.[0]?.publishTime

  const handleChangePlaylist = (playlists: string[]) => {
    if (onChangeMediaList) {
      const list = mediaList?.map((item) => {
        return {
          ...item,
          playlists
        }
      })

      onChangeMediaList(list)
    }
  }

  const handleChangeVisibility = (visibility: VideoVisibility) => {
    if (onChangeMediaList) {
      const list = mediaList?.map((item) => {
        return {
          ...item,
          visibility
        }
      })

      onChangeMediaList(list)
    }
  }

  const handleChangeAgreeTerms = (agreeTerms: boolean) => {
    if (onChangeMediaList) {
      const list = mediaList?.map((item) => {
        return {
          ...item,
          agreeTerms
        }
      })

      onChangeMediaList(list)
    }
  }

  const handleChangePublishTime = (publishTime?: string) => {
    if (onChangeMediaList) {
      const list = mediaList?.map((item) => {
        return {
          ...item,
          publishTime
        }
      })

      onChangeMediaList(list)
    }
  }

  return (
    <FullScreenLayout position="fixed" bottom={0} right={0}>
      <BatchImporterHeader
        title={title}
        onCloseButtonClick={onClose}
        leftControls={
          <FWButton onClick={onBackButtonClick}>{t('Back')}</FWButton>
        }
        rightControls={
          tracking.source !== ImporterSource.ASYNC_MODAL ? (
            <BatchImportCaptionHeaderControls
              disabled={!agreeTerms}
              onUploadClick={onNextButtonClick}
              onCancelClick={onClose}
              mediaList={mediaList}
            />
          ) : (
            <></>
          )
        }
      />
      <Flex overflowY="scroll">
        <BatchImporterCaptionBody
          mediaList={mediaList}
          onChangeMediaList={onChangeMediaList}
          tracking={tracking}
        />
        <BatchImporterAddPlaylistBody
          uploadCount={mediaList.length}
          playlists={playlists}
          visibility={visibility}
          onPlaylists={handleChangePlaylist}
          onVisibility={handleChangeVisibility}
          agreeTerms={agreeTerms}
          onAgreeTerms={handleChangeAgreeTerms}
          tracking={tracking}
          onPublishTime={handleChangePublishTime}
          publishTime={publishTime}
        />
      </Flex>
    </FullScreenLayout>
  )
}

export default BatchImporterCaption
