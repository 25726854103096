import { useCallback, useEffect } from 'react'

import { useAppSelector } from '@src/app/hooks'
import useOTOWidget from '@src/common/hooks/useOTOWidget'
import { setIsWidgetVisible } from '@src/common/redux/ui'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

const WIDGET_ID = 'fw-lazy-load-live-helper'

const shouldHideWidgetPaths = [
  '/assistant',
  '/host_dashboard',
  '/chat_channel/',
  '/insights/'
] as const

const shouldHideWidget = (path: string): boolean => {
  return shouldHideWidgetPaths.some((hidePath) => path.includes(hidePath))
}

const removeWidget = (): void => {
  const widget = document.getElementById(WIDGET_ID)
  if (widget) {
    widget.remove()
  }
}

export function useOTOWidgetVisibility(): void {
  const dispatch = useDispatch()
  const location = useLocation()
  const isWidgetReady = useOTOWidget()
  const isWidgetVisible = useAppSelector((state) => state.ui.isWidgetVisible)

  // Handle production environment widget visibility
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      dispatch(setIsWidgetVisible(true))
    }
  }, [dispatch])

  // Handle widget ready state
  const handleReady = useCallback(() => {
    if (!window._fwn?.liveHelper?.actions) {
      return
    }

    if (shouldHideWidget(location.pathname)) {
      removeWidget()
    } else {
      window._fwn.liveHelper.actions.showWidget()
    }
  }, [location.pathname])

  // Handle widget initialization and cleanup
  useEffect(() => {
    if (!isWidgetReady || !isWidgetVisible) {
      return
    }

    if (window._fwn?.liveHelper) {
      handleReady()
    } else {
      document.addEventListener('fw:ready', handleReady)

      return () => {
        document.removeEventListener('fw:ready', handleReady)
      }
    }
  }, [isWidgetReady, isWidgetVisible, handleReady])

  // Handle widget visibility changes
  useEffect(() => {
    if (!isWidgetVisible) {
      removeWidget()
    }
  }, [isWidgetVisible])
}
