import React from 'react'

import {
  FWButton,
  FWModal,
  ModalFooter
} from '@src/common/components/BaseComponent'
import { useTranslation } from 'react-i18next'

export interface DeleteModalProps {
  isOpen: boolean
  title?: string
  body: React.ReactNode
  onCancel: () => void
  onDelete: () => Promise<void> | void
  deleteText?: string
}

const DeleteModal: React.FC<DeleteModalProps> = ({
  isOpen,
  title = null,
  onCancel,
  onDelete,
  body,
  deleteText
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = React.useState(false)

  const handleDelete = async () => {
    setLoading(true)
    await onDelete()
    setLoading(false)
  }

  return (
    <>
      {!!isOpen && (
        <FWModal
          centered
          closable={false}
          title={title}
          footer={null}
          open
          destroyOnClose
          width={400}
          onCancel={onCancel}
        >
          {body}
          <ModalFooter>
            <FWButton onClick={onCancel}>{t('Cancel')}</FWButton>
            <FWButton onClick={handleDelete} danger loading={loading}>
              {deleteText || t('Yes, Delete')}
            </FWButton>
          </ModalFooter>
        </FWModal>
      )}
    </>
  )
}

export default DeleteModal
