import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@src/app/hooks'
import { pdpGallery_pollStatus } from '@src/modules/channel/redux/YourSite/pdpGallery'

export const usePollPDPGalleryStatus = ({
  businessId,
  storeUrl
}: {
  businessId: string
  storeUrl: string
}): void => {
  const dispatch = useAppDispatch()
  const embedStatus = useAppSelector(
    (state) => state.yourSite.pdpGalleryConfig?.status
  )

  useEffect(() => {
    dispatch(pdpGallery_pollStatus({ businessId, storeUrl }))
  }, [businessId, storeUrl, dispatch])

  useEffect(() => {
    if (embedStatus === 'processing' || embedStatus === null) {
      const intervalId = setInterval(() => {
        if (embedStatus === 'processing') {
          dispatch(pdpGallery_pollStatus({ businessId, storeUrl }))
        }
      }, 5000)

      return () => clearInterval(intervalId)
    }
  }, [embedStatus, businessId, storeUrl, dispatch])
}
