import { useCallback } from 'react'

import { unwrapResult } from '@reduxjs/toolkit'
import { useAppDispatch } from '@src/app/hooks'

export const useUnwrapAsyncThunk = (): ((asyncThunk: any) => any) => {
  const dispatch = useAppDispatch()

  const unwrapAsyncThunk = useCallback(
    (asyncThunk) => {
      return dispatch(asyncThunk).then(unwrapResult)
    },
    [dispatch]
  )

  return unwrapAsyncThunk
}
