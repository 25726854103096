import React, { useEffect, useMemo } from 'react'

import { css } from '@emotion/react'
import {
  localStorageProvider,
  sessionStorageProvider
} from '@src/common/utils/storageProvider'
import { Modal } from 'antd'
import ReactMarkdown from 'react-markdown'

const CHANGELOG_CONTENT_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://raw.githubusercontent.com/loopsocial/changelog/{branch}/cms-changelog-{lang}-prod.md'
    : 'https://raw.githubusercontent.com/loopsocial/changelog/{branch}/cms-changelog-{lang}-dev.md'

const CHANGELOG_VERSION_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://raw.githubusercontent.com/loopsocial/changelog/{branch}/cms-version.json'
    : 'https://raw.githubusercontent.com/loopsocial/changelog/{branch}/cms-version-dev.json'

export const ChangelogModal: React.FC = () => {
  const [open, setOpen] = React.useState(false)
  const [text, setText] = React.useState('')

  const lang = useMemo(() => {
    return localStorageProvider.getItem('i18nextLng')?.split('-')?.[0]
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const branch =
        sessionStorageProvider.getItem('change_log_test_branch') || 'main'
      const lastVersion = localStorage.getItem('change_log_last_version')
      try {
        const response = await fetch(
          CHANGELOG_VERSION_URL.replace('{branch}', branch),
          {
            cache: 'no-store'
          }
        )
        const data = await response.json()
        if (
          data.versionCode &&
          data.versionCode > parseInt(lastVersion || '0')
        ) {
          let logData = ''
          try {
            const response = await fetch(
              CHANGELOG_CONTENT_URL.replace('{lang}', lang || 'en').replace(
                '{branch}',
                branch
              ),
              {
                cache: 'no-store'
              }
            )
            if (response.status === 404) {
              throw new Error('Not found')
            }
            logData = await response.text()
          } catch (error) {
            const response = await fetch(
              CHANGELOG_CONTENT_URL.replace('{lang}', 'en').replace(
                '{branch}',
                branch
              ),
              {
                cache: 'no-store'
              }
            )
            logData = await response.text()
          }
          setText(logData)
          setOpen(true)
          localStorage.setItem('change_log_last_version', data.versionCode)
        }
      } catch (error) {
        // eslint-disable-next-line no-empty
      }
    }

    fetchData()
  }, [lang])

  return (
    <Modal
      open={open}
      centered
      bodyStyle={{
        maxHeight: 600
      }}
      footer={null}
      onCancel={() => setOpen(false)}
      width={720}
    >
      <ReactMarkdown
        css={css`
          max-height: 500px;
          overflow: scroll;
          margin: 26px 40px;
          ::-webkit-scrollbar {
            width: 0;
            background: transparent;
          }
        `}
      >
        {text}
      </ReactMarkdown>
    </Modal>
  )
}
