import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from '@src/app/hooks'
import { ActivationStatusContainer } from '@src/common/components/ActivationStatus/ActivationStatusContainer'
import { AnimateShow } from '@src/common/components/AnimationWrappers'
import {
  Box,
  Flex,
  LoadingIndicator,
  Title
} from '@src/common/components/BaseComponent'
import { FWButton } from '@src/common/components/BaseComponent'
import { useChannelData } from '@src/common/hooks/useChannelData'
import useCurrentActivationStatus from '@src/common/hooks/useCurrentActivationStatus'
import { useStoreData } from '@src/common/hooks/useStoreData'
import { fetchBusinessStores } from '@src/common/redux/businessStore'
import { fetchChannelPlaylists } from '@src/common/redux/channel'
import { CreatePlaylistBanner } from '@src/modules/channel/components/YourSite/Banners/FireworkCreatePlaylistBanner'
import { FireworkEmbedBanner } from '@src/modules/channel/components/YourSite/Banners/FireworkEmbedBanner'
import { FireworkUploadVideoBanner } from '@src/modules/channel/components/YourSite/Banners/FireworkUploadVideoBanner'
import { ShopifyAPIScopeBanner } from '@src/modules/channel/components/YourSite/Banners/ShopifyAPIScopeBanner'
import { StoreSelector } from '@src/modules/channel/components/YourSite/StoreSelector'
import { VerticalStepper } from '@src/modules/channel/components/YourSite/VerticalStepper/VerticalStepper'
import { useAutoEmbed } from '@src/modules/channel/hooks/YourSite/useAutoEmbed'
import { usePDPGalleryConfig } from '@src/modules/channel/hooks/YourSite/usePDPGalleryConfig'
import { usePollPDPGalleryStatus } from '@src/modules/channel/hooks/YourSite/usePollPDPGalleryStatus'
import {
  autoEmbed_fetchEmbedConfig,
  autoEmbed_fetchEmbedStatus
} from '@src/modules/channel/redux/YourSite/autoEmbed'
import theme from '@src/styles/theme'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import {
  GridListTabButton,
  GridListTabLayout
} from '../../components/common/GridListTabButton'
import { HTMLPage } from '../../pages/YourSite/HTMLPage'
import { EmbedConfigGrid } from '../components/Dashboard/EmbedConfigGrid'
import {
  EmbedConfigTable,
  TableDataType
} from '../components/Dashboard/EmbedConfigTable'
import { useEmbedConfigs } from '../hooks/useEmbedConfigs'
import { useEmbedConfigsApi } from '../hooks/useEmbedConfigsApi'

export const YourSiteV2: React.FC = () => {
  const { channelId, businessId } = useParams()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const basePath = `/business/${businessId}/channel/${channelId}/integrations/embed`

  const {
    getSelectedShopifyShopUrl: shopifyShopUrl,
    getShopifyStoreCount: shopifyStoreCount,
    getStoreById,
    selectedStoreId
  } = useStoreData({
    businessId
  })
  const [layout, setLayout] = useState<GridListTabLayout>('grid')
  const { svCount } = useChannelData({ channelId })

  const { listConfigs } = useEmbedConfigsApi({ channelId, businessId })
  const { data: embedConfigs } = useEmbedConfigs()

  const embedConfigsByStoreId = useMemo(() => {
    return embedConfigs
      .filter((config) => config.status === 'enable')
      ?.reduce((acc, config) => {
        if (selectedStoreId !== config.business_store_id) return acc

        acc.push(config)

        return acc
      }, [] as YourSite.EmbedConfig[])
  }, [embedConfigs, selectedStoreId])

  const {
    uploadVideo,
    createPlaylist,
    isActivationStatusModalOpen
  } = useAppSelector((state) => state.activationStatus)

  useCurrentActivationStatus({
    businessId,
    channelId
  })

  // Fetch embed configs
  useEffect(() => {
    listConfigs()
  }, [listConfigs])

  // fetch business stores once
  useEffect(() => {
    dispatch(fetchBusinessStores(businessId, undefined, 100))
  }, [dispatch, businessId])

  const selectedStoreData = getStoreById(selectedStoreId)

  /* This `useEffect` hook is responsible for fetching the channel playlists
  when the component mounts. */
  useEffect(() => {
    dispatch(fetchChannelPlaylists(businessId, channelId))
  }, [dispatch, businessId, channelId])

  const { channelPlaylists } = useAppSelector((state) => state.channel)
  const channelHasPlaylists = channelPlaylists?.[channelId]?.length > 0

  const {
    status: fireworkAppEnabledStatus,
    loading: fireworkAppStatusLoading
  } = useAppSelector((state) => state.yourSite.embedStatus)

  //Shopify Scope Validation
  const {
    status: shopifyScopeStatus,
    loading: shopifyScopeLoading
  } = useAppSelector((state) => state.yourSite.shopifyAPIScopeValidation)

  const { homePageConfig } = useAutoEmbed({ channelId })
  const homePageConfigStatus = homePageConfig?.(shopifyShopUrl)
  const pdpConfig = usePDPGalleryConfig()

  // Fetch Embed Status
  const fetchEmbedStatus = useCallback(async () => {
    if (shopifyShopUrl && !fireworkAppEnabledStatus) {
      await dispatch(autoEmbed_fetchEmbedStatus({ storeUrl: shopifyShopUrl }))
    }
  }, [dispatch, shopifyShopUrl, fireworkAppEnabledStatus])

  useEffect(() => {
    fetchEmbedStatus()
  }, [fetchEmbedStatus])

  // Fetch Embed Config
  const fetchAutoEmbedConfig = useCallback(async () => {
    await dispatch(
      autoEmbed_fetchEmbedConfig({
        channelId,
        businessId
      })
    )
  }, [dispatch, channelId, businessId])

  useEffect(() => {
    fetchAutoEmbedConfig()
  }, [fetchAutoEmbedConfig])

  // Poll PDP Gallery Status
  usePollPDPGalleryStatus({
    businessId,
    storeUrl: shopifyShopUrl
  })

  const displayContent = useMemo(() => {
    return (
      channelHasPlaylists &&
      uploadVideo === 'complete' &&
      fireworkAppEnabledStatus !== null &&
      fireworkAppStatusLoading === false
    )
  }, [
    channelHasPlaylists,
    uploadVideo,
    fireworkAppEnabledStatus,
    fireworkAppStatusLoading
  ])

  const showLoadingIndicator = useMemo(() => {
    return (
      fireworkAppEnabledStatus === null &&
      fireworkAppStatusLoading === true &&
      shopifyScopeStatus === null &&
      shopifyScopeLoading === true
    )
  }, [
    fireworkAppEnabledStatus,
    fireworkAppStatusLoading,
    shopifyScopeStatus,
    shopifyScopeLoading
  ])

  const gettingStartedSteps = [
    {
      stepComponent: (
        <AnimateShow triggerCondition={svCount === 0}>
          <FireworkUploadVideoBanner
            businessId={businessId}
            channelId={channelId}
          />
        </AnimateShow>
      ),
      key: 'step-1',
      active: svCount === 0
    },
    {
      stepComponent: (
        <FireworkEmbedBanner
          businessId={businessId}
          disabled={uploadVideo === 'active' && svCount === 0}
        />
      ),
      key: 'step-2',
      active:
        fireworkAppEnabledStatus === 'disabled' &&
        fireworkAppStatusLoading === false
    },
    {
      stepComponent: (
        <CreatePlaylistBanner
          businessId={businessId}
          channelId={channelId}
          disabled={createPlaylist === 'inactive' || !fireworkAppEnabledStatus}
        />
      ),
      key: 'step-3',
      active: !channelHasPlaylists
    },
    {
      stepComponent: <ShopifyAPIScopeBanner businessId={businessId} />,
      key: 'step-4',
      active: shopifyScopeStatus !== 'success'
    }
  ]

  const gettingStartedStepsCompleted = gettingStartedSteps.filter(
    (step) => step.active
  )

  const handleHomePageClick = () => {
    navigate(`${basePath}/home-page`)
  }

  const handleProductGalleryClick = () => {
    navigate(`${basePath}/product-gallery`)
  }

  const handleNavigateToSelectWidget = () => {
    navigate(`${basePath}/select-widget`)
  }

  const handleEmbedConfigClick = (embedConfig: YourSite.EmbedConfig) => {
    navigate(
      `${basePath}/view-widget/${embedConfig?.layout}/widget/${embedConfig.id}`
    )
  }

  const handleLayoutChange = (layout: GridListTabLayout) => {
    setLayout(layout)
  }

  const handleTableRowClick = (record: TableDataType) => {
    if (record.id === 'homepage') {
      handleHomePageClick()
    } else if (record.id === 'pdp_gallery') {
      handleProductGalleryClick()
    } else {
      handleEmbedConfigClick(record)
    }
  }

  if (
    shopifyStoreCount === 0 ||
    (selectedStoreData && selectedStoreData?.provider !== 'shopify')
  ) {
    return (
      <Box padding={24} height="100%">
        <Flex
          padding="30px"
          background={theme.white}
          borderRadius="12px"
          flexDirection="column"
          width="100%"
          gap={24}
          alignSelf="stretch"
          height="100%"
          overflow="auto"
        >
          <Box width="100%">
            <div
              style={{
                width: 'clamp(200px, 100%, 500px)'
              }}
            >
              <StoreSelector businessId={businessId} />
            </div>
          </Box>
          <HTMLPage />
        </Flex>
      </Box>
    )
  }

  return (
    <Box padding={24} height="100%">
      {isActivationStatusModalOpen && <ActivationStatusContainer />}
      <Flex
        padding="30px"
        background={theme.white}
        borderRadius="12px"
        flexDirection="column"
        width="100%"
        gap={24}
        alignSelf="stretch"
        height="100%"
        overflow="hidden"
      >
        <Flex
          flexDirection="row"
          width="100%"
          gap={24}
          justifyContent="space-between"
          alignItems="center"
        >
          <div style={{ width: 'clamp(200px, 100%, 500px)' }}>
            <StoreSelector businessId={businessId} />
          </div>
        </Flex>
        {showLoadingIndicator ? (
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <LoadingIndicator />
          </Flex>
        ) : (
          <>
            {selectedStoreData && (
              <>
                {gettingStartedStepsCompleted.length > 0 && (
                  <Flex flexDirection="row" width="100%" gap={24}>
                    <VerticalStepper
                      title={t('Get Started')}
                      steps={gettingStartedSteps}
                    />
                  </Flex>
                )}
                {displayContent && (
                  <Flex flexDirection="column" gap="24" height="100%">
                    <Flex
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Flex gap={18}>
                        <Title noMargin fontSize={25} weight={600}>
                          {t('Widgets')}
                        </Title>
                        <GridListTabButton
                          layout={layout}
                          onChangeLayout={handleLayoutChange}
                        />
                      </Flex>
                      <FWButton
                        type="primary"
                        onClick={handleNavigateToSelectWidget}
                        style={{ minWidth: '150px' }}
                      >
                        {t('Create Widget')}
                      </FWButton>
                    </Flex>
                    {layout === 'list' ? (
                      <EmbedConfigTable
                        handleTableRowClick={(record: TableDataType) => ({
                          onClick: () => handleTableRowClick(record)
                        })}
                        selectedStoreId={selectedStoreId}
                        homePageConfigStatus={homePageConfigStatus}
                        pdpConfig={pdpConfig}
                        embedConfigsByStoreId={embedConfigsByStoreId}
                      />
                    ) : (
                      <EmbedConfigGrid
                        handleNavigateToSelectWidget={
                          handleNavigateToSelectWidget
                        }
                        homePageConfigStatus={homePageConfigStatus}
                        handleHomePageClick={handleHomePageClick}
                        pdpConfig={pdpConfig}
                        handleProductGalleryClick={handleProductGalleryClick}
                        embedConfigsByStoreId={embedConfigsByStoreId}
                        handleEmbedConfigClick={handleEmbedConfigClick}
                      />
                    )}
                  </Flex>
                )}
              </>
            )}
          </>
        )}
      </Flex>
    </Box>
  )
}
