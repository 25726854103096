import { isPlainObject, isUndefined } from 'lodash'
import moment from 'moment'

/**
 * Ensures that a URL starts with the http protocol.
 * @param {string} url URL to sanitize
 * @returns {string} URL with http protocol
 */
export const ensureHttp = (url: string): string => {
  return url?.startsWith('http') ? url : `http://${url}`
}

/**
 * Removes all undefined values from the object.
 * @param {object} obj Object to filter
 * @return {object} Object without undefined values
 */
export const removeUndefined = (
  obj?: Record<string, any>
): Record<string, any> => {
  if (!obj) throw Error('obj is not defined')
  if (!isPlainObject(obj)) throw Error('obj is not an object')

  return Object.keys(obj).reduce((acc, key) => {
    if (!isUndefined(obj[key])) acc[key] = obj[key]

    return acc
  }, {})
}

/**
 * Removes all null values from the object.
 * @param {object} obj Object to filter
 * @return {object} Object without undefined values
 */
export const removeNullAndUndefined = (
  obj?: Record<string, any>
): Record<string, any> => {
  if (!obj) throw Error('obj is not defined')
  if (!isPlainObject(obj)) throw Error('obj is not an object')

  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== null && !isUndefined(obj[key])) acc[key] = obj[key]

    return acc
  }, {})
}

/**
 * Converts a moment instance to date string.
 * @param {moment} date Date to convert
 * @return {string} Date string
 */
export const toDateString = (date?: moment.Moment | string): string => {
  if (!moment.isMoment(date)) throw Error('Not a moment instance')

  return date.format('YYYY-MM-DD')
}
/**  Use UTC date without time, and shifted hour
 * @param {date} Date or String format
 * @return {date} Date in ISO-8061 format
 */
export const utcDate = (date: moment.Moment | string): string => {
  const simpleDate = moment(date).format('YYYY-MM-DD')

  return moment.utc(simpleDate).toISOString()
}
/**  Use local timezone date with shifted hours
 * @param {date} Date or String format
 * @return {date} Date in ISO-8061 format
 */
export const localDate = (date: moment.Moment | string): string => {
  const simpleDate = moment(date).format('YYYY-MM-DD')

  return moment(simpleDate).utc().toISOString()
}

export const localTime = (): string => {
  return moment().format()
}

/**
 * Returns a human readable time duration string.
 * @param {number} val Number to convert
 * @param {string} format Format of the number. e.g. 'hours'/'minutes'/'seconds'
 * @return {string} Human readable formatted string
 */
export const toHumanReadableDuration = (
  val: number,
  format: 'hours' | 'minutes' | 'seconds'
): string => {
  return (moment as any)
    .duration(val, format)
    .format('w[w] d[d] h[h] m[m] s[s]', { trim: 'both' })
}

/**
 * It removes any undefined values from an object
 * @param data - The data that is passed in from the form.
 * @returns The sanitized object is being returned.
 */
export const sanitizeInteractionData = (data: any): any => {
  const sanitized = {
    prompt: data?.prompt.trim(),
    collect_email: data.collect_email,
    interaction_type: data?.interaction_type,
    options: data?.options,
    giveaway_celebration_emoji: data?.giveaway_celebration_emoji,
    giveaway_description: data?.giveaway_description,
    terms_and_conditions_url: data?.terms_and_conditions_url,
    interactive_url: data?.interactive_url,
    option_sets: data?.option_sets
  }

  return removeUndefined(sanitized)
}
