import React, { useCallback, useState } from 'react'

import Question from '@src/common/components/Question'

type BatchUploadQuestionWrapperProps = {
  addQuestion: (value: {
    prompt: string
    collect_email: 'required' | 'optional'
  }) => void
  question: {
    prompt: string
    collect_email: 'required' | 'optional'
  }
}

export const BatchUploadQuestionWrapper: React.FC<BatchUploadQuestionWrapperProps> = ({
  addQuestion,
  question
}) => {
  const [text, setText] = useState<string>(question?.prompt)
  const [collect, setCollect] = useState<'required' | 'optional'>(
    question?.collect_email
  )

  const handleAddQuestion = useCallback(
    (question: { prompt: string; collect_email: 'required' | 'optional' }) => {
      addQuestion(question)
    },
    [addQuestion]
  )

  const handleSwitch = (checked) => {
    setCollect(checked ? 'required' : null)
    handleAddQuestion({
      prompt: text,
      collect_email: checked ? 'required' : null
    })
  }

  const handleTextArea = (e) => {
    setText(e.target.value)
    handleAddQuestion({ prompt: e.target.value, collect_email: collect })
  }

  return (
    <Question
      handleSwitch={handleSwitch}
      handleTextArea={handleTextArea}
      text={text}
      collect={collect}
    />
  )
}
