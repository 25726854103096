import React from 'react'

import { ANIMATION_TRANSITION } from '@src/constants'
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion'

type Props = {
  children: React.ReactNode
  triggerCondition: boolean
}

export const AnimateShow = ({
  children,
  triggerCondition
}: Props): JSX.Element => {
  return (
    <AnimatePresence>
      {triggerCondition ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={ANIMATION_TRANSITION}
        >
          {children}
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}
