import React, { useCallback, useState } from 'react'

import { css } from '@emotion/core'
import { BulkHashtagComponent } from '@src/common/components'
import { FWButton } from '@src/common/components/BaseComponent'
import { Flex, Box } from '@src/common/components/BaseComponent'
import { Title } from '@src/common/components/BaseComponent'
import { PLGWrapper } from '@src/common/components/PLGWrapper/PLGWrapper'
import { captionLength } from '@src/common/utils/caption'
import {
  MIN_CAPTION_LENGTH,
  MAX_CAPTION_LENGTH,
  MAX_BULK_HASHTAGS,
  InteractionType
} from '@src/constants'
import {
  BatchImporterEditMedia,
  BatchImporterMediaType,
  ImporterSource,
  TrackingDetails
} from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import BatchImporterMediaItem from '@src/modules/channel/components/Library/Importer/BatchImporter/Media/BatchImporterMediaItem'
import { BatchImporterMediaLinkItem } from '@src/modules/channel/components/Library/Importer/BatchImporter/Media/BatchImporterMediaLinkItem'
import BatchImporterMediaPreviewModal from '@src/modules/channel/components/Library/Importer/BatchImporter/Media/BatchImporterMediaPreviewModal'
import theme from '@src/styles/theme'
import { Input, Select, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { AiFillExclamationCircle } from 'react-icons/ai'

import { BatchUploadCTAWrapper } from '../Interactions/BatchUploadCTAWrapper'
import { BatchUploadPollWrapper } from '../Interactions/BatchUploadPollWrapper'
import { ProductInteraction } from '../Interactions/BatchUploadProductWrapper'
import { BatchUploadQuestionWrapper } from '../Interactions/BatchUploadQuestionWrapper'

interface IProps {
  mediaList?: BatchImporterEditMedia[]
  onChangeMediaList?: (value: BatchImporterEditMedia[]) => void
  tracking?: TrackingDetails
}

const BatchImporterCaptionBody: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const { mediaList, onChangeMediaList, tracking } = props
  const hasMultiple = mediaList.length > 1

  const [previewMedia, setPreviewMedia] = useState<BatchImporterEditMedia>()
  const [togglePreviewModal, setTogglePreviewModal] = useState<boolean>(false)

  const handleInteractionSelection = (
    media: BatchImporterEditMedia,
    value: InteractionType
  ) => {
    if (onChangeMediaList) {
      const list = mediaList.map((item) => {
        if (media.id === item.id) {
          return {
            ...item,
            cta: null,
            question: null,
            poll: null,
            interactionType: value,
            products: [],
            productOptions: []
          }
        } else {
          return item
        }
      })

      onChangeMediaList(list)
    }
  }

  const handleHashtags = (media: BatchImporterEditMedia, value) => {
    if (onChangeMediaList) {
      const list = mediaList.map((item) => {
        if (item.id === media.id) {
          return {
            ...item,
            editHashtags: value || []
          }
        } else {
          return item
        }
      })

      onChangeMediaList(list)
    }
  }

  const handleCaption = (media: BatchImporterEditMedia, value) => {
    if (captionLength(value) > MAX_CAPTION_LENGTH) {
      return
    }

    if (onChangeMediaList) {
      const list = mediaList.map((item) => {
        if (item.id === media.id) {
          return {
            ...item,
            editCaption: value
          }
        } else {
          return item
        }
      })

      onChangeMediaList(list)
    }
  }

  const handleDelete = (media: BatchImporterEditMedia) => {
    if (onChangeMediaList) {
      const list = mediaList.filter((item) => {
        return item.id !== media.id
      })

      onChangeMediaList(list)
    }
  }

  const handlePreviewMedia = (media?: BatchImporterEditMedia) => {
    setPreviewMedia(media)
    setTogglePreviewModal(!!media)
  }

  const handleApplyAll = (value: BatchImporterEditMedia) => {
    const hashtags = value.editHashtags
    if (onChangeMediaList) {
      const list = mediaList.map((item) => {
        return {
          ...item,
          editHashtags: hashtags || []
        }
      })

      onChangeMediaList(list)
    }
  }
  const handleAddProduct = useCallback(
    (
      media: BatchImporterEditMedia,
      products: {
        business_store_id?: string
        product_id: string
        product_unit_id?: string
      }[],
      options: {
        id: string
        unitId?: string
        businessStoreId?: string
      }[]
    ) => {
      if (onChangeMediaList) {
        const list = mediaList.map((item) => {
          if (media.id === item.id) {
            return {
              ...item,
              products: products,
              productOptions: options
            }
          } else {
            return item
          }
        })

        onChangeMediaList(list)
      }
    },
    [mediaList, onChangeMediaList]
  )

  const handleAddQuestion = useCallback(
    (
      media: BatchImporterEditMedia,
      question: {
        prompt: string
        collect_email: 'required' | 'optional'
      }
    ) => {
      if (onChangeMediaList) {
        const list = mediaList.map((item) => {
          if (media.id === item.id) {
            return {
              ...item,
              question: question
            }
          } else {
            return item
          }
        })

        onChangeMediaList(list)
      }
    },
    [mediaList, onChangeMediaList]
  )
  const handleAddCTA = useCallback(
    (
      media: BatchImporterEditMedia,
      cta: {
        action_url: string
        action_type: string
        custom_label?: string
      }
    ) => {
      if (onChangeMediaList) {
        const list = mediaList.map((item) => {
          if (media.id === item.id) {
            return {
              ...item,
              cta: cta
            }
          } else {
            return item
          }
        })

        onChangeMediaList(list)
      }
    },
    [mediaList, onChangeMediaList]
  )
  const handleAddPoll = useCallback(
    (
      media: BatchImporterEditMedia,
      poll: { question: string; options: string[] }
    ) => {
      if (onChangeMediaList) {
        const list = mediaList.map((item) => {
          if (media.id === item.id) {
            return {
              ...item,
              poll: poll
            }
          } else {
            return item
          }
        })

        onChangeMediaList(list)
      }
    },
    [mediaList, onChangeMediaList]
  )

  return (
    <Flex
      flex={2}
      pl="large2"
      pr="large2"
      flexDirection="column"
      alignItems="center"
      css={css`
        > *:not(:last-child) {
          border-bottom: 1px solid #d9d9d9;
        }
      `}
    >
      {mediaList.map((item) => {
        if (!item.interactionType) {
          item.interactionType = InteractionType.PRODUCT
        }

        const useMediaLinkItem =
          (item.mediaType === BatchImporterMediaType.CAROUSEL_ALBUM &&
            !item.children?.[0].mediaUrl) ||
          (!item.thumbnailUrl && !item.mediaUrl)

        return (
          <div
            key={item.id}
            css={css`
              width: 100%;
              padding: 45px 0 40px 0;
            `}
          >
            <Flex flexDirection="column" alignItems="center">
              <Flex flexDirection="row" width="100%">
                <div
                  css={css`
                    width: 160px;
                    min-width: 160px;
                  `}
                >
                  {useMediaLinkItem ? (
                    <BatchImporterMediaLinkItem
                      mediaItem={item}
                      tracking={tracking}
                    />
                  ) : (
                    <BatchImporterMediaItem
                      forView={true}
                      mediaItem={{
                        ...item,
                        // requirement from Designer: not show the consent from editing caption page
                        needConsent: false
                      }}
                      onPreviewMedia={(value) => {
                        handlePreviewMedia(value)
                      }}
                      tracking={tracking}
                    />
                  )}
                </div>
                <div
                  css={css`
                    width: 100%;
                    min-width: 400px;
                    margin: 0 8px 0 24px;
                  `}
                >
                  <Flex flexDirection="column" gap="16">
                    {item?.error?.message && (
                      <Flex
                        bg="#ff40400f"
                        alignItems="center"
                        p="4px 8px"
                        gap={8}
                        filter="none"
                      >
                        <AiFillExclamationCircle color="#ff4040" />
                        <Title
                          fontSize={12}
                          weight={500}
                          noMargin
                          color="#ff4040"
                        >
                          {item.error.message}
                        </Title>
                      </Flex>
                    )}
                    <Flex
                      flexDirection="column"
                      gap="16"
                      opacity={
                        tracking.source === ImporterSource.ASYNC_MODAL ? 0.5 : 1
                      }
                      pointerEvents={
                        tracking.source === ImporterSource.ASYNC_MODAL
                          ? 'none'
                          : 'auto'
                      }
                    >
                      <div
                        css={css`
                          font-size: 14px;
                          font-weight: 500;
                        `}
                      >
                        <span
                          css={css`
                            color: ${theme.danger};
                          `}
                        >
                          &nbsp;*&nbsp;
                        </span>
                        {t('Title')}
                      </div>
                      <div
                        css={css`
                          margin-top: 4px;
                          position: relative;
                        `}
                      >
                        <Input
                          status={
                            !captionLength(item.editCaption) ||
                            captionLength(item.editCaption) <
                              MIN_CAPTION_LENGTH ||
                            captionLength(item.editCaption) > MAX_CAPTION_LENGTH
                              ? 'error'
                              : null
                          }
                          value={item.editCaption}
                          onChange={(e) => handleCaption(item, e.target.value)}
                        />
                      </div>
                      <Flex width="100%">
                        <Flex
                          flexDirection="column"
                          gap="16"
                          cursor="pointer"
                          alignItems="flex-start"
                          width="100%"
                        >
                          <div
                            css={css`
                              font-size: 14px;
                              font-weight: 500;
                            `}
                          >
                            {t('Interaction')}
                          </div>
                          <Box width="100%" my="small">
                            <Select
                              onChange={(value) =>
                                handleInteractionSelection(item, value)
                              }
                              value={item.interactionType}
                              style={{ width: '100%' }}
                            >
                              <Select.Option value={InteractionType.PRODUCT}>
                                {t('Product')}
                              </Select.Option>
                              <Select.Option value={InteractionType.CTA}>
                                {t('CTA')}
                              </Select.Option>
                              <Select.Option value={InteractionType.POLL}>
                                {t('Poll')}
                              </Select.Option>
                              <Select.Option value={InteractionType.QUESTIONS}>
                                {t('Question')}
                              </Select.Option>
                            </Select>
                          </Box>
                          {item.interactionType && (
                            <Flex
                              borderLeft="1px solid #E0E0E0"
                              paddingLeft="12px"
                              width="100%"
                            >
                              {item.interactionType === 'product' && (
                                <ProductInteraction
                                  key={item.id}
                                  addProducts={(value, options) =>
                                    handleAddProduct(item, value, options)
                                  }
                                  products={item.products || []}
                                  productOptions={item.productOptions || []}
                                  context={tracking.source}
                                  css={css`
                                    width: 100%;
                                  `}
                                />
                              )}
                              {item.interactionType === 'question' && (
                                <BatchUploadQuestionWrapper
                                  key={item.id}
                                  addQuestion={(value) =>
                                    handleAddQuestion(item, value)
                                  }
                                  question={
                                    item.question || {
                                      prompt: null,
                                      collect_email: null
                                    }
                                  }
                                />
                              )}
                              {item.interactionType === 'cta' && (
                                <BatchUploadCTAWrapper
                                  key={item.id}
                                  addCTA={(value) => handleAddCTA(item, value)}
                                  cta={
                                    item.cta || {
                                      action_url: '',
                                      action_type: '',
                                      custom_label: ''
                                    }
                                  }
                                />
                              )}
                              {item.interactionType === 'poll' && (
                                <BatchUploadPollWrapper
                                  key={item.id}
                                  addPoll={(value) =>
                                    handleAddPoll(item, value)
                                  }
                                  poll={
                                    item.poll || {
                                      question: '',
                                      options: []
                                    }
                                  }
                                />
                              )}
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                      <PLGWrapper
                        elseNot={
                          <div
                            css={css`
                              margin-top: 12px;
                            `}
                          >
                            <Flex flexDirection="column" width="100%">
                              <Flex flex={1} flexDirection="column">
                                <Typography.Text
                                  style={{ fontWeight: 500, marginBottom: 4 }}
                                >
                                  {`${t('Tags')} (${
                                    item.editHashtags?.length || 0
                                  }/${MAX_BULK_HASHTAGS})`}
                                </Typography.Text>
                                <BulkHashtagComponent
                                  maxHashtagCount={MAX_BULK_HASHTAGS}
                                  value={item.editHashtags}
                                  setHashtagValues={(value) =>
                                    handleHashtags(item, value)
                                  }
                                  hashtagPosition="top"
                                  width="100%"
                                  size="large"
                                  showLabel={false}
                                  showCount={false}
                                />
                              </Flex>
                              {hasMultiple && (
                                <Flex justifyContent="flex-end" width="100%">
                                  <FWButton
                                    type="text"
                                    css={css`
                                      height: 40px;
                                      color: #424242 !important;
                                      padding: 0 !important;
                                    `}
                                    onClick={() => handleApplyAll(item)}
                                  >
                                    <span
                                      style={{ textDecoration: 'underline' }}
                                    >
                                      {t('Apply to all')}
                                    </span>
                                  </FWButton>
                                </Flex>
                              )}
                            </Flex>
                          </div>
                        }
                      />
                    </Flex>
                  </Flex>
                </div>
                <Flex
                  flexDirection="row"
                  mr="xlarge"
                  ml="xlarge2"
                  css={css`
                    flex-shrink: 0;
                  `}
                  opacity={
                    tracking.source === ImporterSource.ASYNC_MODAL ? 0.5 : 1
                  }
                  pointerEvents={
                    tracking.source === ImporterSource.ASYNC_MODAL
                      ? 'none'
                      : 'auto'
                  }
                >
                  {mediaList.length > 1 && (
                    <div>
                      <Box
                        css={css`
                          cursor: pointer;
                        `}
                        onClick={() => handleDelete(item)}
                        as="img"
                        src="/icon/trash.svg"
                        width={40}
                        height={40}
                      />
                    </div>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </div>
        )
      })}
      {togglePreviewModal && (
        <BatchImporterMediaPreviewModal
          modalVisible={togglePreviewModal}
          onCancelClick={() => handlePreviewMedia(null)}
          media={previewMedia}
          tracking={tracking}
        />
      )}
    </Flex>
  )
}

export default BatchImporterCaptionBody
