import { useEffect, useState } from 'react'

import { useAppSelector } from '@src/app/hooks'

export function useBusinessData({
  businessId
}): {
  businessType: string
  isPublisher: boolean
} {
  const business = useAppSelector(
    (state) => state.business.businesses[businessId]
  )

  const [businessType, setBusinessType] = useState(null)
  const [isPublisher, setIsPublisher] = useState(false)

  useEffect(() => {
    setBusinessType(business.type)
    if (business.type.includes('publisher')) {
      setIsPublisher(true)
    }
  }, [business])

  return { businessType, isPublisher }
}
