import React, { useCallback } from 'react'

import { Products } from '@src/common/components/Products'

import { ImporterSource } from '../BatchImporterMediaModel'

type ProductOptions = globalLib.ProductOptions

type ProductInteractionProps = {
  addProducts: (
    value: {
      business_store_id?: string
      product_id: string
    }[],
    productOptions: ProductOptions[]
  ) => void
  products: {
    business_store_id?: string
    product_id: string
  }[]
  productOptions: ProductOptions[]
  context?: ImporterSource
}

export const ProductInteraction: React.FC<ProductInteractionProps> = ({
  addProducts,
  products,
  productOptions = [],
  context = ImporterSource.BULK_UPLOAD
}) => {
  const [selectedProductIds, setSelectedProductIds] = React.useState<string[]>(
    products.reduce((acc: string[], product) => {
      if (product.product_id) {
        acc.push(product.product_id)
      }

      return acc
    }, [])
  )
  const [selectedProductOptions, setSelectedProductOptions] = React.useState<
    ProductOptions[]
  >(productOptions || [])

  const handleAddProducts = useCallback(
    (
      products: {
        business_store_id?: string
        product_id: string
      }[],
      productOptions: ProductOptions[]
    ) => {
      addProducts(products, productOptions)
    },
    [addProducts]
  )

  const convertAndAddProducts = (
    products: {
      business_store_id?: string
      product_id: string
    }[],
    productOptions: ProductOptions[]
  ) => {
    handleAddProducts(products, productOptions)
  }

  return (
    <Products
      convertAndAddProducts={convertAndAddProducts}
      selectedProductIds={selectedProductIds}
      setSelectedProductIds={setSelectedProductIds}
      selectedProductOptions={selectedProductOptions}
      setSelectedProductOptions={setSelectedProductOptions}
      context={context}
    />
  )
}
