import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useRef
} from 'react'

type VUState = Video.VUState

type VUActions = {
  setArchived: (value: VUState['archived_at']) => void
  setCaption: (value: VUState['caption']) => void
  setDescription: (value: VUState['description']) => void
  setAccess: (value: VUState['access']) => void
  setShowPrivateNotification: (
    value: VUState['showPrivateNotification']
  ) => void
  setRepostable: (value: VUState['repostable']) => void
  setVideoUrl: (value: VUState['videoUrl']) => void
  setVideoWH: (value: VUState['videoWH']) => void
  setThumbnailUrl: (value: VUState['thumbnailUrl']) => void
  setMediaKey: (value: VUState['mediaKey']) => void
  setVideoUploaded: (value: VUState['videoUploaded']) => void
  setSelectedProductIds: (value: VUState['selectedProductIds']) => void
  setSelectedProductOptions: (value: VUState['selectedProductOptions']) => void
  setCtaLink: (value: VUState['ctaLink']) => void
  setCtaType: (value: VUState['ctaType']) => void
  setCtaCustomLabel: (value: VUState['ctaCustomLabel']) => void
  setQuestionObj: (value: VUState['questionObj']) => void
  setPollObj: (value: VUState['pollObj']) => void
  setShowVideoOverlayModal: (value: VUState['showVideoOverlayModal']) => void
  setHashtagArray: (value: VUState['hashtagArray']) => void
  setConfirmLoading: (value: VUState['confirmLoading']) => void
  setChanged: (value: VUState['changed']) => void
  setNewPosterPayload: (value: VUState['newPosterPayload']) => void
  setNewAnimatedPosterPayload: (
    value: VUState['newAnimatedPosterPayload']
  ) => void
  setCustomGifAnimatedPosterPayload: (
    value?: VUState['customGifAnimatedPosterPayload'] | null
  ) => void
  setNewWidePosterPayload: (value: VUState['newWidePosterPayload']) => void
  setChangedPosters: (value: VUState['changedPosters']) => void
  setShowDiscardModal: (value: VUState['showDiscardModal']) => void
  setPublishTime: (value: VUState['publishTime']) => void
  setCanEditPublishTime: (value: VUState['canEditPublishTime']) => void
  setClearSchedule: (value: VUState['clearSchedule']) => void
  setSelectPlaylists: (value: VUState['selectPlaylists']) => void
  setBadge: (value: VUState['badge']) => void
  setSubtitles: (value: VUState['subtitles']) => void
  setShowUploadNow: (value: VUState['showUploadNow']) => void
  setOriginalPublishStartDate: (
    value: VUState['originalPublishStartDate']
  ) => void
  setShowUploadNowNotification: (
    value: VUState['showUploadNowNotification']
  ) => void
  setSearchedPlaylists: (value: VUState['searchedPlaylists']) => void
  setSubmitValues: (value: VUState['submitValues']) => void
  setAccessCode: (value: VUState['accessCode']) => void
  setSelectedInteractionType: (
    value: VUState['selectedInteractionType']
  ) => void
  setRemindMeDoubleOptIn: (value: VUState['remindMeDoubleOptIn']) => void
}

type ContextValue = {
  state: VUState
  actions: VUActions
  playerRef: React.MutableRefObject<HTMLVideoElement | null>
}

const initialState: VUState = {
  caption: '',
  description: null,
  access: 'public',
  showPrivateNotification: false,
  repostable: true,
  videoUrl: null,
  videoWH: undefined,
  selectedProductOptions: [],
  thumbnailUrl: null,
  mediaKey: null,
  videoUploaded: false,
  selectedProductIds: [],
  ctaLink: null,
  ctaType: null,
  ctaCustomLabel: null,
  questionObj: null,
  pollObj: null,
  showVideoOverlayModal: false,
  hashtagArray: [],
  confirmLoading: false,
  changed: false,
  newPosterPayload: null,
  newAnimatedPosterPayload: null,
  customGifAnimatedPosterPayload: null,
  newWidePosterPayload: null,
  changedPosters: false,
  showDiscardModal: false,
  publishTime: null,
  canEditPublishTime: true,
  originalPublishStartDate: null,
  clearSchedule: false,
  selectPlaylists: undefined,
  badge: null,
  subtitles: [],
  showUploadNow: false,
  showUploadNowNotification: false,
  searchedPlaylists: [],
  submitValues: {
    values: null,
    reset: () => null
  },
  has_access_code: false,
  accessCode: null,
  selectedInteractionType: null,
  remindMeDoubleOptIn: false,
  archived_at: ''
}

const stateActions = (
  dispatch: React.Dispatch<Partial<VUState>>
): VUActions => {
  return {
    setArchived: (archived_at) => dispatch({ archived_at }),
    setCaption: (caption) => dispatch({ caption }),
    setDescription: (description) => dispatch({ description }),
    setAccess: (access) => dispatch({ access }),
    setShowPrivateNotification: (showPrivateNotification) =>
      dispatch({ showPrivateNotification }),
    setRepostable: (repostable) => dispatch({ repostable }),
    setVideoUrl: (videoUrl) => dispatch({ videoUrl }),
    setVideoWH: (videoWH) => dispatch({ videoWH }),
    setThumbnailUrl: (thumbnailUrl) => dispatch({ thumbnailUrl }),
    setMediaKey: (mediaKey) => dispatch({ mediaKey }),
    setVideoUploaded: (videoUploaded) => dispatch({ videoUploaded }),
    setSelectedProductIds: (selectedProductIds) =>
      dispatch({ selectedProductIds }),
    setSelectedProductOptions: (selectedProductOptions) =>
      dispatch({ selectedProductOptions }),
    setCtaLink: (ctaLink) => dispatch({ ctaLink }),
    setCtaType: (ctaType) => dispatch({ ctaType }),
    setCtaCustomLabel: (ctaCustomLabel) => dispatch({ ctaCustomLabel }),
    setQuestionObj: (questionObj) => dispatch({ questionObj }),
    setPollObj: (pollObj) => dispatch({ pollObj }),
    setShowVideoOverlayModal: (showVideoOverlayModal) =>
      dispatch({ showVideoOverlayModal }),
    setHashtagArray: (hashtagArray) => dispatch({ hashtagArray }),
    setConfirmLoading: (confirmLoading) => dispatch({ confirmLoading }),
    setChanged: (changed) => dispatch({ changed }),
    setNewPosterPayload: (newPosterPayload) => dispatch({ newPosterPayload }),
    setNewAnimatedPosterPayload: (newAnimatedPosterPayload) =>
      dispatch({ newAnimatedPosterPayload }),
    setCustomGifAnimatedPosterPayload: (customGifAnimatedPosterPayload) =>
      dispatch({ customGifAnimatedPosterPayload }),
    setNewWidePosterPayload: (newWidePosterPayload) =>
      dispatch({ newWidePosterPayload }),
    setChangedPosters: (changedPosters) =>
      dispatch({ changedPosters, changed: true }),
    setShowDiscardModal: (showDiscardModal) => dispatch({ showDiscardModal }),
    setPublishTime: (publishTime) => dispatch({ publishTime }),
    setCanEditPublishTime: (canEditPublishTime) =>
      dispatch({ canEditPublishTime }),
    setClearSchedule: (clearSchedule) => dispatch({ clearSchedule }),
    setSelectPlaylists: (selectPlaylists) => dispatch({ selectPlaylists }),
    setBadge: (badge) => dispatch({ badge }),
    setSubtitles: (subtitles) => dispatch({ subtitles }),
    setShowUploadNow: (showUploadNow) => dispatch({ showUploadNow }),
    setOriginalPublishStartDate: (originalPublishStartDate) =>
      dispatch({ originalPublishStartDate }),
    setShowUploadNowNotification: (showUploadNowNotification) =>
      dispatch({ showUploadNowNotification }),
    setSearchedPlaylists: (searchedPlaylists) =>
      dispatch({ searchedPlaylists }),
    setSubmitValues: (submitValues) => dispatch({ submitValues }),
    setAccessCode: (accessCode) => dispatch({ accessCode }),
    setSelectedInteractionType: (selectedInteractionType) =>
      dispatch({ selectedInteractionType }),
    setRemindMeDoubleOptIn: (remindMeDoubleOptIn) =>
      dispatch({ remindMeDoubleOptIn })
  }
}

/** REDUCER */
const reducer = (state: VUState, payload: Partial<VUState>): VUState => {
  const newState = { ...state, ...payload }
  if (payload.ctaLink) {
    newState.selectedProductIds = []
    newState.selectedProductOptions = []
    newState.questionObj = null
    newState.pollObj = null
  }
  if (payload.questionObj) {
    newState.selectedProductIds = []
    newState.selectedProductOptions = []
    newState.ctaLink = null
    newState.pollObj = null
  }
  if (payload.pollObj) {
    newState.selectedProductIds = []
    newState.selectedProductOptions = []
    newState.questionObj = null
    newState.ctaLink = null
  }
  if (payload.selectedProductOptions) {
    newState.ctaLink = null
    newState.questionObj = null
    newState.pollObj = null
  }

  return newState
}

/** CONTEXT PROVIDER */
export const VUContext = createContext<ContextValue | undefined>(undefined)

const VUProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const actions = useMemo(() => stateActions(dispatch), [dispatch])
  const playerRef = useRef<HTMLVideoElement>(null)

  return (
    <VUContext.Provider value={{ state, actions, playerRef }}>
      {children}
    </VUContext.Provider>
  )
}

/** Hook wrapper for useContext **/
export const useVUContext = (): ContextValue => {
  const ctx = useContext(VUContext)
  if (!ctx) throw new Error('useVUContext must be used within VUProvider')
  else return ctx
}

export default VUProvider
