import React from 'react'

import { Box } from '@src/common/components/BaseComponent'
import EmbedIframe from '@src/common/components/EmbedIframe'
import usePixelAmpTracking from '@src/common/hooks/usePixelAmpTracking'
import { SIGMA_INSIGHTS } from '@src/constants'
import { useParams } from 'react-router-dom'

const InsightsTab: React.FC = () => {
  usePixelAmpTracking()
  const { videoId } = useParams()

  return (
    <Box height="100%" flex="1">
      <EmbedIframe level={SIGMA_INSIGHTS.VIDEO_SHORT_VIDEO} id={videoId} />
    </Box>
  )
}

export default InsightsTab
