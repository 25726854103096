import {
  createAsyncThunk,
  createSlice,
  Slice,
  SliceCaseReducers
} from '@reduxjs/toolkit'
import api from '@src/common/utils/api'
import { Locale } from '@src/common/utils/languages'

const SLICE_NAME = 'microsites'

export type PageType = {
  id?: string
  title: string
  path: string
  locale: Locale | string
  metadata?: {
    meta_description?: string
    meta_tags?: string
    config?: {
      ui_theme?: 'string'
      ui_font?: 'string'
      ui_full_cta?: boolean
      ui_border_style?: 'string'
      ui_button_color?: 'string'
      ui_button_font_color?: 'string'
    }
  } | null
  analytics_tags?: string
  blocks: ContentBlock[]
  is_draft: boolean
  published_at?: string | null
  // FE only
  isMerged?: true
  prodId?: string
}

type Image = {
  alt: string
  url: string
}

export type ContentBlock = {
  id: string
  type: Microsite.BlockType
  version: number
  config?: {
    hero_text_mode?: 'even' | 'stretched'
    hero_flip_order?: boolean
    mode?: 'default' | 'pinned' | 'fullscreen'
    dock?: boolean
    captions?: boolean
    autoplay?: boolean
    max_videos?: number
    paginate_by?: number
  }
  content?: {
    title?: string
    playlist_id?: string
    video_id?: string
    logo?: Image
    logo_position?: string
    navigation?: Microsite.Link[]
    copyright?: string
    social?: Microsite.SocialLink[]
    color?: string
    size?: 'small' | 'medium' | 'full'
    padding?: 'small' | 'medium' | 'large'
    line_weight?: number
    text?: string
    align?: 'left' | 'center' | 'right'
    image?: Image
    html?: string
  }
  appearance?: {
    bg_color?: string
    bg_image?: string
    bg_repeat?: string
    bg_attachment?: string
    bg_position?: string
    font_color?: string
    font_size?: number
  }
}

type Instructions = {
  cname: {
    address: string
    value: string
  }
  txt: {
    address: string
    value: string
  }
}

type SliceState = {
  instructions: Record<string, Instructions>
  domains: Microsite.DomainType[]
  pages: PageType[]
  allPages: PageType[]
  isLoading: boolean
  error: string | null
}

const initialState: SliceState = {
  instructions: {},
  isLoading: false,
  error: null,
  domains: [],
  pages: [],
  allPages: []
}

export const fetchDomains = createAsyncThunk(
  `${SLICE_NAME}/fetchDomains`,
  async (params: { channelId: string }) => {
    const response = await api.get(
      `/microsites/channels/${params.channelId}/domains`
    )

    return { domains: response.data.data }
  }
)

export const createDomain = createAsyncThunk(
  `${SLICE_NAME}/createDomain`,
  async (params: { channelId: string; domainName: string }) => {
    const response = await api.post(
      `/microsites/channels/${params.channelId}/domains`,
      {
        domain_name: params.domainName
      }
    )

    return { domain: response.data.data }
  }
)

export const deleteDomain = createAsyncThunk(
  `${SLICE_NAME}/deleteDomain`,
  async (params: { channelId: string; domainId: string }) => {
    await api.delete(
      `/microsites/channels/${params.channelId}/domains/${params.domainId}`
    )

    return { domainId: params.domainId }
  }
)

export const refreshDomains = createAsyncThunk(
  `${SLICE_NAME}/refreshDomain`,
  async (params: {
    channelId: string
    domainName: string
    domainId: string
  }) => {
    const response = await api.post(
      `/microsites/channels/${params.channelId}/domains`,
      {
        domain_name: params.domainName
      }
    )

    return { domain: response.data.data, oldId: params.domainId }
  }
)

export const getDNSInstructions = createAsyncThunk(
  `${SLICE_NAME}/getDNSInstructions`,
  async (params: { channelId: string; domainName: string }) => {
    const response = await api.get(
      `/microsites/channels/${params.channelId}/instructions?domain_name=${params.domainName}`
    )

    return {
      domainName: params.domainName,
      dns: response.data.data
    }
  }
)

export const fetchPages = createAsyncThunk(
  `${SLICE_NAME}/fetchPages`,
  async (params: { channelId: string }) => {
    const response = await api.get(
      `/microsites/channels/${params.channelId}/pages`
    )

    return { pages: response.data.data }
  }
)

export const createPage = createAsyncThunk(
  `${SLICE_NAME}/createPage`,
  async (params: { page: PageType; channelId: string }) => {
    const response = await api.post(
      `/microsites/channels/${params.channelId}/pages`,
      { ...params.page, is_draft: true }
    )

    return { page: response.data.data }
  }
)

export const updatePage = createAsyncThunk(
  `${SLICE_NAME}/updatePage`,
  async (params: { page: PageType; channelId: string }) => {
    const response = await api.patch(
      `/microsites/channels/${params.channelId}/pages/${params.page.id}`,
      params.page
    )

    return { page: response.data.data }
  }
)

export const publishPage = createAsyncThunk(
  `${SLICE_NAME}/publishPage`,
  async (params: { page: PageType; channelId: string }) => {
    const prodId = params.page.prodId ? params.page.prodId : params.page.id

    const response = await api.patch(
      `/microsites/channels/${params.channelId}/pages/${prodId}`,
      { ...params.page, id: prodId, is_draft: false }
    )

    if (params.page.prodId) {
      await api.delete(
        `/microsites/channels/${params.channelId}/pages/${params.page.id}`
      )
    }

    return { page: response.data.data }
  }
)

export const deletePage = createAsyncThunk(
  `${SLICE_NAME}/deletePage`,
  async (params: { channelId: string; id: string }) => {
    await api.delete(
      `/microsites/channels/${params.channelId}/pages/${params.id}`
    )

    return { id: params.id }
  }
)

export const setDefaultPage = createAsyncThunk(
  `${SLICE_NAME}/setDefaultPage`,
  async (params: { domainId: string; pageId: string; channelId: string }) => {
    const response = await api.patch(
      `/microsites/channels/${params.channelId}/domains/${params.domainId}`,
      {
        default_page_id: params.pageId
      }
    )

    return { domain: response.data.data }
  }
)

const slice: Slice<
  SliceState,
  SliceCaseReducers<SliceState>,
  typeof SLICE_NAME
> = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDomains.pending, onStart)
      .addCase(fetchDomains.rejected, onError)
      .addCase(fetchDomains.fulfilled, (state, action) => {
        const { domains = [] } = action.payload
        state.domains = domains
        state.isLoading = false
      })
      .addCase(createDomain.pending, onStart)
      .addCase(createDomain.rejected, onError)
      .addCase(createDomain.fulfilled, (state, action) => {
        const { domain } = action.payload
        state.isLoading = false
        state.domains = [...state.domains, domain]
      })
      .addCase(deleteDomain.pending, onStart)
      .addCase(deleteDomain.rejected, onError)
      .addCase(deleteDomain.fulfilled, (state, action) => {
        const { domainId } = action.payload
        state.isLoading = false
        state.domains = state.domains.filter(({ id }) => id !== domainId)
      })
      .addCase(refreshDomains.pending, onStart)
      .addCase(refreshDomains.rejected, onError)
      .addCase(refreshDomains.fulfilled, (state, action) => {
        const { domain, oldId } = action.payload
        state.domains = state.domains.filter(({ id }) => id !== oldId)
        state.isLoading = false
        state.domains = [...state.domains, domain]
      })
      .addCase(getDNSInstructions.rejected, onError)
      .addCase(getDNSInstructions.fulfilled, (state, action) => {
        const { dns, domainName } = action.payload
        state.error = null
        state.instructions[domainName] = dns
        state.isLoading = false
      })
      .addCase(setDefaultPage.pending, onStart)
      .addCase(setDefaultPage.rejected, onError)
      .addCase(setDefaultPage.fulfilled, (state, action) => {
        const { domain } = action.payload
        state.domains = state.domains.filter(({ id }) => id !== domain.id)
        state.isLoading = false
        state.domains = [...state.domains, domain]
      })
      .addCase(fetchPages.pending, onStart)
      .addCase(fetchPages.rejected, onError)
      .addCase(fetchPages.fulfilled, (state, action) => {
        const { pages = [] } = action.payload
        state.allPages = pages
        state.pages = mergePages(pages)
        state.isLoading = false
      })
      .addCase(createPage.pending, onStart)
      .addCase(createPage.rejected, onError)
      .addCase(createPage.fulfilled, (state, action) => {
        const { page = {} } = action.payload
        state.allPages.unshift(page)
        state.pages = mergePages(state.allPages)
        state.isLoading = false
      })
      .addCase(updatePage.pending, onStart)
      .addCase(updatePage.rejected, onError)
      .addCase(updatePage.fulfilled, (state, action) => {
        const { page = {} } = action.payload
        state.allPages = state.allPages.map((pg) =>
          pg.id === page.id ? page : pg
        )
        state.pages = mergePages(state.allPages)
        state.isLoading = false
      })
      .addCase(deletePage.pending, onStart)
      .addCase(deletePage.rejected, onError)
      .addCase(deletePage.fulfilled, (state, action) => {
        const { id } = action.payload
        state.isLoading = false
        state.allPages = state.allPages.filter((page) => page.id !== id)
        state.pages = mergePages(state.allPages)
      })
      .addCase(publishPage.pending, onStart)
      .addCase(publishPage.rejected, onError)
      .addCase(publishPage.fulfilled, (state, action) => {
        const { page = {} } = action.payload
        state.allPages = state.allPages.filter((pg) => pg.path !== page.path)
        state.allPages.unshift(page)
        state.pages = mergePages(state.allPages)
        state.isLoading = false
      })
  }
})

// Helper actions
function onStart(state) {
  state.isLoading = true
  state.error = null
}

function onError(state, action) {
  state.error = action.type
  state.isLoading = false
}

function mergePages(pages) {
  const map = {}

  for (let i = 0; i < pages.length; i++) {
    const page = pages[i]

    if (map[page.path]) {
      let prodId = map[page.path].id

      if (page.is_draft) {
        map[page.path] = page
      } else {
        prodId = page.id
      }

      map[page.path].isMerged = true
      map[page.path].prodId = prodId
    } else map[page.path] = page
  }

  return Object.values(map) as PageType[]
}

export default slice.reducer
