import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import { css } from '@emotion/react'
import { useAppSelector } from '@src/app/hooks'
import { Flex, Label } from '@src/common/components/BaseComponent'
import { uploadVideoSearchHashtags } from '@src/common/redux/channel'
import {
  BULK_HASHTAG_ACTION,
  MAX_BULK_HASHTAGS,
  MAX_HASHTAG_LENGTH,
  MAX_HASHTAG_VISIBLE_LENGTH
} from '@src/constants'
import { trimText } from '@src/modules/channel/utils/Insights/stats'
import { Select, Tag } from 'antd'
import debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

const { Option } = Select

type IProps = {
  setHashtagValues: React.Dispatch<SetStateAction<string[]>>
  maxHashtagCount: number
  value?: string[]
  hashtagPosition?: string // top/center/bottom
  width?: number | string
  size?: string
  showLabel?: boolean
  boldLabel?: boolean
  showCount?: boolean
  disabled?: boolean
}

function search(query, dispatch) {
  dispatch(uploadVideoSearchHashtags(query))
}

const debouncedSearch = debounce(search, 300)

function hashtagMode(mode: string): 'tags' | undefined | 'multiple' {
  const hash = {
    top: undefined,
    bottom: undefined,
    center: 'tags'
  }

  return hash[mode]
}

const BulkHashtagComponent = ({
  setHashtagValues,
  maxHashtagCount,
  value = [],
  hashtagPosition = BULK_HASHTAG_ACTION.BOTTOM,
  width,
  size,
  showLabel = true,
  showCount = true,
  boldLabel = true,
  disabled = false
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [hashtagCount, setHashtagCount] = useState(0)
  const [currentHashtags, setCurrentHashtags] = useState(value)
  const [inputValue, setInputValue] = useState<string[] | string | undefined>(
    hashtagPosition === BULK_HASHTAG_ACTION.CENTER ? value : undefined
  )
  const hashtagSearch = useAppSelector(
    (state) => state.channel.searchedHashtagNames || []
  )
  const handleHashtagSearch = useCallback(
    (input) => {
      const value = input.replace(/ /g, '')
      if (value.length <= MAX_HASHTAG_LENGTH || value.includes(',')) {
        debouncedSearch(value, dispatch)
        hashtagPosition !== BULK_HASHTAG_ACTION.CENTER && setInputValue(value)
      }
    },
    [dispatch, hashtagPosition]
  )

  useEffect(() => {
    setCurrentHashtags(value)
    setHashtagCount(value.length)
  }, [value])

  const handleInput = useCallback(
    (hashtag) => {
      const value =
        typeof hashtag === 'string'
          ? currentHashtags.includes(hashtag)
            ? currentHashtags
            : [...currentHashtags, hashtag]
          : hashtag
      if (value.length <= maxHashtagCount) {
        setHashtagValues(value)
        setCurrentHashtags(value)
        setHashtagCount(value?.length)
      }
      setInputValue('')
    },
    [setHashtagValues, currentHashtags, maxHashtagCount]
  )

  const handleInputChange = debounce(handleInput, 300, {
    leading: true,
    trailing: false
  })

  const handleCloseHashtag = useCallback(
    (value) => {
      handleInputChange(currentHashtags.filter((h) => h !== value))
    },
    [currentHashtags, handleInputChange]
  )

  const currentHashtagComponent = useMemo(
    () => (
      <Flex width={width || 380} flexWrap="wrap">
        {currentHashtags.map((hashtag) => (
          <Tag
            closable
            key={hashtag}
            color="#E5F0FF"
            css={css`
              svg {
                color: #000000;
              }
            `}
            onClose={() => handleCloseHashtag(hashtag)}
            style={{
              color: 'black',
              marginRight: 4,
              marginTop: hashtagPosition === BULK_HASHTAG_ACTION.BOTTOM ? 4 : 0,
              marginBottom: hashtagPosition === BULK_HASHTAG_ACTION.TOP ? 4 : 0
            }}
          >
            {trimText(hashtag, MAX_HASHTAG_VISIBLE_LENGTH)}
          </Tag>
        ))}
      </Flex>
    ),
    [width, currentHashtags, handleCloseHashtag, hashtagPosition]
  )

  const handleKeyDown = useCallback(
    (event) => {
      if (
        event.key === 'Enter' &&
        hashtagPosition !== BULK_HASHTAG_ACTION.CENTER &&
        currentHashtags.length < maxHashtagCount &&
        inputValue &&
        currentHashtags.indexOf(
          typeof inputValue === 'string'
            ? inputValue
            : inputValue[inputValue.length - 1]
        ) < 0
      ) {
        event.preventDefault()
        if (inputValue?.includes(',') && typeof inputValue === 'string') {
          const hashtags = inputValue.split(',')
          const currentHashtagsCopy = [...currentHashtags]
          for (const h of hashtags) {
            if (
              !currentHashtagsCopy.includes(h) &&
              currentHashtagsCopy.length < MAX_BULK_HASHTAGS
            )
              currentHashtagsCopy.push(h)
          }
          handleInputChange(currentHashtagsCopy)
        } else if (typeof inputValue === 'string' && inputValue.length) {
          handleInputChange([...currentHashtags, inputValue])
        }
      }
    },
    [
      currentHashtags,
      inputValue,
      handleInputChange,
      maxHashtagCount,
      hashtagPosition
    ]
  )

  return (
    <Flex flexDirection="column" alignItems="flex-start" width="100%" gap="4">
      {showLabel && (
        <Flex flexDirection="row" display="flex" gap="12">
          <Label bold={boldLabel} aria-label={t('Hashtags')}>
            {t('Hashtags')}
          </Label>
          {showCount && (
            <Label bold={false}>{`(${hashtagCount}/${maxHashtagCount})`}</Label>
          )}
        </Flex>
      )}
      {hashtagPosition === BULK_HASHTAG_ACTION.TOP && currentHashtagComponent}
      <Select
        mode={hashtagMode(hashtagPosition)}
        maxTagCount={maxHashtagCount}
        maxTagTextLength={MAX_HASHTAG_LENGTH}
        showSearch={hashtagPosition !== BULK_HASHTAG_ACTION.CENTER}
        value={inputValue}
        placeholder={t('Press enter to add hashtag')}
        onSearch={handleHashtagSearch}
        onChange={handleInputChange}
        onInputKeyDown={handleKeyDown}
        // @ts-expect-error type check
        size={size || 'middle'}
        style={{
          width: width || 380
        }}
        disabled={disabled}
      >
        {hashtagSearch.map((hashtag) => {
          return <Option key={hashtag}>{hashtag}</Option>
        })}
      </Select>
      {hashtagPosition === BULK_HASHTAG_ACTION.BOTTOM &&
        currentHashtagComponent}
    </Flex>
  )
}

export default BulkHashtagComponent
