import React from 'react'

import { css } from '@emotion/core'
import { Box, Flex } from '@src/common/components/BaseComponent'
import theme from '@src/styles/theme'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'

type IProps = {
  type?: string
  onHelpBtnClick: () => void
}
const CopiedModal: React.FC<IProps> = ({ type = null, onHelpBtnClick }) => {
  const { t } = useTranslation()

  function renderText() {
    switch (type) {
      case 'email':
        return t(
          'Paste this code directly into the email body and it should appear as a clickable image.'
        )
      default:
        return t(
          "Paste this code directly into the HTML portion of your site, and you'll be good to go. "
        )
    }
  }

  return (
    <Flex flexDirection="column" alignItems="center">
      <Box
        as="img"
        alt="copied"
        src={type === 'email' ? '/copied_email.png' : '/copied.png'}
        mb="xxxlarge"
      />
      <Flex flexDirection="column" justifyContent="flex-start">
        <Typography.Title level={5}>{t('Code Copied')}</Typography.Title>
        <Typography.Paragraph>{renderText()}</Typography.Paragraph>
        {type !== 'email' ? (
          <Typography.Paragraph>
            {t('Need more info? Check out our ')}
            <span
              css={css`
                color: ${theme.primary};
                cursor: pointer;
              `}
              onClick={onHelpBtnClick}
            >
              {t('Learn more about embeds')}
            </span>
            {t(' page.')}
          </Typography.Paragraph>
        ) : null}
      </Flex>
    </Flex>
  )
}

export default CopiedModal
