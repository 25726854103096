import React, { useMemo } from 'react'

import { css } from '@emotion/core'
import { Box, Flex, LinkButton } from '@src/common/components/BaseComponent'
import { Title } from '@src/common/components/BaseComponent'
import { Divider } from '@src/common/components/BaseComponent'
import { VideoCardIconContainer } from '@src/common/components/VideoCardIconContainer'
import {
  addCdnUrl2BatchImporterMedia,
  BatchImporterMedia,
  BatchImporterMediaType,
  ImporterSource,
  ImporterSourceFrom,
  mediaTypeIconMap,
  TrackingDetails
} from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import ConsentStatusIcon from '@src/modules/channel/components/Library/Importer/BatchImporter/Media/ConsentStatusIcon'
import { Avatar, Checkbox } from 'antd'
import { useTranslation } from 'react-i18next'

interface IProps {
  forView?: boolean
  onlyThumbnail?: boolean
  canChecked?: boolean
  checked?: boolean
  indeterminate?: boolean
  mediaItem?: BatchImporterMedia
  onSelectedMedia?: (checked: boolean, value: BatchImporterMedia) => void
  onPreviewMedia?: (value: BatchImporterMedia) => void
  tracking: TrackingDetails
}

const BatchImporterMediaItem: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const {
    forView,
    onlyThumbnail,
    checked,
    indeterminate,
    mediaItem: inputMediaItem,
    onSelectedMedia,
    onPreviewMedia,
    tracking
  } = props

  const mediaItem = useMemo(() => {
    return addCdnUrl2BatchImporterMedia(inputMediaItem)
  }, [inputMediaItem])

  const canChecked = !mediaItem?.disabled

  const handleSelectedMedia = (checked: boolean, value: BatchImporterMedia) => {
    if (onSelectedMedia) {
      onSelectedMedia(checked, value)
    }
  }

  const avatarIcon = useMemo(() => {
    if (
      tracking.source === ImporterSource.CONSENT_REQUESTS_PAGE ||
      tracking.source === ImporterSource.ASYNC_MODAL
    ) {
      switch (mediaItem.sourceFrom) {
        case ImporterSourceFrom.Instagram:
          return '/icon/ig_white.svg'
        case ImporterSourceFrom.Tiktok:
          return '/icon/tiktok_white.svg'
      }
    }

    return mediaItem?.avatar
  }, [mediaItem, tracking])

  return (
    <div
      css={css`
        background: #f7f7f7;
        border-radius: 6px;
        padding-bottom: 178%;
        position: relative;
        overflow: hidden;

        .importer-media-item-preview-button {
          display: none;
        }
        .importer-media-item-info {
          display: flex;
        }

        &:hover {
          .importer-media-item-preview-button {
            display: ${canChecked ? 'flex' : 'none'};
          }
          .importer-media-item-info {
            display: ${canChecked ? 'none' : 'flex'};
          }
        }
      `}
    >
      <div
        onClick={() => {
          if (!forView && canChecked) {
            handleSelectedMedia(!checked, mediaItem)
          }
        }}
        css={css`
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          cursor: ${forView ? 'default' : 'pointer'};
        `}
      >
        {mediaItem?.thumbnailUrl && (
          <img
            src={mediaItem?.thumbnailUrl}
            alt=""
            loading="lazy"
            css={css`
              object-fit: cover;
              width: 100%;
              height: 100%;
            `}
          />
        )}
        {!mediaItem?.thumbnailUrl && (
          <>
            {mediaItem.mediaType === BatchImporterMediaType.VIDEO && (
              <Box
                as="video"
                src={mediaItem?.mediaUrl}
                alt=""
                loading="lazy"
                css={css`
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                `}
              />
            )}
            {mediaItem.mediaType === BatchImporterMediaType.IMAGE && (
              <img
                src={mediaItem?.mediaUrl}
                alt=""
                loading="lazy"
                css={css`
                  object-fit: cover;
                  width: 100%;
                  height: 100%;
                `}
              />
            )}
            {mediaItem.mediaType === BatchImporterMediaType.CAROUSEL_ALBUM &&
              mediaItem.mediaUrl && (
                <img
                  src={mediaItem.mediaUrl}
                  alt=""
                  loading="lazy"
                  css={css`
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                  `}
                />
              )}
            {mediaItem.mediaType === BatchImporterMediaType.CAROUSEL_ALBUM &&
              !mediaItem.mediaUrl &&
              mediaItem.children?.[0].mediaType ===
                BatchImporterMediaType.IMAGE && (
                <img
                  src={mediaItem.children?.[0].mediaUrl}
                  alt=""
                  loading="lazy"
                  css={css`
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                  `}
                />
              )}
            {mediaItem.mediaType === BatchImporterMediaType.CAROUSEL_ALBUM &&
              !mediaItem.mediaUrl &&
              mediaItem.children?.[0].mediaType ===
                BatchImporterMediaType.VIDEO && (
                <Box
                  as="video"
                  src={mediaItem.children?.[0].mediaUrl}
                  alt=""
                  loading="lazy"
                  css={css`
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                  `}
                />
              )}
          </>
        )}
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          height="33.33%"
          backgroundImage="linear-gradient(to bottom, rgba(43, 43, 43, 0.3), rgba(43, 43, 43, 0))"
          width="100%"
        />
        <Box
          position="absolute"
          bottom="0"
          left="0"
          right="0"
          height="50%"
          backgroundImage="linear-gradient(to top, rgba(43, 43, 43, 1), rgba(43, 43, 43, 0))"
          width="100%"
        />
        {!forView && (
          <div
            css={css`
              position: absolute;
              top: 10px;
              left: 10px;
              z-index: 1;
            `}
          >
            <Checkbox
              style={{
                transform: 'scale(1.5)'
              }}
              checked={checked}
              indeterminate={indeterminate}
              disabled={!canChecked}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => {
                handleSelectedMedia(e.target.checked, mediaItem)
              }}
            />
          </div>
        )}
        {!onlyThumbnail && (
          <div
            css={css`
              position: absolute;
              top: 10px;
              right: 10px;
              z-index: 1;
            `}
          >
            <Flex gap={4} alignItems="center">
              {mediaItem?.needConsent && mediaItem?.consentStatus && (
                <ConsentStatusIcon status={mediaItem?.consentStatus} />
              )}
              <VideoCardIconContainer padding="4px">
                <img
                  alt="media_type"
                  src={`${mediaTypeIconMap[mediaItem?.mediaType]}`}
                />
              </VideoCardIconContainer>
            </Flex>
          </div>
        )}
        {!canChecked && !forView && (
          <div
            css={css`
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              background-color: #ffffff;
              opacity: 0.8;
            `}
          />
        )}
        {!onlyThumbnail && (
          <div
            css={css`
              position: absolute;
              bottom: 10px;
              left: 10px;
              right: 10px;
              z-index: 1;
            `}
          >
            <Flex className="importer-media-item-preview-button">
              <LinkButton
                title={t('Preview')}
                onClick={() => {
                  if (onPreviewMedia && (canChecked || forView)) {
                    onPreviewMedia(mediaItem)
                  }
                }}
              />
            </Flex>
            <Flex className="importer-media-item-info" flexDirection="column">
              {(!!mediaItem?.likeCount || mediaItem?.username) && (
                <Flex flexDirection="column" width="100%">
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    gap="8px"
                  >
                    {mediaItem?.username && (
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        gap="6px"
                        css={css`
                          overflow: hidden;
                        `}
                      >
                        {avatarIcon && (
                          <Avatar
                            src={avatarIcon}
                            css={css`
                              flex-shrink: 0;
                            `}
                            size={16}
                          />
                        )}
                        <Title
                          fontSize={12}
                          weight={500}
                          color="#fff"
                          noMargin
                          ellipsis={{ rows: 1 }}
                        >
                          {`@${mediaItem?.username}`}
                        </Title>
                      </Flex>
                    )}
                    {!!mediaItem?.likeCount && (
                      <Flex
                        flexDirection="row"
                        alignItems="center"
                        gap="2px"
                        css={css`
                          flex-shrink: 0;
                        `}
                      >
                        <img
                          width="12px"
                          height="12px"
                          alt="like-count"
                          src="/icon/heart.svg"
                        />
                        <div
                          css={css`
                            color: white;
                            font-size: 8px;
                            font-weight: 500;
                          `}
                        >
                          {mediaItem.likeCount}
                        </div>
                      </Flex>
                    )}
                  </Flex>
                  {mediaItem?.caption && <Divider my="8px" />}
                </Flex>
              )}
              {mediaItem?.caption && (
                <div
                  css={css`
                    color: white;
                    font-size: 14px;
                    font-weight: 500;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  `}
                >
                  {mediaItem?.caption}
                </div>
              )}
            </Flex>
          </div>
        )}
      </div>
    </div>
  )
}

export default BatchImporterMediaItem
