export default {
  // Colors
  primary: '#0F6BFF',
  gray10: '#848484',
  gray12: '#9d9d9d',
  gray13: '#9b9b9b',
  gray14: '#e2e2e2',
  gray15: '#bdbdbd',
  gray16: '#F5F5F5',
  gray17: '#F9F9F9',
  gray18: '#F3F3F3',
  gray19: '#717171',
  gray20: '#d9d9d9',
  gray21: '#C4C4C4',
  grayScale300: '#e1e4e8',
  grayScale100: '#F5F7FA',
  baseGray: '#F0F0F0',
  text: '#404040',
  purple: '#A40FF4',
  violet: '#240B36',
  fadedPrimary: '#EBF3FF',
  green: '#22C67A',
  linkBlue: '#175A99',
  red: '#F40C70',
  white: '#FFFFFF',
  orange: '#FFA723',
  danger: '#E90000',
  textSecondary: '#6B6E73',
  textPrimary: '#3F4145',
  textTertiary: '#B2B6BB',
  titleColor: '#2B2B2B',
  subTitleColor: '#757575',

  // Sidebar
  sidebarBg: '#001B40',
  sidebarHover: '#2D3545',
  sidebarActiveBorder: '#0F6BFF',
  sidebarActive: '#0060E5',
  sidebarLinkRow: '#A0ACBF',
  sidebarActiveIconColor: '#ffffff',

  // Radius
  radiusCard: '2px',
  radiusButton: '2px',

  // Width
  widthThumbnail: '160px',

  // Height
  heightThumbnail: '260px'
}

// Looked up from https://chir.ag/projects/name-that-color
const COLOR_CORNFLOWER_BLUE = '#6091f7'
const COLOR_CORNFLOWER = '#99d0e7'
const COLOR_FOUNTAIN_BLUE = '#58bec0'
const COLOR_VISTA_BLUE = '#9adcc1'
const COLOR_FERN = '#61be67'
const COLOR_FEIJOA = '#aad679'
const COLOR_CREAM_CAN = '#f5d364'
const COLOR_TUMBLEWEED = '#e1a87d'
const COLOR_MANDY = '#de5666'
const COLOR_VIOLA = '#d088ce'
export const CHART_COLORS = [
  COLOR_CORNFLOWER_BLUE,
  COLOR_CORNFLOWER,
  COLOR_FOUNTAIN_BLUE,
  COLOR_VISTA_BLUE,
  COLOR_FERN,
  COLOR_FEIJOA,
  COLOR_CREAM_CAN,
  COLOR_TUMBLEWEED,
  COLOR_MANDY,
  COLOR_VIOLA
]

export const boxShadows = {
  shadow1:
    '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)',
  shadow2:
    '0px 3px 6px -4px #0000001F, 0px 6px 16px 0px #00000014, 0px 9px 28px 8px #0000000D'
}
