import { localStorageProvider } from '@src/common/utils/storageProvider'

const saveTiktokHashtagList = (userId: string, hashtagList: string[]): void => {
  localStorageProvider.setItem(
    `fw_tiktok_hashtag_list_${userId}`,
    JSON.stringify([...new Set(hashtagList)])
  )
}

const getTiktokHashtagList = (userId: string): string[] => {
  return JSON.parse(
    localStorageProvider.getItem(`fw_tiktok_hashtag_list_${userId}`) || '[]'
  )
}

const saveTiktokUsernameList = (
  userId: string,
  usernameList: string[]
): void => {
  localStorageProvider.setItem(
    `fw_tiktok_username_list_${userId}`,
    JSON.stringify([...new Set(usernameList)])
  )
}

const getTiktokUsernameList = (userId: string): string[] => {
  return JSON.parse(
    localStorageProvider.getItem(`fw_tiktok_username_list_${userId}`) || '[]'
  )
}

export {
  saveTiktokHashtagList,
  getTiktokHashtagList,
  saveTiktokUsernameList,
  getTiktokUsernameList
}
