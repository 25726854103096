import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'

import { Option, Select } from '@src/common/components/Select/Select'
import { searchPlaylists } from '@src/common/redux/playlist'
import { TOP_PLAYLIST } from '@src/constants'
import { SelectProps } from 'antd'
import { RefSelectProps } from 'antd/lib/select'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

export interface PlaylistSelectProps {
  setPlaylists: Dispatch<SetStateAction<string[]>>
  playlists: string[]
  inputRef?: React.Ref<RefSelectProps>
  multiple?: boolean
  defaultSearchedPlaylists?: globalLib.Playlist[]
  maxTagCount?: number | 'responsive'
  showSearch?: boolean
  videosFetchStatus?: 'loading' | 'success' | 'error'
}

export const PlaylistSelect: React.FC<PlaylistSelectProps & SelectProps> = ({
  playlists,
  setPlaylists,
  inputRef,
  multiple = false,
  defaultSearchedPlaylists = [],
  maxTagCount = 'responsive',
  showSearch = true,
  videosFetchStatus = 'success',
  ...props
}) => {
  const { businessId, channelId } = useParams()
  const { t } = useTranslation()
  const [playlistSearchValue, setPlaylistSearchValue] = useState<string>()
  const [searchedPlaylists, setSearchedPlaylists] = useState<
    globalLib.Playlist[]
  >(defaultSearchedPlaylists)

  const timer = useRef<ReturnType<typeof setTimeout>>(null)
  const handlePlaylistSearch = useCallback(
    (value: string) => {
      setPlaylistSearchValue(value)
      const loadPlaylists = async (value) => {
        const playlistResults = ((await searchPlaylists({
          businessId,
          channelId,
          q: value,
          page_size: 100
        })) as unknown) as globalLib.Playlist[]
        setSearchedPlaylists(playlistResults)
      }

      clearTimeout(timer.current)
      timer.current = setTimeout(() => loadPlaylists(value), 350)
    },
    [businessId, channelId]
  )

  const handleChange = (value) => {
    setPlaylists(value)
    setPlaylistSearchValue('')
  }

  const initialFetch = useRef(false)
  useEffect(() => {
    if (!initialFetch.current && !defaultSearchedPlaylists.length) {
      handlePlaylistSearch('')
      initialFetch.current = true
    }
  }, [handlePlaylistSearch, defaultSearchedPlaylists])

  return (
    <Select
      ref={inputRef}
      showArrow
      showSearch={showSearch}
      mode={multiple ? 'multiple' : undefined}
      maxTagCount={maxTagCount}
      filterOption={false}
      value={playlists}
      onChange={handleChange}
      searchValue={playlistSearchValue}
      defaultActiveFirstOption={false}
      placeholder={t('Search for a playlist')}
      notFoundContent={t('No playlists found')}
      onSearch={handlePlaylistSearch}
      style={{ width: '100%' }}
      onBlur={() => handlePlaylistSearch('')}
      status={videosFetchStatus === 'error' ? 'error' : undefined}
      {...props}
    >
      {searchedPlaylists.map(
        (playlist) =>
          playlist.description !== TOP_PLAYLIST.UNIQUE_DESCRIPTION && (
            <Option value={playlist.id} key={playlist.id}>
              {playlist.name}
            </Option>
          )
      )}
    </Select>
  )
}
