import React, { useContext } from 'react'

import { RocketOutlined } from '@ant-design/icons'
import { useAppSelector } from '@src/app/hooks'
import { FWButton } from '@src/common/components/BaseComponent'
import { BusinessContext } from '@src/common/contexts/BusinessContext'
import usePixelAmpTracking from '@src/common/hooks/usePixelAmpTracking'
import { isPLGBusiness } from '@src/common/redux/selectors/subscription'
import { TRACKING_EVENTS } from '@src/common/utils/tracking'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

type Props = {
  isPrimary?: boolean
  size?: SizeType
  trackingProps?: {
    [key: string]: any
  }
}

export const UpgradeButton: React.FC<Props> = ({ trackingProps }) => {
  const businessId = useContext(BusinessContext)
  const { t } = useTranslation()
  const { pixelAmpTracking } = usePixelAmpTracking()
  const navigate = useNavigate()

  const plgBusiness = useAppSelector((state) =>
    isPLGBusiness(state, businessId)
  )

  const handleUpgradeClick = () => {
    pixelAmpTracking(TRACKING_EVENTS.BUSINESS_CLICK_UPGRADE_BUTTON, {
      ...trackingProps
    })
    navigate(`/business/${businessId}/pricing-plan`)
  }

  return (
    <>
      {!!plgBusiness && (
        <FWButton
          type="primary"
          icon={
            <RocketOutlined
              style={{
                fontSize: '18px',
                color: '#B5D1FF',
                marginRight: 0
              }}
            />
          }
          onClick={handleUpgradeClick}
        >
          {t('Upgrade')}
        </FWButton>
      )}
    </>
  )
}
