import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const CreateStore = loadable(() => import('../pages/CreateStore'))
const Store = loadable(() => import('../pages/Store'))
const BusinessStoreConnect = loadable(() => import('../pages/Connect'))
const BusinessStoreConnectWithoutAuth = loadable(
  () => import('../pages/ConnectWithoutAuth')
)
const BusinessStoreList = loadable(() => import('../pages/BusinessStoreList'))
const Paywall = loadable(() => import('../pages/Paywall/Paywall'), {
  resolveComponent: (component) => component.Paywall
})

export const Routes = (
  <>
    <Route path="/business_store_connect" element={<BusinessStoreConnect />} />
    <Route
      path="/business/:businessId/business_store_list"
      element={<BusinessStoreList />}
    />
    <Route
      path="/business/:businessId/store/create"
      element={<CreateStore />}
    />
    <Route path="/business/:businessId/connect_store" element={<Store />} />
    <Route path="/business/:businessId/store/:storeId" element={<Store />} />
    <Route
      path="/business/:businessId/store/:storeId/:storeTab"
      element={<Store />}
    />
    <Route path="/packages" element={<Paywall />} />
  </>
)

export const UnauthenticatedRoutes = (
  <Route
    path="/business_store_connect"
    element={<BusinessStoreConnectWithoutAuth />}
  />
)
