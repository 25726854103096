import { useMemo } from 'react'

import { useAppSelector } from '@src/app/hooks'
import { makeGetRequest } from '@src/common/redux/selectors/request'

export function useRequestState(
  request: string
): {
  loading: boolean
  state: 'idle' | 'pending' | 'resolved' | 'rejected'
} {
  const getRequest = useMemo(makeGetRequest, []) as any
  const { loading, state } = useAppSelector((state) =>
    getRequest(state, request)
  )

  return { loading, state }
}
