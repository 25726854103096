import React from 'react'

import { css } from '@emotion/core'
import theme from '@src/styles/theme'

import { useSpace } from '../../hooks'

type IProps = {
  children?: React.ReactNode
  required?: boolean
  style?: Record<string, any>
  htmlFor?: string
  bold?: boolean
}

const Label: React.FC<IProps> = ({ children, bold = true, ...props }) => {
  const spacing = useSpace('xxsmall')
  const { required } = props

  return (
    <label
      {...props}
      css={css`
        color: #404040;
        display: block;
        margin-bottom: ${spacing};
        font-weight: ${bold ? 500 : 'normal'};
      `}
    >
      {children}
      {required && (
        <span
          css={css`
            color: ${theme.danger};
          `}
        >
          &nbsp;*
        </span>
      )}
    </label>
  )
}

export default Label
