import styled from '@emotion/styled'
import theme from '@src/styles/theme'

export const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 8px;
  background: #fff;
  border: solid 1px ${theme.grayScale300};
  padding: 18px;
  gap: 24px;
  height: 85px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'default')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};
`
