import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import api, { getBasePath } from '@src/common/utils/api'
import { SLICE_NAME } from '@src/modules/channel/redux/YourSite/constants'

import { SliceState } from '../../redux/YourSite/initState'

const apiUrls = (businessId: string, channelId: string) => {
  const baseApiUrl = getBasePath(businessId, channelId)

  return {
    create: `${baseApiUrl}/embed_configs`,
    list: `${baseApiUrl}/embed_configs`,
    fetch: (embedConfigId: string) =>
      `${baseApiUrl}/embed_configs/${embedConfigId}`,
    update: (embedConfigId: string) =>
      `${baseApiUrl}/embed_configs/${embedConfigId}`,
    delete: (embedConfigId: string) =>
      `${baseApiUrl}/embed_configs/${embedConfigId}`
  }
}

export const embedConfigs_create = createAsyncThunk(
  `${SLICE_NAME}/embedConfigs_create`,
  async (payload: {
    businessId: string
    channelId: string
    business_store_id: string
    widget_properties: IAnyWidgetParams
    layout: string
    status: 'enable' | 'disable'
  }) => {
    const response = await api.post(
      apiUrls(payload.businessId, payload.channelId).create,
      payload
    )

    return response.data
  }
)

export const embedConfigs_list = createAsyncThunk(
  `${SLICE_NAME}/embedConfigs_list`,
  async (payload: { businessId: string; channelId: string }) => {
    const response = await api.get(
      apiUrls(payload.businessId, payload.channelId).list
    )

    return response.data
  }
)

export const embedConfigs_fetch = createAsyncThunk(
  `${SLICE_NAME}/embedConfigs_fetch`,
  async (payload: {
    businessId: string
    channelId: string
    embedConfigId: string
  }) => {
    const response = await api.get(
      apiUrls(payload.businessId, payload.channelId).fetch(
        payload.embedConfigId
      )
    )

    return response.data
  }
)

export const embedConfigs_update = createAsyncThunk(
  `${SLICE_NAME}/embedConfigs_update`,
  async (payload: {
    businessId: string
    channelId: string
    embedConfigId: string
    widget_properties: IAnyWidgetParams
    status: 'enable' | 'disable'
  }) => {
    const response = await api.patch(
      apiUrls(payload.businessId, payload.channelId).update(
        payload.embedConfigId
      ),
      payload
    )

    return response.data
  }
)

export const embedConfigs_delete = createAsyncThunk(
  `${SLICE_NAME}/embedConfigs_delete`,
  async (payload: {
    businessId: string
    channelId: string
    embedConfigId: string
  }) => {
    const response = await api.delete(
      apiUrls(payload.businessId, payload.channelId).delete(
        payload.embedConfigId
      )
    )

    return response.data
  }
)

export const embedConfigsBuilder = (
  builder: ActionReducerMapBuilder<SliceState>
): void => {
  builder
    .addCase(embedConfigs_list.fulfilled, (state, action) => {
      state.embedConfigs = {
        loading: false,
        data: action.payload
      }
    })
    .addCase(embedConfigs_list.rejected, (state) => {
      state.embedConfigs = {
        loading: false,
        data: []
      }
    })
    .addCase(embedConfigs_list.pending, (state) => {
      state.embedConfigs = {
        loading: true,
        data: []
      }
    })
    .addCase(embedConfigs_create.fulfilled, (state, action) => {
      state.embedConfigs = {
        loading: false,
        data: [...state.embedConfigs.data, action.payload]
      }
    })
    .addCase(embedConfigs_create.rejected, (state) => {
      state.embedConfigs = {
        loading: false,
        data: [...state.embedConfigs.data]
      }
    })
    .addCase(embedConfigs_create.pending, (state) => {
      state.embedConfigs = {
        loading: true,
        data: [...state.embedConfigs.data]
      }
    })
    .addCase(embedConfigs_update.fulfilled, (state, action) => {
      state.embedConfigs = {
        loading: false,
        data: state.embedConfigs.data.map((config) =>
          config.id === action.payload.id ? action.payload : config
        )
      }
    })
    .addCase(embedConfigs_update.rejected, (state) => {
      state.embedConfigs = {
        loading: false,
        data: [...state.embedConfigs.data]
      }
    })
    .addCase(embedConfigs_update.pending, (state) => {
      state.embedConfigs = {
        loading: true,
        data: [...state.embedConfigs.data]
      }
    })
    .addCase(embedConfigs_fetch.fulfilled, (state, action) => {
      state.embedConfigs = {
        loading: false,
        data: state.embedConfigs.data.concat(action.payload)
      }
    })
    .addCase(embedConfigs_fetch.rejected, (state) => {
      state.embedConfigs = {
        loading: false,
        data: [...state.embedConfigs.data]
      }
    })
    .addCase(embedConfigs_fetch.pending, (state) => {
      state.embedConfigs = {
        loading: true,
        data: [...state.embedConfigs.data]
      }
    })
}
