import React, { FC, useContext, useEffect, useRef, useState } from 'react'

import { useAppSelector } from '@src/app/hooks'
import { LoadingIndicator } from '@src/common/components/BaseComponent'
import { BusinessContext } from '@src/common/contexts/BusinessContext'
import api, { cancelToken } from '@src/common/utils/api'
import { useLocation } from 'react-router'

type NabooFeatureFlagKeys =
  | 'cms_custom_poster'
  | 'cms_livestream_agora'
  | 'cms_livestream_custom_poster'
  | 'cms_bulk_upload_video'
  | 'cms_livestream_media_gateway'
  | 'cms_livestream_ai_content_moderation'
  | 'cms_livestream_horizontal'
  | 'cms_video_horizontal'
  | 'cms_edit_in_fw_studio'
  | 'cms_sigma_embed'
  | 'cms_smart_button'
  | 'cms_hide_store_for_member_lite'
  | 'cms_microsite_pages'
  | 'one_to_one_offline_visitors'
  | 'one_to_one_qualify_visitor'
  | 'one_to_one_primary_color'
  | 'one_to_one_accumulated_page_view'
  | 'one_to_one_multiple_time_slots'
  | 'one_to_one_agent_notes'
  | 'cms_oauth_app_fwstudio'
  | 'cms_oauth_app_embed_instructions'
  | 'cms_oauth_app_sidebar_nav'
  | 'cms_dcs_config'
  | 'cms_business_webhooks'
  | 'cms_interactive_video'
  | 'cms_maintenance_mode'
  | 'cms_livestream_hq_simulcast'
  | 'cms_video_2_live'
  | 'cms_livestream_publisher_assistant'
  | 'cms_branding_settings_font'
  | 'cms_flash_deal' // TODO: confirm sunset
  | 'cms_show_product_detail'
  | 'cms_privacy_policy'
  | 'cms_products_tab'
  | 'cms_consent_management'
  | 'cms_ig_crawler'
  | 'cms_tiktok_crawler'
  | 'cms_livestream_automation'
  | 'cms_google_drive_importer'
  | 'cms_youtube_importer'
  | 'cms_shop_minis_auto_sync'
  | 'cms_notification_center'
  | 'cms_pdp_gallery'
  | 'cms_livestream_suggestions'
  | 'cms_hide_all_videos_menu'
  | 'ava_additional_product_info'
  | 'cms_ava_floating_widget'
  | 'ava_pdp_crawler'
  | 'ava_input_preference'
  | 'cms_livestream_winner_chat'
  | 'cms_livestream_smart_scheduler'

export type NabooFeatureFlag = {
  [key in NabooFeatureFlagKeys]?: boolean
}

export const NabooFFContext = React.createContext<NabooFeatureFlag>(
  {} as NabooFeatureFlag
)

export const NabooFFContextProvider: FC = ({ children }) => {
  const businessId = useContext(BusinessContext)
  const user = useAppSelector((state) => state.profile.user)
  const oauthApp = useAppSelector((state) => state.user.oauthApp)
  const { pathname } = useLocation()
  const currentBusinessId = useRef()
  const [isLoading, setIsLoading] = useState(true)

  const [nabooFF, setNabooFF] = useState<NabooFeatureFlag>({})

  useEffect(() => {
    const params = {
      user: user.encoded_id,
      business: businessId
    }

    // channel
    const channelRegex = /\/channel\/([^/]+)/
    const channelMatch = pathname.match(channelRegex)
    if (channelMatch && channelMatch[1]) {
      params['channel'] = channelMatch[1]
    }

    // chat channel
    const regex = /\/chat_channel\/([^/]+)/
    const match = pathname.match(regex)
    if (match && match[1]) {
      params['chat_channel'] = match[1]
    }

    const ct = cancelToken.source()
    businessId !== currentBusinessId.current && setIsLoading(true)

    // fetch feature flags from Naboo
    const fetchFeatureFlags = async () =>
      (
        await api.get(`/api/feature_flags`, {
          params: {
            ...params,
            oaparams: oauthApp
              ? {
                  oauth_app: oauthApp
                }
              : undefined
          },
          cancelToken: ct.token
        })
      ).data as NabooFeatureFlag

    // fetch feature flags from Naboo
    fetchFeatureFlags()
      .then((flags) => {
        setNabooFF({
          ...flags
        })
      })
      .catch(() => {
        // ignore
      })
      .finally(() => {
        setIsLoading(false)
        currentBusinessId.current = businessId
      })

    return () => {
      ct.cancel('Cancel')
    }
  }, [businessId, user, oauthApp, pathname])

  return (
    <NabooFFContext.Provider value={nabooFF}>
      {isLoading ? <LoadingIndicator /> : children}
    </NabooFFContext.Provider>
  )
}
