import React from 'react'

import { css } from '@emotion/react'
import { Box } from '@src/common/components/BaseComponent'

export const SidebarIcon = ({
  icon,
  width,
  height
}: {
  icon: string
  width: string
  height: string
}): JSX.Element => {
  return (
    <Box
      as="img"
      width={width}
      height={height}
      data-tour="channels"
      css={css`
        flex-shrink: 0;
        -webkit-mask: url(${icon}) no-repeat center;
        mask: url(${icon}) no-repeat center;

        &.active {
          background-color: #fff;
          color: #fff;
        }
      `}
    />
  )
}
