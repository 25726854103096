import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const Invitation = loadable(
  () => import('@src/modules/invitation/pages/Invitation')
)

const UnauthenticatedInvitation = loadable(
  () => import('@src/modules/invitation/pages/UnauthenticatedInvitation')
)

export const Routes = (
  <>
    <Route path="/invitation/:token" element={<Invitation />} />
  </>
)
export const UnauthenticatedRoutes = (
  <Route path="/invitation/:token" element={<UnauthenticatedInvitation />} />
)
