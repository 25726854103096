import React from 'react'

import { Flex } from '@src/common/components/BaseComponent'
import { FWButton } from '@src/common/components/BaseComponent'
import { captionLength } from '@src/common/utils/caption'
import { MAX_CAPTION_LENGTH, MIN_CAPTION_LENGTH } from '@src/constants'
import { useTranslation } from 'react-i18next'

import { BatchImporterEditMedia } from '../BatchImporterMediaModel'

type BBatchImportCaptionHeaderControlsProps = {
  onUploadClick: () => void
  onCancelClick: () => void
  mediaList: BatchImporterEditMedia[]
  disabled?: boolean
}

export const BatchImportCaptionHeaderControls: React.FC<BBatchImportCaptionHeaderControlsProps> = ({
  onUploadClick,
  onCancelClick,
  mediaList,
  disabled
}) => {
  const { t } = useTranslation()

  return (
    <Flex gap="5">
      <FWButton onClick={onCancelClick}>{t('Cancel')}</FWButton>
      <FWButton
        type="primary"
        onClick={onUploadClick}
        disabled={
          disabled ||
          mediaList.findIndex((item) => {
            return (
              !captionLength(item.editCaption) ||
              captionLength(item.editCaption) < MIN_CAPTION_LENGTH ||
              captionLength(item.editCaption) > MAX_CAPTION_LENGTH
            )
          }) >= 0
        }
      >
        {t('Upload')}
      </FWButton>
    </Flex>
  )
}
