import React from 'react'

import { css } from '@emotion/react'
import { Flex, FWButton, Grid } from '@src/common/components/BaseComponent'
import theme from '@src/styles/theme'
import { useTranslation } from 'react-i18next'

import { useWidgetAssets } from '../../hooks/useWidgetAssets'
import { CreateWidgetTile } from '../FeatureTiles/CreateWidgetTile'
import { FeatureTile } from '../FeatureTiles/FeatureTile'

type Props = {
  homePageConfigStatus: AutoEmbedConfiguration
  handleHomePageClick: () => void
  pdpConfig: YourSite.PDPGallery
  handleProductGalleryClick: () => void
  embedConfigsByStoreId: YourSite.EmbedConfig[]
  handleEmbedConfigClick: (embedConfig: YourSite.EmbedConfig) => void
  handleNavigateToSelectWidget: () => void
}

export const EmbedConfigGrid = ({
  homePageConfigStatus,
  handleHomePageClick,
  pdpConfig,
  handleProductGalleryClick,
  embedConfigsByStoreId,
  handleEmbedConfigClick,
  handleNavigateToSelectWidget
}: Props): JSX.Element => {
  const { t } = useTranslation()

  const { images, titles } = useWidgetAssets()

  return (
    <Grid
      gridTemplateColumns="repeat(auto-fit, 302px)"
      gap={24}
      overflow="auto"
      pb={80}
    >
      <FeatureTile
        imageSrc="/your-site/home_page_integration.svg"
        title={t('Home Page')}
        noCodeEmbed
        description={
          homePageConfigStatus && homePageConfigStatus.enabled_at !== null ? (
            <Flex
              width="100%"
              justifyContent="flex-start"
              gap="4"
              alignItems="center"
              color={theme.textSecondary}
              textTransform="capitalize"
            >
              <span>{`${homePageConfigStatus?.widget_type}`}</span>
              <span
                css={css`
                  color: ${theme.textTertiary};
                `}
              >
                -
              </span>
              <span>{t('Active')}</span>
            </Flex>
          ) : null
        }
        onClick={handleHomePageClick}
        disabled={homePageConfigStatus?.enabled_at === null}
        footerControls={
          homePageConfigStatus && homePageConfigStatus.enabled_at === null
            ? [
                <FWButton
                  key="activate"
                  onClick={handleHomePageClick}
                  type="primary"
                  css={css`
                    width: 73px;
                    justify-content: center;
                    padding: 4px 10px;
                  `}
                >
                  {t('Activate')}
                </FWButton>
              ]
            : null
        }
      />
      <FeatureTile
        imageSrc="/your-site/product_gallery.svg"
        title={t('Product Gallery')}
        noCodeEmbed
        description={
          pdpConfig && pdpConfig?.status === 'completed' ? (
            <Flex
              width="100%"
              justifyContent="flex-start"
              gap="4"
              alignItems="center"
              color={theme.textSecondary}
              textTransform="capitalize"
            >
              <span>{t('PDP Gallery - Active')}</span>
            </Flex>
          ) : null
        }
        onClick={handleProductGalleryClick}
        disabled={pdpConfig?.status === null}
        footerControls={
          pdpConfig?.status === null
            ? [
                <FWButton
                  key="activate"
                  onClick={handleProductGalleryClick}
                  type="primary"
                  css={css`
                    width: 73px;
                    justify-content: center;
                    padding: 4px 10px;
                  `}
                >
                  {t('Activate')}
                </FWButton>
              ]
            : null
        }
      />
      {!!embedConfigsByStoreId.length &&
        embedConfigsByStoreId.map((embedConfig) => (
          <FeatureTile
            key={embedConfig.id}
            imageSrc={images[embedConfig.layout]}
            title={titles[embedConfig.layout]}
            description={`ID: ${embedConfig.embed_config_id}`}
            onClick={() => handleEmbedConfigClick(embedConfig)}
          />
        ))}
      <CreateWidgetTile onClick={handleNavigateToSelectWidget} />
    </Grid>
  )
}
