import React from 'react'

import loadable from '@loadable/component'
import { Navigate, Route } from 'react-router-dom'

const Landing = loadable(() => import('../pages/Landing'), {
  resolveComponent: (component) => component.Landing
})
const NotFound = loadable(() => import('../pages/NotFound'))
const Guest = loadable(() => import('../pages/Guest'))
const Home = loadable(() => import('../pages/Home'))

export const Routes = (
  <>
    <Route path="/" element={<Landing />} />
    <Route path="/guest" element={<Guest />} />
    <Route path="*" element={<NotFound />} />
  </>
)
export const UnauthenticatedRoutes = (
  <>
    <Route path="/" element={<Home />} />
    <Route path="/guest" element={<Guest />} />
    <Route path="*" element={<Navigate to="/" />} />
  </>
)
