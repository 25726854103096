import { combineReducers } from '@reduxjs/toolkit'
import * as oneToOneReducers from '@src/modules/one-to-one/redux'

import * as common from '../../common/redux'
import * as automationReducers from '../../modules/automation/redux'
import * as avaReducers from '../../modules/ava/redux'
import * as channelReducers from '../../modules/channel/redux'
import * as liveChatReducers from '../../modules/live-chat/redux'
import * as oauthAppReducers from '../../modules/oauth-app/redux'
import * as profileReducers from '../../modules/profile/redux'
import * as searchReducers from '../../modules/search/redux'
import * as settingsReducers from '../../modules/settings/redux'
import * as storeReducers from '../../modules/store/redux'
import * as webhookReducers from '../../modules/webhook/redux'

export default combineReducers({
  ...channelReducers,
  ...storeReducers,
  ...common,
  ...settingsReducers,
  ...oauthAppReducers,
  ...oneToOneReducers,
  ...avaReducers,
  ...automationReducers,
  ...profileReducers,
  ...searchReducers,
  ...liveChatReducers,
  ...webhookReducers
})
