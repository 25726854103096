/**
 * @fileoverview
 *
 * Activation Status Container
 *
 * This component is a container for the Activation Status component. It is a card that contains the ActivationSteps component.
 */
import React, { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@src/app/hooks'
import { Box, Flex, Title } from '@src/common/components/BaseComponent'
import useActivationStatus from '@src/common/hooks/Onboarding/useActivationStatus'
import useCurrentActivationStatus from '@src/common/hooks/useCurrentActivationStatus'
import {
  setActivationStatus,
  setActivationStatusModalOpen,
  setActivationStatusModalMinimized
} from '@src/common/redux/activationStatus'
import { Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { ActivationSteps } from './ActivationSteps'
import {
  CardContainer,
  CardHeader,
  ModuleBorderWrap,
  MinimizedCard
} from './ActivationSteps.styles'
import { PDPActivationSteps } from './PDPActivationSteps'

export const ActivationStatusContainer: React.FC = () => {
  const {
    getActivationStatus,
    isModalOpen,
    setModalClose
  } = useActivationStatus()
  const dispatch = useAppDispatch()
  const { businessId, channelId } = useParams()
  const { t } = useTranslation()

  useCurrentActivationStatus({
    businessId,
    channelId
  })

  const { isActivationStatusModalMinimized } = useAppSelector(
    (state) => state.activationStatus
  )

  const isSidebarCollapsed = useAppSelector(
    (state) => state.ui.sidebarCollapsed
  )
  const {
    onboardingContext,
    isActivationStatusModalOpen: showActivationStatusModal
  } = useAppSelector((state) => state.activationStatus)

  useEffect(() => {
    if (isModalOpen) {
      dispatch(setActivationStatusModalOpen(true))
    }
  }, [dispatch, isModalOpen])

  // Hydrate activation status from local storage
  // pre-set completed steps
  useEffect(() => {
    dispatch(setActivationStatus(getActivationStatus()))
  }, [dispatch, getActivationStatus, showActivationStatusModal])

  const hideActivationStatusModal = () => {
    dispatch(setActivationStatusModalOpen(false))
    setModalClose()
  }
  const openModal = () => {
    dispatch(setActivationStatusModalMinimized(false))
  }
  const hideModal = () => {
    dispatch(setActivationStatusModalMinimized(true))
  }

  if (!showActivationStatusModal) return null

  return (
    <Box
      position="fixed"
      bottom={isActivationStatusModalMinimized ? '0' : '32'}
      left={isSidebarCollapsed ? 70 : 250}
      width="30vw"
      zIndex="10"
      transition="all 0.15s"
    >
      {isActivationStatusModalMinimized ? (
        <MinimizedCard>
          <Space>
            {onboardingContext === 'home' ? (
              <Title color="#fff" fontSize={16} weight={600}>
                {t('Add Videos to Home Page')}
              </Title>
            ) : (
              <Title color="#fff" fontSize={16} weight={600}>
                {t('Add Videos to Product Pages')}
              </Title>
            )}
          </Space>
          <Box
            as="img"
            width={20}
            height={20}
            src="/chevron_up_white.svg"
            onClick={openModal}
            css={{ cursor: 'pointer' }}
          />
        </MinimizedCard>
      ) : (
        <ModuleBorderWrap>
          <CardContainer
            style={{
              borderRadius: isActivationStatusModalMinimized
                ? '12px 12px 0 0'
                : '12px'
            }}
          >
            <CardHeader>
              <Space>
                {onboardingContext === 'home' ? (
                  <Title color="#0060E5" fontSize={18} weight={600}>
                    {t('Add Videos to Home Page')}
                  </Title>
                ) : (
                  <Title color="#0060E5" fontSize={18} weight={600}>
                    {t('Add Videos to Product Pages')}
                  </Title>
                )}
              </Space>

              <Box
                as="img"
                alt="close"
                src="/close_activation.svg"
                width="10px"
                height="10px"
                position="absolute"
                top="12px"
                right="12px"
                onClick={hideActivationStatusModal}
                css={{ cursor: 'pointer' }}
              />
            </CardHeader>
            <Flex paddingLeft="14px">
              {onboardingContext === 'home' ? (
                <ActivationSteps />
              ) : (
                <PDPActivationSteps />
              )}
            </Flex>
            <Box position="absolute" bottom="6px" right="8px">
              <Box
                as="img"
                src="/chevron_down_navy.svg"
                onClick={hideModal}
                css={{ cursor: 'pointer' }}
              />
            </Box>
          </CardContainer>
        </ModuleBorderWrap>
      )}
    </Box>
  )
}
