import React, { useState } from 'react'

import {
  LogoutOutlined,
  NotificationOutlined,
  RightCircleOutlined,
  SettingOutlined
} from '@ant-design/icons'
import { css } from '@emotion/react'
import { useAppSelector } from '@src/app/hooks'
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuLink,
  MenuList
} from '@src/common/components/BaseComponent'
import { SideBarItemText } from '@src/common/components/Sidebar/SideBarItemText'
import { useSpace } from '@src/common/hooks'
import { useBusinessEntryPoint } from '@src/common/hooks/useBusinessEntryPoint'
import { useNabooFeatureFlag } from '@src/common/hooks/useFeatureFlag'
import useOAuthApp from '@src/common/hooks/useOAuthApp'
import usePixelAmpTracking from '@src/common/hooks/usePixelAmpTracking'
import { logout } from '@src/common/redux/session'
import { setIsSidebarCollapsed } from '@src/common/redux/ui'
import {
  businessList as BUSINESS_LIST_STORAGE_KEY,
  setStorage
} from '@src/common/utils/localStorage'
import { TRACKING_EVENTS } from '@src/common/utils/tracking'
import LocalBusinessMenuItem from '@src/modules/profile/components/LocalBusinessMenuItem'
import NotificationsModal from '@src/modules/profile/components/NotificationsModal'
import { useNotification } from '@src/modules/profile/hooks/useNotification'
import { Avatar, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

export interface ProfileButtonProps {
  businessId: string
  onClickLink: () => void
  expand: boolean
}

export const ProfileButton: React.FC<ProfileButtonProps> = ({
  businessId,
  onClickLink,
  expand
}) => {
  const { isOAuthAppEmbed } = useOAuthApp()

  const { pixelAmpTracking } = usePixelAmpTracking()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const smallSpace = useSpace('small')
  const user = useAppSelector((state) => state.profile.user)
  const username = useAppSelector(
    (state) => state.profile?.user?.username || ''
  )
  const fetchedBusinessIds = useAppSelector((state) => state.business.ids)
  const businessPaging = useAppSelector((state) => state.business.paging)
  const businessMap = useAppSelector((state) => state.business.businesses)

  const nabooFF = useNabooFeatureFlag()

  const { switchBusiness } = useBusinessEntryPoint()

  const businessList = fetchedBusinessIds
    .map((id) => businessMap[id])
    .filter(Boolean)
    .filter(({ id }) => id !== businessId)
    .slice(0, 9)

  const handleClickProfile = () => {
    pixelAmpTracking(TRACKING_EVENTS.BUSINESS_CLICK_USER_PROFILE)
  }

  const handleClickLogout = () => {
    dispatch(logout())
    pixelAmpTracking(TRACKING_EVENTS.BUSINESS_CLICK_USER_LOGOUT)
  }

  const handleSelectBusiness = async (businessId: string) => {
    dispatch(setIsSidebarCollapsed(true))
    onClickLink()
    setStorage(BUSINESS_LIST_STORAGE_KEY, [username], businessId)

    // Start Entry Point Flow
    // Favorited, but business data has not yet been obtained
    // Only happens when favorites business is on the second pagination
    switchBusiness(businessId, !fetchedBusinessIds.includes(businessId))
  }

  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false)

  const { hasNewNotifications, hasNotification } = useNotification()

  const handleClickNotification = () => {
    setIsNotificationModalOpen(true)
  }

  const closeNotificationModal = () => {
    setIsNotificationModalOpen(false)
  }

  return (
    <>
      {!isOAuthAppEmbed && (
        <Menu>
          <MenuButton
            className="cy-user-menu-button"
            onClick={() => {
              pixelAmpTracking(TRACKING_EVENTS.BUSINESS_CLICK_USER_AVATAR)
            }}
            style={{
              height: 40,
              display: 'flex',
              flexDirection: 'row',
              paddingLeft: 10,
              color: '#A0ACBF',
              backgroundColor: 'transparent',
              border: 'none',
              paddingRight: 0,
              alignItems: 'center',
              cursor: 'pointer'
            }}
          >
            <Box position="relative" display="inline-block">
              <Avatar
                size={18}
                src={user.avatar_url}
                css={css`
                  flex-shrink: 0;
                  border-radius: 28px;
                  border: 1px solid #bfdaff;
                `}
              />
              {hasNewNotifications && (
                <Box
                  position="absolute"
                  top="0"
                  left="12px"
                  width="10px"
                  height="10px"
                  borderRadius="50%"
                  as="span"
                  style={{
                    backgroundColor: 'red'
                  }}
                  border="2px solid white"
                  zIndex={10}
                />
              )}
            </Box>
            <SideBarItemText
              text={user.name}
              show={expand}
              customStyles={css`
                margin-left: 12px;
                text-align: left;
                width: 120px;
              `}
            />
          </MenuButton>
          <MenuList style={{ display: 'flex', flexDirection: 'column' }}>
            {nabooFF.cms_oauth_app_sidebar_nav && (
              <>
                {businessPaging?.next && !isOAuthAppEmbed && (
                  <>
                    <MenuLink
                      to="/businesses"
                      as={Link as never}
                      onSelect={() => null}
                    >
                      <span>
                        <RightCircleOutlined /> {t('See All Businesses')}
                      </span>
                    </MenuLink>
                    <Divider style={{ margin: '6px 0 10px 0' }} />
                  </>
                )}
                <Box maxHeight="300" overflowY="scroll">
                  {businessId && (
                    <LocalBusinessMenuItem
                      id={businessId}
                      key={businessId}
                      onSelect={() => handleSelectBusiness(businessId)}
                    />
                  )}
                  {businessList?.map((item) => (
                    <MenuItem
                      id={item.id}
                      key={item.id}
                      selected={businessId === item.id}
                      onSelect={() => handleSelectBusiness(item.id)}
                    >
                      {item?.favorite ? (
                        <Flex flexDirection="row" alignItems="center">
                          <Box
                            css={css`
                              margin-right: 6px;
                              margin-bottom: 2px;
                            `}
                            as="img"
                            src="/icon/star.svg"
                            height="16"
                            width="16"
                          />
                          <div> {item.name}</div>
                        </Flex>
                      ) : (
                        item.name
                      )}
                    </MenuItem>
                  ))}
                </Box>
              </>
            )}
            <Divider style={{ margin: '6px 0 10px 0' }} />
            <MenuLink to="/profile" as={Link as never}>
              <SettingOutlined
                onClick={handleClickProfile}
                css={css`
                  margin-right: ${smallSpace};
                `}
              />
              {t('Profile')}
            </MenuLink>
            {hasNotification && (
              <MenuLink
                onClick={handleClickNotification}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Flex position="relative" alignItems="center">
                  <NotificationOutlined
                    css={css`
                      margin-right: ${smallSpace};
                    `}
                  />
                  {hasNewNotifications && (
                    <Box
                      position="absolute"
                      top="-5px"
                      right="5px"
                      width="10px"
                      height="10px"
                      borderRadius="50%"
                      as="span"
                      style={{
                        backgroundColor: 'red'
                      }}
                      border="2px solid white"
                      zIndex={10}
                    />
                  )}
                </Flex>
                {t('Updates')}
              </MenuLink>
            )}
            <MenuItem className="cy-logout-link" onSelect={handleClickLogout}>
              <LogoutOutlined
                css={css`
                  margin-right: ${smallSpace};
                `}
              />
              {t('Logout')}
            </MenuItem>
          </MenuList>
        </Menu>
      )}

      <NotificationsModal
        visible={isNotificationModalOpen}
        onClose={closeNotificationModal}
      />
    </>
  )
}
