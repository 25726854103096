import styled from '@emotion/styled'
import { animated } from 'react-spring'

export default styled(animated.div)<{ height: number; opacity: number }>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
  height: ${(props) => props.height}%;
  opacity: ${(props) => props.opacity};
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.5);
  transition: ${(props) => {
    const willAppear = props.opacity === 1
    const opacityDuration = 0.15
    const heightDelay = willAppear ? 0 : opacityDuration

    return `opacity ${opacityDuration}s linear, height 0s linear ${heightDelay}s`
  }};
`
