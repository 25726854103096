import React, { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@src/app/hooks'
import { Flex } from '@src/common/components/BaseComponent'
import { useCustomNavigate } from '@src/common/hooks/useCustomNavigate'
import usePixelAmpTracking from '@src/common/hooks/usePixelAmpTracking'
import { setCurrentStep } from '@src/common/redux/activationStatus'
import { TRACKING_EVENTS } from '@src/common/utils/tracking'
import { AUTO_EMBED_TABS } from '@src/modules/channel/constants/yourSite'
import { setIntegrationsActiveTab } from '@src/modules/channel/redux/YourSite'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import { ActivationStepLink, StepList } from './ActivationSteps.styles'

export const PDPActivationSteps = React.memo(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { businessId, channelId } = useParams()
  const { pixelAmpTracking } = usePixelAmpTracking()
  const { navigateToHome } = useCustomNavigate()

  const { currentStep, matchVideoToProduct, uploadVideo } = useAppSelector(
    (state) => state.activationStatus
  )
  const dispatch = useAppDispatch()
  const handleNavigation = (listItem: string) => {
    dispatch(setIntegrationsActiveTab(listItem))
    navigate(`/business/${businessId}/channel/${channelId}/integrations`)
  }

  const onStepClick = (step: number) => {
    switch (step) {
      case 0:
        pixelAmpTracking(
          TRACKING_EVENTS.BUSINESS_ACTIVATION_STATUS_EVENTS.PDP
            .CLICK_UPLOAD_VIDEO
        )
        navigateToHome({ businessId, channelId })
        break
      case 1:
        pixelAmpTracking(
          TRACKING_EVENTS.BUSINESS_ACTIVATION_STATUS_EVENTS.PDP
            .CLICK_ADD_VIDEO_TO_PRODUCT
        )
        navigate(`/business/${businessId}/channel/${channelId}/products`)
        break
      case 2:
        pixelAmpTracking(
          TRACKING_EVENTS.BUSINESS_ACTIVATION_STATUS_EVENTS
            .CLICK_TURN_ON_FIREWORK_EMBED
        )
        handleNavigation(AUTO_EMBED_TABS.PRODUCT_PAGES)

        break
      default:
        break
    }
  }

  useEffect(() => {
    if (matchVideoToProduct === 'complete') {
      dispatch(setCurrentStep(2))
    } else if (uploadVideo === 'complete') {
      dispatch(setCurrentStep(1))
    } else {
      dispatch(setCurrentStep(0))
    }
  }, [matchVideoToProduct, uploadVideo, dispatch])

  return (
    <StepList>
      <li
        style={{
          color: currentStep === 0 ? '#3F4145' : '#B2B6BB'
        }}
      >
        <Trans t={t}>
          Upload Videos to the{' '}
          <ActivationStepLink onClick={() => onStepClick(0)}>
            Video Library
          </ActivationStepLink>
        </Trans>
        <Flex style={{ fontSize: '12px' }}>
          <Trans t={t} style={{ width: '100%' }}>
            *Tag products to make them shoppable
          </Trans>
        </Flex>
      </li>
      <li style={{ color: currentStep === 1 ? '#3F4145' : '#B2B6BB' }}>
        <Trans t={t}>
          Go to the{' '}
          <ActivationStepLink onClick={() => onStepClick(1)}>
            Products tab
          </ActivationStepLink>{' '}
          and match videos to products.
        </Trans>
      </li>
      <li style={{ color: currentStep === 2 ? '#3F4145' : '#B2B6BB' }}>
        <Trans t={t}>
          Go to the Your Site tab and follow the instructions for{' '}
          <ActivationStepLink onClick={() => onStepClick(2)}>
            Product Detail Pages.
          </ActivationStepLink>{' '}
        </Trans>
      </li>
    </StepList>
  )
})

PDPActivationSteps.displayName = 'PDPActivationSteps'
