import React from 'react'

import { Flex } from '@src/common/components/BaseComponent'
import { Title } from '@src/common/components/BaseComponent'
import { useNabooFeatureFlag } from '@src/common/hooks'
import { ConsentStatus } from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import { useTranslation } from 'react-i18next'

interface IProps {
  status?: ConsentStatus
}

const ConsentStatusIcon: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const nff = useNabooFeatureFlag()
  const { status } = props

  if (!status || !nff.cms_consent_management) {
    return <></>
  }

  const color = (() => {
    if (status === ConsentStatus.Pending) {
      return '#3F4145'
    } else if (status === ConsentStatus.Approved) {
      return '#00731C'
    } else if (status === ConsentStatus.Denied) {
      return '#992626'
    }
  })()

  const bgColor = (() => {
    if (status === ConsentStatus.Pending) {
      return '#E5F0FF'
    } else if (status === ConsentStatus.Approved) {
      return '#E5FFEC'
    } else if (status === ConsentStatus.Denied) {
      return '#FFE5E5'
    }
  })()

  const text = (() => {
    if (status === ConsentStatus.Pending) {
      return t('Pending')
    } else if (status === ConsentStatus.Approved) {
      return t('Approved')
    } else if (status === ConsentStatus.Denied) {
      return t('Denied')
    }
  })()

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      padding="1px 8px"
      borderRadius="12px"
      fontSize="10px"
      bg={bgColor}
      color={color}
      height="22px"
      outline={`1px solid ${color}`}
    >
      <Title noMargin fontSize={12} weight={500} color={color}>
        {text}
      </Title>
    </Flex>
  )
}

export default ConsentStatusIcon
