import { useCallback, useMemo } from 'react'

import { localStorageProvider } from '@src/common/utils/storageProvider'
import { KEY_ONBOARDING_STATUS } from '@src/constants'

export const useOnboarding = (): {
  initializeOnboarding: () => void
  handleCompletedOnboardingWelcomeMessage: () => void
  handleHidePlaylistBanner: () => void
  handlePDPOnboarding: () => void
  isWelcomeMessageComplete: boolean
  isPlaylistBannerTaskComplete: boolean
  isPDPOnboardingComplete: boolean
} => {
  /**
   * Onboarding object
   * oq: Onboarding Questionnaire
   * wm: Welcome Message
   * pb: Playlist Banner
   *  */

  const onboardingObject = useMemo(
    () => ({
      wm: false,
      pb: false,
      pdp: false
    }),
    []
  )

  const initializeOnboarding = useCallback(() => {
    if (!localStorageProvider.getItem(KEY_ONBOARDING_STATUS)) {
      localStorageProvider.setItem(
        KEY_ONBOARDING_STATUS,
        JSON.stringify({
          ...onboardingObject
        })
      )
    }
  }, [onboardingObject])

  const handleCompletedOnboardingWelcomeMessage = useCallback(() => {
    localStorageProvider.setItem(
      KEY_ONBOARDING_STATUS,
      JSON.stringify({
        ...onboardingObject,
        wm: true
      })
    )
  }, [onboardingObject])

  const handleHidePlaylistBanner = useCallback(() => {
    localStorageProvider.setItem(
      KEY_ONBOARDING_STATUS,
      JSON.stringify({
        ...onboardingObject,
        wm: true,
        pb: true
      })
    )
  }, [onboardingObject])

  const handlePDPOnboarding = useCallback(() => {
    localStorageProvider.setItem(
      KEY_ONBOARDING_STATUS,
      JSON.stringify({
        ...onboardingObject,
        wm: true,
        pdp: true
      })
    )
  }, [onboardingObject])

  const isWelcomeMessageComplete = useMemo(() => {
    const onboardingStatus = JSON.parse(
      localStorageProvider.getItem(KEY_ONBOARDING_STATUS)
    )

    if (!onboardingStatus) return true

    return onboardingStatus?.wm
  }, [])

  const isPlaylistBannerTaskComplete = useMemo(() => {
    const onboardingStatus = JSON.parse(
      localStorageProvider.getItem(KEY_ONBOARDING_STATUS)
    )

    return onboardingStatus?.pb || false
  }, [])

  const isPDPOnboardingComplete = useMemo(() => {
    const onboardingStatus = JSON.parse(
      localStorageProvider.getItem(KEY_ONBOARDING_STATUS)
    )

    return onboardingStatus?.pdp || false
  }, [])

  return {
    initializeOnboarding,
    handleCompletedOnboardingWelcomeMessage,
    handleHidePlaylistBanner,
    handlePDPOnboarding,
    isWelcomeMessageComplete,
    isPlaylistBannerTaskComplete,
    isPDPOnboardingComplete
  }
}
