import React from 'react'

import css from '@emotion/css'
import { Box } from '@src/common/components/BaseComponent'
import { DatePicker } from 'antd'
import moment, { Moment } from 'moment'

type IProps = {
  startDate: Moment
  endDate: Moment
  onDateSelected: (start: Moment, end: Moment) => void
  socomApp?: boolean
}

const DateRangeSelector: React.FC<IProps> = ({
  startDate,
  endDate,
  onDateSelected,
  socomApp = false
}) => {
  const onDateChange = (dates) => {
    if (dates && dates[0] && dates[1]) {
      onDateSelected(dates[0], dates[1])
    }
  }

  return (
    <Box width="100%" pr="small">
      <DatePicker.RangePicker
        inputReadOnly={false}
        defaultValue={[startDate, endDate]}
        disabledDate={(date) => date?.isAfter(moment(new Date()))}
        onChange={onDateChange}
        css={css`
          width: ${socomApp ? '100%' : '300px'};
        `}
      />
    </Box>
  )
}

export default DateRangeSelector
