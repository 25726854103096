import React from 'react'

import { UnauthenticatedRoutes as AppRoutes } from '@src/app/routes'
import { UnauthenticatedRoutes as ChannelRoutes } from '@src/modules/channel/routes/UnauthenticatedRoutes'
import { UnauthenticatedRoutes as IntegrationRoutes } from '@src/modules/integration/routes'
import { UnauthenticatedRoutes as InvitationRoutes } from '@src/modules/invitation/routes'
import { UnauthenticatedRoutes as PricingPlanRoutes } from '@src/modules/pricing-plan/routes'
import { UnauthenticatedRoutes as StoreRoutes } from '@src/modules/store/routes'
import { Routes } from 'react-router-dom'

export default function UnauthenticatedRoutes(): JSX.Element {
  return (
    <Routes>
      {PricingPlanRoutes}
      {InvitationRoutes}
      {StoreRoutes}
      {IntegrationRoutes}
      {ChannelRoutes}
      {AppRoutes}
    </Routes>
  )
}
