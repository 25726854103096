import { useCallback, useMemo } from 'react'

import { useAppDispatch, useAppSelector } from '@src/app/hooks'
import { autoEmbed_upsertEmbedConfig } from '@src/modules/channel/redux/YourSite/autoEmbed'

import { SHOPIFY_STORE_PAGES } from '../../constants/yourSite'

type UseAutoEmbedHook = {
  isThemeBannerVisible: boolean
  isHelpBannerVisible: boolean
  playlistVideos: globalLib.IVideo[]
  playlistVideosStatus: 'loading' | 'success' | 'error'
  autoEmbedPlaylistId: string
  channelPlaylists: globalLib.Playlist[]
  homePageConfig: (shopUrl: string) => AutoEmbedConfiguration | undefined
  saveData: ({
    configPath,
    businessId,
    data,
    isContentDisplayed,
    shopifyShopUrl
  }: {
    configPath: string
    businessId: string
    data: YourSite.FormData
    isContentDisplayed: boolean
    shopifyShopUrl: string
  }) => Promise<any>
}

type UseAutoEmbedProps = {
  channelId: string
}

enum CONFIG_PATHS {
  SHOPIFY_STANDARD_HOMEPAGE = '/',
  SHOPIFY_STANDARD_COLLECTION_PAGE = '/collections/',
  SHOPIFY_STANDARD_PRODUCT_PAGE = '/products/'
}

export const useAutoEmbed = ({
  channelId
}: UseAutoEmbedProps): UseAutoEmbedHook => {
  const dispatch = useAppDispatch()
  const {
    isEnableBannerVisible: isThemeBannerVisible,
    isHelpBannerVisible,
    playlistVideos,
    playlistVideosStatus,
    selectedPlaylistId: autoEmbedPlaylistId,
    autoEmbedConfig
  } = useAppSelector((state) => state.yourSite)

  const { channelPlaylists } = useAppSelector((state) => state.channel)

  const homePageConfig = useMemo(
    () => (shopUrl: string) =>
      autoEmbedConfig?.find(
        (config) =>
          config.page_type === SHOPIFY_STORE_PAGES.SHOPIFY_STANDARD_HOMEPAGE &&
          config.authority === shopUrl
      ),

    [autoEmbedConfig]
  )

  const saveData = useCallback(
    async ({
      businessId,
      data,
      isContentDisplayed,
      configPath,
      shopifyShopUrl
    }: {
      configPath: string
      businessId: string
      data: YourSite.FormData
      isContentDisplayed: boolean
      shopifyShopUrl: string
    }) => {
      return await dispatch(
        autoEmbed_upsertEmbedConfig({
          channelId,
          businessId,
          autoEmbedConfig: {
            path: CONFIG_PATHS[configPath],
            channel_id: channelId,
            title: data.title,
            authority: shopifyShopUrl,
            playlist_id: data.playlist_id,
            page_type: SHOPIFY_STORE_PAGES.SHOPIFY_STANDARD_HOMEPAGE,
            widget_type: data.widget_type,
            sibling_selector: `main .shopify-section:nth-of-type(${data.position})`,
            widget_properties: {
              branding: true
            },
            enabled_at: isContentDisplayed ? new Date().toISOString() : null
          }
        })
      )
    },
    [dispatch, channelId]
  )

  return {
    isThemeBannerVisible,
    isHelpBannerVisible,
    playlistVideos,
    playlistVideosStatus,
    autoEmbedPlaylistId,
    channelPlaylists: channelPlaylists?.[channelId],
    homePageConfig,
    saveData
  }
}
