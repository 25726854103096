import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import { useAppSelector } from '@src/app/hooks'
import { usePixelAmpTracking } from '@src/common/hooks/index'
import { useLocalStorageItem } from '@src/common/hooks/useLocalStorageItem'
import {
  createChannelPlaylist,
  fetchChannelPlaylists,
  updateChannelPlaylist
} from '@src/common/redux/channel'
import {
  addPlaylistsToPlaylistGroup,
  fetchPlaylistGroup,
  fetchPlaylistGroups,
  insertPlaylistInPlaylistGroup,
  removePlaylistInPlaylistGroup,
  searchAndSortPlaylists,
  sortPlaylistsInPlaylistGroupSuccess
} from '@src/common/redux/playlist'
import { localStorageProvider } from '@src/common/utils/storageProvider'
import { TRACKING_EVENTS } from '@src/common/utils/tracking'
import { isNil } from 'lodash'
import { useDispatch } from 'react-redux'

type usePlaylistGroupsHook = {
  showPlaylistOptions: boolean
  setShowPlaylistOptions: React.Dispatch<SetStateAction<boolean>>
  playlistIds: string[]
  setPlaylistIds: React.Dispatch<SetStateAction<string[]>>
  addingPlaylists: boolean
  setAddingPlaylists: React.Dispatch<SetStateAction<boolean>>
  playlistGroups: globalLib.PlaylistGroup[]
  group: globalLib.PlaylistGroup | null
  loadMorePlaylists: (paging: globalLib.Paging) => Promise<void>
  loadMoreSearchedPlaylists: (paging: globalLib.Paging) => Promise<void>
  loadGroups: () => Promise<void>
  loadGroupById: (playlistGroupId: string) => Promise<void>
  insertSinglePlaylistToGroup: (
    playlistGroupId: string,
    playlistId: string,
    index: number
  ) => Promise<void>
  removeSinglePlaylistToGroup: (
    playlistGroupId: string,
    playlistId: string
  ) => Promise<void>
  bulkInsertPlaylistsInGroup: (
    playlistGroupId: string,
    playlistIds: string[]
  ) => Promise<void>
  sortPlaylistsInPlaylistGroup: (
    sortedList: globalLib.Playlist[]
  ) => Promise<void>
  lastUsedPlaylists: globalLib.Playlist[]
  updateLastUsed: (playlist: globalLib.Playlist) => void
  createOrModifyPlaylist: (
    playlistName: string,
    playlistId?: string
  ) => Promise<string>
  removeFromLastUsed: (playlistId: string) => void
  allPlaylists: any[]
  firstPlaylistId: string | null
}

type Props = {
  businessId: string
  channelId: string
}
const usePlaylistsAndGroups = ({
  businessId,
  channelId
}: Props): usePlaylistGroupsHook => {
  const dispatch = useDispatch()
  const [showPlaylistOptions, setShowPlaylistOptions] = useState(false)
  const [playlistIds, setPlaylistIds] = useState([])
  const [addingPlaylists, setAddingPlaylists] = useState(false)
  const { pixelAmpTracking } = usePixelAmpTracking()

  const playlists = useAppSelector(
    (state) => state.channel.channelPlaylists[channelId]
  )
  const searchedPlaylists = useAppSelector(
    (state) => state.playlists.searchedPlaylist
  )
  const recentPlaylists = JSON.parse(
    localStorageProvider.getItem('lastUsedPlaylists')
  )
  const recentPlaylistsByChannelId =
    recentPlaylists && recentPlaylists[channelId]

  // concat playlists with searched playlists and recent playlists to get an overall view of the playlists the user has seen
  const allPlaylists = useMemo(
    () => [].concat(playlists, searchedPlaylists, recentPlaylistsByChannelId),
    [playlists, recentPlaylistsByChannelId, searchedPlaylists]
  )

  const playlistGroups =
    useAppSelector((state) => state.playlists.channelPlaylistGroups) || []

  const playlistGroupPaging = useAppSelector(
    (state) => state.playlists.channelPlaylistGroupsPaging
  )

  const group = useAppSelector((state) => state.playlists.channelPlaylistGroup)

  const [lastUsedPlaylists, setLastUsedPlaylists] = useLocalStorageItem(
    'lastUsedPlaylists'
  )

  const firstPlaylistId = playlists?.[0]?.id || null

  useEffect(() => {
    // Initialize lastUsedPlaylists if it doesn't exist
    if (!lastUsedPlaylists) {
      setLastUsedPlaylists({ [channelId]: [] })
    } else if (!lastUsedPlaylists[channelId]) {
      setLastUsedPlaylists({ ...lastUsedPlaylists, [channelId]: [] })
    }
  }, [channelId, lastUsedPlaylists, setLastUsedPlaylists])

  const loadMorePlaylists = async (paging) => {
    await dispatch(fetchChannelPlaylists(businessId, channelId, paging.next))
  }

  const loadMoreSearchedPlaylists = async (paging) => {
    await dispatch(
      searchAndSortPlaylists({
        businessId,
        channelId,
        q: '',
        sort: '',
        page: paging
      })
    )
  }

  const loadGroups = async () => {
    await dispatch(
      fetchPlaylistGroups({ businessId, channelId, page: playlistGroupPaging })
    )
  }

  const loadGroupById = useCallback(
    async (playlistGroupId) => {
      await dispatch(
        fetchPlaylistGroup({ businessId, channelId, groupId: playlistGroupId })
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )

  const bulkInsertPlaylistsInGroup = useCallback(
    async (playlistGroupId, playlistIds) => {
      await dispatch(
        addPlaylistsToPlaylistGroup({
          businessId,
          channelId,
          groupId: playlistGroupId,
          playlistIds
        })
      )
    },
    [businessId, channelId, dispatch]
  )

  const insertSinglePlaylistToGroup = useCallback(
    async (playlistGroupId, playlistId, index) => {
      await dispatch(
        insertPlaylistInPlaylistGroup({
          businessId,
          channelId,
          groupId: playlistGroupId,
          playlistId,
          index
        })
      )
    },
    [businessId, channelId, dispatch]
  )

  const removeSinglePlaylistToGroup = useCallback(
    async (playlistGroupId, playlistId) => {
      await removePlaylistInPlaylistGroup({
        businessId,
        channelId,
        groupId: playlistGroupId,
        playlistId
      })
    },
    [businessId, channelId]
  )

  const sortPlaylistsInPlaylistGroup = useCallback(
    async (sortedList) => {
      await dispatch(
        sortPlaylistsInPlaylistGroupSuccess({ playlists: sortedList })
      )
    },
    [dispatch]
  )

  const updateLastUsed = (playlist: globalLib.Playlist) => {
    const { id } = playlist

    const playlistExists = lastUsedPlaylists[channelId].find((p) => p.id === id)
    if (playlistExists) {
      lastUsedPlaylists[channelId].splice(
        lastUsedPlaylists[channelId].indexOf(playlistExists),
        1
      )
    }

    lastUsedPlaylists[channelId].unshift({
      ...playlist
    })

    if (lastUsedPlaylists[channelId].length > 5) {
      lastUsedPlaylists[channelId].pop()
    }

    setLastUsedPlaylists(lastUsedPlaylists)
  }

  const removeFromLastUsed = (playlistId: string) => {
    const playlistExists = lastUsedPlaylists[channelId].find(
      (p) => p.id === playlistId
    )
    if (playlistExists) {
      lastUsedPlaylists[channelId].splice(
        lastUsedPlaylists[channelId].indexOf(playlistExists),
        1
      )
    }
    setLastUsedPlaylists(lastUsedPlaylists)
  }

  const createOrModifyPlaylist = async (
    data: any,
    playlistId?: string
  ): Promise<string> => {
    if (!isNil(playlistId)) {
      await dispatch(
        updateChannelPlaylist(businessId, channelId, playlistId, data)
      )
    } else {
      const res = ((await dispatch(
        createChannelPlaylist(businessId, channelId, data)
      )) as unknown) as any
      const { id } = res.data
      pixelAmpTracking(TRACKING_EVENTS.PLAYLIST_EVENTS.CREATE_PLAYLIST, {
        _playlist_id: id
      })

      return id
    }
  }

  return {
    playlistGroups,
    group,
    loadMorePlaylists,
    loadMoreSearchedPlaylists,
    loadGroups,
    loadGroupById,
    insertSinglePlaylistToGroup,
    bulkInsertPlaylistsInGroup,
    removeSinglePlaylistToGroup,
    showPlaylistOptions,
    setShowPlaylistOptions,
    playlistIds,
    setPlaylistIds,
    addingPlaylists,
    setAddingPlaylists,
    sortPlaylistsInPlaylistGroup,
    lastUsedPlaylists,
    updateLastUsed,
    createOrModifyPlaylist,
    removeFromLastUsed,
    allPlaylists,
    firstPlaylistId
  }
}

export default usePlaylistsAndGroups
