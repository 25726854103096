export type Locale =
  | 'en-US'
  | 'es'
  | 'id'
  | 'ja'
  | 'pl'
  | 'pt-BR'
  | 'zh-HK'
  | 'de'
  | 'vi'
  | 'ko'

type Language = {
  locale: Locale
  name: string
}

const languages: Language[] = [
  { locale: 'en-US', name: 'English' },
  { locale: 'es', name: 'Español' },
  { locale: 'id', name: 'Indonesian' },
  { locale: 'ja', name: '日本語' },
  { locale: 'pl', name: 'Polski' },
  { locale: 'pt-BR', name: 'Português' },
  { locale: 'zh-HK', name: '繁體中文' },
  { locale: 'de', name: 'Deutsch' },
  { locale: 'vi', name: 'Tiếng Việt' },
  { locale: 'ko', name: '한국어' }
]

export const localeMap: Record<Locale, Language> = {
  'en-US': { locale: 'en-US', name: 'English' },
  es: { locale: 'es', name: 'Español' },
  id: { locale: 'id', name: 'Indonesian' },
  ja: { locale: 'ja', name: '日本語' },
  pl: { locale: 'pl', name: 'Polski' },
  'pt-BR': { locale: 'pt-BR', name: 'Português' },
  'zh-HK': { locale: 'zh-HK', name: '繁體中文' },
  de: { locale: 'de', name: 'Deutsch' },
  vi: { locale: 'vi', name: 'Tiếng Việt' },
  ko: { locale: 'ko', name: '한국어' }
}

export const languageRegions: { key: string; value: string }[] = [
  { key: 'aa-DJ', value: 'Afar (Ddjibouti)' },
  { key: 'aa-ER', value: 'Afar (Eritrea)' },
  { key: 'aa-ET', value: 'Afar (Ethiopia)' },
  { key: 'af', value: 'Afrikaans' },
  { key: 'af-NA', value: 'Afrikaans (Namibia)' },
  { key: 'af-ZA', value: 'Afrikaans (South Africa)' },
  { key: 'ak', value: 'Akan' },
  { key: 'ak-GH', value: 'Akan (Ghana)' },
  { key: 'am', value: 'Amharic' },
  { key: 'am-ET', value: 'Amharic (Ethiopia)' },
  { key: 'an-ES', value: 'Aragonese (Spain)' },
  { key: 'ar', value: 'Arabic' },
  { key: 'ar-AE', value: 'Arabic (United Arab Emirates)' },
  { key: 'ar-BH', value: 'Arabic (Bahrain)' },
  { key: 'ar-DZ', value: 'Arabic (Algeria)' },
  { key: 'ar-EG', value: 'Arabic (Egypt)' },
  { key: 'ar-IN', value: 'Arabic (India)' },
  { key: 'ar-IQ', value: 'Arabic (Iraq)' },
  { key: 'ar-JO', value: 'Arabic (Jordan)' },
  { key: 'ar-KW', value: 'Arabic (Kuwait)' },
  { key: 'ar-LB', value: 'Arabic (Lebanon)' },
  { key: 'ar-LY', value: 'Arabic (Libya)' },
  { key: 'ar-MA', value: 'Arabic (Morocco)' },
  { key: 'ar-OM', value: 'Arabic (Oman)' },
  { key: 'ar-QA', value: 'Arabic (Qatar)' },
  { key: 'ar-SA', value: 'Arabic (Saudi Arabia)' },
  { key: 'ar-SD', value: 'Arabic (Sudan)' },
  { key: 'ar-SY', value: 'Arabic (Syria)' },
  { key: 'ar-TN', value: 'Arabic (Tunisia)' },
  { key: 'ar-YE', value: 'Arabic (Yemen)' },
  { key: 'as', value: 'Assamese' },
  { key: 'as-IN', value: 'Assamese (India)' },
  { key: 'asa', value: 'Asu' },
  { key: 'asa-TZ', value: 'Asu (Tanzania)' },
  { key: 'ast-ES', value: 'Asturian (Spain)' },
  { key: 'az', value: 'Azerbaijani' },
  { key: 'az-AZ', value: 'Azerbaijani (Azerbaijan)' },
  { key: 'az-Cyrl', value: 'Azerbaijani (Cyrillic)' },
  { key: 'az-Cyrl-AZ', value: 'Azerbaijani (Cyrillic, Azerbaijan)' },
  { key: 'az-Latn', value: 'Azerbaijani (Latin)' },
  { key: 'az-Latn-AZ', value: 'Azerbaijani (Latin, Azerbaijan)' },
  { key: 'be', value: 'Belarusian' },
  { key: 'be-BY', value: 'Belarusian (Belarus)' },
  { key: 'bem', value: 'Bemba' },
  { key: 'bem-ZM', value: 'Bemba (Zambia)' },
  { key: 'ber-DZ', value: 'UTF-8' },
  { key: 'ber-MA', value: 'Berber (Morocco)' },
  { key: 'bez', value: 'Bena' },
  { key: 'bez-TZ', value: 'Bena (Tanzania)' },
  { key: 'bg', value: 'Bulgarian' },
  { key: 'bg-BG', value: 'Bulgarian (Bulgaria)' },
  { key: 'bho-IN', value: 'Bhojpuri (India)' },
  { key: 'bm', value: 'Bambara' },
  { key: 'bm-ML', value: 'Bambara (Mali)' },
  { key: 'bn', value: 'Bengali' },
  { key: 'bn-BD', value: 'Bengali (Bangladesh)' },
  { key: 'bn-IN', value: 'Bengali (India)' },
  { key: 'bo', value: 'Tibetan' },
  { key: 'bo-CN', value: 'Tibetan (China)' },
  { key: 'bo-IN', value: 'Tibetan (India)' },
  { key: 'br-FR', value: 'Breton (France)' },
  { key: 'brx-IN', value: 'Bodo (India)' },
  { key: 'bs', value: 'Bosnian' },
  { key: 'bs-BA', value: 'Bosnian (Bosnia and Herzegovina)' },
  { key: 'byn-ER', value: 'Bilin (Eritrea)' },
  { key: 'ca', value: 'Catalan' },
  { key: 'ca-AD', value: 'Catalan (Andorra)' },
  { key: 'ca-ES', value: 'Catalan (Spain)' },
  { key: 'ca-FR', value: 'Catalan (France)' },
  { key: 'ca-IT', value: 'Catalan (Italy)' },
  { key: 'cgg', value: 'Chiga' },
  { key: 'cgg-UG', value: 'Chiga (Uganda)' },
  { key: 'chr', value: 'Cherokee' },
  { key: 'chr-US', value: 'Cherokee (United States)' },
  { key: 'crh-UA', value: 'Crimean Tatar (Ukraine)' },
  { key: 'cs', value: 'Czech' },
  { key: 'cs-CZ', value: 'Czech (Czech Republic)' },
  { key: 'csb-PL', value: 'Kashubian (Poland)' },
  { key: 'cv-RU', value: 'Chuvash (Russia)' },
  { key: 'cy', value: 'Welsh' },
  { key: 'cy-GB', value: 'Welsh (United Kingdom)' },
  { key: 'da', value: 'Danish' },
  { key: 'da-DK', value: 'Danish (Denmark)' },
  { key: 'dav', value: 'Taita' },
  { key: 'dav-KE', value: 'Taita (Kenya)' },
  { key: 'de', value: 'German' },
  { key: 'de-AT', value: 'German (Austria)' },
  { key: 'de-BE', value: 'German (Belgium)' },
  { key: 'de-CH', value: 'German (Switzerland)' },
  { key: 'de-DE', value: 'German (Germany)' },
  { key: 'de-LI', value: 'German (Liechtenstein)' },
  { key: 'de-LU', value: 'German (Luxembourg)' },
  { key: 'dv-MV', value: 'Dhivehi (Maldives)' },
  { key: 'dz-BT', value: 'Dzongkha (Buthan)' },
  { key: 'ebu', value: 'Embu' },
  { key: 'ebu-KE', value: 'Embu (Kenya)' },
  { key: 'ee', value: 'Ewe' },
  { key: 'ee-GH', value: 'Ewe (Ghana)' },
  { key: 'ee-TG', value: 'Ewe (Togo)' },
  { key: 'el', value: 'Greek' },
  { key: 'el-CY', value: 'Greek (Cyprus)' },
  { key: 'el-GR', value: 'Greek (Greece)' },
  { key: 'en', value: 'English' },
  { key: 'en-AG', value: 'English (Antigua & Barbuda)' },
  { key: 'en-AS', value: 'English (American Samoa)' },
  { key: 'en-AU', value: 'English (Australia)' },
  { key: 'en-BE', value: 'English (Belgium)' },
  { key: 'en-BW', value: 'English (Botswana)' },
  { key: 'en-BZ', value: 'English (Belize)' },
  { key: 'en-CA', value: 'English (Canada)' },
  { key: 'en-DK', value: 'English (Denmark)' },
  { key: 'en-GB', value: 'English (United Kingdom)' },
  { key: 'en-GU', value: 'English (Guam)' },
  { key: 'en-HK', value: 'English (Hong Kong SAR China)' },
  { key: 'en-IE', value: 'English (Ireland)' },
  { key: 'en-IN', value: 'English (India)' },
  { key: 'en-JM', value: 'English (Jamaica)' },
  { key: 'en-MH', value: 'English (Marshall Islands)' },
  { key: 'en-MP', value: 'English (Northern Mariana Islands)' },
  { key: 'en-MT', value: 'English (Malta)' },
  { key: 'en-MU', value: 'English (Mauritius)' },
  { key: 'en-NA', value: 'English (Namibia)' },
  { key: 'en-NZ', value: 'English (New Zealand)' },
  { key: 'en-PH', value: 'English (Philippines)' },
  { key: 'en-PK', value: 'English (Pakistan)' },
  { key: 'en-SG', value: 'English (Singapore)' },
  { key: 'en-TT', value: 'English (Trinidad and Tobago)' },
  { key: 'en-UM', value: 'English (U.S. Minor Outlying Islands)' },
  { key: 'en-US', value: 'English (United States)' },
  { key: 'en-VI', value: 'English (U.S. Virgin Islands)' },
  { key: 'en-ZA', value: 'English (South Africa)' },
  { key: 'en-ZM', value: 'English (Zambia)' },
  { key: 'en-ZW', value: 'English (Zimbabwe)' },
  { key: 'eo', value: 'Esperanto' },
  { key: 'es', value: 'Spanish' },
  { key: 'es-419', value: 'Spanish (Latin America)' },
  { key: 'es-AR', value: 'Spanish (Argentina)' },
  { key: 'es-BO', value: 'Spanish (Bolivia)' },
  { key: 'es-CL', value: 'Spanish (Chile)' },
  { key: 'es-CO', value: 'Spanish (Colombia)' },
  { key: 'es-CR', value: 'Spanish (Costa Rica)' },
  { key: 'es-CU', value: 'Spanish (Cuba)' },
  { key: 'es-DO', value: 'Spanish (Dominican Republic)' },
  { key: 'es-EC', value: 'Spanish (Ecuador)' },
  { key: 'es-ES', value: 'Spanish (Spain)' },
  { key: 'es-GQ', value: 'Spanish (Equatorial Guinea)' },
  { key: 'es-GT', value: 'Spanish (Guatemala)' },
  { key: 'es-HN', value: 'Spanish (Honduras)' },
  { key: 'es-MX', value: 'Spanish (Mexico)' },
  { key: 'es-NI', value: 'Spanish (Nicaragua)' },
  { key: 'es-PA', value: 'Spanish (Panama)' },
  { key: 'es-PE', value: 'Spanish (Peru)' },
  { key: 'es-PR', value: 'Spanish (Puerto Rico)' },
  { key: 'es-PY', value: 'Spanish (Paraguay)' },
  { key: 'es-SV', value: 'Spanish (El Salvador)' },
  { key: 'es-US', value: 'Spanish (United States)' },
  { key: 'es-UY', value: 'Spanish (Uruguay)' },
  { key: 'es-VE', value: 'Spanish (Venezuela)' },
  { key: 'et', value: 'Estonian' },
  { key: 'et-EE', value: 'Estonian (Estonia)' },
  { key: 'eu', value: 'Basque' },
  { key: 'eu-ES', value: 'Basque (Spain)' },
  { key: 'fa', value: 'Persian' },
  { key: 'fa-AF', value: 'Persian (Afghanistan)' },
  { key: 'fa-IR', value: 'Persian (Iran)' },
  { key: 'ff', value: 'Fulah' },
  { key: 'ff-SN', value: 'Fulah (Senegal)' },
  { key: 'fi', value: 'Finnish' },
  { key: 'fi-FI', value: 'Finnish (Finland)' },
  { key: 'fil', value: 'Filipino' },
  { key: 'fil-PH', value: 'Filipino (Philippines)' },
  { key: 'fo', value: 'Faroese' },
  { key: 'fo-FO', value: 'Faroese (Faroe Islands)' },
  { key: 'fr', value: 'French' },
  { key: 'fr-BE', value: 'French (Belgium)' },
  { key: 'fr-BF', value: 'French (Burkina Faso)' },
  { key: 'fr-BI', value: 'French (Burundi)' },
  { key: 'fr-BJ', value: 'French (Benin)' },
  { key: 'fr-BL', value: 'French (Saint Barthélemy)' },
  { key: 'fr-CA', value: 'French (Canada)' },
  { key: 'fr-CD', value: 'French (Congo - Kinshasa)' },
  { key: 'fr-CF', value: 'French (Central African Republic)' },
  { key: 'fr-CG', value: 'French (Congo - Brazzaville)' },
  { key: 'fr-CH', value: 'French (Switzerland)' },
  { key: 'fr-CI', value: 'French (Côte d’Ivoire)' },
  { key: 'fr-CM', value: 'French (Cameroon)' },
  { key: 'fr-DJ', value: 'French (Djibouti)' },
  { key: 'fr-FR', value: 'French (France)' },
  { key: 'fr-GA', value: 'French (Gabon)' },
  { key: 'fr-GN', value: 'French (Guinea)' },
  { key: 'fr-GP', value: 'French (Guadeloupe)' },
  { key: 'fr-GQ', value: 'French (Equatorial Guinea)' },
  { key: 'fr-KM', value: 'French (Comoros)' },
  { key: 'fr-LU', value: 'French (Luxembourg)' },
  { key: 'fr-MC', value: 'French (Monaco)' },
  { key: 'fr-MF', value: 'French (Saint Martin)' },
  { key: 'fr-MG', value: 'French (Madagascar)' },
  { key: 'fr-ML', value: 'French (Mali)' },
  { key: 'fr-MQ', value: 'French (Martinique)' },
  { key: 'fr-NE', value: 'French (Niger)' },
  { key: 'fr-RE', value: 'French (Réunion)' },
  { key: 'fr-RW', value: 'French (Rwanda)' },
  { key: 'fr-SN', value: 'French (Senegal)' },
  { key: 'fr-TD', value: 'French (Chad)' },
  { key: 'fr-TG', value: 'French (Togo)' },
  { key: 'fur-IT', value: 'Friulian (Italy)' },
  { key: 'fy-DE', value: 'Western Frisian (Germany)' },
  { key: 'fy-NL', value: 'Western Frisian (Netherlands)' },
  { key: 'ga', value: 'Irish' },
  { key: 'ga-IE', value: 'Irish (Ireland)' },
  { key: 'gd-GB', value: 'Scottish Gaelic (United Kingdom)' },
  { key: 'gez-ER', value: 'Geez (Eritrea)' },
  { key: 'gez-ET', value: 'Geez (Ethiopia)' },
  { key: 'gl', value: 'Galician' },
  { key: 'gl-ES', value: 'Galician (Spain)' },
  { key: 'gsw', value: 'Swiss German' },
  { key: 'gsw-CH', value: 'Swiss German (Switzerland)' },
  { key: 'gu', value: 'Gujarati' },
  { key: 'gu-IN', value: 'Gujarati (India)' },
  { key: 'guz', value: 'Gusii' },
  { key: 'guz-KE', value: 'Gusii (Kenya)' },
  { key: 'gv', value: 'Manx' },
  { key: 'gv-GB', value: 'Manx (United Kingdom)' },
  { key: 'ha', value: 'Hausa' },
  { key: 'ha-Latn', value: 'Hausa (Latin)' },
  { key: 'ha-Latn-GH', value: 'Hausa (Latin, Ghana)' },
  { key: 'ha-Latn-NE', value: 'Hausa (Latin, Niger)' },
  { key: 'ha-Latn-NG', value: 'Hausa (Latin, Nigeria)' },
  { key: 'ha-NG', value: 'Hausa (Nigeria)' },
  { key: 'haw', value: 'Hawaiian' },
  { key: 'haw-US', value: 'Hawaiian (United States)' },
  { key: 'he', value: 'Hebrew' },
  { key: 'he-IL', value: 'Hebrew (Israel)' },
  { key: 'hi', value: 'Hindi' },
  { key: 'hi-IN', value: 'Hindi (India)' },
  { key: 'hne-IN', value: 'Chhattisgarhi (India)' },
  { key: 'hr', value: 'Croatian' },
  { key: 'hr-HR', value: 'Croatian (Croatia)' },
  { key: 'hsb-DE', value: 'Upper Sorbian (Germany)' },
  { key: 'ht-HT', value: 'Haitian (Haiti)' },
  { key: 'hu', value: 'Hungarian' },
  { key: 'hu-HU', value: 'Hungarian (Hungary)' },
  { key: 'hy', value: 'Armenian' },
  { key: 'hy-AM', value: 'Armenian (Armenia)' },
  { key: 'id', value: 'Indonesian' },
  { key: 'id-ID', value: 'Indonesian (Indonesia)' },
  { key: 'ig', value: 'Igbo' },
  { key: 'ig-NG', value: 'Igbo (Nigeria)' },
  { key: 'ii', value: 'Sichuan Yi' },
  { key: 'ii-CN', value: 'Sichuan Yi (China)' },
  { key: 'ik-CA', value: 'Inupiaq (Canada)' },
  { key: 'is', value: 'Icelandic' },
  { key: 'is-IS', value: 'Icelandic (Iceland)' },
  { key: 'it', value: 'Italian' },
  { key: 'it-CH', value: 'Italian (Switzerland)' },
  { key: 'it-IT', value: 'Italian (Italy)' },
  { key: 'iu-CA', value: 'Inuktitut (Canada)' },
  { key: 'iw-IL', value: 'Hebrew (Israel)' },
  { key: 'ja', value: 'Japanese' },
  { key: 'ja-JP', value: 'Japanese (Japan)' },
  { key: 'jmc', value: 'Machame' },
  { key: 'jmc-TZ', value: 'Machame (Tanzania)' },
  { key: 'ka', value: 'Georgian' },
  { key: 'ka-GE', value: 'Georgian (Georgia)' },
  { key: 'kab', value: 'Kabyle' },
  { key: 'kab-DZ', value: 'Kabyle (Algeria)' },
  { key: 'kam', value: 'Kamba' },
  { key: 'kam-KE', value: 'Kamba (Kenya)' },
  { key: 'kde', value: 'Makonde' },
  { key: 'kde-TZ', value: 'Makonde (Tanzania)' },
  { key: 'kea', value: 'Kabuverdianu' },
  { key: 'kea-CV', value: 'Kabuverdianu (Cape Verde)' },
  { key: 'khq', value: 'Koyra Chiini' },
  { key: 'khq-ML', value: 'Koyra Chiini (Mali)' },
  { key: 'ki', value: 'Kikuyu' },
  { key: 'ki-KE', value: 'Kikuyu (Kenya)' },
  { key: 'kk', value: 'Kazakh' },
  { key: 'kk-Cyrl', value: 'Kazakh (Cyrillic)' },
  { key: 'kk-Cyrl-KZ', value: 'Kazakh (Cyrillic, Kazakhstan)' },
  { key: 'kk-KZ', value: 'Kazakh (Kazakhstan)' },
  { key: 'kl', value: 'Kalaallisut' },
  { key: 'kl-GL', value: 'Kalaallisut (Greenland)' },
  { key: 'kln', value: 'Kalenjin' },
  { key: 'kln-KE', value: 'Kalenjin (Kenya)' },
  { key: 'km', value: 'Khmer' },
  { key: 'km-KH', value: 'Khmer (Cambodia)' },
  { key: 'kn', value: 'Kannada' },
  { key: 'kn-IN', value: 'Kannada (India)' },
  { key: 'ko', value: 'Korean' },
  { key: 'ko-KR', value: 'Korean (South Korea)' },
  { key: 'kok', value: 'Konkani' },
  { key: 'kok-IN', value: 'Konkani (India)' },
  { key: 'ks-IN', value: 'Kashmiri (India)' },
  { key: 'ku-TR', value: 'Kurdish (Turkey)' },
  { key: 'kw', value: 'Cornish' },
  { key: 'kw-GB', value: 'Cornish (United Kingdom)' },
  { key: 'ky-KG', value: 'Kirghiz (Kyrgyzstan)' },
  { key: 'lag', value: 'Langi' },
  { key: 'lag-TZ', value: 'Langi (Tanzania)' },
  { key: 'lb-LU', value: 'Luxembourgish (Luxembourg)' },
  { key: 'lg', value: 'Ganda' },
  { key: 'lg-UG', value: 'Ganda (Uganda)' },
  { key: 'li-BE', value: 'Limburgan (Belgium)' },
  { key: 'li-NL', value: 'Limburgan (Netherlands)' },
  { key: 'lij-IT', value: 'Ligurian (Italy)' },
  { key: 'lo-LA', value: 'Lao (Laos)' },
  { key: 'lt', value: 'Lithuanian' },
  { key: 'lt-LT', value: 'Lithuanian (Lithuania)' },
  { key: 'luo', value: 'Luo' },
  { key: 'luo-KE', value: 'Luo (Kenya)' },
  { key: 'luy', value: 'Luyia' },
  { key: 'luy-KE', value: 'Luyia (Kenya)' },
  { key: 'lv', value: 'Latvian' },
  { key: 'lv-LV', value: 'Latvian (Latvia)' },
  { key: 'mag-IN', value: 'Magahi (India)' },
  { key: 'mai-IN', value: 'Maithili (India)' },
  { key: 'mas', value: 'Masai' },
  { key: 'mas-KE', value: 'Masai (Kenya)' },
  { key: 'mas-TZ', value: 'Masai (Tanzania)' },
  { key: 'mer', value: 'Meru' },
  { key: 'mer-KE', value: 'Meru (Kenya)' },
  { key: 'mfe', value: 'Morisyen' },
  { key: 'mfe-MU', value: 'Morisyen (Mauritius)' },
  { key: 'mg', value: 'Malagasy' },
  { key: 'mg-MG', value: 'Malagasy (Madagascar)' },
  { key: 'mhr-RU', value: 'Eastern Mari (Russia)' },
  { key: 'mi-NZ', value: 'Maori (New Zealand)' },
  { key: 'mk', value: 'Macedonian' },
  { key: 'mk-MK', value: 'Macedonian (Macedonia)' },
  { key: 'ml', value: 'Malayalam' },
  { key: 'ml-IN', value: 'Malayalam (India)' },
  { key: 'mn-MN', value: 'Mongolian (Mongolia)' },
  { key: 'mr', value: 'Marathi' },
  { key: 'mr-IN', value: 'Marathi (India)' },
  { key: 'ms', value: 'Malay' },
  { key: 'ms-BN', value: 'Malay (Brunei)' },
  { key: 'ms-MY', value: 'Malay (Malaysia)' },
  { key: 'mt', value: 'Maltese' },
  { key: 'mt-MT', value: 'Maltese (Malta)' },
  { key: 'my', value: 'Burmese' },
  { key: 'my-MM', value: 'Burmese (Myanmar [Burma])' },
  { key: 'nan-TW', value: 'Min Nan Chinese (Taiwan)' },
  { key: 'naq', value: 'Nama' },
  { key: 'naq-NA', value: 'Nama (Namibia)' },
  { key: 'nb', value: 'Norwegian Bokmål' },
  { key: 'nb-NO', value: 'Norwegian Bokmål (Norway)' },
  { key: 'nd', value: 'North Ndebele' },
  { key: 'nd-ZW', value: 'North Ndebele (Zimbabwe)' },
  { key: 'nds-DE', value: 'Low German (German)' },
  { key: 'nds-NL', value: 'Low German (Netherlands)' },
  { key: 'ne', value: 'Nepali' },
  { key: 'ne-IN', value: 'Nepali (India)' },
  { key: 'ne-NP', value: 'Nepali (Nepal)' },
  { key: 'nl', value: 'Dutch' },
  { key: 'nl-AW', value: 'Dutch (Aruba)' },
  { key: 'nl-BE', value: 'Dutch (Belgium)' },
  { key: 'nl-NL', value: 'Dutch (Netherlands)' },
  { key: 'nn', value: 'Norwegian Nynorsk' },
  { key: 'nn-NO', value: 'Norwegian Nynorsk (Norway)' },
  { key: 'nr-ZA', value: 'South Ndebele (South Africa)' },
  { key: 'nso-ZA', value: 'Pedi (South Africa)' },
  { key: 'nyn', value: 'Nyankole' },
  { key: 'nyn-UG', value: 'Nyankole (Uganda)' },
  { key: 'oc-FR', value: 'Occitan (France)' },
  { key: 'om', value: 'Oromo' },
  { key: 'om-ET', value: 'Oromo (Ethiopia)' },
  { key: 'om-KE', value: 'Oromo (Kenya)' },
  { key: 'or', value: 'Oriya' },
  { key: 'or-IN', value: 'Oriya (India)' },
  { key: 'os-RU', value: 'Ossetian (Russia)' },
  { key: 'pa', value: 'Punjabi' },
  { key: 'pa-Arab', value: 'Punjabi (Arabic)' },
  { key: 'pa-Arab-PK', value: 'Punjabi (Arabic, Pakistan)' },
  { key: 'pa-Guru', value: 'Punjabi (Gurmukhi)' },
  { key: 'pa-Guru-IN', value: 'Punjabi (Gurmukhi, India)' },
  { key: 'pa-IN', value: 'Panjabi (India)' },
  { key: 'pa-PK', value: 'Panjabi (Pakistan)' },
  { key: 'pap-AN', value: 'Papiamento (Netherlands)' },
  { key: 'pl', value: 'Polish' },
  { key: 'pl-PL', value: 'Polish (Poland)' },
  { key: 'ps', value: 'Pashto' },
  { key: 'ps-AF', value: 'Pashto (Afghanistan)' },
  { key: 'pt', value: 'Portuguese' },
  { key: 'pt-BR', value: 'Portuguese (Brazil)' },
  { key: 'pt-GW', value: 'Portuguese (Guinea-Bissau)' },
  { key: 'pt-MZ', value: 'Portuguese (Mozambique)' },
  { key: 'pt-PT', value: 'Portuguese (Portugal)' },
  { key: 'rm', value: 'Romansh' },
  { key: 'rm-CH', value: 'Romansh (Switzerland)' },
  { key: 'ro', value: 'Romanian' },
  { key: 'ro-MD', value: 'Romanian (Moldova)' },
  { key: 'ro-RO', value: 'Romanian (Romania)' },
  { key: 'rof', value: 'Rombo' },
  { key: 'rof-TZ', value: 'Rombo (Tanzania)' },
  { key: 'ru', value: 'Russian' },
  { key: 'ru-MD', value: 'Russian (Moldova)' },
  { key: 'ru-RU', value: 'Russian (Russia)' },
  { key: 'ru-UA', value: 'Russian (Ukraine)' },
  { key: 'rw', value: 'Kinyarwanda' },
  { key: 'rw-RW', value: 'Kinyarwanda (Rwanda)' },
  { key: 'rwk', value: 'Rwa' },
  { key: 'rwk-TZ', value: 'Rwa (Tanzania)' },
  { key: 'sa-IN', value: 'Sanskrit (India)' },
  { key: 'saq', value: 'Samburu' },
  { key: 'saq-KE', value: 'Samburu (Kenya)' },
  { key: 'sc-IT', value: 'Sardinian (Italy)' },
  { key: 'sd-IN', value: 'Sindhi (India)' },
  { key: 'se-NO', value: 'Northern Sami (Norway)' },
  { key: 'seh', value: 'Sena' },
  { key: 'seh-MZ', value: 'Sena (Mozambique)' },
  { key: 'ses', value: 'Koyraboro Senni' },
  { key: 'ses-ML', value: 'Koyraboro Senni (Mali)' },
  { key: 'sg', value: 'Sango' },
  { key: 'sg-CF', value: 'Sango (Central African Republic)' },
  { key: 'shi', value: 'Tachelhit' },
  { key: 'shi-Latn', value: 'Tachelhit (Latin)' },
  { key: 'shi-Latn-MA', value: 'Tachelhit (Latin, Morocco)' },
  { key: 'shi-Tfng', value: 'Tachelhit (Tifinagh)' },
  { key: 'shi-Tfng-MA', value: 'Tachelhit (Tifinagh, Morocco)' },
  { key: 'shs-CA', value: 'Shuswap (Canada)' },
  { key: 'si', value: 'Sinhala' },
  { key: 'si-LK', value: 'Sinhala (Sri Lanka)' },
  { key: 'sid-ET', value: 'Sidamo (Ethiopia)' },
  { key: 'sk', value: 'Slovak' },
  { key: 'sk-SK', value: 'Slovak (Slovakia)' },
  { key: 'sl', value: 'Slovenian' },
  { key: 'sl-SI', value: 'Slovenian (Slovenia)' },
  { key: 'sn', value: 'Shona' },
  { key: 'sn-ZW', value: 'Shona (Zimbabwe)' },
  { key: 'so', value: 'Somali' },
  { key: 'so-DJ', value: 'Somali (Djibouti)' },
  { key: 'so-ET', value: 'Somali (Ethiopia)' },
  { key: 'so-KE', value: 'Somali (Kenya)' },
  { key: 'so-SO', value: 'Somali (Somalia)' },
  { key: 'sq', value: 'Albanian' },
  { key: 'sq-AL', value: 'Albanian (Albania)' },
  { key: 'sq-MK', value: 'Albanian (Macedonia)' },
  { key: 'sr', value: 'Serbian' },
  { key: 'sr-Cyrl', value: 'Serbian (Cyrillic)' },
  { key: 'sr-Cyrl-BA', value: 'Serbian (Cyrillic, Bosnia and Herzegovina)' },
  { key: 'sr-Cyrl-ME', value: 'Serbian (Cyrillic, Montenegro)' },
  { key: 'sr-Cyrl-RS', value: 'Serbian (Cyrillic, Serbia)' },
  { key: 'sr-Latn', value: 'Serbian (Latin)' },
  { key: 'sr-Latn-BA', value: 'Serbian (Latin, Bosnia and Herzegovina)' },
  { key: 'sr-Latn-ME', value: 'Serbian (Latin, Montenegro)' },
  { key: 'sr-Latn-RS', value: 'Serbian (Latin, Serbia)' },
  { key: 'sr-ME', value: 'Serbian (Montenegro)' },
  { key: 'sr-RS', value: 'Serbian (Serbia)' },
  { key: 'ss-ZA', value: 'Swati (South Africa)' },
  { key: 'st-ZA', value: 'Southern Sotho (South Africa)' },
  { key: 'sv', value: 'Swedish' },
  { key: 'sv-FI', value: 'Swedish (Finland)' },
  { key: 'sv-SE', value: 'Swedish (Sweden)' },
  { key: 'sw', value: 'Swahili' },
  { key: 'sw-KE', value: 'Swahili (Kenya)' },
  { key: 'sw-TZ', value: 'Swahili (Tanzania)' },
  { key: 'ta', value: 'Tamil' },
  { key: 'ta-IN', value: 'Tamil (India)' },
  { key: 'ta-LK', value: 'Tamil (Sri Lanka)' },
  { key: 'te', value: 'Telugu' },
  { key: 'te-IN', value: 'Telugu (India)' },
  { key: 'teo', value: 'Teso' },
  { key: 'teo-KE', value: 'Teso (Kenya)' },
  { key: 'teo-UG', value: 'Teso (Uganda)' },
  { key: 'tg-TJ', value: 'Tajik (Tajikistan)' },
  { key: 'th', value: 'Thai' },
  { key: 'th-TH', value: 'Thai (Thailand)' },
  { key: 'ti', value: 'Tigrinya' },
  { key: 'ti-ER', value: 'Tigrinya (Eritrea)' },
  { key: 'ti-ET', value: 'Tigrinya (Ethiopia)' },
  { key: 'tig-ER', value: 'Tigre (Eritrea)' },
  { key: 'tk-TM', value: 'Turkmen (Turkmenistan)' },
  { key: 'tl-PH', value: 'Tagalog (Philippines)' },
  { key: 'tn-ZA', value: 'Tswana (South Africa)' },
  { key: 'to', value: 'Tonga' },
  { key: 'to-TO', value: 'Tonga (Tonga)' },
  { key: 'tr', value: 'Turkish' },
  { key: 'tr-CY', value: 'Turkish (Cyprus)' },
  { key: 'tr-TR', value: 'Turkish (Turkey)' },
  { key: 'ts-ZA', value: 'Tsonga (South Africa)' },
  { key: 'tt-RU', value: 'Tatar (Russia)' },
  { key: 'tzm', value: 'Central Morocco Tamazight' },
  { key: 'tzm-Latn', value: 'Central Morocco Tamazight (Latin)' },
  { key: 'tzm-Latn-MA', value: 'Central Morocco Tamazight (Latin, Morocco)' },
  { key: 'ug-CN', value: 'Uighur (China)' },
  { key: 'uk', value: 'Ukrainian' },
  { key: 'uk-UA', value: 'Ukrainian (Ukraine)' },
  { key: 'unm-US', value: 'Unami (America)' },
  { key: 'ur', value: 'Urdu' },
  { key: 'ur-IN', value: 'Urdu (India)' },
  { key: 'ur-PK', value: 'Urdu (Pakistan)' },
  { key: 'uz', value: 'Uzbek' },
  { key: 'uz-Arab', value: 'Uzbek (Arabic)' },
  { key: 'uz-Arab-AF', value: 'Uzbek (Arabic, Afghanistan)' },
  { key: 'uz-Cyrl', value: 'Uzbek (Cyrillic)' },
  { key: 'uz-Cyrl-UZ', value: 'Uzbek (Cyrillic, Uzbekistan)' },
  { key: 'uz-Latn', value: 'Uzbek (Latin)' },
  { key: 'uz-Latn-UZ', value: 'Uzbek (Latin, Uzbekistan)' },
  { key: 'uz-UZ', value: 'Uzbek (Uzbekistan)' },
  { key: 've-ZA', value: 'Venda (South Africa)' },
  { key: 'vi', value: 'Vietnamese' },
  { key: 'vi-VN', value: 'Vietnamese (Vietnam)' },
  { key: 'vun', value: 'Vunjo' },
  { key: 'vun-TZ', value: 'Vunjo (Tanzania)' },
  { key: 'wa-BE', value: 'Walloon (Belgium)' },
  { key: 'wae-CH', value: 'Walser (Switzerland)' },
  { key: 'wal-ET', value: 'Wolaytta (Ethiopia)' },
  { key: 'wo-SN', value: 'Wolof (Senegal)' },
  { key: 'xh-ZA', value: 'Xhosa South Africa)' },
  { key: 'xog', value: 'Soga' },
  { key: 'xog-UG', value: 'Soga (Uganda)' },
  { key: 'yi-US', value: 'Yiddish (America)' },
  { key: 'yo', value: 'Yoruba' },
  { key: 'yo-NG', value: 'Yoruba (Nigeria)' },
  { key: 'yue-HK', value: 'Yue Chinese (China)' },
  { key: 'zh', value: 'Chinese' },
  { key: 'zh-CN', value: 'Chinese (China)' },
  { key: 'zh-HK', value: 'Chinese (Hong Kong)' },
  { key: 'zh-Hans', value: 'Chinese (Simplified Han)' },
  { key: 'zh-Hans-CN', value: 'Chinese (Simplified Han, China)' },
  { key: 'zh-Hans-HK', value: 'Chinese (Simplified Han, Hong Kong SAR China)' },
  { key: 'zh-Hans-MO', value: 'Chinese (Simplified Han, Macau SAR China)' },
  { key: 'zh-Hans-SG', value: 'Chinese (Simplified Han, Singapore)' },
  { key: 'zh-Hant', value: 'Chinese (Traditional Han)' },
  {
    key: 'zh-Hant-HK',
    value: 'Chinese (Traditional Han, Hong Kong SAR China)'
  },
  { key: 'zh-Hant-MO', value: 'Chinese (Traditional Han, Macau SAR China)' },
  { key: 'zh-Hant-TW', value: 'Chinese (Traditional Han, Taiwan)' },
  { key: 'zh-SG', value: 'Chinese (Singapore)' },
  { key: 'zh-TW', value: 'Chinese (Taiwan)' },
  { key: 'zu', value: 'Zulu' },
  { key: 'zu-ZA', value: 'Zulu (South Africa)' }
]

export default languages
