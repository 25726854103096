import React from 'react'

import { css } from '@emotion/core'

import { useSpace } from '../../hooks'

type IProps = {
  align?: 'left' | 'right'
  space?: string
  childrent?: React.ReactNode
  className?: string
}

const Inline: React.FC<IProps> = ({
  align = 'left',
  space,
  children,
  className,
  ...props
}) => {
  const spacing = useSpace(space)

  return (
    <div
      {...props}
      css={css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin-top: -${spacing};
        > * {
          margin-top: ${spacing};
        }
        ${align === 'left' &&
        css`
          justify-content: flex-start;
          > *:not(:last-child) {
            margin-right: ${spacing};
          }
        `}
        ${align === 'right' &&
        css`
          justify-content: flex-end;
          > *:not(:first-of-type) {
            margin-left: ${spacing};
          }
        `}
        ${className}
      `}
    >
      {children}
    </div>
  )
}

export default Inline
