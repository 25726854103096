import React from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Flex } from '@src/common/components/BaseComponent'
import { Text } from '@src/common/components/Text'
import theme from '@src/styles/theme'
import { useTranslation } from 'react-i18next'

type CreateWidgetTileProps = {
  onClick: () => void
}

export const CreateWidgetTile = ({
  onClick
}: CreateWidgetTileProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="283px"
      width="300px"
      border={`1px dashed ${theme.grayScale300}`}
      borderRadius="6px"
      cursor="pointer"
      onClick={onClick}
    >
      <div>
        <PlusOutlined style={{ fontSize: '24px', color: theme.textTertiary }} />
      </div>
      <Text
        css={css`
          color: ${theme.textTertiary};
        `}
      >
        {t('Create Widget')}
      </Text>
    </Flex>
  )
}
