import React, { forwardRef } from 'react'

import { css } from '@emotion/react'
import { Box } from '@src/common/components/BaseComponent'
import { Select as AntdSelect, SelectProps as AntdSelectProps } from 'antd'

const { Option } = AntdSelect

const Select = forwardRef<any, AntdSelectProps>((props, ref) => {
  return (
    <AntdSelect
      ref={ref}
      allowClear
      suffixIcon={
        <Box
          as="img"
          src="/chevron_down_black.svg"
          alt="arrow-down"
          width={12}
        />
      }
      css={css`
        .ant-select-selector {
          border-radius: 6px !important;
          height: 34px !important;
        }
        .ant-select-selection-item {
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      `}
      {...props}
    />
  )
})

Select.displayName = 'Select'

// const Option = (props: OptionProps): JSX.Element => {
//   return <AntdSelect.Option {...props} />
// }

export { Select, Option }

export default Select
