import qs from 'qs'

/**
 * Parses a query param string into an object.
 * @param {string} params Query params string
 * @return {object} Parsed query params
 */
export const parseQueryParams = (params: string): qs.ParsedQs => {
  if (!params) return {}
  const idx = params.indexOf('?')

  return idx !== -1 ? qs.parse(params.slice(idx + 1)) : qs.parse(params)
}

/**
 * Opens a new tab with the external URL.
 * @param {string} url URL to open
 */
export const openExternalUrl = (url: string): void => {
  window.open(url, '_blank')
}
