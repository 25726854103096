import React from 'react'

import { Flex } from '@src/common/components/BaseComponent'
import { Title } from '@src/common/components/BaseComponent'

import { Step, StepCircle, StepLine } from './VerticalStepper.styles'
import { StepperContainer } from './VerticalStepper.styles'

interface VerticalStepperProps {
  steps: {
    stepComponent: React.ReactNode
    id?: number
    key: string
    active?: boolean
  }[]
  title: string
}

export const VerticalStepper: React.FC<VerticalStepperProps> = ({
  steps,
  title
}) => {
  return (
    <Flex flexDirection="row" width="100%" gap={24}>
      <StepperContainer>
        {steps
          .filter((step) => step.active)
          .map((step, index) => (
            <Step key={step.key}>
              <StepLine />
              <StepCircle>{step?.id || index + 1}</StepCircle>
            </Step>
          ))}
      </StepperContainer>
      <Flex flexDirection="column" gap={12} flex="1">
        <Title weight={600} fontSize={20} noMargin>
          {title}
        </Title>
        {steps
          .filter((step) => step.active)
          .map((step) => (
            <div key={step.key}>{step.stepComponent}</div>
          ))}
      </Flex>
    </Flex>
  )
}
