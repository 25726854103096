import { useEffect, useState } from 'react'

import { useAppSelector } from '@src/app/hooks'
import { useNabooFeatureFlag } from '@src/common/hooks'
import { setHasNewNotification } from '@src/common/redux/ui'
import i18next from '@src/localization/i18n'
import { GITHUB_JSON_URL } from '@src/modules/profile/constants'
import { useDispatch } from 'react-redux'

export const useNotification = () => {
  const user = useAppSelector((state) => state.profile.user)

  const dispatch = useDispatch()
  const nabooFF = useNabooFeatureFlag()

  const [hasNotification, setHasNotification] = useState(false)

  const [language, setLanguage] = useState(
    localStorage.getItem('i18nextLng') || navigator.language || 'en'
  )

  useEffect(() => {
    const handleLanguageChange = (lng: string) => {
      setLanguage(lng)
    }
    i18next.on('languageChanged', handleLanguageChange)

    return () => {
      i18next.off('languageChanged', handleLanguageChange)
    }
  }, [])

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch(GITHUB_JSON_URL)
        if (!response.ok) throw new Error('Failed to fetch notifications')

        const data = await response.json()
        const notificationList =
          data[language] ?? data[language?.split('-')?.[0]] ?? data['en']

        if (!notificationList || notificationList.length === 0) {
          setHasNotification(false)
        } else {
          // Get read notifications from localStorage
          const readNotificationsKey = `readNotifications_${user?.id}_${language}`
          const storedReadNotifications = localStorage.getItem(
            readNotificationsKey
          )
          const readNotifications = storedReadNotifications
            ? JSON.parse(storedReadNotifications)
            : []

          const hasUnread = notificationList.some(
            (notif) => !readNotifications.includes(notif.id)
          )
          dispatch(setHasNewNotification(hasUnread))
          setHasNotification(true)
        }
      } catch (error) {
        setHasNotification(false)
      }
    }

    if (nabooFF.cms_notification_center) {
      fetchNotifications()
    }
  }, [dispatch, language, user, nabooFF.cms_notification_center])

  const hasNewNotifications = useAppSelector(
    (state) => state.ui.hasNewNotifications
  )

  return {
    hasNotification,
    hasNewNotifications
  }
}
