import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Flex } from '@src/common/components/BaseComponent'
import { useNabooFeatureFlag } from '@src/common/hooks'
import { useToast } from '@src/common/hooks/useToast'
import { uploadVideoPoster } from '@src/common/redux/channel'
import { getSortedVideoPosters } from '@src/common/utils/videoPoster'
import { useVUContext } from '@src/modules/channel/contexts/VUContext'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import CustomAnimatedPoster from './CustomAnimatedPoster'
import CustomStaticPoster from './CustomStaticPoster'
import PosterDragger from './PosterDragger'
type AnimatedPosterPayload = globalLib.AnimatedPosterPayload
type PosterPayload = globalLib.PosterPayload

interface FormPosterProps {
  video?: {
    video_posters?: globalLib.VideoPoster[]
    video_files?: globalLib.VideoFile[]
  }
  // video maybe null, so we have to define the video type
  videoType?: 'live_stream' | 'video'
  setNewPosterPayload(
    posterPayload: PosterPayload | AnimatedPosterPayload
  ): void
  setNewWidePosterPayload(posterPayload: PosterPayload): void
  setNewAnimatedPosterPayload?(posterPayload: AnimatedPosterPayload): void
  setCustomGifAnimatedPosterPayload?(
    posterPayload?: AnimatedPosterPayload | null
  ): void
  customPoster?: boolean
  setChangedPosters(boolean): void
  size?: number
}

const FormPoster: React.FC<FormPosterProps> = ({
  video,
  videoType = 'video',
  setNewPosterPayload,
  setNewWidePosterPayload,
  setNewAnimatedPosterPayload,
  setCustomGifAnimatedPosterPayload,
  setChangedPosters,
  customPoster = false,
  size = 99
}) => {
  const { t } = useTranslation()
  const { errorToast } = useToast()
  const dispatch = useDispatch()
  const nff = useNabooFeatureFlag()
  const [posterUrl, setPosterUrl] = useState(null)
  const [posterUploading, setPosterUploading] = useState(false)
  const [posterLoading, setPosterLoading] = useState(false)
  const [posterProgress, setPosterProgress] = useState(0)
  const [posterFile, setPosterFile] = useState(null)
  const [posterImg, setPosterImg] = useState(null)
  const [widePosterUrl, setWidePosterUrl] = useState(null)
  const [widePosterUploading, setWidePosterUploading] = useState(false)
  const [widePosterLoading, setWidePosterLoading] = useState(false)
  const [widePosterProgress, setWidePosterProgress] = useState(0)
  const [widePosterFile, setWidePosterFile] = useState(null)
  const [widePosterImg, setWidePosterImg] = useState(null)
  const [animatedPoster, setAnimatedPoster] = useState(null)
  const setDefaultPoster = useCallback(() => {
    setPosterUrl(null)
    setNewPosterPayload(null)
  }, [setNewPosterPayload])
  const { state } = useVUContext()

  const enableHorizontalPoster =
    videoType === 'live_stream'
      ? nff.cms_livestream_horizontal
      : nff.cms_video_horizontal

  const isHorizontalVideo =
    enableHorizontalPoster &&
    (video?.video_files?.[0]?.video_file_type === 'horizontal' ||
      (state.videoUrl &&
        state.videoWH &&
        state.videoWH.width > state.videoWH.height))

  const videoPosters = video?.video_posters
  const verticalStaticPoster = useMemo(
    () =>
      getSortedVideoPosters(videoPosters)?.find((poster) => {
        const { video_poster_type, orientation } = poster

        return (
          video_poster_type === 'static' &&
          (orientation !== 'horizontal' || !enableHorizontalPoster)
        )
      }),
    [enableHorizontalPoster, videoPosters]
  )

  const convertAnimatedPosterToPayload = (poster) => ({
    id: poster.id,
    key: poster.key,
    aspect_ratio: poster.aspect_ratio,
    height: poster.height,
    width: poster.width,
    format: poster.format,
    temp_poster_url: poster.url,
    start_time: poster.start_time,
    duration: poster.duration,
    original: poster.original
  })

  const convertPosterToPayload = useCallback(
    (poster) => ({
      id: customPoster ? poster.id : undefined,
      key: poster.key,
      aspect_ratio: poster.aspect_ratio,
      height: poster.height,
      width: poster.width,
      format: poster.format,
      temp_poster_url: poster.url,
      original: poster.original
    }),
    [customPoster]
  )

  const setInitialAnimatedPosters = useCallback(
    (video?: { video_posters?: globalLib.VideoPoster[] }) => {
      const animatedVideoPoster = getSortedVideoPosters(
        video?.video_posters
      )?.find((poster) => {
        const { video_poster_type } = poster

        return video_poster_type === 'animated'
      })

      let customGifAnimatedVideoPoster
      if (animatedVideoPoster?.format !== 'gif') {
        // Get the gif animated video poster whose original is not true.
        customGifAnimatedVideoPoster = video?.video_posters?.find((poster) => {
          const { format, original } = poster

          return format === 'gif' && !original
        })
      }
      if (customPoster) {
        if (animatedVideoPoster) {
          setAnimatedPoster(animatedVideoPoster)
          setNewAnimatedPosterPayload?.(
            convertAnimatedPosterToPayload(animatedVideoPoster)
          )
          if (customGifAnimatedVideoPoster) {
            setCustomGifAnimatedPosterPayload?.(
              convertAnimatedPosterToPayload(customGifAnimatedVideoPoster)
            )
          }
        } else {
          setAnimatedPoster(null)
          setNewAnimatedPosterPayload?.(null)
          setCustomGifAnimatedPosterPayload?.(null)
        }
      }
    },
    [
      customPoster,
      setCustomGifAnimatedPosterPayload,
      setNewAnimatedPosterPayload
    ]
  )

  const setInitialVerticalStaticPosters = useCallback(
    (video?: { video_posters?: globalLib.VideoPoster[] }) => {
      const videoPoster = customPoster
        ? verticalStaticPoster
        : getFirstVideoPosterByOrientation(video, 'vertical') ||
          getFirstVideoPosterByOrientation(video, 'square') ||
          (!enableHorizontalPoster
            ? getFirstVideoPosterByOrientation(video, 'horizontal')
            : undefined)
      if (videoPoster) {
        setPosterUrl(videoPoster.url)
        setNewPosterPayload(convertPosterToPayload(videoPoster))
      } else {
        setDefaultPoster()
      }
    },
    [
      customPoster,
      verticalStaticPoster,
      enableHorizontalPoster,
      setNewPosterPayload,
      convertPosterToPayload,
      setDefaultPoster
    ]
  )

  const setInitialHorizontalStaticPosters = useCallback(
    (video?: { video_posters?: globalLib.VideoPoster[] }) => {
      const wideStaticPoster = getSortedVideoPosters(
        video?.video_posters
      )?.find((poster) => {
        const { orientation, video_poster_type, aspect_ratio } = poster

        return (
          (orientation === 'horizontal' || aspect_ratio === '16:9') &&
          video_poster_type === 'static'
        )
      })

      if (wideStaticPoster) {
        setWidePosterUrl(wideStaticPoster.url)
        setNewWidePosterPayload(convertPosterToPayload(wideStaticPoster))
      }
    },
    [convertPosterToPayload, setNewWidePosterPayload]
  )

  const setInitialPosters = useCallback(
    (video?: { video_posters?: globalLib.VideoPoster[] }) => {
      setChangedPosters(false)
      setInitialVerticalStaticPosters(video)
      setInitialHorizontalStaticPosters(video)
      setInitialAnimatedPosters(video)
    },
    [
      setChangedPosters,
      setInitialVerticalStaticPosters,
      setInitialHorizontalStaticPosters,
      setInitialAnimatedPosters
    ]
  )

  const onDiscardAnimatedPosterChanges = useCallback(() => {
    setInitialAnimatedPosters(video)
  }, [setInitialAnimatedPosters, video])
  const onDiscardVerticalStaticPosterChanges = useCallback(() => {
    setInitialVerticalStaticPosters(video)
  }, [setInitialVerticalStaticPosters, video])
  const onDiscardHorizontalStaticPosterChanges = useCallback(() => {
    setInitialHorizontalStaticPosters(video)
  }, [setInitialHorizontalStaticPosters, video])

  useEffect(() => {
    setInitialPosters(video)
  }, [video, setInitialPosters])

  const getFirstVideoPosterByOrientation = (video, orientation, exceptId?) => {
    const compareWidthAndHeight = (poster) => {
      switch (orientation) {
        case 'horizontal':
          return poster.width > poster.height
        case 'vertical':
          return poster.width < poster.height
        case 'square':
          return poster.width === poster.height
        default:
          return false
      }
    }

    const videoPosters = getSortedVideoPosters(video?.video_posters)?.filter(
      (poster) =>
        (poster.orientation === orientation ||
          (!poster.orientation && compareWidthAndHeight(poster))) && // if orientation is not set, we compare width and height
        poster.id !== exceptId
    )

    return !!videoPosters?.length && videoPosters[0]
  }

  const setPortraitPoster = (file) => {
    const URL = window.URL || window.webkitURL
    const img = new Image()
    img.src = URL.createObjectURL(file)

    setPosterUploading(true)
    setPosterUrl(URL.createObjectURL(file))
    setPosterFile(file)
    setPosterImg(img)
    setPosterProgress(0)
  }

  const validateAndSetVerticalPoster = async (file) => {
    const URL = window.URL || window.webkitURL
    const img = new Image()
    img.src = URL.createObjectURL(file)

    const isValid = await validPoster(img)
    if (!isValid) {
      setPosterUploading(false)
      setPosterUrl(null)

      return false
    }

    setPosterUploading(true)
    setPosterUrl(URL.createObjectURL(file))
    setPosterFile(file)
    setPosterImg(img)
    setPosterProgress(0)

    return true
  }

  const validPoster = async (img) => {
    const loadImage = () =>
      new Promise((resolve, reject) => {
        img.onload = () => resolve(img)
        img.onerror = reject
      })

    setPosterLoading(true)
    await loadImage()
    setPosterLoading(false)

    return img.width <= img.height
  }

  const validateAndSetWidePoster = async (file) => {
    const URL = window.URL || window.webkitURL
    const img = new Image()
    img.src = URL.createObjectURL(file)

    const isValid = await validWidePoster(img)
    if (!isValid) {
      setWidePosterUploading(false)
      setWidePosterUrl(null)

      return false
    }

    setWidePosterUploading(true)
    setWidePosterUrl(URL.createObjectURL(file))
    setWidePosterFile(file)
    setWidePosterImg(img)
    setWidePosterProgress(0)

    return true
  }

  const validWidePoster = async (img) => {
    const loadWideImage = () =>
      new Promise((resolve, reject) => {
        img.onload = () => resolve(img)
        img.onerror = reject
      })

    setWidePosterLoading(true)
    await loadWideImage()
    setWidePosterLoading(false)

    return img.width > img.height
  }

  const removePoster = (isWide) => {
    if (isWide) {
      setNewWidePosterPayload(null)
      setWidePosterUrl(null)
    } else {
      setDefaultPoster()
    }
    setChangedPosters(true)
  }

  const removeFileExtension = (filename: string) => {
    const lastDotIndex = filename.lastIndexOf('.')
    if (lastDotIndex === -1) {
      return filename
    } else {
      return filename.substring(0, lastDotIndex)
    }
  }

  const convertToJpeg = async (file) => {
    return new Promise((resolve, reject) => {
      if (file.type === 'image/jpeg') {
        resolve(file)

        return
      }

      const image = new Image()
      const reader = new FileReader()

      reader.onload = (event) => {
        image.onload = () => {
          const canvas = document.createElement('canvas')
          canvas.width = image.width
          canvas.height = image.height

          const context = canvas.getContext('2d')
          context.drawImage(image, 0, 0)

          canvas.toBlob((blob) => {
            if (blob) {
              const fileName = `${removeFileExtension(file.name)}.jpg`
              resolve(new File([blob], fileName, { type: blob.type }))
            } else {
              reject()
            }
          }, 'image/jpeg')
        }

        image.onerror = () => {
          reject()
        }
        image.src = event.target.result as string
      }

      reader.onerror = () => {
        reject()
      }

      reader.readAsDataURL(file)
    })
  }

  const uploadPosterProps = (isWide = false) => {
    let accept = []
    if (customPoster) {
      /**
       * Currently, we don't support uploading files for animated posters.
       * Therefore, the accept is only for static posters now.
       * When we need to support uploading files for animated posters, the static posters and animated posters should use different accept.
       */
      accept = ['.jpg', '.jpeg', '.png', '.webp', '.gif']
    } else {
      accept = ['.jpg', '.jpeg', '.webp', '.gif']
    }

    return {
      isWide,
      widePosterLoading,
      posterProgress: isWide ? widePosterProgress : posterProgress,
      posterUploading: isWide ? widePosterUploading : posterUploading,
      removePoster: () => removePoster(isWide),
      name: 'file',
      accept: accept.join(','),
      disabled: isWide
        ? widePosterUploading || widePosterLoading
        : posterUploading || posterLoading,
      multiple: false,
      fileList: [],
      size,
      beforeUpload: async (file) => {
        const fileExtension = `.${file.name.split('.').pop().toLowerCase()}`
        if (!accept.includes(fileExtension)) {
          errorToast(
            t('{{file}} file is in the wrong format', {
              file: file.name
            })
          )

          return false
        }

        let resultFile = file
        if (customPoster) {
          try {
            resultFile = await convertToJpeg(file)
          } catch (e) {
            errorToast(t('{{file}} file upload failed', { file: file.name }))

            return false
          }
        }

        if (isWide) {
          const isValid = await validateAndSetWidePoster(resultFile)
          if (!isValid) {
            errorToast(t('This field only accepts horizontal images'))

            return false
          }
        } else {
          // It is not allowed to upload horizontal image on static vertical posters
          if (enableHorizontalPoster) {
            const isValid = await validateAndSetVerticalPoster(resultFile)

            if (!isValid) {
              errorToast(t('This field only accepts vertical images'))

              return false
            }
          } else {
            setPortraitPoster(resultFile)
          }
        }

        return resultFile
      },
      customRequest: ({ file, onProgress, onSuccess }) => {
        dispatch(uploadVideoPoster(file, onProgress, onSuccess))
      },
      onChange: (info) => {
        const { status } = info.file
        if (status === 'done') {
          isWide ? setWidePosterUploading(false) : setPosterUploading(false)
        } else if (status === 'error')
          errorToast(t('{{file}} file upload failed', { file: info.file.name }))
      },
      onSuccess: ({ key }) => {
        setChangedPosters(true)
        if (isWide) {
          setWidePosterUploading(false)
          setNewWidePosterPayload(
            getPosterPayload(buildPosterAttributes(key, true))
          )
        } else {
          setPosterUploading(false)
          setNewPosterPayload(getPosterPayload(buildPosterAttributes(key)))
        }
      },
      onProgress: ({ percent }) => {
        isWide ? setWidePosterProgress(percent) : setPosterProgress(percent)
      }
    }
  }

  const buildPosterAttributes = (key, isWide = false) => ({
    key,
    img: isWide ? widePosterImg : posterImg,
    file: isWide ? widePosterFile : posterFile,
    url: isWide ? widePosterUrl : posterUrl
  })

  const getPosterPayload = ({ key, img, file, url }) => {
    const { width, height, aspect_ratio } = getImgAttribute(img)
    const payload = {
      key: key,
      aspect_ratio: aspect_ratio,
      height: height,
      width: width,
      format: getFileExtension(file?.name || ''),
      temp_poster_url: url
    }

    return payload
  }

  const getImgAttribute = (img) => {
    const ratios = ['9:16', '3:4', '4:5', '1:1', '5:4', '4:3', '16:9']
    const ratios2 = [0.5625, 0.75, 0.8, 1.0, 1.25, 1.3333, 1.7778]
    const r = img?.width / img?.height
    const idx = ratios2.reduce((a, b, index) => {
      return Math.abs(b - r) < Math.abs(ratios2[a] - r) ? index : a
    }, 0)

    return {
      width: img?.width || 1,
      height: img?.height || 1,
      aspect_ratio: ratios[idx] || '1:1'
    }
  }

  const getFileExtension = (filename) => {
    const formats = {
      png: 'png',
      jpg: 'jpg',
      jpeg: 'jpg',
      gif: 'gif',
      heic: 'heic',
      svg: 'svg',
      webp: 'webp'
    }
    const fileExtension = filename.split('.').pop().toLowerCase()

    return formats[fileExtension]
  }

  const horizontalPoster = () => {
    return customPoster ? (
      <CustomStaticPoster
        {...uploadPosterProps(true)}
        imgUrl={widePosterUrl}
        video={video}
        setNewPosterPayload={setNewWidePosterPayload}
        setChangedPosters={setChangedPosters}
        posterProgress={widePosterProgress}
        onDiscardChanges={onDiscardHorizontalStaticPosterChanges}
      />
    ) : (
      <PosterDragger
        {...uploadPosterProps(true)}
        imgUrl={widePosterUrl}
        posterUploading={widePosterUploading}
        posterProgress={widePosterProgress}
      />
    )
  }

  return (
    <Flex alignItems="start" gap="16px">
      {customPoster && (
        <Flex flexDirection="column">
          <Flex justifyContent="flex-start" mb={12}>
            <Typography.Text>{t('Animated')}</Typography.Text>
          </Flex>
          <CustomAnimatedPoster
            video={video}
            enableHorizontalPoster={enableHorizontalPoster}
            isHorizontalVideo={isHorizontalVideo}
            animatedVideoPoster={animatedPoster}
            setChangedPosters={setChangedPosters}
            setNewAnimatedPosterPayload={setNewAnimatedPosterPayload}
            setCustomGifAnimatedPosterPayload={
              setCustomGifAnimatedPosterPayload
            }
            onDiscardChanges={onDiscardAnimatedPosterChanges}
            size={size}
          />
        </Flex>
      )}
      <Flex flexDirection="column">
        {customPoster && (
          <Flex justifyContent="flex-start" mb={12}>
            <Typography.Text>{t('Static')}</Typography.Text>
          </Flex>
        )}
        {customPoster ? (
          <CustomStaticPoster
            {...uploadPosterProps()}
            enableHorizontalPoster={enableHorizontalPoster}
            imgUrl={posterUrl}
            video={video}
            setNewPosterPayload={setNewPosterPayload}
            setChangedPosters={setChangedPosters}
            posterProgress={posterProgress}
            onDiscardChanges={onDiscardVerticalStaticPosterChanges}
          />
        ) : (
          <PosterDragger
            {...uploadPosterProps()}
            imgUrl={posterUrl}
            posterUploading={posterUploading}
            posterProgress={posterProgress}
          />
        )}
      </Flex>
      {enableHorizontalPoster ? horizontalPoster() : null}
    </Flex>
  )
}

export default FormPoster
