import { createAction, createSlice } from '@reduxjs/toolkit'
import { removeUndefined } from '@src/common/utils/sanitize'
import { Dispatch } from 'redux'

import api from '../../../common/utils/api'

type IProps = {
  urlResources: { [key: string]: AvaUrlResource[] }
}

const initialState: IProps = {
  urlResources: {}
}

const slice = createSlice({
  name: 'avaUrlResource',
  initialState: initialState,
  reducers: {
    createAvaUrlResourceSuccess(state, action) {
      const { urlResources, domainAssistantId } = action.payload
      state.urlResources[domainAssistantId] = urlResources.concat(
        state.urlResources[domainAssistantId]
      )
    },
    fetchAvaUrlResourceSuccess(state, action) {
      const { urlResource, domainAssistantId } = action.payload
      const resources = state.urlResources[domainAssistantId]
      if (resources) {
        const index = resources.findIndex((item) => item.id === urlResource.id)
        if (index !== -1) {
          state.urlResources[domainAssistantId][index] = urlResource
        }
      }
    },
    updateAvaUrlResourceSuccess(state, action) {
      const { urlResource, domainAssistantId } = action.payload
      const resources = state.urlResources[domainAssistantId]
      if (resources) {
        const index = resources.findIndex((item) => item.id === urlResource.id)
        if (index !== -1) {
          state.urlResources[domainAssistantId][index] = urlResource
        }
      }
    },
    deleteAvaUrlResourceSuccess(state, action) {
      const { urlResourceId, domainAssistantId } = action.payload
      const resources = state.urlResources[domainAssistantId]
      if (resources) {
        const index = resources.findIndex((item) => item.id === urlResourceId)
        if (index !== -1) {
          state.urlResources[domainAssistantId] = state.urlResources[
            domainAssistantId
          ].slice(index, index + 1)
        }
      }
    }
  }
})

export default slice.reducer

export const {
  createAvaUrlResourceSuccess,
  deleteAvaUrlResourceSuccess,
  fetchAvaUrlResourceSuccess,
  updateAvaUrlResourceSuccess
} = slice.actions

const createAvaUrlResourceRequest = createAction(
  'avaUrlResource/createAvaUrlResourceRequest'
)
const createAvaUrlResourceFailure = createAction(
  'avaUrlResource/createAvaUrlResourceFailure'
)

type AvaUrlResourceRequest = {
  description?: string
  title?: string
  text?: string
  url?: string
}

export function createAvaUrlResource(
  businessId: string,
  domainAssistantId: string,
  data: AvaUrlResourceRequest
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createAvaUrlResourceRequest())
      const response = await api.post(
        `/api/bus/${businessId}/domain_assistants/${domainAssistantId}/url_resources`,
        {
          ...sanitizeTextResourceParams(data)
        }
      )
      dispatch(
        createAvaUrlResourceSuccess({
          urlResources: response.data,
          domainAssistantId
        })
      )

      return response
    } catch (error) {
      dispatch(createAvaUrlResourceFailure())

      return error
    }
  }
}

const fetchAvaUrlResourceRequest = createAction(
  'avaUrlResource/fetchAvaUrlResourceRequest'
)
const fetchAvaUrlResourceFailure = createAction(
  'avaUrlResource/fetchAvaUrlResourceFailure'
)

export function fetchAvaUrlResource(
  businessId: string,
  domainAssistantId: string,
  urlResourceId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchAvaUrlResourceRequest())
      const response = await api.get(
        `/api/bus/${businessId}/domain_assistants/${domainAssistantId}/url_resources/${urlResourceId}`
      )
      dispatch(
        fetchAvaUrlResourceSuccess({
          textResource: response.data,
          domainAssistantId
        })
      )

      return response
    } catch (error) {
      dispatch(fetchAvaUrlResourceFailure())

      return error
    }
  }
}

const deleteAvaUrlResourceRequest = createAction(
  'avaUrlResource/deleteAvaUrlResourceRequest'
)
const deleteAvaUrlResourceFailure = createAction(
  'avaUrlResource/deleteAvaUrlResourceFailure'
)

export function deleteAvaUrlResource(
  businessId: string,
  domainAssistantId: string,
  urlResourceId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteAvaUrlResourceRequest())
      const response = await api.delete(
        `/api/bus/${businessId}/domain_assistants/${domainAssistantId}/url_resources/${urlResourceId}`
      )
      dispatch(
        deleteAvaUrlResourceSuccess({
          urlResourceId,
          domainAssistantId
        })
      )

      return response
    } catch (error) {
      dispatch(deleteAvaUrlResourceFailure())

      return error
    }
  }
}

const updateAvaUrlResourceRequest = createAction(
  'avaUrlResource/updateAvaUrlResourceRequest'
)
const updateAvaUrlResourceFailure = createAction(
  'avaUrlResource/updateAvaUrlResourceFailure'
)

export function updateAvaUrlResource(
  businessId: string,
  domainAssistantId: string,
  urlResourceId: string,
  data: AvaUrlResourceRequest
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateAvaUrlResourceRequest())
      const response = await api.put(
        `/api/bus/${businessId}/domain_assistants/${domainAssistantId}/url_resources/${urlResourceId}`,
        {
          ...sanitizeTextResourceParams(data)
        }
      )
      dispatch(
        updateAvaUrlResourceSuccess({
          urlResource: response.data,
          domainAssistantId
        })
      )

      return response
    } catch (error) {
      dispatch(updateAvaUrlResourceFailure())

      return error
    }
  }
}

const sanitizeTextResourceParams = (data: AvaUrlResourceRequest) => {
  const sanitized = {
    url: data.url,
    description: data.description,
    title: data.title,
    text: data.text
  }

  return removeUndefined(sanitized)
}

export async function crawlWebpage(
  businessId: string,
  assistantId: string,
  webpage: string
): Promise<CrawlWebpageResult> {
  const url = `bus/${businessId}/domain_assistants/${assistantId}/url_resources/crawl`

  const response = await api.post(url, {
    url: webpage
  })

  return response.data
}
