import { useCallback } from 'react'

import { useAppDispatch, useAppSelector } from '@src/app/hooks'
import { useUnwrapAsyncThunk } from '@src/common/hooks/useUnwrapAsyncThunk'
import { removeNullAndUndefined } from '@src/common/utils/sanitize'

import { setEmbedConfigStatusToDisable } from '../../redux/YourSite'
import {
  embedConfigs_create,
  embedConfigs_delete,
  embedConfigs_list,
  embedConfigs_update
} from '../redux/embedConfigs'

type Props = {
  channelId: string
  businessId: string
}

type useEmbedConfigsApiReturn = {
  createConfig: (payload: {
    layout: WidgetLayouts
    widgetProperties: IAnyWidgetParams
  }) => Promise<YourSite.EmbedConfig>
  listConfigs: () => void
  deleteConfig: (embedConfigId: string) => void
  updateConfig: (
    embedConfigId: string,
    payload: {
      layout: WidgetLayouts
      widgetProperties: IAnyWidgetParams
    }
  ) => Promise<YourSite.EmbedConfig>
}

export const useEmbedConfigsApi = ({
  channelId,
  businessId
}: Props): useEmbedConfigsApiReturn => {
  const dispatch = useAppDispatch()
  const unwrapAsyncThunk = useUnwrapAsyncThunk()
  const businessStoreId = useAppSelector(
    (state) => state.yourSite.selectedStoreIdByBusinessId[businessId]
  )

  const baseWidgetProperties = useCallback(
    (widgetType: WidgetLayouts): IAnyWidgetParams & { style?: string } => {
      switch (widgetType) {
        case 'carousel_grid':
          return {
            mode: 'grid'
          }
        case 'dynamic_carousel':
          return {
            thumbnail_style: 'dynamic'
          }
        case 'horizontal_carousel':
          return {
            thumbnail_orientation: 'horizontal'
          }
        case 'player':
          return {
            max_videos: 1
          }
        case 'floating_player':
          return {
            mode: 'pinned'
          }
        case 'player_deck':
          return {
            player_deck_layout: true
          }
        case 'horizontal_player':
          return {
            height: '100vh'
          }
        default:
          return {}
      }
    },
    []
  )

  const createConfig = async (payload: {
    layout: WidgetLayouts
    widgetProperties: IAnyWidgetParams
  }) => {
    return unwrapAsyncThunk(
      embedConfigs_create({
        channelId,
        businessId,
        business_store_id: businessStoreId,
        layout: payload.layout === 'player' ? 'storyblock' : payload.layout,
        status: 'enable',
        widget_properties: removeNullAndUndefined({
          ...baseWidgetProperties(payload.layout),
          ...payload.widgetProperties
        })
      })
    )
  }

  const listConfigs = useCallback(() => {
    dispatch(
      embedConfigs_list({
        businessId,
        channelId
      })
    )
  }, [dispatch, businessId, channelId])

  const deleteConfig = async (embedConfigId: string) => {
    dispatch(
      embedConfigs_delete({
        channelId,
        businessId,
        embedConfigId
      })
    )
    dispatch(setEmbedConfigStatusToDisable({ id: embedConfigId }))
  }

  const updateConfig = async (
    embedConfigId: string,
    payload: {
      layout: WidgetLayouts
      widgetProperties: IAnyWidgetParams
    }
  ) => {
    return unwrapAsyncThunk(
      embedConfigs_update({
        channelId,
        businessId,
        embedConfigId,
        status: 'enable',
        widget_properties: removeNullAndUndefined({
          ...baseWidgetProperties(payload.layout),
          ...payload.widgetProperties
        })
      })
    )
  }

  return {
    createConfig,
    listConfigs,
    deleteConfig,
    updateConfig
  }
}
