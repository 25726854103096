import { createSlice, Slice, SliceCaseReducers } from '@reduxjs/toolkit'
import { embedConfigsBuilder } from '@src/modules/channel/YourSiteV2/redux/embedConfigs'

import { autoEmbedBuilder } from './autoEmbed'
import { SLICE_NAME } from './constants'
import { initialState, SliceState } from './initState'
import { pdpGalleryBuilder } from './pdpGallery'
import { shopifyBuilder } from './shopify'

const slice: Slice<
  SliceState,
  SliceCaseReducers<SliceState>,
  typeof SLICE_NAME
> = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetPlaylistVideos: (state) => {
      state.playlistVideos = []
    },
    toggleHelpBanner: (state) => {
      state.isEnableBannerVisible = false
      state.isHelpBannerVisible = true
    },
    setAutoEmbedSelectedPlaylistId: (state, action) => {
      state.selectedPlaylistId = action.payload
    },
    setIntegrationsActiveTab: (state, action) => {
      state.integrationsActiveTab = action.payload
    },
    setSelectedStoreId: (state, action) => {
      state.selectedStoreIdByBusinessId[action.payload.businessId] =
        action.payload.storeId
    },
    setEmbedConfigStatusToDisable: (state, action) => {
      const { id } = action.payload
      state.embedConfigs.data = state.embedConfigs.data.map((config) =>
        config.id === id ? { ...config, status: 'disable' } : config
      )
    }
  },
  extraReducers: (builder) => {
    autoEmbedBuilder(builder)
    pdpGalleryBuilder(builder)
    shopifyBuilder(builder)
    embedConfigsBuilder(builder)
  }
})

export const {
  resetPlaylistVideos,
  toggleHelpBanner,
  setAutoEmbedSelectedPlaylistId,
  setIntegrationsActiveTab,
  setSelectedStoreId,
  setEmbedConfigStatusToDisable
} = slice.actions

export default slice.reducer
