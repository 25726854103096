import { useState, useEffect } from 'react'

import { useAppSelector } from '@src/app/hooks'
import { sessionStorageProvider } from '@src/common/utils/storageProvider'

import { STORE_PROVIDERS } from '../../constants'

type useOAuthAppHook = {
  oauthApp: string
  oauthDefaultBusinessId: string | null
  oauthDefaultBusinessStoreId: string | null
  isOAuthAppEmbed: boolean
  isShopifyEmbed: boolean
  isDudaEmbed: boolean
}

const useOAuthApp = (): useOAuthAppHook => {
  const oauthApp = useAppSelector((state) => state.user.oauthApp)

  const [oauthDefaultBusinessId, setOAuthDefaultBusinessId] = useState(null)
  const [
    oauthDefaultBusinessStoreId,
    setOAuthDefaultBusinessStoreId
  ] = useState(null)

  const isOAuthAppEmbed = oauthApp && oauthApp !== '' ? true : false

  const isShopifyEmbed =
    oauthApp === STORE_PROVIDERS.SHOPIFY ||
    oauthApp === STORE_PROVIDERS.TEMP_SHOPIFY

  const isDudaEmbed = oauthApp === 'duda'

  useEffect(() => {
    setOAuthDefaultBusinessId(sessionStorageProvider.getItem('businessId'))
    setOAuthDefaultBusinessStoreId(
      sessionStorageProvider.getItem('businessStoreId')
    )
  }, [])

  return {
    oauthApp,
    oauthDefaultBusinessId,
    oauthDefaultBusinessStoreId,
    isOAuthAppEmbed,
    isShopifyEmbed,
    isDudaEmbed
  }
}

export default useOAuthApp
