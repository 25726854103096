import React from 'react'

import { FullScreenLayout } from '@src/common/components/BaseComponent'
import { Outlet } from 'react-router-dom'

export const YourSiteLayout: React.FC = () => {
  return (
    <FullScreenLayout overflow="auto" height="100%">
      <Outlet />
    </FullScreenLayout>
  )
}
