import api, { validateResponseSuccess } from './api'

export type S3Result = {
  key?: string
  url?: string
}

/**
 * Asynchronously retrieves an S3 signature for uploading a file to a specific path in the knowledge base.
 *
 * This function generates a signature required for uploading a file to an S3 bucket. The file will be uploaded
 * to a path that is specific to a business and a channel within that business. The path is structured as:
 * `/bus/{businessId}/channel/{channelId}/text_resources/upload_signatures`.
 *
 * @param {File} file - The file object that needs to be uploaded to S3.
 * @param {string} businessId - The unique identifier for the business.
 * @param {string} channelId - The unique identifier for the channel within the business.
 * @returns {Promise<S3Result>} - A promise that resolves to an S3Result object containing the signature and other relevant information.
 *
 */
export const getKnowledgeBaseS3Signature = async (
  file: File,
  businessId: string,
  channelId: string
): Promise<S3Result> => {
  return await getS3Signature(
    file,
    `/bus/${businessId}/channels/${channelId}/text_resources/upload_signature`
  )
}

/**
 * Gets the signature needed to upload to an S3 bucket.
 * @param {File} file File to upload
 * @return {object} S3 Signature object
 */
export const getS3Signature = async (file, path = '/upload_signatures') => {
  // Fetch signature from Naboo to know which bucket to upload to
  const res = await api.post(path, {
    filename: file.name,
    mime_type: file.type
  })
  if (!validateResponseSuccess(res)) throw Error('Error uploading file')

  return res.data
}

/**
 * Uploads a file to S3.
 * @param {Blob | File} file File to upload
 * @param {func} onProgress Function to execute while upload in progress
 * @param {func} onSuccess Function to execute after upload succeeds
 * @param {func} onError Function to execute on error
 */
export const uploadToS3 = async (
  file,
  signature,
  onProgress,
  onSuccess,
  onError
) => {
  try {
    const uploadError = Error('Error uploading file')

    const xhr = new global.XMLHttpRequest()
    xhr.open('PUT', signature.put_url)
    xhr.setRequestHeader('Content-Type', file.type)
    xhr.upload.onprogress = (event) => {
      const progress = Math.round((event.loaded * 100) / event.total)
      if (onProgress) onProgress(progress)
    }
    xhr.onload = async () => {
      if (xhr.status === 200) {
        if (onSuccess) {
          await onSuccess({
            key: signature.key,
            url: `${signature.action}/${signature.key}`,
            etag: xhr.getResponseHeader('ETag')
          })
        }
      } else {
        throw uploadError
      }
    }
    xhr.onerror = () => {
      throw uploadError
    }

    xhr.send(file)
  } catch (err) {
    if (onError) onError(err)
  }
}

export const uploadFileToS3 = async (
  file,
  signature,
  onProgress = null
): Promise<S3Result> => {
  return new Promise((resolve, reject) => {
    uploadToS3(
      file,
      signature,
      onProgress,
      () => {
        resolve({
          key: signature.key,
          url: `${signature.action}/${signature.key}`
        })
      },
      (err) => {
        reject(err)
      }
    )
  })
}

export const startMultiPartUpload = async (file, parts) => {
  // Fetch signature from Naboo to know which bucket to upload to
  const res = await api.post('/upload_multipart/signatures', {
    filename: file.name,
    mime_type: file.type,
    parts_count: parts
  })
  if (!validateResponseSuccess(res)) throw Error('Error uploading file')

  return res.data
}

export const completeMultiPartUpload = async (key, upload_id, parts) => {
  const res = await api.post('/upload_multipart/complete', {
    key,
    upload_id,
    parts
  })
  if (!validateResponseSuccess(res)) throw Error('Error uploading file')

  return res.data
}
