import React from 'react'

import { useAppSelector } from '@src/app/hooks'
import { MenuItem } from '@src/common/components/BaseComponent'

type IProps = {
  id: string
  onSelect: () => void
}
export default function LocalBusinessMenuItem(props: IProps): JSX.Element {
  const { id, onSelect } = props

  const business = useAppSelector((state) => state.business.businesses[id])
  const favoriteBusinessList = useAppSelector(
    (state) =>
      Object.values(state.business.businesses).filter(
        (business) => business.favorite
      ) || []
  )

  const businessName =
    business?.name || favoriteBusinessList.find((item) => item.id === id)?.name

  return (
    <MenuItem selected onSelect={onSelect}>
      <span>{businessName}</span>
    </MenuItem>
  )
}
