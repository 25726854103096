import React, { SetStateAction, useEffect, useRef, useState } from 'react'

import { CloseOutlined } from '@ant-design/icons'
import { css } from '@emotion/core'
import { useAppSelector } from '@src/app/hooks'
import { Box, Flex } from '@src/common/components/BaseComponent'
import { useOnClickOutside } from '@src/common/hooks/useOnClickOutside'
import usePixelAmpTracking from '@src/common/hooks/usePixelAmpTracking'
import {
  fetchMeteredUsage,
  fetchSubscriptionUsage
} from '@src/common/redux/subscriptions'
import { TRACKING_EVENTS } from '@src/common/utils/tracking'
import theme from '@src/styles/theme'
import { Spin, Typography } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

type Props = {
  businessId: string
  setShowUsage: React.Dispatch<SetStateAction<number>>
  top: number
}

export const AccountUsage = ({
  businessId,
  setShowUsage,
  top
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { pixelAmpTracking } = usePixelAmpTracking()
  const usageDiv = useRef(null)
  const streamMinutes = useRef({
    replay: 0,
    total: 0,
    live: 0
  })
  const [overUsage, setOverUsage] = useState({
    channels: false,
    teamMembers: false,
    playlists: false,
    videoViews: false,
    livestreamMinutes: false
  })

  useEffect(() => {
    pixelAmpTracking(TRACKING_EVENTS.BUSINESS_OPEN_OVERVIEW_MODAL)
  }, [pixelAmpTracking])

  useOnClickOutside(usageDiv, () => {
    setShowUsage(-1)
  })

  const subscription = useAppSelector(
    (state) => state.subscriptions.subscriptionsByBusinessId[businessId]
  )

  const subscriptionUsage = useAppSelector(
    (state) => state.subscriptions.subscriptionUsageByBusinessId[businessId]
  )

  const businessUsage = useAppSelector(
    (state) => state.subscriptions.meteredUsageByBusinessId[businessId]
  )

  const businessData = useAppSelector(
    (state) => state.business.businesses[businessId]
  )

  const billingRange = subscriptionUsage?.meta?.billing_dates

  useEffect(() => {
    if (businessData.subscription) {
      dispatch(fetchSubscriptionUsage(businessId))
    }
    dispatch(fetchMeteredUsage(businessId))
  }, [dispatch, businessId, businessData])

  useEffect(() => {
    if (businessUsage && subscriptionUsage) {
      const roundMin = (min) => Math.round(min * 10) / 10

      setOverUsage({
        playlists:
          businessUsage.current.playlists >= businessUsage.maximum.playlists,
        teamMembers:
          businessUsage.current.team_members >=
          businessUsage.maximum.team_members,
        videoViews:
          subscriptionUsage.current.video_views >=
          subscriptionUsage.maximum.video_views,
        channels:
          businessUsage.current.channels >= businessUsage.maximum.channels,
        livestreamMinutes:
          subscriptionUsage.current.live_stream_minutes >=
          subscriptionUsage.maximum.live_stream_minutes
      })
      const liveMinutes = Number(
        subscriptionUsage.current.live_stream_minutes || 0
      )
      const replayMinutes = Number(
        subscriptionUsage.current.live_stream_replay_minutes || 0
      )
      streamMinutes.current.live = roundMin(liveMinutes)
      streamMinutes.current.replay = roundMin(replayMinutes)
      streamMinutes.current.total =
        streamMinutes.current.live + streamMinutes.current.replay
    }
  }, [businessUsage, subscriptionUsage])

  return (
    <Flex
      minWidth="300"
      width="350"
      minHeight="300"
      bg="#FFF"
      style={{
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
      }}
      borderRadius="4"
      flexDirection="column"
      position="fixed"
      top={top - 30}
      left="64"
      zIndex="10000"
      p="xlarge"
      ref={usageDiv}
    >
      <Flex justifyContent="space-between">
        <Typography.Title level={5}>
          {t('Your business overview')}
        </Typography.Title>
        <Box onClick={() => setShowUsage(-1)} cursor="pointer">
          <CloseOutlined
            css={css`
              font-size: 1rem;
            `}
          />
        </Box>
      </Flex>
      {billingRange && (
        <Typography.Paragraph style={{ fontSize: '0.8rem' }}>
          {t('From {{start}} to {{end}}.', {
            start: moment(billingRange.start_date).format('MMMM Do'),
            end: moment(billingRange.end_date).format('MMMM Do')
          })}
        </Typography.Paragraph>
      )}
      <Box display="grid" gridTemplateColumns="1fr 1fr">
        <Box py="medium" textAlign="left">
          {t('Account type')}
        </Box>
        <Box py="medium" textAlign="right" textTransform="capitalize">
          <Typography.Text strong>
            {subscription && subscription?.plan_type}
          </Typography.Text>
        </Box>
        {businessUsage && subscriptionUsage ? (
          <>
            <Box py="medium" textAlign="left">
              {t('Team members')}
            </Box>
            <Box
              py="medium"
              textAlign="right"
              color={overUsage.teamMembers ? theme.primary : theme.text}
            >
              <span>{businessUsage.current.team_members}</span>
            </Box>
            <Box py="medium" textAlign="left">
              {t('Video Views')}
            </Box>
            <Box
              py="medium"
              textAlign="right"
              color={overUsage.videoViews ? theme.primary : theme.text}
            >
              <span>
                {subscriptionUsage.current.video_views.toLocaleString()}/
                {subscriptionUsage.maximum.video_views.toLocaleString()}
              </span>
            </Box>
          </>
        ) : (
          <Flex
            flexDirection="row"
            width={1}
            my="large"
            justifyContent="center"
            gridColumn="1 / span 2"
          >
            <Spin size="large" />
          </Flex>
        )}
      </Box>
    </Flex>
  )
}
