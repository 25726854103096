import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

import { YourSiteLayout } from '../layout'

const HomePage = loadable(() => import('../pages/HomePage'), {
  resolveComponent: (component) => component.HomePage
})

const ProductGallery = loadable(() => import('../pages/ProductGallery'), {
  resolveComponent: (component) => component.ProductGallery
})

const SelectWidget = loadable(() => import('../pages/SelectWidget'), {
  resolveComponent: (component) => component.SelectWidget
})

const CreateViewWidget = loadable(() => import('../pages/CreateViewWidget'), {
  resolveComponent: (component) => component.CreateViewWidget
})

export const YourSiteV2Routes = (
  <>
    <Route
      path="/business/:businessId/channel/:channelId/integrations/embed"
      element={<YourSiteLayout />}
    >
      <Route path="home-page" element={<HomePage />} />
      <Route path="product-gallery" element={<ProductGallery />} />
      <Route path="select-widget" element={<SelectWidget />} />
      <Route
        path="create-widget/:widgetType"
        element={<CreateViewWidget mode="create" />}
      />
      <Route
        path="view-widget/:widgetType/widget/:embedConfigId"
        element={<CreateViewWidget mode="view" />}
      />
    </Route>
  </>
)
