import React, { ReactNode } from 'react'

import { Box, Flex, Styles } from '@src/common/components/BaseComponent'
import theme from '@src/styles/theme'

interface Props extends Styles {
  icon?: Element | ReactNode
  caption: Element | ReactNode
  highlighted: boolean
  handleClick: () => void
  total?: number
  menu?: Element | ReactNode
  leftIcon?: Element | ReactNode
}

export const ListItem: React.FC<Props> = ({
  icon = null,
  caption,
  total = -1,
  highlighted,
  handleClick,
  menu,
  leftIcon = null,
  ...props
}): JSX.Element => {
  return (
    <Box
      data-cy="list-item"
      width="100%"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignContent="center"
      color={highlighted ? theme.primary : theme.text}
      mt="10"
      minHeight="36"
      border={`solid 1px ${highlighted ? theme.primary : theme.gray13}`}
      bg="FFF"
      cursor="pointer"
      borderRadius="4"
      px="xsmall"
      {...props}
    >
      <Box display="grid" gridTemplateColumns="auto 1fr auto auto" width="100%">
        <Box pr="xxsmall">{icon}</Box>
        <Box
          display="grid"
          alignItems="center"
          onClick={handleClick}
          width="100%"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          px="xxsmall"
        >
          {caption}
        </Box>
        {total >= 0 ? (
          <Flex alignItems="center" onClick={handleClick}>
            <span>{total}</span>
          </Flex>
        ) : null}
        {menu}
        {leftIcon}
      </Box>
    </Box>
  )
}
