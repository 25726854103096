import { useCallback, useMemo } from 'react'

/**
 * @fileoverview useActivationStatus hook
 *
 * This hook is used to get and set the activation status of the user.
 * This activation status should only be used for PLG customers
 *
 * Shape of the activation status object in localStorage will be based on
 * globalLib.ACTIVATION_STEPS
 * Values will be based on globalLib.ACTIVATION_STEP_STATUS
 *
 * initState: globalLib.ActivationStatus
 * getActivationStatus: () => globalLib.ActivationStatus
 * initializeActivationStatus: () => void
 * setUploadVideoComplete: () => void
 * setCreatePlaylistComplete: () => void
 * setEmbedPlaylistComplete: () => void
 * isActivationStatusComplete: boolean
 */
import { useAppDispatch } from '@src/app/hooks'
import { setActivationStatus } from '@src/common/redux/activationStatus'
import { localStorageProvider } from '@src/common/utils/storageProvider'
import { KEY_ACTIVATION_STATUS } from '@src/constants'

type useActivationStatusHook = {
  initState: globalLib.ActivationStatus
  getActivationStatus: () => globalLib.ActivationStatus
  initializeActivationStatus: () => void
  setUploadVideoComplete: () => void
  setCreatePlaylistComplete: () => void
  setEmbedPlaylistComplete: () => void
  isActivationStatusComplete: boolean
  setMatchVideoComplete: () => void
  setOpenInstructionsComplete: () => void
  setUploadVideoNotComplete: () => void
  setModalOpen: () => void
  setModalClose: () => void
  isModalOpen: boolean
}

export default function useActivationStatus(): useActivationStatusHook {
  const dispatch = useAppDispatch()

  /* The `initState` constant is an object that represents the initial activation
  status for each activation step. It is used to set the default values for the
  activation status object in the local storage. */
  const initState: globalLib.ActivationStatus = useMemo(
    () => ({
      uploadVideo: 'active',
      createPlaylist: 'inactive',
      embedPlaylist: 'inactive',
      matchVideoToProduct: 'inactive',
      openInstructions: 'inactive',
      modalOpen: false
    }),
    []
  )

  /**
   * The function initializes the activation status by setting it in the local
   * storage.
   */
  const initializeActivationStatus = () => {
    if (!localStorageProvider.getItem(KEY_ACTIVATION_STATUS))
      localStorageProvider.setItem(
        KEY_ACTIVATION_STATUS,
        JSON.stringify({ ...initState })
      )
  }

  /**
   * The function `getActivationStatus` retrieves the activation status from local
   * storage and returns it, or returns the initial state if the status is not
   * found.
   * @returns The function `getActivationStatus` returns the value of the `status`
   * variable if it is not null or undefined. If the `status` variable is null or
   * undefined, it returns the value of the `initState` variable.
   */
  const getActivationStatus = useCallback(() => {
    const status = JSON.parse(
      localStorageProvider.getItem(KEY_ACTIVATION_STATUS)
    ) as globalLib.ActivationStatus

    return status ?? { ...initState, modalOpen: false }
  }, [initState])

  const setUploadVideoComplete = useCallback(() => {
    if (localStorageProvider.getItem(KEY_ACTIVATION_STATUS)) {
      localStorageProvider.setItem(
        KEY_ACTIVATION_STATUS,
        JSON.stringify({
          ...getActivationStatus(),
          uploadVideo: 'complete',
          createPlaylist: 'active',
          embedPlaylist: 'inactive',
          matchVideoToProduct: 'active',
          openInstructions: 'inactive'
        })
      )
      dispatch(
        setActivationStatus({
          ...getActivationStatus(),
          uploadVideo: 'complete',
          createPlaylist: 'active',
          embedPlaylist: 'inactive',
          matchVideoToProduct: 'active',
          openInstructions: 'inactive'
        })
      )
    }
  }, [dispatch, getActivationStatus])

  const setUploadVideoNotComplete = useCallback(() => {
    if (localStorageProvider.getItem(KEY_ACTIVATION_STATUS)) {
      localStorageProvider.setItem(
        KEY_ACTIVATION_STATUS,
        JSON.stringify({
          ...getActivationStatus(),
          uploadVideo: 'active',
          createPlaylist: 'inactive',
          embedPlaylist: 'inactive',
          matchVideoToProduct: 'inactive',
          openInstructions: 'inactive'
        })
      )
      dispatch(
        setActivationStatus({
          ...getActivationStatus(),
          uploadVideo: 'active',
          createPlaylist: 'inactive',
          embedPlaylist: 'inactive',
          matchVideoToProduct: 'inactive',
          openInstructions: 'inactive'
        })
      )
    }
  }, [dispatch, getActivationStatus])

  /* The `isActivationStatusComplete` function is a callback function that checks if the activation status is complete. */
  const isActivationStatusComplete = useMemo(() => {
    const status = getActivationStatus()

    return Object.values(status).every((value) => value === 'complete')
  }, [getActivationStatus])

  const setCreatePlaylistComplete = useCallback(() => {
    if (localStorageProvider.getItem(KEY_ACTIVATION_STATUS)) {
      localStorageProvider.setItem(
        KEY_ACTIVATION_STATUS,
        JSON.stringify({
          ...getActivationStatus(),
          uploadVideo: 'complete',
          createPlaylist: 'complete',
          embedPlaylist: 'active',
          matchVideoToProduct: 'active',
          openInstructions: 'inactive'
        })
      )

      dispatch(
        setActivationStatus({
          ...getActivationStatus(),
          uploadVideo: 'complete',
          createPlaylist: 'complete',
          embedPlaylist: 'active',
          matchVideoToProduct: 'active',
          openInstructions: 'inactive'
        })
      )
    }
  }, [dispatch, getActivationStatus])

  const setEmbedPlaylistComplete = useCallback(() => {
    if (localStorageProvider.getItem(KEY_ACTIVATION_STATUS)) {
      localStorageProvider.setItem(
        KEY_ACTIVATION_STATUS,
        JSON.stringify({
          ...getActivationStatus(),
          uploadVideo: 'complete',
          createPlaylist: 'complete',
          embedPlaylist: 'complete',
          matchVideoToProduct: 'active',
          openInstructions: 'inactive'
        })
      )

      dispatch(
        setActivationStatus({
          ...getActivationStatus(),
          uploadVideo: 'complete',
          createPlaylist: 'complete',
          embedPlaylist: 'complete',
          matchVideoToProduct: 'active',
          openInstructions: 'inactive'
        })
      )
    }
  }, [dispatch, getActivationStatus])

  const setMatchVideoComplete = useCallback(() => {
    if (localStorageProvider.getItem(KEY_ACTIVATION_STATUS)) {
      localStorageProvider.setItem(
        KEY_ACTIVATION_STATUS,
        JSON.stringify({
          ...getActivationStatus(),
          uploadVideo: 'complete',
          createPlaylist: 'active',
          embedPlaylist: 'inactive',
          matchVideoToProduct: 'complete',
          openInstructions: 'active'
        })
      )
      dispatch(
        setActivationStatus({
          ...getActivationStatus(),
          uploadVideo: 'complete',
          createPlaylist: 'active',
          embedPlaylist: 'inactive',
          matchVideoToProduct: 'complete',
          openInstructions: 'active'
        })
      )
    }
  }, [dispatch, getActivationStatus])

  const setOpenInstructionsComplete = useCallback(() => {
    if (localStorageProvider.getItem(KEY_ACTIVATION_STATUS)) {
      localStorageProvider.setItem(
        KEY_ACTIVATION_STATUS,
        JSON.stringify({
          ...getActivationStatus(),
          uploadVideo: 'complete',
          matchVideoToProduct: 'complete',
          openInstructions: 'complete',
          createPlaylist: 'active',
          embedPlaylist: 'inactive',
          modalOpen: true
        })
      )
      dispatch(
        setActivationStatus({
          ...getActivationStatus(),
          uploadVideo: 'complete',
          matchVideoToProduct: 'complete',
          openInstructions: 'complete',
          createPlaylist: 'active',
          embedPlaylist: 'inactive',
          modalOpen: true
        })
      )
    }
  }, [dispatch, getActivationStatus])

  const setModalOpen = useCallback(() => {
    if (localStorageProvider.getItem(KEY_ACTIVATION_STATUS)) {
      localStorageProvider.setItem(
        KEY_ACTIVATION_STATUS,
        JSON.stringify({ ...getActivationStatus(), modalOpen: true })
      )
    }
  }, [getActivationStatus])

  const setModalClose = useCallback(() => {
    if (localStorageProvider.getItem(KEY_ACTIVATION_STATUS)) {
      localStorageProvider.setItem(
        KEY_ACTIVATION_STATUS,
        JSON.stringify({ ...getActivationStatus(), modalOpen: false })
      )
    }
  }, [getActivationStatus])

  const isModalOpen = useMemo(() => {
    const status = getActivationStatus()

    return status.modalOpen
  }, [getActivationStatus])

  return {
    initState,
    getActivationStatus,
    initializeActivationStatus,
    setUploadVideoComplete,
    setCreatePlaylistComplete,
    setEmbedPlaylistComplete,
    isActivationStatusComplete,
    setMatchVideoComplete,
    setOpenInstructionsComplete,
    setUploadVideoNotComplete,
    setModalOpen,
    setModalClose,
    isModalOpen
  }
}
