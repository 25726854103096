import { useEffect } from 'react'

import debounce from 'lodash/debounce'

function getWindowSize() {
  return {
    innerWidth: window.innerWidth,
    innerHeight: window.innerHeight,
    outerWidth: window.outerWidth,
    outerHeight: window.outerHeight
  }
}

export default function useWindowSize(effect, debounceTime = 200): void {
  useEffect(() => {
    function onResize() {
      effect(getWindowSize())
    }

    let resizeCallback = onResize
    if (debounceTime) {
      resizeCallback = debounce(onResize, debounceTime)
    }

    window.addEventListener('resize', resizeCallback)

    return () => {
      window.removeEventListener('resize', resizeCallback)
    }
  }, [effect, debounceTime])
}
