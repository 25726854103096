import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const Channel = loadable(() => import('@src/modules/channel/pages'))

export const ChannelRoutes = (
  <>
    <Route
      path="/business/:businessId/channel/:channelId"
      element={<Channel />}
    />
    <Route
      path="/business/:businessId/channel/:channelId/insights/:subtab"
      element={<Channel />}
    />
    <Route
      path="/business/:businessId/channel/:channelId/:tab"
      element={<Channel />}
    />
  </>
)
