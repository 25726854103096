import React from 'react'

import { css } from '@emotion/core'
import { Box, Flex } from '@src/common/components/BaseComponent'
import { FWButton } from '@src/common/components/BaseComponent'
import theme from '@src/styles/theme'
import { useTranslation } from 'react-i18next'

interface Props {
  isComplete?: boolean
  hideCancelButton?: boolean
  onCancelButtonClick?: () => void
  onDoneButtonClick?: () => void
}

const BatchImporterLoadingFooter: React.FC<Props> = ({
  isComplete,
  hideCancelButton = false,
  onCancelButtonClick,
  onDoneButtonClick
}) => {
  const { t } = useTranslation()

  return (
    <Box
      gridTemplateColumns="auto 1fr auto"
      alignItems="center"
      width="100%"
      height="86px"
      bg={theme.white}
      zIndex="102"
      py="medium"
      padding="0"
      borderTop={`1px solid ${theme.gray20}`}
    >
      <div
        css={css`
          width: 100%;
          height: 100%;
        `}
      >
        <Flex
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="flex-end"
        >
          {!hideCancelButton && (
            <FWButton
              css={css`
                margin-right: 20px;
              `}
              onClick={onCancelButtonClick}
            >
              {t('Cancel')}
            </FWButton>
          )}
          <FWButton
            type="primary"
            disabled={!isComplete}
            css={css`
              margin-right: 20px;
              color: #fff !important;
              background-color: ${!isComplete
                ? '#b8d3ff !important'
                : '#0F6BFF  !important'};
              border-color: ${!isComplete
                ? '#b8d3ff !important'
                : '#0F6BFF  !important'};
            `}
            onClick={onDoneButtonClick}
          >
            {t('Done')}
          </FWButton>
        </Flex>
      </div>
    </Box>
  )
}

export default BatchImporterLoadingFooter
