import React from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import { css } from '@emotion/react'
import { Box, Flex, FormLabel } from '@src/common/components/BaseComponent'
import { useCTATranslationEntries } from '@src/common/hooks/useCTATranslationEntries'
import { CTA_TYPE } from '@src/constants'
import { Input, Select, Tooltip } from 'antd'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type CTAProps = {
  type: string
  link: string
  customLabel: string
  isLivestream?: boolean
  setCtaType?: (value) => void
  handleTypeChange: (value) => void
  handleLinkChange: (e) => void
  handleLabelChange: (e) => void
}

export const CTA: React.FC<CTAProps> = ({
  type,
  link,
  customLabel,
  isLivestream = false,
  setCtaType = true,
  handleTypeChange,
  handleLinkChange,
  handleLabelChange
}) => {
  const { t } = useTranslation()

  const {
    formState: { errors }
  } = useFormContext()

  const options = useCTATranslationEntries()
    .filter(
      ([key]) =>
        ![CTA_TYPE.CTA, CTA_TYPE.CUSTOM, CTA_TYPE.REMINDER].includes(key)
    )
    .map(([key, value]) => ({
      value: key,
      label: value
    }))

  return (
    <Box width="100%">
      {isLivestream ? (
        <>
          <Input
            addonBefore="RSVP"
            placeholder={t('Insert link...')}
            onChange={handleLinkChange}
            disabled={type !== CTA_TYPE.CTA}
            value={link}
          />
        </>
      ) : (
        <>
          <Flex alignItems="center">
            <Input
              addonBefore={
                setCtaType ? (
                  <Select
                    defaultValue={CTA_TYPE.VIEW}
                    onChange={handleTypeChange}
                    style={{ width: '180px' }}
                    value={type}
                  >
                    {options.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                    <Select.Option
                      style={{
                        // This is added to create a border between the last option
                        // and the custom option ANT Select component does not allow
                        // any components as children other than Option
                        borderTop: '1px solid gainsboro',
                        borderImage:
                          'linear-gradient(to left, transparent 5%, gainsboro 5%, gainsboro 95%, transparent 95%) 100% 1'
                      }}
                      key={CTA_TYPE.CUSTOM}
                      value={CTA_TYPE.CUSTOM}
                    >
                      {t('Custom')}
                    </Select.Option>
                  </Select>
                ) : (
                  'RSVP'
                )
              }
              placeholder={t('Insert link...')}
              onChange={handleLinkChange}
              value={link}
              status={errors.action_url ? 'error' : ''}
            />
            <Tooltip
              placement="topRight"
              title={t(
                '"https://" will be added by default if no protocol is specified.'
              )}
              css={css`
                .ant-tooltip-inner {
                  max-width: 100px;
                }
              `}
            >
              <InfoCircleOutlined
                css={css`
                  margin-left: 10px;
                `}
              />
            </Tooltip>
          </Flex>
          {type === CTA_TYPE.CUSTOM && (
            <>
              <FormLabel
                css={css`
                  margin-top: 16px;
                `}
              >
                {t('CTA label')}
              </FormLabel>
              <Input
                placeholder={t('Enter your custom label here')}
                onChange={handleLabelChange}
                status={errors.action_custom_label ? 'error' : ''}
                value={customLabel}
                maxLength={28}
                showCount
              />
            </>
          )}
        </>
      )}
    </Box>
  )
}
