import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

export const useWidgetAssets = (): {
  images: { [key: string]: string }
  titles: { [key: string]: string }
  descriptions: { [key: string]: string }
  placeholderImages: { [key: string]: { desktop: string; mobile: string } }
  widgetArray: {
    id: string
    title: string
    description: string
    image: string
    type: string
    popular: boolean
    placeholderImage: {
      desktop: string
      mobile: string
    }
  }[]
} => {
  const { t } = useTranslation()

  const images = useMemo(() => {
    return {
      hero_unit: '/your-site/hero.svg',
      player: '/your-site/player.svg',
      carousel_grid: '/your-site/grid.svg',
      carousel_row: '/your-site/carousel.svg',
      storyblock: '/your-site/storyblock.svg',
      floating_player: '/your-site/floating_player.svg',
      player_deck: '/your-site/player_deck.svg',
      horizontal_player: '/your-site/horiz_player.svg',
      dynamic_carousel: '/your-site/dyna_carousel.svg',
      horizontal_carousel: '/your-site/horiz_carousel.svg'
    }
  }, [])

  const placeholderImages = useMemo(() => {
    return {
      hero_unit: {
        desktop: '/your-site/ghost/hero.svg',
        mobile: '/your-site/ghost/mobile/hero.svg'
      },
      player: {
        desktop: '/your-site/ghost/player.svg',
        mobile: '/your-site/ghost/mobile/player.svg'
      },
      carousel_grid: {
        desktop: '/your-site/ghost/grid.svg',
        mobile: '/your-site/ghost/mobile/grid.svg'
      },
      carousel_row: {
        desktop: '/your-site/ghost/carousel.svg',
        mobile: '/your-site/ghost/mobile/carousel.svg'
      },
      storyblock: {
        desktop: '/your-site/ghost/storyblock.svg',
        mobile: '/your-site/ghost/mobile/storyblock.svg'
      },
      floating_player: {
        desktop: '/your-site/ghost/floating_player.svg',
        mobile: '/your-site/ghost/mobile/floating_player.svg'
      },
      player_deck: {
        desktop: '/your-site/ghost/player_deck.svg',
        mobile: '/your-site/ghost/mobile/player_deck.svg'
      },
      horizontal_player: {
        desktop: '/your-site/ghost/horiz_player.svg',
        mobile: '/your-site/ghost/mobile/horiz_player.svg'
      },
      dynamic_carousel: {
        desktop: '/your-site/ghost/dyna_carousel.svg',
        mobile: '/your-site/ghost/mobile/dyna_carousel.svg'
      },
      horizontal_carousel: {
        desktop: '/your-site/ghost/horiz_carousel.svg',
        mobile: '/your-site/ghost/mobile/horiz_carousel.svg'
      }
    }
  }, [])

  const titles = useMemo(() => {
    return {
      carousel_row: t('Carousel'),
      player_deck: t('Player Deck'),
      storyblock: t('Reels (storyblock)'),
      player: t('Player'),
      hero_unit: t('Hero Video'),
      carousel_grid: t('Grid'),
      floating_player: t('Floating Player'),
      horizontal_player: t('Horizontal Player'),
      dynamic_carousel: t('Dynamic Carousel'),
      horizontal_carousel: t('Horizontal Carousel')
    }
  }, [t])

  const descriptions = useMemo(() => {
    return {
      hero_unit: t(
        'A large, attention-grabbing video featured prominently on a page.'
      ),
      player: t(
        'A standalone video player for streaming individual vertical videos.'
      ),
      carousel_grid: t(
        'A structured layout showcasing multiple videos in a grid format.'
      ),
      carousel_row: t(
        'A horizontal video slider that lets users scroll through multiple videos.'
      ),
      storyblock: t(
        'Displays short-form vertical video stories in an interactive block layout.'
      ),
      floating_player: t(
        'A mini video player that stays visible as users scroll the page'
      ),
      player_deck: t(
        'A horizontal video layout that allows users to browse multiple products.'
      ),
      horizontal_player: t(
        'A wide-format video player optimized for horizontal video playback.'
      ),
      dynamic_carousel: t(
        'A small PDP carousel that updates content based on matching products.'
      ),
      horizontal_carousel: t(
        'A scrolling carousel displaying video in a horizontal row.'
      )
    }
  }, [t])

  const popularEmbeds = useMemo(() => {
    return {
      carousel_row: true,
      player_deck: true,
      storyblock: true,
      player: false,
      hero_unit: false,
      carousel_grid: false,
      floating_player: false,
      horizontal_player: false,
      dynamic_carousel: false,
      horizontal_carousel: false
    }
  }, [])

  const widgetArray = useMemo(() => {
    return Object.keys(titles).reduce((acc, key) => {
      acc.push({
        id: key,
        title: titles[key],
        description: descriptions[key],
        image: images[key],
        type: [key],
        placeholderImage: placeholderImages[key],
        popular: popularEmbeds[key]
      })

      return acc
    }, [])
  }, [titles, descriptions, images, placeholderImages, popularEmbeds])

  return {
    images,
    titles,
    descriptions,
    placeholderImages,
    widgetArray
  }
}
