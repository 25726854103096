import React from 'react'

import { Banner } from '@src/common/components/Banner/Banner'
import { FWButton } from '@src/common/components/BaseComponent'
import { usePixelAmpTracking } from '@src/common/hooks'
import usePlaylistsAndGroups from '@src/common/hooks/usePlaylistsAndGroups'
import { TRACKING_EVENTS } from '@src/common/utils/tracking'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

interface CreatePlaylistBannerProps {
  businessId: string
  channelId: string
  disabled?: boolean
}

export const CreatePlaylistBanner: React.FC<CreatePlaylistBannerProps> = ({
  businessId,
  channelId,
  disabled
}) => {
  const { t } = useTranslation()
  const { pixelAmpTracking } = usePixelAmpTracking()
  const navigate = useNavigate()
  const { firstPlaylistId } = usePlaylistsAndGroups({
    businessId,
    channelId
  })

  const clickCreatePlaylist = () => {
    pixelAmpTracking(
      TRACKING_EVENTS.BUSINESS_ACTIVATION_STATUS_EVENTS
        .CLICK_CREATE_PLAYLIST_AND_ADD_VIDEO
    )
    if (firstPlaylistId)
      navigate(
        `/business/${businessId}/channel/${channelId}/videos?activeTab=playlist&activePlaylist=${firstPlaylistId}`
      )
    else
      navigate(
        `/business/${businessId}/channel/${channelId}/videos?activeTab=playlist_overview`
      )
  }

  return (
    <>
      <Banner
        disabled={disabled}
        title={t('Create a Playlist')}
        description={t('Add videos to a Playlist for your Home Page')}
        action={
          <FWButton type="primary" onClick={clickCreatePlaylist}>
            {t('Create Playlist')}
          </FWButton>
        }
      />
    </>
  )
}
