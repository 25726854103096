import { TFunction } from 'i18next'

// `getStoreApiErrorParser` returns an error message string based on the store API error code.
// Parameters:
// - `error` (any): The error object from the store API, e.g., {code: 'xxx', details?: {maxLimit: xxx}}.
// - `t` (TFunction): The translation function.
const getStoreApiErrorParser = (error: any, t: TFunction): string => {
  const errorMessageObj = {
    COMMERCE_STORE_PRODUCT_LINKED_TO_DOMAIN_ASSISTANT: t(
      'The store contains product(s) that are equipped to one of the AVA’s knowledge base, please disassociate the product from the AVA(s) before deleting the product(s).'
    ),
    COMMERCE_PRODUCT_LINKED_TO_DOMAIN_ASSISTANT: t(
      'The product(s) are equipped to one of the AVA’s knowledge base, please disassociate the product from the AVA(s) before deleting the product(s).'
    )
  }

  return errorMessageObj[error?.code]
}

export { getStoreApiErrorParser }
