/**
 * @fileoverview
 * Helper functions to be reused when creating the Table Headers
 * and the Table Columns for Products Table
 */

export const shouldRenderCheckboxCol = (
  onAdd: (product: globalLib.Product) => void,
  onRemove: (product: globalLib.Product) => void,
  selectType: string
): boolean => !!(onAdd && onRemove && selectType === 'checkbox')

export const checkboxColSize = {
  md: 1,
  xs: 2
}
export const shouldRenderHighlightCol = (
  onHighlight: (product: globalLib.Product) => void
): boolean => !!onHighlight
export const highlightColSize = {
  xs: 2
}
export const shouldRenderProductThumbnailCol = (
  variant: globalLib.ProductUnit | boolean
): boolean => !variant
export const productThumbnailColSize = {
  md: 3,
  xs: 4
}
export const productNameColSize = (
  onAdd: (product: globalLib.Product) => void,
  onRemove: (product: globalLib.Product) => void,
  selectType: string,
  onCreate: (product: globalLib.Product) => void,
  onDelete: (productId: string) => void,
  onToggleProductDrop?: (product: globalLib.Product, isHidden: boolean) => void
): { md: number; xs: number } => {
  return {
    md: 10,
    xs:
      (onAdd && onRemove && selectType === 'button') || onCreate
        ? 9
        : onDelete
        ? onToggleProductDrop
          ? 8
          : 11
        : 14
  }
}
export const shouldRenderVariantCol = (
  variant: globalLib.ProductUnit
): boolean => !!variant
export const variantColSize = {
  md: 6,
  xs: 4
}
export const productPriceColSize = {
  md: 3,
  xs: 0
}
export const shouldRenderProductDropCol = (
  onToggleProductDrop?: (product: globalLib.Product, isHidden: boolean) => void
): boolean => !!onToggleProductDrop

export const productDropColSize = {
  md: 2,
  xs: 5
}
export const shouldRenderAddRemoveProductCol = (
  onAdd: (product: globalLib.Product) => void,
  onRemove: (product: globalLib.Product) => void,
  selectType: string
): boolean => !!(onAdd && onRemove && selectType === 'button')
export const addRemoveProductColSize = {
  md: 3,
  xs: 7
}
export const shouldRenderImportCol = (
  onCreate: (product: globalLib.Product) => void
): boolean => !!onCreate
export const importColSize = {
  md: 2,
  xs: 7
}
export const shouldRenderDeleteCol = (
  onDelete: (productId: string) => void
): boolean => !!onDelete
export const deleteColSize = (
  onToggleProductDrop?: (product: globalLib.Product, isHidden: boolean) => void
): { md: number; xs: number } => {
  return {
    md: 3,
    xs: onToggleProductDrop ? 3 : 5
  }
}
export const shouldRenderProductAvailableCol = (
  setAvailability: (product: globalLib.Product) => void
): boolean => !!setAvailability
export const productAvailableColSize = {
  md: 3,
  xs: 5
}
export const shouldRenderExpandSelectVariantCol = (
  variant: globalLib.ProductUnit,
  product: globalLib.Product,
  collapseUnit?: (product: globalLib.Product) => void,
  expandProducts?: string[]
): boolean =>
  !!(
    !variant &&
    collapseUnit &&
    expandProducts &&
    product.product_units &&
    product.product_units.length > 1
  )
export const expandSelectVariantColSize = {
  md: 1,
  xs: 1
}

export const displayDealDiscount = (
  product: globalLib.Product,
  deal: globalLib.FlashDeal,
  language: string
): string => {
  const formatter = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: product.product_currency
  })

  if (!deal) return ''

  return deal.discount_type === 'percentage'
    ? `${deal.discount_amount}%`
    : `${formatter.format(deal.discount_amount)}`
}
