import { S3Result } from '@src/common/utils/s3'
import { localStorageProvider } from '@src/common/utils/storageProvider'

export type IgHashtagMedia = {
  id?: string
  caption?: string
  media_type?: string
  like_count?: number
  media_url?: string
  permalink?: string
  timestamp?: string
  username?: string
  children?: {
    data: {
      id?: string
      media_type?: string
      media_url?: string
    }[]
  }
  consentStatus?: string
  consentId?: string
  signature?: S3Result
}

export type IgHashtagPaging = {
  cursors?: {
    before?: string
    after?: string
  }
  next?: string
}

export type IgHashtagMediaRes = {
  data?: IgHashtagMedia[]
  paging: IgHashtagPaging
}

export type IIgBusinessAccount = {
  username?: string
  name?: string
  igId?: string
  id?: string
}

export type IPublicPage = {
  accessToken?: string
  category?: string
  categoryList?: { id: string; name: string }[]
  name?: string
  id?: string
  instagramBusinessAccount?: IIgBusinessAccount
}

export type IgHashtag = {
  id?: string
  name: string
}

export type Source = 'hashtag' | 'account' | 'discover_name'
export type Mode = 'recent' | 'popular'
export type PostType = 'reels' | 'stories' | 'tags'

const unique = (arr) => {
  const list = []

  return arr.filter((item) => !list.includes(item.name) && list.push(item.name))
}

const saveIgHashtagList = (
  userId: string,
  igHashtagList: IgHashtag[]
): void => {
  localStorageProvider.setItem(
    `ig_hashtag_list_${userId}`,
    JSON.stringify(unique(igHashtagList))
  )
}

const getIgHashtagList = (userId: string): IgHashtag[] => {
  return JSON.parse(
    localStorageProvider.getItem(`ig_hashtag_list_${userId}`) || '[]'
  )
}

const saveDiscoverNameList = (
  userId: string,
  discoverNameList: string[]
): void => {
  localStorageProvider.setItem(
    `ig_discover_name_list_${userId}`,
    JSON.stringify(discoverNameList)
  )
}

const getDiscoverNameList = (userId: string): string[] => {
  return JSON.parse(
    localStorageProvider.getItem(`ig_discover_name_list_${userId}`) || '[]'
  )
}

const saveIgAccountList = (
  userId: string,
  accountList: IPublicPage[]
): void => {
  localStorageProvider.setItem(
    `fb_account_list_${userId}`,
    JSON.stringify(accountList)
  )
}

const getIgAccountList = (userId: string): IPublicPage[] => {
  return JSON.parse(
    localStorageProvider.getItem(`fb_account_list_${userId}`) || '[]'
  )
}

const saveIgUsernameList = (userId: string, usernameList: string[]): void => {
  localStorageProvider.setItem(
    `fb_username_list_${userId}`,
    JSON.stringify(usernameList)
  )
}

const getIgUsernameList = (userId: string): string[] => {
  return JSON.parse(
    localStorageProvider.getItem(`fb_username_list_${userId}`) || '[]'
  )
}

const saveIgCrawlerHashtagList = (
  userId: string,
  hashtagList: string[]
): void => {
  localStorageProvider.setItem(
    `fb_crawler_hashtag_list_${userId}`,
    JSON.stringify(hashtagList)
  )
}

const getIgCrawlerHashtagList = (userId: string): string[] => {
  return JSON.parse(
    localStorageProvider.getItem(`fb_crawler_hashtag_list_${userId}`) || '[]'
  )
}

export {
  saveIgHashtagList,
  getIgHashtagList,
  saveDiscoverNameList,
  getDiscoverNameList,
  saveIgAccountList,
  getIgAccountList,
  saveIgUsernameList,
  getIgUsernameList,
  saveIgCrawlerHashtagList,
  getIgCrawlerHashtagList
}
