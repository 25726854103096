import React from 'react'

import { css } from '@emotion/react'
import { Flex } from '@src/common/components/BaseComponent'
import { FWButton } from '@src/common/components/BaseComponent'
import { useAvaJs } from '@src/common/hooks/Ava/useAvaJs'
import { Modal } from 'antd'
import { Trans, useTranslation } from 'react-i18next'

export default function SupportAvaModal({
  onCancel
}: {
  onCancel: () => void
}): JSX.Element {
  const { t } = useTranslation()
  useAvaJs()

  const onOkClick = () => {
    onCancel()
    window._fwn.ava.actions.showWidget({
      domain_assistant_id: 'Zg2mNo'
    })
  }

  return (
    <Modal
      open
      onCancel={onCancel}
      width={500}
      closable
      footer={
        <Flex gap="5px" width="100%" justifyContent="flex-end">
          <FWButton onClick={onCancel}>{t('Cancel')}</FWButton>
          <FWButton
            type="link"
            href="https://calendly.com/d/cpbk-gnd-3fp/30m-w-firework?utm_campaign=live_chat_button&utm_source=business_portal"
            target="_blank"
          >
            {t('Book a meeting with support')}
          </FWButton>
          <FWButton type="primary" onClick={onOkClick}>
            {t('Ask AI Specialist')}
          </FWButton>
        </Flex>
      }
    >
      <div style={{ paddingTop: 12 }}>
        <Trans t={t}>
          Live chat is available from 3AM EST to 7PM EST. You can contact
          support at
          <FWButton
            href="mailTo:slasupport@fireworkhq.com"
            type="link"
            css={css`
              padding: 4px;
            `}
          >
            slasupport@fireworkhq.com
          </FWButton>
          and someone from the Firework team will get back to you as soon as
          possible.
        </Trans>
      </div>
    </Modal>
  )
}
