import React from 'react'

import { AutomationRoutes } from '@src/modules/channel/routes/Automation'
import { ChannelRoutes } from '@src/modules/channel/routes/Channel'
import { DynamicContentRoutes } from '@src/modules/channel/routes/DynamicContent'
import { InsightsRoutes } from '@src/modules/channel/routes/Insights'
import { ImporterRoutes } from '@src/modules/channel/routes/Library/Importer'
import { JourneyRoutes } from '@src/modules/channel/routes/Library/Journey'
import { LivestreamRoutes } from '@src/modules/channel/routes/Library/Livestream'
import { VideoRoutes } from '@src/modules/channel/routes/Library/Video'
import { ManagementRoutes } from '@src/modules/channel/routes/Management'
import { MyContentRoutes } from '@src/modules/channel/routes/MyContent'
import { SearchRoutes } from '@src/modules/channel/routes/Search'
import { YourSiteRoutes } from '@src/modules/channel/routes/YourSite'
import { YourSiteV2Routes } from '@src/modules/channel/YourSiteV2/routes'

export const Routes = (
  <>
    {InsightsRoutes}
    {ChannelRoutes}
    {VideoRoutes}
    {LivestreamRoutes}
    {DynamicContentRoutes}
    {AutomationRoutes}
    {JourneyRoutes}
    {ImporterRoutes}
    {ManagementRoutes}
    {MyContentRoutes}
    {SearchRoutes}
    {YourSiteV2Routes}
    {YourSiteRoutes}
  </>
)
