import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@src/app/hooks'
import { usePixelAmpTracking } from '@src/common/hooks'
import { TRACKING_EVENTS } from '@src/common/utils/tracking'
import {
  setCmdBarIsClosed,
  setCmdBarIsOpen
} from '@src/modules/search/redux/search'

export const useHandleOpenCommandPalette = (): void => {
  const dispatch = useAppDispatch()
  const { pixelAmpTracking } = usePixelAmpTracking()
  const isOpen = useAppSelector((state) => state.search.cmdBarIsOpen)
  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (
        (navigator?.platform?.toLowerCase().includes('mac')
          ? e.metaKey
          : e.ctrlKey) &&
        e.key === 'k'
      ) {
        e.preventDefault()
        e.stopPropagation()
        pixelAmpTracking(TRACKING_EVENTS.BUSINESS_ACCESS_SEARCH_NAVIGATION, {
          action: 'shortcut'
        })
        if (!isOpen) {
          dispatch(setCmdBarIsOpen())
        } else {
          dispatch(setCmdBarIsClosed())
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [dispatch, pixelAmpTracking, isOpen])
}
