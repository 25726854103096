import React from 'react'

import styled from '@emotion/styled'
import theme from '@src/styles/theme'
import { Button, ButtonProps, Tooltip, TooltipProps } from 'antd'

const FWButtonComponent = styled(Button)<ButtonProps>`
  font-weight: normal;
`

interface FWButtonProps extends ButtonProps {
  tooltipProps?: TooltipProps
}

const FWButton: React.FC<FWButtonProps> = ({ tooltipProps, ...rest }) => {
  if (!tooltipProps) {
    return <FWButtonComponent {...rest} />
  }

  return (
    <Tooltip {...tooltipProps} placement={tooltipProps?.placement || 'top'}>
      <div>
        <FWButtonComponent {...rest} />
      </div>
    </Tooltip>
  )
}

export const FWButtonNoPadding = styled(FWButton)`
  padding: 0;
`

export const FWButtonLink = styled(FWButton)`
  color: ${theme.textSecondary};
  padding: 0;
  span {
    text-decoration: underline !important;
  }
`

export default FWButton
