import React from 'react'

import { css } from '@emotion/core'
import { Box, Flex } from '@src/common/components/BaseComponent'
import { Divider } from 'antd'

export default function DropDownMenuItem(props: {
  icon?: string | JSX.Element
  title?: string | JSX.Element
  onClick?: () => void
  showBottomLine?: boolean
  disabled?: boolean
  color?: string
  fontSize?: number
}): JSX.Element {
  const {
    onClick,
    showBottomLine,
    title,
    icon,
    disabled,
    color,
    fontSize
  } = props

  return (
    <Flex
      flexDirection="column"
      width="100%"
      onClick={() => {
        if (!disabled && onClick) {
          onClick()
        }
      }}
      css={css`
        opacity: ${disabled ? '0.5' : 'unset'};
        cursor: ${disabled ? 'unset' : 'pointer'};
        color: ${color || '#424242'};
      `}
    >
      <Flex
        flexDirection="row"
        width="100%"
        alignItems="center"
        css={css`
          user-select: none;
          padding: 12px 20px;

          &:hover {
            background-color: #ebf3ff;
          }
        `}
      >
        {icon && (
          <>
            {typeof icon === 'string' ? (
              <Box
                css={css`
                  margin-right: 12px;
                `}
                as="img"
                src={icon}
                width="15px"
                height="15px"
              />
            ) : (
              <div
                css={css`
                  margin-right: 12px;
                  font-size: 15px;
                  line-height: 15px;
                  height: 15px;
                  width: 15px;
                `}
              >
                {icon}
              </div>
            )}
          </>
        )}
        {title && (
          <>
            {typeof title === 'string' ? (
              <div
                css={css`
                  font-size: ${fontSize || 12}px;
                `}
              >
                {title}
              </div>
            ) : (
              title
            )}
          </>
        )}
      </Flex>
      {showBottomLine && (
        <Divider
          css={css`
            margin: 1px 0;
          `}
        />
      )}
    </Flex>
  )
}
