import { AUTO_EMBED_TABS } from '../../constants/yourSite'

export type SliceState = {
  selectedPlaylistId: string
  playlistVideos: globalLib.IVideo[]
  playlistVideosStatus: 'loading' | 'success' | 'error'
  isEnableBannerVisible: boolean
  isHelpBannerVisible: boolean
  autoEmbedConfig: AutoEmbedConfiguration[]
  integrationsActiveTab: string
  embedStatus: {
    loading: boolean
    status: 'enabled' | 'disabled' | 'error' | null
  }
  embedStatusByStoreUrl: {
    [storeUrl: string]: {
      loading: boolean
      status: 'enabled' | 'disabled' | 'error' | null
    }
  }
  selectedStoreIdByBusinessId: {
    [businessId: string]: string
  }
  pdpGalleryConfig: YourSite.PDPGallery
  shopifyAPIScopeValidation: {
    loading: boolean
    status: 'success' | 'missing_required_scopes' | null
    message: string
  }
  embedConfigs: {
    loading: boolean
    data: YourSite.EmbedConfig[]
  }
}

export const initialState: SliceState = {
  selectedPlaylistId: null,
  playlistVideos: [],
  playlistVideosStatus: 'success',
  isEnableBannerVisible: true,
  isHelpBannerVisible: false,
  autoEmbedConfig: null,
  integrationsActiveTab: AUTO_EMBED_TABS.HOME_PAGE,
  embedStatus: {
    loading: false,
    status: null
  },
  embedStatusByStoreUrl: {},
  selectedStoreIdByBusinessId: {},
  pdpGalleryConfig: null,
  shopifyAPIScopeValidation: {
    loading: false,
    status: null,
    message: null
  },
  embedConfigs: {
    loading: false,
    data: []
  }
}
