import React from 'react'

import loadable from '@loadable/component'
import { VIDEO_UPLOAD_METHOD } from '@src/constants'
import InsightsTab from '@src/modules/channel/components/Library/Video/VUForm/InsightsTab'
import { Route } from 'react-router'

const ChannelVideoUpload = loadable(
  () => import('@src/modules/channel/pages/Library/Video/ChannelVideoUpload'),
  {
    resolveComponent: (component) => component.ChannelVideoUpload
  }
)

export const VideoRoutes = (
  <>
    <Route
      path="/business/:businessId/channel/:channelId/video/create"
      element={<ChannelVideoUpload method={VIDEO_UPLOAD_METHOD.LOCAL_UPLOAD} />}
    />
    <Route
      path="/business/:businessId/channel/:channelId/video/:videoId"
      element={<ChannelVideoUpload method={VIDEO_UPLOAD_METHOD.EDIT_VIDEO} />}
    />
    <Route
      path="/business/:businessId/channel/:channelId/video/:videoId/edit"
      element={<ChannelVideoUpload method={VIDEO_UPLOAD_METHOD.EDIT_VIDEO} />}
    />
    <Route
      path="/app/business/:businessId/channel/:channelId/video/:videoId/insights"
      element={<InsightsTab />}
    />
  </>
)
