import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { css } from '@emotion/core'
import { Box, Flex } from '@src/common/components/BaseComponent'
import { Title } from '@src/common/components/BaseComponent'
import {
  BatchImporterMedia,
  BatchImporterMediaType,
  ImporterSourceFrom
} from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import {
  AlbumContainer,
  AlbumItemCheckBoxContainer,
  AlbumItemContainer,
  AlbumItemContent,
  Container,
  Image,
  MediaContainer,
  MediaContent,
  MediaGridContainer
} from '@src/modules/channel/components/Library/Importer/BatchImporter/Media/BatchImporterPreviewMedia.styles'
import { Checkbox } from 'antd'
import { useTranslation } from 'react-i18next'

interface IProps {
  media?: BatchImporterMedia
  selectedMediaList?: BatchImporterMedia[]
  onSelectedAlbumMedia?: (checked: boolean, value: BatchImporterMedia) => void
  onChangeDuration?: (duration?: number) => void
  canSelect?: boolean
  allowDownload?: boolean
  autoPlay?: boolean
  showSourceUrl?: boolean
}

const BatchImporterPreviewMedia: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const {
    media,
    onSelectedAlbumMedia,
    selectedMediaList,
    onChangeDuration,
    canSelect,
    allowDownload,
    autoPlay,
    showSourceUrl
  } = props
  const videoRef = useRef(null)

  const handleSelectedAlbumMedia = (
    checked: boolean,
    value: BatchImporterMedia
  ) => {
    if (onSelectedAlbumMedia) {
      onSelectedAlbumMedia(checked, value)
    }
  }

  const getDuration = (videoElement): Promise<number | undefined> => {
    return new Promise((resolve) => {
      if (!videoElement) {
        resolve(undefined)

        return
      }

      if (videoElement.readyState >= 1) {
        resolve(videoElement.duration)
      } else {
        videoElement.onloadedmetadata = () => {
          resolve(videoElement.duration)
        }

        videoElement.onerror = () => {
          resolve(undefined)
        }
      }
    })
  }

  const handleChangeDuration = useCallback(
    (duration?: number) => {
      if (onChangeDuration) {
        onChangeDuration(duration)
      }
    },
    [onChangeDuration]
  )

  const sourceFromName = useMemo(() => {
    const sourceFrom = media?.sourceFrom
    if (sourceFrom === ImporterSourceFrom.Tiktok) {
      return 'TikTok'
    } else if (sourceFrom === ImporterSourceFrom.Instagram) {
      return 'Instagram'
    } else if (sourceFrom === ImporterSourceFrom.YouTube) {
      return 'YouTube'
    }

    return undefined
  }, [media])

  useEffect(() => {
    const videoElement = videoRef.current

    if (videoElement) {
      getDuration(videoElement)
        .then((duration) => {
          handleChangeDuration(duration)
        })
        .catch(() => {
          handleChangeDuration(undefined)
        })
    }
  }, [handleChangeDuration])

  return (
    <Container>
      {media?.mediaType === BatchImporterMediaType.CAROUSEL_ALBUM && (
        <div
          css={css`
            overflow-y: scroll;
            height: 100%;
            min-width: 150px;

            &::-webkit-scrollbar {
              display: none;
            }
          `}
        >
          <AlbumContainer>
            {media?.children?.map((albumItem) => {
              const checked =
                selectedMediaList?.findIndex((selectedAlbumMediaItem) => {
                  return selectedAlbumMediaItem.id === albumItem.id
                }) > -1

              return (
                <AlbumItemContainer key={albumItem.id}>
                  <AlbumItemContent>
                    {albumItem?.mediaType === BatchImporterMediaType.VIDEO ? (
                      <>
                        {media?.needCrawlerVideo ? (
                          <Image src={albumItem?.thumbnailUrl} />
                        ) : (
                          <Box
                            ref={videoRef}
                            as="video"
                            src={albumItem?.mediaUrl}
                            css={css`
                              object-fit: cover;
                              width: 100%;
                              height: 100%;
                              background: black;
                            `}
                            controls
                            controlsList={!allowDownload ? 'nodownload' : ''}
                            autoPlay={false}
                          />
                        )}
                      </>
                    ) : (
                      <Image src={albumItem?.mediaUrl} />
                    )}
                    <Box
                      position="absolute"
                      top="0"
                      left="0"
                      right="0"
                      height="33.33%"
                      backgroundImage="linear-gradient(to bottom, rgba(43, 43, 43, 0.3), rgba(43, 43, 43, 0))"
                      width="100%"
                    />
                    {canSelect && (
                      <AlbumItemCheckBoxContainer>
                        <Checkbox
                          style={{
                            transform: 'scale(1.5)'
                          }}
                          checked={checked}
                          onClick={(e) => e.stopPropagation()}
                          onChange={(e) => {
                            handleSelectedAlbumMedia(
                              e.target.checked,
                              albumItem
                            )
                          }}
                        />
                      </AlbumItemCheckBoxContainer>
                    )}
                  </AlbumItemContent>
                </AlbumItemContainer>
              )
            })}
          </AlbumContainer>
        </div>
      )}
      <Flex flexDirection="column">
        <MediaGridContainer>
          <MediaContainer>
            <MediaContent>
              {media?.mediaType === BatchImporterMediaType.VIDEO ? (
                <>
                  {media?.needCrawlerVideo ? (
                    <Image src={media?.thumbnailUrl} />
                  ) : (
                    <Box
                      ref={videoRef}
                      as="video"
                      src={media?.mediaUrl}
                      css={css`
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        background: black;
                      `}
                      controls
                      controlsList={!allowDownload ? 'nodownload' : ''}
                      autoPlay={autoPlay}
                    />
                  )}
                </>
              ) : (
                <Image src={media?.mediaUrl} />
              )}
            </MediaContent>
          </MediaContainer>
        </MediaGridContainer>
        {showSourceUrl && !!media?.sourceUrl?.length && sourceFromName && (
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            p={8}
            cursor="pointer"
            gap={8}
            onClick={() => {
              window.open(media?.sourceUrl, '_blank')
            }}
          >
            <Title fontSize={16} weight={500} color="#0F6BFF" noMargin>
              {`${t('Open in')} ${sourceFromName}`}
            </Title>
            <Box as="img" src="/icon/link_out.svg" height="16px" width="16px" />
          </Flex>
        )}
      </Flex>
    </Container>
  )
}

export default BatchImporterPreviewMedia
