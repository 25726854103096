import { useCallback } from 'react'

import { useAppSelector, useAppDispatch } from '@src/app/hooks'
import { joinWSTopic, leaveWSTopic } from '@src/common/redux/websocket'

type Props = {
  sendJsonMessage: (payload: any) => void
}

export const useCommonWebsocketTopics = ({
  sendJsonMessage
}: Props): {
  joinTopic: (topicId: string) => void
  leaveTopic: (topicId: string) => void
} => {
  const topicsJoined = useAppSelector((state) => state.websocket.topicsJoined)
  const dispatch = useAppDispatch()

  const joinTopic = useCallback(
    (topicId: string) => {
      if (!topicsJoined.find((topic) => topic === topicId)) {
        sendJsonMessage({
          topic: topicId,
          ref: 1,
          event: 'phx_join',
          payload: {
            private_message_format: 'non_mobile'
          }
        })
        dispatch(joinWSTopic({ topic: topicId }))
      }
    },
    [dispatch, sendJsonMessage, topicsJoined]
  )

  const leaveTopic = useCallback(
    (topicId: string) => {
      sendJsonMessage({
        topic: topicId,
        ref: 1,
        event: 'phx_leave',
        payload: {}
      })
      dispatch(leaveWSTopic({ topic: topicId }))
    },
    [dispatch, sendJsonMessage]
  )

  return {
    joinTopic,
    leaveTopic
  }
}
