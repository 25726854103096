import React from 'react'

import { css } from '@emotion/react'
import { Title } from '@src/common/components/BaseComponent'
import { Text } from '@src/common/components/Text'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { useWidgetAssets } from '../../hooks/useWidgetAssets'

export type TableDataType = YourSite.EmbedConfig & { widgetType: string }

type Props = {
  selectedStoreId: string
  homePageConfigStatus: AutoEmbedConfiguration
  pdpConfig: YourSite.PDPGallery
  embedConfigsByStoreId: YourSite.EmbedConfig[]
  handleTableRowClick: (record: TableDataType) => void
}

export const EmbedConfigTable = ({
  selectedStoreId,
  homePageConfigStatus,
  pdpConfig,
  embedConfigsByStoreId,
  handleTableRowClick
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { businessId, channelId } = useParams()
  const { titles } = useWidgetAssets()

  const tableColumns = [
    {
      title: t('Name'),
      dataIndex: 'layout',
      key: 'name',
      render: function render(
        text: string,
        record: YourSite.EmbedConfig & { widgetType: string }
      ) {
        if (record.widgetType === 'no_code') {
          return (
            <Title
              fontSize={14}
              weight={600}
              css={css`
                text-transform: capitalize;
              `}
            >
              {text}
            </Title>
          )
        }

        return (
          <Title fontSize={14} weight={600}>
            {titles[text]}
          </Title>
        )
      }
    },
    {
      title: t('Embed Type'),
      dataIndex: 'widgetType',
      key: 'widgetType',
      render: function render(
        _text,
        record: YourSite.EmbedConfig & { widgetType: string }
      ) {
        if (record.widgetType === 'no_code') {
          return t('No Code Embed')
        }

        return t('Manual')
      }
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      render: function render(text: string) {
        return text === 'enable' ? t('Active') : t('Inactive')
      }
    },
    {
      title: t('Widget ID'),
      dataIndex: 'embed_config_id',
      key: 'widgetConfigId',
      render: function render(text: string) {
        if (!text) return null

        return (
          <Text code copyable>
            {text}
          </Text>
        )
      }
    }
  ]

  const homePageConfigTableRow = {
    widgetType: 'no_code',
    business_store_id: selectedStoreId,
    layout: homePageConfigStatus?.widget_type,
    embed_config_id: null,
    business_id: businessId,
    channel_id: channelId,
    status: (homePageConfigStatus?.enabled_at ? 'enable' : 'disable') as
      | 'enable'
      | 'disable',
    widget_properties: {},
    id: 'homepage'
  }

  const pdpGalleryConfigTableRow = {
    widgetType: 'no_code',
    business_store_id: selectedStoreId,
    layout: 'storyblock',
    embed_config_id: null,
    business_id: businessId,
    channel_id: channelId,
    status: (pdpConfig?.status ? 'enable' : 'disable') as 'enable' | 'disable',
    widget_properties: {},
    id: 'pdp_gallery'
  }

  const embedConfigTableData = embedConfigsByStoreId.map((item) => ({
    ...item,
    widgetType: 'manual'
  }))

  const tableData = [
    homePageConfigTableRow,
    pdpGalleryConfigTableRow,
    ...embedConfigTableData
  ]

  return (
    <Table<TableDataType>
      sticky
      scroll={{
        x: 'calc(100vw - 200px)',
        y: 'calc(100vh - 350px)'
      }}
      columns={tableColumns}
      dataSource={tableData}
      rowKey="id"
      onRow={(record: TableDataType) => ({
        onClick: () => handleTableRowClick(record)
      })}
    />
  )
}
