import React, { useEffect, useState } from 'react'

import { css } from '@emotion/core'
import { Flex } from '@src/common/components/BaseComponent'
import { Progress } from 'antd'
import { useTranslation } from 'react-i18next'

export type LoadingStatus = 'success' | 'fail' | 'in_progress'

interface IProps {
  randomProgress?: boolean
  progress?: number
  status?: LoadingStatus
  onSuccess?: () => void
}

const BatchImporterLoadingBody: React.FC<IProps> = (props) => {
  const { t } = useTranslation()
  const [progressState, setProgressState] = useState<number>(0)
  const {
    randomProgress = true,
    progress,
    status = 'in_progress',
    onSuccess
  } = props

  useEffect(() => {
    let interval = undefined
    if (randomProgress && status === 'in_progress') {
      interval = setInterval(() => {
        const randomInt = Math.floor(Math.random() * 10)
        setProgressState((preProgressState) => {
          const p = preProgressState + randomInt
          if (p < 100) {
            return p
          }

          return preProgressState
        })
      }, 1000)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [randomProgress, status])

  useEffect(() => {
    if (!randomProgress && status === 'in_progress') {
      setProgressState(progress)
    }
  }, [randomProgress, progress, status])

  useEffect(() => {
    let timeout = undefined
    if (status === 'success') {
      setProgressState(100)
      timeout = setTimeout(() => {
        if (onSuccess) {
          onSuccess()
        }
      }, 500)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [status, onSuccess])

  const getStatusString = (): string => {
    if (status === 'fail') {
      return t('Loading failed')
    } else if (status === 'success') {
      return t('Loading Successfully')
    } else {
      return t('Loading videos...')
    }
  }

  return (
    <Flex
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <div
        css={css`
          font-size: 24px;
        `}
      >
        {getStatusString()}
      </div>
      <div
        css={css`
          margin-top: 20px;
          font-size: 16px;
          text-align: center;
        `}
      >
        {t('This may take a few moments if you have a lot of content.')}
      </div>
      <div
        css={css`
          margin-top: 12px;
          font-size: 16px;
        `}
      >
        {`${progressState}%`}
      </div>
      <div
        css={css`
          width: 186px;
          margin-top: 10px;
        `}
      >
        <Progress
          status={status === 'fail' ? 'exception' : undefined}
          percent={progressState}
          showInfo={status !== 'in_progress'}
        />
      </div>
    </Flex>
  )
}

export default BatchImporterLoadingBody
