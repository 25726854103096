import React from 'react'

import { css } from '@emotion/core'
import { useAppSelector } from '@src/app/hooks'
import { Flex } from '@src/common/components/BaseComponent'
import { PLGWrapper } from '@src/common/components/PLGWrapper/PLGWrapper'
import usePixelAmpTracking from '@src/common/hooks/usePixelAmpTracking'
import { TRACKING_EVENTS } from '@src/common/utils/tracking'
import { VideoVisibility } from '@src/constants'
import { PlaylistSelectWithCreate } from '@src/modules/channel/components/Library/Playlist/PlaylistSelectWithCreate'
import { Checkbox, Radio, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { ImporterSource, TrackingDetails } from '../BatchImporterMediaModel'
import { ScheduleUploadForm } from './ScheduleUploadForm'

interface IProps {
  playlists?: string[]
  visibility?: VideoVisibility
  onPlaylists?: (value: string[]) => void
  onVisibility?: (value: VideoVisibility) => void
  agreeTerms?: boolean
  onAgreeTerms?: (value: boolean) => void
  tracking?: TrackingDetails
  uploadCount: number
  onPublishTime?: (value?: string) => void
  publishTime?: string
}

const BatchImporterAddPlaylistBody: React.FC<IProps> = (props): JSX.Element => {
  const { t } = useTranslation()

  const { channelId } = useParams()
  const { pixelAmpTracking } = usePixelAmpTracking()
  const channelPlaylists = useAppSelector<globalLib.Playlist[]>(
    (state) => state.channel.channelPlaylists[channelId] || []
  )

  const {
    playlists = [],
    onPlaylists,
    visibility,
    onVisibility,
    agreeTerms,
    onAgreeTerms,
    tracking,
    uploadCount,
    onPublishTime,
    publishTime
  } = props

  const handlePlaylists = (value) => {
    const source = tracking.source
    if (source === ImporterSource.BULK_UPLOAD)
      pixelAmpTracking(TRACKING_EVENTS.BUSINESS_BULK_UPLOAD_ADD_TO_PLAYLIST, {
        _playlist_id: value
      })
    else if (
      source === ImporterSource.INSTAGRAM_PERSONAL ||
      source === ImporterSource.INSTAGRAM_BUSINESS ||
      source === ImporterSource.INSTAGRAM_LINK
    )
      pixelAmpTracking(TRACKING_EVENTS.BUSINESS_IG_IMPORTER_ADD_TO_PLAYLIST, {
        _playlist_id: value
      })
    else if (
      source === ImporterSource.TIKTOK_LINK ||
      source === ImporterSource.TIKTOK
    )
      pixelAmpTracking(TRACKING_EVENTS.BUSINESS_TIKTOK_ADD_TO_PLAYLIST, {
        _playlist_id: value
      })

    if (onPlaylists) {
      onPlaylists(value || [])
    }
  }

  const handleVisibility = (value: VideoVisibility) => {
    if (onVisibility) {
      onVisibility(value)
    }
  }

  const handleAgreeTerms = (value: boolean) => {
    if (onAgreeTerms) {
      onAgreeTerms(value)
    }
  }

  const setPublishTime = (value?: string) => {
    if (onPublishTime) {
      onPublishTime(value)
    }
  }

  return (
    <Flex
      flex={1}
      flexDirection="column"
      p="large2"
      minWidth={350}
      position="sticky"
      borderLeft="1px solid #d9d9d9"
      top={0}
      opacity={tracking.source === ImporterSource.ASYNC_MODAL ? 0.5 : 1}
      pointerEvents={
        tracking.source === ImporterSource.ASYNC_MODAL ? 'none' : 'auto'
      }
    >
      <Flex flexDirection="column" alignItems="center" gap="large">
        {uploadCount > 1 && (
          <div
            css={css`
              width: 100%;
              font-size: 16px;
              font-weight: 600;
            `}
          >
            {t('Apply to All')}
          </div>
        )}
        <div
          css={css`
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;
          `}
        >
          <Typography.Text>{t('Playlists')}</Typography.Text>
          <PlaylistSelectWithCreate
            playlists={playlists}
            setPlaylists={handlePlaylists}
            defaultSearchedPlaylists={channelPlaylists}
            multiple
          />
        </div>
        <div
          css={css`
            width: 100%;
          `}
        >
          <PLGWrapper
            elseNot={
              <Flex width="100%" flexDirection="column" gap="small">
                <div
                  css={css`
                    width: 100%;
                    font-size: 14px;
                  `}
                >
                  {t('Visibility')}
                </div>
                <Radio
                  checked={visibility === 'public'}
                  onChange={() => {
                    handleVisibility('public')
                  }}
                >
                  <div
                    css={css`
                      font-size: 14px;
                    `}
                  >
                    {t('Default')}
                  </div>
                </Radio>
                <Radio
                  onChange={() => {
                    handleVisibility('private')
                  }}
                  checked={visibility === 'private'}
                >
                  <div
                    css={css`
                      font-size: 14px;
                    `}
                  >
                    {t('Private (Only visible on your account)')}
                  </div>
                </Radio>
                {visibility === 'public' && (
                  <div
                    css={css`
                      margin-top: 14px;
                      width: 100%;
                    `}
                  >
                    <ScheduleUploadForm
                      setPublishTime={setPublishTime}
                      publishTime={publishTime}
                    />
                  </div>
                )}
              </Flex>
            }
          />
        </div>

        <div
          css={css`
            margin-top: 14px;
            width: 100%;
            font-size: 20px;
          `}
        >
          <Checkbox
            checked={agreeTerms}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              handleAgreeTerms(e.target.checked)
            }}
          >
            <div style={{ color: '#6B6E73' }}>
              {t('I agree to Firework')}{' '}
              <a
                href="https://fw.tv/community_guidelines"
                rel="noopener noreferrer"
                target="_blank"
                css={css`
                  color: #6b6e73;
                  text-decoration: underline;

                  &:hover {
                    text-decoration: underline;
                  }
                `}
              >
                {t('community guidelines')}
              </a>
              {' & '}
              <a
                href="https://fw.tv/terms"
                rel="noopener noreferrer"
                target="_blank"
                css={css`
                  color: #6b6e73;
                  text-decoration: underline;

                  &:hover {
                    text-decoration: underline;
                  }
                `}
              >
                {t('terms')}
              </a>
            </div>
          </Checkbox>
        </div>
      </Flex>
    </Flex>
  )
}

export default BatchImporterAddPlaylistBody
