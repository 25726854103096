import React from 'react'

import { Flex } from '@src/common/components/BaseComponent'
import { Divider } from 'antd'
import { Typography } from 'antd'

type FWDividerProps = {
  label?: string
}

export const FWDivider: React.FC<FWDividerProps> = ({ label }) => {
  return (
    <Flex flexDirection="column">
      {label && (
        <Typography.Text style={{ marginBottom: '0px' }} type="secondary">
          {label}
        </Typography.Text>
      )}
      <Divider style={{ margin: '8px 0' }} />
    </Flex>
  )
}
