import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const PricingPlan = loadable(() => import('../pages/PricingPlan'))
const Addons = loadable(() => import('../pages/Addons'), {
  resolveComponent: (component) => component.Addons
})
const Pricing = loadable(() => import('../pages/Pricing'))

export const Routes = (
  <>
    <Route
      path="/business/:businessId/pricing-plan"
      element={<PricingPlan />}
    />
    <Route
      path="/business/:businessId/subscription/packages/:packageId/addons"
      element={<Addons />}
    />
  </>
)

export const UnauthenticatedRoutes = (
  <Route path="/pricing" element={<Pricing />} />
)
