import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@src/app/hooks'
import { fetchBusinesses } from '@src/common/redux/business'
import { fetchChannels } from '@src/common/redux/channel'
import api from '@src/common/utils/api'
import { localStorageProvider } from '@src/common/utils/storageProvider'

/**
 * The favorite data was saved in local storage before.
 * Hook to synchronize favorite businesses and channels from local storage to the server
 * We will delete this file in a few months
 */
export const useSyncFavorites = (businessId: string): void => {
  const user = useAppSelector((state) => state.profile.user || {})
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!user.encoded_id || !businessId) {
      return
    }

    const syncFavoriteBusinesses = async () => {
      const localStorageKey = `favorite_business_${user.encoded_id}`
      const favoriteBusinessListRaw = localStorageProvider.getItem(
        localStorageKey
      )
      if (!favoriteBusinessListRaw) return
      localStorageProvider.removeItem(localStorageKey)

      let favoriteBusinessList = []
      try {
        favoriteBusinessList = JSON.parse(favoriteBusinessListRaw).reverse()
      } catch {
        favoriteBusinessList = []
      }

      Promise.all(
        favoriteBusinessList.map(({ id }) =>
          api.post(`/api/bus/${id}/favorites`)
        )
      ).finally(() => {
        favoriteBusinessList?.length && dispatch(fetchBusinesses())
      })
    }

    const syncFavoriteChannels = async () => {
      const localStorageKeys = `favorite_channel_${businessId}_${user.encoded_id}`

      const favoriteChannelsRaw = localStorageProvider.getItem(localStorageKeys)
      if (!favoriteChannelsRaw) return
      let favoriteChannels = []
      try {
        localStorageProvider.removeItem(localStorageKeys)
        favoriteChannels = JSON.parse(favoriteChannelsRaw)
          .map(({ encoded_id }) => encoded_id)
          .reverse()
      } catch (error) {
        favoriteChannels = []
      }

      Promise.all(
        favoriteChannels.map((channelId) =>
          api.post(`/api/bus/${businessId}/favorites`, {
            channel_id: channelId
          })
        )
      ).finally(() => {
        dispatch(fetchChannels(businessId))
      })
    }
    syncFavoriteBusinesses()
    syncFavoriteChannels()
  }, [businessId, dispatch, user.encoded_id])
}
