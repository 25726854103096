import React from 'react'

import { useAppSelector } from '@src/app/hooks'
import { Banner } from '@src/common/components/Banner/Banner'
import { FWButton } from '@src/common/components/BaseComponent'
import { useStoreData } from '@src/common/hooks/useStoreData'
import { Spin } from 'antd'
import { useTranslation } from 'react-i18next'

interface FireworkEmbedBannerProps {
  businessId: string
  disabled?: boolean
}

export const FireworkEmbedBanner: React.FC<FireworkEmbedBannerProps> = ({
  businessId,
  disabled
}) => {
  const { t } = useTranslation()
  const {
    getSelectedShopifyStoreDomainUrl: shopifyStoreDomainUrl
  } = useStoreData({ businessId })

  const { loading, status } = useAppSelector(
    (state) => state.yourSite.embedStatus
  )

  const navigateToFireworkEmbed = () => {
    window.open(
      `${shopifyStoreDomainUrl}/?context=apps&activateAppId=347a2e4c-f5cb-410d-8354-490d1e80558c/firework-header`,
      '_blank'
    )
  }

  if (loading) {
    return (
      <Banner
        title={t('Firework App status...')}
        description={t('Checking if the app is installed and activated...')}
        action={<Spin />}
      />
    )
  }

  if (['disabled', 'error'].includes(status)) {
    return (
      <>
        <Banner
          disabled={disabled}
          title={t('Turn on the Firework Embed App on your Shopify site')}
          description={t(
            'In your theme customizer, turn on the app, then click ‘Save’. Refresh this page once you’re done.'
          )}
          action={
            <FWButton type="primary" onClick={navigateToFireworkEmbed}>
              {t('Turn on Firework')}
            </FWButton>
          }
        />
      </>
    )
  }

  return null
}
