import { useEffect, useState } from 'react'

const useOTOWidget = (): boolean => {
  const [isLoaded, setIsLoaded] = useState(false)

  const handleWidgetReady = () => {
    setIsLoaded(true)
  }
  // Inject One to One script

  useEffect(() => {
    if (window._fwn?.liveHelper) {
      handleWidgetReady()
    } else {
      const existingScript = document.querySelector(
        `script[src="https://asset.fwcdn3.com/js/live-helper.js"]`
      )

      if (!existingScript) {
        const script = document.createElement('script')
        script.src = 'https://asset.fwcdn3.com/js/live-helper.js'
        script.async = true
        script.dataset.fwparam_chat_channel_id = 'Zg20d5'
        script.dataset.fwparam_initial_actions = 'chat_only'

        document.body.appendChild(script)
        document.addEventListener('fw:ready', handleWidgetReady)
      }
    }
  }, [])

  return isLoaded
}
export default useOTOWidget
