import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

import { YourSiteV2 } from '../YourSiteV2/pages/YourSiteV2'

const ProductPages = loadable(() => import('../pages/YourSite/ProductPages'), {
  resolveComponent: (component) => component.ProductPages
})

const YourSiteLayout = loadable(() => import('../layout/YourSite'), {
  resolveComponent: (component) => component.YourSiteLayout
})

const OtherPages = loadable(() => import('../pages/YourSite/OtherPages'), {
  resolveComponent: (component) => component.OtherPages
})

const HTMLPages = loadable(() => import('../pages/YourSite/HTMLPage'), {
  resolveComponent: (component) => component.HTMLPage
})

export const YourSiteRoutes = (
  <>
    <Route
      path="/business/:businessId/channel/:channelId/integrations/v2"
      element={<YourSiteV2 />}
    />
    <Route
      path="/business/:businessId/channel/:channelId/integrations/embed"
      element={<YourSiteLayout />}
    >
      <Route path="product-detail-pages" element={<ProductPages />} />
      <Route path="all-pages" element={<OtherPages />} />
      <Route path="advanced-html" element={<HTMLPages />} />
    </Route>
  </>
)
