import { useCallback, useMemo } from 'react'

import { useAppSelector } from '@src/app/hooks'
import sendAmplitudeTrackingEvent from '@src/common/utils/amplitudeTracking'
import sendVisitorEventPixelTracking from '@src/common/utils/pixelTracking'
import { localTime } from '@src/common/utils/sanitize'
import { getLocale } from '@src/common/utils/tracking'
import { useParams } from 'react-router-dom'

import { logInfo } from '../utils/logging'

type usePixelAmpTrackingHook = {
  pixelAmpTracking: (event: string, extraProps?: any) => void
  pixelVisitorTracking: (event: string, extraProps?: any) => void
  amplitudeTracking: (event: string, extraProps?: any) => void
  pageViewPixelAmpTracking: (event: string, extraProps?: any) => void
  pixelGuestTracking: (event: string, extraProps?: any) => void
}

const emailRegex = /^[a-zA-Z0-9._%+-]+@(fireworkhq|loopapps)\.com$/

const usePixelAmpTracking = (): usePixelAmpTrackingHook => {
  const { livestreamId, videoId } = useParams()
  const user = useAppSelector((state) => state.profile.user)
  /* defaultTracking contains: _business_id, _channel_id, business_type */
  const defaultTracking =
    useAppSelector((state) => state.business?.defaultTracking) || {}
  const livestreamDetail = useAppSelector(
    (state) => state.livestream?.livestreams[livestreamId]
  )
  const livestreamDetailStatus = livestreamDetail?.status
  const primaryEmail = useAppSelector((state) => state.profile.primaryEmail)

  const isInternalUser = useMemo(() => {
    return emailRegex.test(primaryEmail)
  }, [primaryEmail])

  const basicData = useMemo(() => {
    return {
      _video_id: videoId,
      _live_stream_id: livestreamId,
      live_stream_status: livestreamDetailStatus
    }
  }, [videoId, livestreamId, livestreamDetailStatus])

  const pageViewPixelAmpTracking = useCallback(
    (event, extraProps = {}) => {
      if (user.encoded_id && primaryEmail) {
        const href = window?.location?.href
        basicData['page_url'] = href
        const trackingProps = {
          ...defaultTracking,
          ...basicData,
          ...extraProps,
          client_local_time: localTime(),
          locale: getLocale()
        }
        sendVisitorEventPixelTracking({
          data: {
            _user_id: user.encoded_id,
            event_properties: trackingProps,
            internal_user: isInternalUser
          },
          event
        })
        sendAmplitudeTrackingEvent({
          event,
          props: {
            ...trackingProps,
            internal_user: isInternalUser
          }
        })
      }
    },
    // eslint-disable-next-line
    [basicData, user.encoded_id, defaultTracking, primaryEmail]
  )

  const pixelAmpTracking = useCallback(
    (event, extraProps = {}) => {
      const href = window?.location?.href
      // page_url should not be added to basicData outside of the function since it will be a dependency and make the function changed every time the url changes. This causes duplicated tracking events.
      basicData['app_page_url'] = href
      const trackingProps = {
        ...defaultTracking,
        ...basicData,
        ...extraProps,
        client_local_time: localTime(),
        locale: getLocale()
      }

      logInfo(event, trackingProps)

      if (user.encoded_id) {
        sendVisitorEventPixelTracking({
          data: {
            _user_id: user.encoded_id,
            event_properties: trackingProps,
            internal_user: isInternalUser
          },
          event
        })
        sendAmplitudeTrackingEvent({
          event,
          props: {
            ...trackingProps,
            internal_user: isInternalUser
          }
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultTracking, basicData, user.encoded_id]
  )

  const pixelVisitorTracking = useCallback(
    (event, extraProps = {}) => {
      const href = window?.location?.href
      basicData['app_page_url'] = href
      const trackingProps = {
        ...defaultTracking,
        ...basicData,
        ...extraProps,
        client_local_time: localTime(),
        locale: getLocale()
      }

      logInfo(event, trackingProps)
      sendVisitorEventPixelTracking({
        data: {
          _user_id: user.encoded_id,
          event_properties: trackingProps,
          internal_user: isInternalUser
        },
        event
      })
    },
    // eslint-disable-next-line
    [basicData, user.encoded_id, defaultTracking]
  )

  const pixelGuestTracking = useCallback((event, extraProps = {}) => {
    const trackingProps = {
      client_local_time: localTime(),
      locale: getLocale()
    }

    logInfo(event, trackingProps)
    sendVisitorEventPixelTracking({
      data: {
        event_properties: trackingProps,
        ...extraProps
      },
      event
    })
  }, [])

  const amplitudeTracking = useCallback(
    (event, extraProps = {}) => {
      const href = window?.location?.href
      basicData['app_page_url'] = href
      const trackingProps = {
        ...defaultTracking,
        ...basicData,
        ...extraProps,
        client_local_time: localTime(),
        locale: getLocale()
      }

      logInfo(event, trackingProps)

      sendAmplitudeTrackingEvent({
        event,
        props: {
          ...trackingProps,
          internal_user: isInternalUser
        }
      })
    },
    // eslint-disable-next-line
    [defaultTracking, basicData]
  )

  return {
    pixelAmpTracking,
    pixelVisitorTracking,
    amplitudeTracking,
    pageViewPixelAmpTracking,
    pixelGuestTracking
  }
}

export default usePixelAmpTracking
