import React, { ReactNode, useCallback, useEffect, useState } from 'react'

import { FullScreenLayout } from '@src/common/components/BaseComponent'
import BatchImporterHeader from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterHeader'
import BatchImporterLoadingBody from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterLoading/BatchImporterLoadingBody'
import BatchImporterLoadingFooter from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterLoading/BatchImporterLoadingFooter'
import { BatchImporterResult } from '@src/modules/channel/components/Library/Importer/BatchImporter/BatchImporterMediaModel'
import { useTranslation } from 'react-i18next'

export type LoadingStatus = 'success' | 'fail' | 'in_progress'

interface IProps {
  title: string
  subtitle?: string | ReactNode
  onClose: () => void
  onStart: () => Promise<BatchImporterResult>
  onSuccess: () => void
}

const BatchImporterLoading: React.FC<IProps> = (props) => {
  const { title, subtitle, onClose, onStart, onSuccess } = props
  const { t } = useTranslation()
  const [statusState, setStatusState] = useState<LoadingStatus>('in_progress')

  const handleStart = useCallback(async () => {
    setStatusState('in_progress')
    const res = await onStart()
    if (res && res.success) {
      setStatusState('success')
    } else {
      setStatusState('fail')
    }
  }, [onStart])

  useEffect(() => {
    handleStart().then()
  }, [handleStart])

  return (
    <FullScreenLayout position="fixed" bottom={0} right={0}>
      <BatchImporterHeader
        title={title}
        subTitle={subtitle || t('Operation in progress')}
        onCloseButtonClick={onClose}
      />
      <BatchImporterLoadingBody status={statusState} onSuccess={onSuccess} />
      <BatchImporterLoadingFooter
        onCancelButtonClick={onClose}
        onDoneButtonClick={onClose}
        isComplete={statusState === 'fail'}
      />
    </FullScreenLayout>
  )
}

export default BatchImporterLoading
