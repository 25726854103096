import { useCallback, useMemo } from 'react'

import { useAppSelector } from '@src/app/hooks'
import {
  getBusinessStores,
  getShopifyStores
} from '@src/common/redux/selectors/businessStores'

export const useStoreData = ({
  businessId
}: {
  businessId: string
}): {
  selectedStoreId: string | undefined
  getShopifyStoreCount: number
  getStoreCount: number
  getShopifyStoreList: () => globalLib.BusinessStore[]
  getStoreList: () => globalLib.BusinessStore[]
  getShopifyStoreById: (id: string) => globalLib.BusinessStore | undefined
  getShopifyStoreDomainUrlByUid: (uid: string) => string | undefined
  getSelectedShopifyStoreDomainUrl: string | undefined
  getSelectedShopifyShopUrl: string | undefined
  getStoreById: (id: string) => globalLib.BusinessStore | undefined
  storeProductList: globalLib.Product[]
  getStoreNameById: (id: string) => string | undefined
} => {
  // Get Shopify Stores to get domain of the first shopify store in the list if more than one exist
  const shopifyStores = useAppSelector((state) => getShopifyStores(state))
  const storesList = useAppSelector((state) => getBusinessStores(state))

  const selectedStoreId = useAppSelector(
    (state) => state.yourSite.selectedStoreIdByBusinessId[businessId]
  )

  const storeProductList = useAppSelector(
    (state) =>
      state.businessStore.productsByBusinessStoreId?.[selectedStoreId]?.products
  )

  /**
   * The function `getShopifyStoreByUid` retrieves a Shopify store object based on
   * a given UID.
   * @param {string} uid - The `uid` parameter is a string that represents the
   * unique identifier of a Shopify store.
   * @returns The function `getShopifyStoreByUid` returns the Shopify store object
   * that matches the provided `uid` parameter.
   */
  const getShopifyStoreById = useCallback(
    (id: string): globalLib.BusinessStore | undefined => {
      const shopifyStore = shopifyStores.find((store) => store.id === id)

      return shopifyStore
    },
    [shopifyStores]
  )

  /* Return the number of Shopify Stores in the current business */
  const getShopifyStoreCount: number = useMemo(() => {
    return shopifyStores.length
  }, [shopifyStores])

  /* Return the number of Stores in the current business */
  const getStoreCount: number = useMemo(() => {
    return storesList.length
  }, [storesList])

  /**
   * Return the list of Shopify Stores in the current business
   */
  const getShopifyStoreList = (): globalLib.BusinessStore[] => {
    return shopifyStores
  }

  const getStoreList = (): globalLib.BusinessStore[] => {
    return storesList.filter((store) => store.business_id === businessId)
  }

  /**
   * The function `getShopifyStoreDomainUrlByUid` retrieves the Shopify store
   * domain URL based on a unique identifier (uid).
   * @param {string} uid - The `uid` parameter is a unique identifier used to
   * retrieve information about a Shopify store.
   * @returns The function `getShopifyStoreDomainUrlByUid` returns the Shopify
   * store domain URL based on the provided `uid`.
   */
  const getShopifyStoreDomainUrlByUid = (uid: string): string | undefined => {
    const shopifyStore = getShopifyStoreById(uid)

    return shopifyStore?.provider_details?.shopify_shop_url
  }

  /**
   * The function `getSelectedShopifyStoreDomainUrl` returns the URL for editing
   * the current theme of a selected Shopify store.
   * @returns The function `getSelectedShopifyStoreDomainUrl` is returning a string
   * that represents the URL for the Shopify store's theme editor. The URL is
   * constructed using the Shopify store's UID obtained from
   * `getShopifyStoreByUid(selectedShopifyStoreId)` function.
   */
  const getSelectedShopifyStoreDomainUrl = useMemo((): string | undefined => {
    const shopifyStore = getShopifyStoreById(selectedStoreId)

    return `https://${shopifyStore?.uid}/admin/themes/current/editor`
  }, [getShopifyStoreById, selectedStoreId])

  /**
   * The function `getSelectedShopifyShopUrl` retrieves the Shopify shop URL based
   * on the selected Shopify store ID.
   * @returns The function `getSelectedShopifyShopUrl` is returning the Shopify
   * shop URL from the `provider_details` of the Shopify store associated with the
   * `selectedShopifyStoreId`. If the `shopifyStore` or the `provider_details` or
   * the `shopify_shop_url` is undefined, it will return `undefined`.
   */
  const getSelectedShopifyShopUrl = useMemo((): string | undefined => {
    const shopifyStore = getShopifyStoreById(selectedStoreId)

    return shopifyStore?.provider_details?.shopify_shop_url
  }, [getShopifyStoreById, selectedStoreId])

  /* Return Store data by store id from currently cached stores for the current business */
  const getStoreById = useCallback(
    (id: string): globalLib.BusinessStore | undefined => {
      const store = storesList.find((store) => store.id === id)

      return store
    },
    [storesList]
  )

  /** Return store name by store id */
  const getStoreNameById = useCallback(
    (id: string): string | undefined => {
      const store = getStoreById(id)

      return store?.name
    },
    [getStoreById]
  )

  return {
    selectedStoreId,
    getShopifyStoreCount,
    getStoreCount,
    getShopifyStoreList,
    getStoreList,
    getShopifyStoreById,
    getShopifyStoreDomainUrlByUid,
    getSelectedShopifyStoreDomainUrl,
    getSelectedShopifyShopUrl,
    getStoreById,
    storeProductList,
    getStoreNameById
  }
}
