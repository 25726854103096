import { createAction, createSlice } from '@reduxjs/toolkit'
import { removeUndefined } from '@src/common/utils/sanitize'
import { Dispatch } from 'redux'

import api from '../../../common/utils/api'

type IProps = {
  nextPage: Record<string, number> | null
  textResources: { [key: string]: AvaTextResource[] }
}

const initialState: IProps = {
  nextPage: null,
  textResources: {}
}

const slice = createSlice({
  name: 'avaTextResource',
  initialState: initialState,
  reducers: {
    fetchAvaTextResourcesSuccess(state, action) {
      const { entries, nextPage, page, domainAssistantId } = action.payload
      state.textResources[domainAssistantId] = (page
        ? state.textResources[domainAssistantId]
        : []
      ).concat(entries)
      state.nextPage = nextPage
    },
    fetchAvaTextResourceSuccess(state, action) {
      const { textResource, domainAssistantId } = action.payload
      const resources = state.textResources[domainAssistantId]
      if (resources) {
        const index = resources.findIndex((item) => item.id === textResource.id)
        if (index !== -1) {
          state.textResources[domainAssistantId][index] = textResource
        }
      }
    },
    createAvaTextResourceSuccess(state, action) {
      const { textResource, domainAssistantId } = action.payload
      state.textResources[domainAssistantId] = textResource.concat(
        state.textResources[domainAssistantId]
      )
    },
    updateAvaTextResourceSuccess(state, action) {
      const { textResource, domainAssistantId } = action.payload
      const resources = state.textResources[domainAssistantId]
      if (resources) {
        const index = resources.findIndex((item) => item.id === textResource.id)
        if (index !== -1) {
          state.textResources[domainAssistantId][index] = textResource
        }
      }
    },
    deleteAvaTextResourceSuccess(state, action) {
      const { textResourceId, domainAssistantId } = action.payload
      const resources = state.textResources[domainAssistantId]
      if (resources) {
        const index = resources.findIndex((item) => item.id === textResourceId)
        if (index !== -1) {
          state.textResources[domainAssistantId] = state.textResources[
            domainAssistantId
          ].slice(index, index + 1)
        }
      }
    }
  }
})

export default slice.reducer

export const {
  fetchAvaTextResourcesSuccess,
  fetchAvaTextResourceSuccess,
  createAvaTextResourceSuccess,
  updateAvaTextResourceSuccess,
  deleteAvaTextResourceSuccess
} = slice.actions

const fetchAvaTextResourcesRequest = createAction(
  'avaTextResource/fetchAvaTextResourcesRequest'
)
const fetchAvaTextResourcesFailure = createAction(
  'avaTextResource/fetchAvaTextResourcesFailure'
)

export function fetchAvaTextResources(
  businessId: string,
  domainAssistantId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  page?: Record<string, number>,
  pageSize = 10
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchAvaTextResourcesRequest())
      const response = await api.get(
        `/api/bus/${businessId}/domain_assistants/${domainAssistantId}/text_resources`,
        {
          params: { ...page, page_size: pageSize }
        }
      )
      const { entries, next_page } = response.data
      dispatch(
        fetchAvaTextResourcesSuccess({
          entries,
          page,
          nextPage: next_page,
          domainAssistantId
        })
      )

      return response
    } catch (error) {
      dispatch(fetchAvaTextResourcesFailure())

      return error
    }
  }
}

const fetchAvaTextResourceRequest = createAction(
  'avaTextResource/fetchAvaTextResourceRequest'
)
const fetchAvaTextResourceFailure = createAction(
  'avaTextResource/fetchAvaTextResourceFailure'
)

export function fetchAvaTextResource(
  businessId: string,
  domainAssistantId: string,
  textResourceId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(fetchAvaTextResourceRequest())
      const response = await api.get(
        `/api/bus/${businessId}/domain_assistants/${domainAssistantId}/text_resources/${textResourceId}`
      )
      dispatch(
        fetchAvaTextResourceSuccess({
          textResource: response.data,
          domainAssistantId
        })
      )

      return response
    } catch (error) {
      dispatch(fetchAvaTextResourceFailure())

      return error
    }
  }
}

const createAvaTextResourceRequest = createAction(
  'avaTextResource/createAvaTextResourceRequest'
)
const createAvaTextResourceFailure = createAction(
  'avaTextResource/createAvaTextResourceFailure'
)

type Status = 'active' | 'inactive' | 'pending'
type ResourceType = 'description' | 'question_and_answer' | 'oto_conversation'

type AvaTextResourceRequest = {
  // Used for ava integration into one to one functionality
  conversationId?: string
  fileKey?: string
  fileType?: string
  resourceType: ResourceType
  status: Status
  text?: string
  title?: string
  videoId?: string
}

export function createAvaTextResource(
  businessId: string,
  domainAssistantId: string,
  data: AvaTextResourceRequest
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(createAvaTextResourceRequest())
      const response = await api.post(
        `/api/bus/${businessId}/domain_assistants/${domainAssistantId}/text_resources`,
        {
          ...sanitizeTextResourceParams(data)
        }
      )
      dispatch(
        createAvaTextResourceSuccess({
          textResource: response.data,
          domainAssistantId
        })
      )

      return response
    } catch (error) {
      dispatch(createAvaTextResourceFailure())

      return error
    }
  }
}

const updateAvaTextResourceRequest = createAction(
  'avaTextResource/updateAvaTextResourceRequest'
)
const updateAvaTextResourceFailure = createAction(
  'avaTextResource/updateAvaTextResourceFailure'
)

export function updateAvaTextResource(
  businessId: string,
  domainAssistantId: string,
  textResourceId: string,
  data: AvaTextResourceRequest
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(updateAvaTextResourceRequest())
      const response = await api.put(
        `/api/bus/${businessId}/domain_assistants/${domainAssistantId}/text_resources/${textResourceId}`,
        {
          ...sanitizeTextResourceParams(data)
        }
      )
      dispatch(
        updateAvaTextResourceSuccess({
          textResource: response.data
        })
      )

      return response
    } catch (error) {
      dispatch(updateAvaTextResourceFailure())

      return error
    }
  }
}

const deleteAvaTextResourceRequest = createAction(
  'avaTextResource/deleteAvaTextResourceRequest'
)
const deleteAvaTextResourceFailure = createAction(
  'avaTextResource/deleteAvaTextResourceFailure'
)

export function deleteAvaTextResource(
  businessId: string,
  domainAssistantId: string,
  textResourceId: string
) {
  return async (dispatch: Dispatch): Promise<string | any> => {
    try {
      dispatch(deleteAvaTextResourceRequest())
      const response = await api.delete(
        `/api/bus/${businessId}/domain_assistants/${domainAssistantId}/text_resources/${textResourceId}`
      )
      dispatch(
        deleteAvaTextResourceSuccess({
          textResourceId,
          domainAssistantId
        })
      )

      return response
    } catch (error) {
      dispatch(deleteAvaTextResourceFailure())

      return error
    }
  }
}

const sanitizeTextResourceParams = (data: AvaTextResourceRequest) => {
  const sanitized = {
    conversation_id: data.conversationId,
    file_key: data.fileKey,
    file_type: data.fileType,
    resource_type: data.resourceType,
    status: data.status,
    text: data.text,
    title: data.title,
    video_id: data.videoId
  }

  return removeUndefined(sanitized)
}
