import React, { useState } from 'react'

import {
  DownOutlined,
  QuestionCircleOutlined,
  UpOutlined
} from '@ant-design/icons'
import { css } from '@emotion/react'
import {
  Box,
  Flex,
  Styles,
  Label,
  FWButton,
  ColorDisplay,
  LoadingIndicator
} from '@src/common/components/BaseComponent'
import {
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  TimePicker,
  Tooltip,
  Typography
} from 'antd'
import { TooltipProps } from 'antd/es'
import { SizeType } from 'antd/es/config-provider/SizeContext'
import { Moment } from 'moment'
import {
  Control,
  Controller,
  FieldError,
  FieldValues,
  Message,
  Path,
  PathValue,
  RegisterOptions,
  ValidationRule
} from 'react-hook-form'

export const ERROR_HEIGHT = 20
export const MAX_SPAN = 350

interface InputCtrlProps<T> extends Styles {
  control: Control<T>
  error?: any
  label?: string | JSX.Element | React.ReactNode
  hideLabel?: boolean
  name: Path<T>
  tooltip?: string
  placeholder?: string
  onChange?: (e: any) => void
  onBlur?: (e: any) => void
  onKeyDown?: (e: any) => void
  autoComplete?: 'off' | 'on'
  required?: boolean
  requiredMessage?: Message | ValidationRule<boolean>
  disabled?: boolean
  inputRef?: React.MutableRefObject<any>
  size?: SizeType
  maxLength?: number
  type?: string
  boldLabel?: boolean
  hideError?: boolean
  rules?: RegisterOptions
  selectedValue?: string
  prohibitedInput?: (e: any) => boolean
}

export function InputCtrl<T extends FieldValues>({
  name,
  label,
  hideLabel,
  autoComplete = 'on',
  placeholder,
  tooltip,
  control,
  error,
  required,
  requiredMessage,
  disabled,
  onChange,
  onBlur,
  onKeyDown,
  inputRef,
  size = 'large',
  maxLength,
  type = 'text',
  boldLabel = false,
  maxWidth = MAX_SPAN,
  hideError,
  rules,
  prohibitedInput,
  ...rest
}: InputCtrlProps<T>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: requiredMessage || true, ...rules }}
      render={({ field, fieldState }) => (
        <Box maxWidth={maxWidth} {...rest}>
          {label && (
            <LabelCtrl
              label={label}
              tooltip={tooltip}
              required={required}
              htmlFor={`InputCtrl-${name}`}
              display={hideLabel ? 'none' : 'block'}
              bold={boldLabel}
            />
          )}
          <Input
            {...field}
            value={field.value as string | number | readonly string[]}
            type={type}
            size={size}
            id={`InputCtrl-${name}`}
            placeholder={placeholder}
            autoComplete={autoComplete}
            disabled={disabled}
            onKeyDown={onKeyDown}
            maxLength={maxLength}
            status={!hideError && (error || fieldState.error) ? 'error' : ''}
            onBlur={(e) => {
              field.onBlur()
              onBlur && onBlur(e)
            }}
            onChange={(e) => {
              if (!prohibitedInput || !prohibitedInput(e)) {
                field.onChange(e)
                onChange && onChange(e)
              }
            }}
            ref={(e) => {
              field.ref(e)
              if (inputRef?.current) inputRef.current = e
            }}
          />
          {!hideError && error && (
            <ErrorMessageCtrl error={error || fieldState.error} />
          )}
        </Box>
      )}
    />
  )
}

export function CheckboxCtrl<T extends FieldValues>({
  name,
  control,
  error,
  tooltip,
  label,
  disabled,
  ...rest
}: InputCtrlProps<T>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <Box maxWidth={MAX_SPAN} {...rest}>
            <Checkbox
              {...field}
              checked={field.value as boolean}
              disabled={disabled}
            >
              <LabelCtrl label={label} tooltip={tooltip} />
            </Checkbox>
            <ErrorMessageCtrl error={error || fieldState.error} />
          </Box>
        )
      }}
    />
  )
}

interface SelectCtrlProps<T> extends InputCtrlProps<T> {
  options: any[] | readonly any[]
  defaultValue?: string
  allowClear?: boolean
  onClear?: () => void
  labelGap?: string
  optionKeys?: {
    value: string
    label: string
    description?: string
  } | null
  disabled?: boolean
  filterOption?: (
    inputValue: string,
    option?: { value: string; key: string; children: string | React.ReactNode }
  ) => boolean
  hideError?: boolean
  optionType?: 'default' | 'button'
}

export function SelectCtrl<T extends FieldValues>({
  name,
  placeholder,
  label,
  options = [],
  defaultValue,
  tooltip,
  optionKeys: keys,
  required,
  control,
  error,
  allowClear,
  hideLabel,
  onClear,
  size = 'large',
  disabled = false,
  filterOption,
  hideError,
  onChange,
  ...rest
}: SelectCtrlProps<T>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field, fieldState }) => {
        return (
          <Box maxWidth={MAX_SPAN} {...rest}>
            <LabelCtrl
              label={label}
              tooltip={tooltip}
              hideLabel={hideLabel}
              required={required}
              htmlFor={`SelectCtrl-${name}`}
            />
            <Select
              {...field}
              id={`SelectCtrl-${name}`}
              style={{ width: '100%' }}
              status={!hideError && (error || fieldState.error) ? 'error' : ''}
              placeholder={placeholder}
              defaultValue={defaultValue as PathValue<T, Path<T>>}
              allowClear={allowClear}
              onClear={onClear}
              size={size}
              disabled={disabled}
              filterOption={filterOption}
              showSearch={!!filterOption}
              virtual={false}
              onChange={(e) => {
                field.onChange(e)
                onChange?.(e)
              }}
            >
              {options.map((op) => {
                const isObj = typeof op !== 'string'
                const value = keys ? op[keys.value] : isObj ? op.value : op
                const label = keys ? op[keys.label] : isObj ? op.label : op

                return (
                  <Select.Option value={value} key={op.id || label}>
                    {label}
                  </Select.Option>
                )
              })}
            </Select>
            {!hideError && (error || fieldState.error) && (
              <ErrorMessageCtrl error={error || fieldState.error} />
            )}
          </Box>
        )
      }}
    />
  )
}

interface TextAreaCtrlProps<T> extends Styles {
  control: Control<T>
  error?: any
  label?: string
  name: Path<T>
  tooltip?: string
  placeholder?: string
  textRows?: number
  required?: boolean
  disabled?: boolean
  maxLength?: number
  onChange?: (e: any) => void
  size?: SizeType
  showCount?: boolean
}

export function TextAreaCtrl<T>({
  textRows = 3,
  placeholder,
  control,
  error,
  tooltip,
  label,
  required = false,
  disabled = false,
  onChange,
  name,
  maxLength,
  size = 'large',
  showCount = true,
  ...rest
}: TextAreaCtrlProps<T>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field, fieldState }) => {
        return (
          <Box maxWidth={MAX_SPAN} {...rest}>
            {label && (
              <LabelCtrl
                label={label}
                tooltip={tooltip}
                required={required}
                htmlFor={`TextAreaCtrl-${name}`}
              />
            )}
            <Input.TextArea
              id={`TextAreaCtrl-${name}`}
              autoSize={{ minRows: textRows, maxRows: textRows }}
              size={size}
              placeholder={placeholder}
              disabled={disabled}
              maxLength={maxLength}
              {...field}
              value={field.value as string | ReadonlyArray<string> | number}
              onChange={(e) => {
                field.onChange(e)
                onChange && onChange(e)
              }}
              showCount={showCount}
            />
            {error || fieldState.error ? (
              <ErrorMessageCtrl error={error || fieldState.error} />
            ) : null}
          </Box>
        )
      }}
    />
  )
}

interface InputNumberCtrlProps<T = any> extends Styles, InputCtrlProps<T> {
  control: Control<T>
  error?: FieldError
  label?: string
  name: Path<T>
  tooltip?: string
  placeholder?: string
  textRows?: number
  required?: boolean
  min?: number
  max?: number
  step?: number
  addonAfter?: any
  disabled?: boolean
  size?: SizeType
  defaultValue?: number
  style?: Record<string, any>
  showControls?: boolean
}

export const InputNumberCtrl: React.FC<InputNumberCtrlProps> = ({
  name,
  min = 0,
  max = 100,
  step = 1,
  tooltip,
  control,
  error,
  placeholder,
  addonAfter = null,
  required = false,
  disabled,
  label,
  size = 'large',
  defaultValue = 0,
  style,
  ...rest
}) => (
  <Controller
    name={name}
    control={control}
    rules={{ required }}
    render={({ field, fieldState }) => {
      return (
        <Box maxWidth={MAX_SPAN} {...rest}>
          {label && (
            <LabelCtrl
              label={label}
              tooltip={tooltip}
              required={required}
              htmlFor={`InputNumberCtrl-${name}`}
            />
          )}
          <InputNumber
            id={`InputNumberCtrl-${name}`}
            min={min}
            max={max}
            size={size}
            addonAfter={addonAfter}
            placeholder={placeholder}
            disabled={disabled}
            step={step}
            defaultValue={defaultValue}
            style={style}
            {...field}
          />
          {(!!error || !!fieldState.error) && (
            <ErrorMessageCtrl error={error || fieldState.error} />
          )}
        </Box>
      )
    }}
  />
)

export function RadioCtrl<T extends FieldValues>({
  name,
  label,
  hideLabel,
  options,
  control,
  required,
  error,
  boldLabel = false,
  defaultValue,
  tooltip,
  optionKeys: keys,
  maxWidth,
  mt,
  labelGap,
  disabled,
  selectedValue,
  optionType = 'default',
  size = 'middle',
  ...rest
}: SelectCtrlProps<T>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Flex
          maxWidth={maxWidth || MAX_SPAN}
          mt={mt}
          gap={labelGap}
          flexDirection="column"
        >
          <LabelCtrl
            label={label}
            hideLabel={hideLabel}
            bold={boldLabel}
            tooltip={tooltip}
            required={required}
            htmlFor={`RadioCtrl-${name}`}
          />
          <Radio.Group
            disabled={disabled}
            id={`RadioCtrl-${name}`}
            {...field}
            value={selectedValue ?? field.value}
            optionType={optionType}
            buttonStyle="solid"
            size={size}
          >
            <Flex {...rest}>
              {options.map((op) => {
                const isObj = typeof op !== 'string'
                const value = keys ? op[keys.value] : isObj ? op.value : op
                const label = keys ? op[keys.label] : isObj ? op.label : op
                const description = keys
                  ? op[keys.description]
                  : isObj
                  ? op.description
                  : op

                return (
                  <Radio
                    name={name}
                    value={value}
                    key={op.id || value}
                    style={{
                      marginBottom: description && !disabled ? '8px' : 'auto'
                    }}
                  >
                    {label}
                    {description && !disabled ? (
                      <>
                        <br />
                        <Typography.Text
                          css={css`
                            color: #6b6e73;
                            margin-bottom: 8px;
                          `}
                        >
                          {description}
                        </Typography.Text>
                      </>
                    ) : null}
                  </Radio>
                )
              })}
            </Flex>
          </Radio.Group>
          {error ? <ErrorMessageCtrl error={error} /> : null}
        </Flex>
      )}
    />
  )
}

interface FormColorPickerProps extends Styles {
  name: string
  label: string
  control: Control<any>
}

export const ColorPickerCtrl: React.FC<FormColorPickerProps> = ({
  name,
  label,
  control,
  ...rest
}) => {
  return control ? (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box maxWidth={MAX_SPAN} {...rest}>
          <LabelCtrl label={label} htmlFor={`ColorPickerCtrl-${name}`} />
          <ColorDisplay
            id={`ColorPickerCtrl-${name}`}
            selectedColor={field.value}
            setSelectedColor={field.onChange}
            pickerSize={30}
            hasPicker
            top="10px"
            size="middle"
            width="auto"
            placeholder="Enter a HEX color"
          />
          <Box height={ERROR_HEIGHT} />
        </Box>
      )}
    />
  ) : null
}

type IconButtonProps = {
  onClick: (e: any) => void
  icon: JSX.Element
  cssString?: string
  iconSize?: string
  disabled?: boolean
  tooltipProps?: TooltipProps
}

export const IconButton: React.FC<IconButtonProps> = ({
  icon,
  onClick,
  iconSize = '20px',
  cssString = '',
  disabled,
  tooltipProps
}) => (
  <FWButton
    type="link"
    disabled={disabled}
    tooltipProps={tooltipProps}
    icon={icon}
    onClick={onClick}
    css={css`
      padding: 0;
      svg {
        height: ${iconSize};
        width: ${iconSize};
      }
      ${cssString}
    `}
  />
)

interface DateInputCtrlProps<T> extends InputCtrlProps<T> {
  value?: Moment | null
  onChangeDate: (date: Moment) => void
  disabledDate?: (current: Moment) => boolean
  format?: string
  allowClear?: boolean
  showTime?:
    | {
        format: string
        use12Hours: boolean
      }
    | boolean
}

export function DateInputCtrl<T extends FieldValues>({
  name,
  label,
  hideLabel,
  placeholder,
  tooltip,
  control,
  error,
  required,
  requiredMessage,
  disabled,
  onChangeDate,
  onKeyDown,
  inputRef,
  boldLabel = false,
  value = null,
  disabledDate,
  format,
  showTime = false,
  size = 'large',
  allowClear = true,
  ...rest
}: DateInputCtrlProps<T>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: requiredMessage || true }}
      render={({ field, fieldState }) => (
        <Box maxWidth={MAX_SPAN} {...rest}>
          <LabelCtrl
            label={label}
            tooltip={tooltip}
            required={required}
            htmlFor={`DateCtrl-${name}`}
            display={hideLabel ? 'none' : 'block'}
            bold={boldLabel}
          />
          <DatePicker
            {...field}
            showToday={false}
            id={`DateCtrl-${name}`}
            placeholder={placeholder}
            value={value ?? (field.value as Moment)}
            disabled={disabled}
            disabledDate={disabledDate}
            size={size}
            onKeyDown={onKeyDown}
            showTime={showTime}
            format={format}
            showNow={false}
            allowClear={allowClear}
            status={error || fieldState.error ? 'error' : ''}
            onChange={(value) => {
              field.onChange(value)
              onChangeDate && onChangeDate(value)
            }}
            ref={(e) => {
              field.ref(e)
              if (inputRef?.current) inputRef.current = e
            }}
            css={css`
              width: 100%;
            `}
          />
          {(error || fieldState.error) &&
            Object.keys(error || fieldState.error).length > 0 && (
              <ErrorMessageCtrl error={error || fieldState.error} />
            )}
        </Box>
      )}
    />
  )
}

interface TimeInputCtrlProps<T> extends InputCtrlProps<T> {
  value?: Moment | null
  onChangeTime: (date: Moment) => void
  allowClear?: boolean
  disabledTime?: (
    current: Moment
  ) => {
    disabledHours: () => number[]
    disabledMinutes: (currentHour?: any) => number[]
  }
  format?: string
}

export function TimeInputCtrl<T extends FieldValues>({
  name,
  label,
  hideLabel,
  placeholder,
  tooltip,
  control,
  error,
  required,
  requiredMessage,
  disabled,
  onChangeTime,
  onKeyDown,
  inputRef,
  boldLabel = false,
  value = null,
  disabledTime,
  format,
  size = 'large',
  allowClear = true,
  ...rest
}: TimeInputCtrlProps<T>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: requiredMessage || true }}
      render={({ field, fieldState }) => (
        <Box maxWidth={MAX_SPAN} {...rest}>
          <LabelCtrl
            label={label}
            tooltip={tooltip}
            required={required}
            htmlFor={`TimeCtrl-${name}`}
            display={hideLabel ? 'none' : 'block'}
            bold={boldLabel}
          />
          <TimePicker
            {...field}
            id={`TimeCtrl-${name}`}
            placeholder={placeholder}
            value={value ?? (field.value as Moment)}
            disabled={disabled}
            disabledTime={disabledTime}
            size={size}
            onKeyDown={onKeyDown}
            format={format}
            allowClear={allowClear}
            showNow={false}
            status={error || fieldState.error ? 'error' : ''}
            onChange={(value) => {
              field.onChange(value)
              onChangeTime && onChangeTime(value)
            }}
            ref={(e) => {
              field.ref(e)
              if (inputRef?.current) inputRef.current = e
            }}
            css={css`
              width: 100%;
            `}
          />
          {(error || fieldState.error) &&
            Object.keys(error || fieldState.error).length > 0 && (
              <ErrorMessageCtrl error={error || fieldState.error} />
            )}
        </Box>
      )}
    />
  )
}

interface SwitchCtrlProps<T> extends Omit<InputCtrlProps<T>, 'size'> {
  flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  gap?: string | number
  alignItems?: string
  justifyContent?: string
  checked?: boolean
  checkedChildren?: React.ReactNode
  unCheckedChildren?: React.ReactNode
  size?: 'small' | 'default'
}

export function SwitchCtrl<T extends FieldValues>({
  name,
  control,
  error,
  label,
  hideLabel,
  tooltip,
  required,
  flexDirection = 'column',
  gap = '16px',
  alignItems,
  justifyContent,
  checked,
  onChange,
  disabled,
  checkedChildren = null,
  unCheckedChildren = null,
  size = 'default',
  ...rest
}: SwitchCtrlProps<T>): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <Flex
            flexDirection={flexDirection}
            gap={gap}
            justifyContent={justifyContent}
            alignItems={alignItems}
            maxWidth={MAX_SPAN}
            {...rest}
          >
            {label && (
              <LabelCtrl
                label={label}
                tooltip={tooltip}
                hideLabel={hideLabel}
                required={required}
                htmlFor={`SwitchCtrl-${name}`}
              />
            )}
            <Box>
              <Switch
                {...field}
                checked={checked ?? (field.value as boolean)}
                onChange={(e) => {
                  field.onChange(e)
                  onChange && onChange(e)
                }}
                id={`SwitchCtrl-${name}`}
                disabled={disabled}
                checkedChildren={checkedChildren}
                unCheckedChildren={unCheckedChildren}
                size={size}
              />
            </Box>
            {error || fieldState.error ? (
              <ErrorMessageCtrl error={error || fieldState.error} />
            ) : null}
          </Flex>
        )
      }}
    />
  )
}

/** Helpers */

interface LabelCtrlProps extends Styles {
  htmlFor?: string
  label: string | JSX.Element | React.ReactNode
  tooltip?: string
  required?: boolean
  hideLabel?: boolean
  bold?: boolean
}

export const LabelCtrl: React.FC<LabelCtrlProps> = ({
  label,
  tooltip,
  required,
  hideLabel,
  htmlFor = '',
  bold = false,
  ...rest
}) => (
  <>
    {!hideLabel && (
      <Box as="span" {...rest}>
        <Label
          bold={bold}
          htmlFor={htmlFor}
          required={required}
          aria-label={label}
        >
          {label}
          {tooltip && <FormTooltip title={tooltip} />}
        </Label>
      </Box>
    )}
  </>
)

export const FormTooltip = ({ title }: { title: string }): JSX.Element => (
  <Tooltip placement="bottom" trigger={['hover', 'click']} title={title}>
    <QuestionCircleOutlined style={{ marginLeft: '4px' }} />
  </Tooltip>
)

export const ErrorMessageCtrl = ({
  error = {}
}: {
  error: any
}): JSX.Element => {
  return (
    <Box height={ERROR_HEIGHT} color="red">
      {error?.message && error.message}
    </Box>
  )
}
interface PageLoaderProps extends Styles {
  isLoading: boolean
}
export const PageLoader: React.FC<PageLoaderProps> = ({
  isLoading,
  ...rest
}) => {
  return isLoading ? (
    <Flex
      position="fixed"
      background="radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 100%);"
      top={0}
      left={0}
      height="100%"
      width="100%"
      justifyContent="center"
      alignItems="center"
      zIndex={5}
      {...rest}
    >
      <LoadingIndicator />
    </Flex>
  ) : null
}

interface FieldsetCollapseProps extends Styles {
  legend: string | JSX.Element | React.ReactNode
  contentTemplate?: string
  startOpen?: boolean
  isExpandable?: boolean
}

export const FieldsetCollapse: React.FC<FieldsetCollapseProps> = ({
  startOpen = false,
  legend,
  children,
  contentTemplate = '1fr',
  fontSize = 14,
  isExpandable = true,
  ...styles
}) => {
  const [isExpanded, setExpanded] = useState(isExpandable ? startOpen : true)
  const toggleExpand = () => {
    if (!isExpandable) {
      return
    }
    setExpanded(!isExpanded)
  }

  return (
    <Box as="fieldset" gridColumn="1 / -1" position="relative" width="100%">
      <FWButton
        type="link"
        onClick={toggleExpand}
        style={{
          width: '100%',
          padding: 0,
          height: '40px'
        }}
      >
        <Flex
          as="legend"
          borderBottom="1px solid #e9e9e9"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          cursor="pointer"
          userSelect="none"
          color="#404040"
          width="100%"
          fontSize={fontSize}
          height="100%"
          pr="small"
          m={0}
          {...styles}
        >
          <span>{legend}</span>
          {isExpandable ? (
            !isExpanded ? (
              <DownOutlined />
            ) : (
              <UpOutlined />
            )
          ) : null}
        </Flex>
      </FWButton>

      {isExpanded && (
        <Box
          marginTop="large"
          pb="medium"
          gridTemplateColumns={contentTemplate}
          display="grid"
          width="100%"
          gap="medium"
        >
          {children}
        </Box>
      )}
    </Box>
  )
}
interface AnchorProps extends Styles {
  href: string
}

export const Anchor: React.FC<AnchorProps> = ({
  href,
  children,
  ...styles
}) => {
  return (
    <Box {...styles} display="inline-block">
      <a
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        style={{ color: 'inherit' }}
      >
        {children}
      </a>
    </Box>
  )
}
