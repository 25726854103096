import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '@src/common/utils/api'
import { UploadFile } from 'antd'
import noop from 'lodash/noop'

export type Response = {
  next_page: { before_id: number } | null
  entries: AIKnowledgeBase.Resource[]
}

interface AiKnowledgeBaseState {
  channels: Record<string, Response>
}

const initialState: AiKnowledgeBaseState = {
  channels: {}
}

const SLICE_NAME = 'aiKnowledgeBase'

type BaseParams = { channelId: string; businessId: string }

export const fetchKnowledgeBaseResources = createAsyncThunk(
  `${SLICE_NAME}/fetchKnowledgeBaseResources`,
  async ({
    businessId,
    channelId,
    beforeId,
    title
  }: BaseParams & { beforeId?: number; title?: string }) => {
    const { data } = await api.get(
      `/bus/${businessId}/channels/${channelId}/text_resources`,
      {
        params: {
          before_id: beforeId,
          title
        }
      }
    )

    return data as Response
  }
)

export const fetchKnowledgeBaseResource = createAsyncThunk(
  `${SLICE_NAME}/fetchKnowledgeBaseResource`,
  async (params: BaseParams & { id: string }) => {
    const { data } = await api.get(
      `/bus/${params.businessId}/channels/${params.channelId}/text_resources/${params.id}`
    )

    return data.channel_text_resource as AIKnowledgeBase.Resource
  }
)

export const deleteKnowledgeBaseResource = createAsyncThunk(
  `${SLICE_NAME}/deleteKnowledgeBaseResource`,
  async (params: BaseParams & { id: string }) => {
    await api
      .delete(
        `/bus/${params.businessId}/channels/${params.channelId}/text_resources/${params.id}`
      )
      .catch(noop)
  }
)

export const createTextResource = createAsyncThunk(
  `${SLICE_NAME}/createTextResource`,
  async (
    params: BaseParams & {
      text: string
      title: string
      resourceType?: 'text' | 'url'
      url?: string
    }
  ) => {
    const { data } = await api.post(
      `/bus/${params.businessId}/channels/${params.channelId}/text_resources`,
      {
        text: params.text,
        title: params.title,
        resource_type: params.resourceType || 'text',
        url: params.resourceType === 'url' ? params.url : undefined
      }
    )

    return data.channel_text_resource as AIKnowledgeBase.Resource
  }
)

export const createFileResource = createAsyncThunk(
  `${SLICE_NAME}/createFileResource`,
  async (
    params: BaseParams & { file: UploadFile; title: string; file_key: string }
  ) => {
    const { data } = await api.post(
      `/bus/${params.businessId}/channels/${params.channelId}/text_resources`,
      {
        resource_type: 'pdf',
        file: { mime_type: params.file.type, filename: params.file.name },
        bytesize: params.file.size,
        title: params.title,
        file_key: params.file_key
      }
    )

    return data as {
      channel_text_resource: AIKnowledgeBase.Resource
      signature: { put_url: string }
    }
  }
)

export const markFileResourceUpdated = createAsyncThunk(
  `${SLICE_NAME}/markFileResourceUpdated`,
  async (params: BaseParams & { file: UploadFile; id: string }) => {
    const { data } = await api.patch(
      `/bus/${params.businessId}/channels/${params.channelId}/text_resources/${params.id}/upload_completed`
    )

    return data as {
      channel_text_resource: AIKnowledgeBase.Resource
    }
  }
)

export const updateTextResource = createAsyncThunk(
  `${SLICE_NAME}/updateTextResource`,
  async (params: BaseParams & { text: string; id: string; title: string }) => {
    const {
      data
    } = await api.patch(
      `/bus/${params.businessId}/channels/${params.channelId}/text_resources/${params.id}`,
      { text: params.text, resource_type: 'text', title: params.title }
    )

    return data.channel_text_resource as AIKnowledgeBase.Resource
  }
)

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchKnowledgeBaseResources.fulfilled,
      (state, { payload, meta }) => {
        const existing = state.channels[meta.arg.channelId]
        if (meta.arg.beforeId && existing) {
          // Append entries for pagination
          existing.entries = [...existing.entries, ...payload.entries]
          existing.next_page = payload.next_page
        } else {
          // Initial load
          state.channels[meta.arg.channelId] = payload
        }
      }
    )
    builder.addCase(
      fetchKnowledgeBaseResource.fulfilled,
      (state, { payload, meta }) => {
        // replace the existing resource
        const channel = state.channels[meta.arg.channelId]
        if (channel) {
          channel.entries = channel.entries.map((r) =>
            r.id === payload.id ? payload : r
          )
        }
      }
    )
    builder.addCase(
      deleteKnowledgeBaseResource.fulfilled,
      (state, { meta }) => {
        const channel = state.channels[meta.arg.channelId]
        const entries = channel?.entries ?? []
        const idx = entries.findIndex(({ id }) => id === meta.arg.id)
        if (idx !== -1) {
          delete entries[idx]
          channel.entries = entries.filter(Boolean)
        }
      }
    )
    builder.addCase(
      createTextResource.fulfilled,
      (state, { payload, meta }) => {
        // Strangely, the server is returning a time in the future for the inserted_at value
        const inserted_at = new Date(
          new Date(payload.inserted_at).getTime() - 1000
        ).toUTCString()

        const updated_at = new Date(
          new Date(payload.updated_at).getTime() - 1000
        ).toUTCString()

        state.channels[meta.arg.channelId].entries.unshift({
          ...payload,
          inserted_at,
          updated_at
        })
      }
    )

    builder.addCase(
      createFileResource.fulfilled,
      (state, { payload, meta }) => {
        state.channels[meta.arg.channelId].entries.unshift(
          payload.channel_text_resource
        )
      }
    )

    builder.addCase(
      updateTextResource.fulfilled,
      (state, { payload, meta }) => {
        const entries = state.channels[meta.arg.channelId].entries
        const idx = entries.findIndex(({ id }) => id === meta.arg.id)
        if (idx !== -1) {
          entries[idx] = payload
        }
      }
    )

    builder.addCase(
      markFileResourceUpdated.fulfilled,
      (state, { meta, payload }) => {
        const entries = state.channels[meta.arg.channelId].entries
        const idx = entries.findIndex(({ id }) => meta.arg.id === id)
        entries[idx] = payload.channel_text_resource
      }
    )
  }
})

export default slice.reducer
