import React, { useContext } from 'react'

import { useAppSelector } from '@src/app/hooks'
import { BusinessContext } from '@src/common/contexts/BusinessContext'
import { isPLGBusiness } from '@src/common/redux/selectors/subscription'

type PLGWrapperProps = {
  ifPLGBusiness?: React.ReactNode
  elseNot?: React.ReactNode
}

export const PLGWrapper: React.FC<PLGWrapperProps> = ({
  ifPLGBusiness = null,
  elseNot = null
}) => {
  const businessId = useContext(BusinessContext)

  const plgBusiness = useAppSelector((state) =>
    isPLGBusiness(state, businessId)
  )

  return <div>{plgBusiness ? ifPLGBusiness : elseNot}</div>
}
