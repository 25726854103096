import React from 'react'

import { css } from '@emotion/core'
import theme from '@src/styles/theme'
import { Box } from 'fwego'
import { NavLink } from 'react-router-dom'

import { SideBarItemText } from './SideBarItemText'

const externalLinkProps = { target: '_blank', rel: 'noopener' }

export type LinkRowProps = {
  to?: string
  href?: string
  icon?: React.ReactNode
  text?: string
  mt?: number
  submenu?: boolean
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  iconWidth?: number
  rightIcon?: React.ReactNode
  leftIcon?: React.ReactNode
  tourStep?: string
  className?: string
  showTooltip?: boolean
  backgroundColor?: string
}

const LinkRow: React.FC<LinkRowProps> = ({
  to,
  href,
  icon,
  text,
  mt,
  iconWidth = 18,
  onClick,
  tourStep,
  className = 'cy-link-row',
  backgroundColor = undefined,
  showTooltip = false
}) => {
  const isExternal = !!href
  const Component = !to ? Box : isExternal ? 'a' : NavLink
  const rowProps = isExternal ? externalLinkProps : null

  return (
    <Component
      className={className}
      to={to}
      href={href}
      onClick={onClick}
      {...rowProps}
      data-tour={tourStep}
      css={css`
        color: ${theme.sidebarLinkRow};
        height: 40px;
        cursor: pointer;
        width: 100%;
        margin-top: ${mt ?? 16}px;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        flex-grow: 0;
        text-decoration: none;
        padding-left: ${(40 - iconWidth) / 2}px;
        background-color: ${backgroundColor ?? 'transparent'};
        border-radius: 8px;
        border: none;
        img {
          background-color: #bfdaff;
        }
        &:hover {
          background-color: ${backgroundColor ?? theme.sidebarHover};
        }
        &.active img {
          background-color: ${backgroundColor ?? theme.sidebarActiveIconColor};
        }
        &.active span {
          color: white !important;
        }
        &.active {
          background-color: ${backgroundColor ?? theme.sidebarActive};
          color: #fff;
        }
        &.submenu {
          padding-left: 48px;
        }
        > .anticon {
          margin-right: 8px;
        }
      `}
    >
      {icon}
      <SideBarItemText text={text} show={showTooltip} />
    </Component>
  )
}

export default LinkRow
