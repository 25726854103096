import { useAppSelector } from '@src/app/hooks'

export const useEmbedConfigs = (): {
  data: YourSite.EmbedConfig[]
  loading: boolean
  businessStoreIdByBusinessId: {
    [businessId: string]: string
  }
} => {
  const { loading, data } = useAppSelector(
    (state) => state.yourSite.embedConfigs
  )

  const businessStoreIdByBusinessId = useAppSelector(
    (state) => state.yourSite.selectedStoreIdByBusinessId
  )

  return {
    loading,
    data,
    businessStoreIdByBusinessId
  }
}
