import React, { useState } from 'react'

import { css } from '@emotion/core'
import { Flex, Box } from '@src/common/components/BaseComponent'
import useWindowSize from '@src/common/hooks/useWindowSize'
import { Modal, ModalProps } from 'antd'

interface Props extends ModalProps {
  stickyHeader?: boolean
  stickyFooter?: boolean
}

const FullScreenModal: React.FC<Props> = ({
  stickyHeader = false,
  stickyFooter = false,
  children,
  ...props
}): JSX.Element => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  useWindowSize((windowSize) => setWindowHeight(windowSize.innerHeight))

  return (
    <Modal
      {...props}
      closeIcon={
        <Flex
          p="small"
          justifyContent="center"
          alignItems="center"
          className="close-full-modal"
          height="100%"
        >
          <Box as="img" src="/close_black.svg" alt="close" />
        </Flex>
      }
      open
      centered
      closable
      maskClosable={false}
      width="100%"
      css={css`
        min-height: ${windowHeight}px;
        width: 100vw;
        max-width: 100vw;
        top: 0;
        padding: 0;
        margin: 0;
        border-radius: 0;
        .ant-modal-title {
          font-size: 18px;
          font-weight: 700;
        }
        .ant-modal-body {
          padding-top: 0;
        }
        .ant-modal-header {
          border-radius: 0 !important;
        }
        .ant-modal-content {
          border-radius: 0 !important;
          height: 100%;
          min-height: ${windowHeight}px;
        }
        ${stickyHeader
          ? `.close-full-modal {
            position: fixed;
            top: 15px;
            right: 10px;
            height: 20px;
          }
          .ant-modal-header {
            position: fixed;
            top: 0;
            width: 100%;
            background: #ffffff;
            z-index: 5;
          }
          .ant-modal-body {
            margin: 50px 0px;
            padding: 24px;
          }`
          : ''}
        ${stickyFooter
          ? `
          .ant-modal-content {
            overflow: scroll;
            height: 150vh;
          }
          .ant-modal-footer {
            position: fixed;
            bottom: 0;
            width: 100%;
            background: #ffffff;
          }
          `
          : ''}
      `}
    >
      {children}
    </Modal>
  )
}

export default FullScreenModal
