import React, { useCallback, useEffect, useState } from 'react'

import {
  ExclamationCircleOutlined,
  InfoCircleOutlined
} from '@ant-design/icons'
import { css } from '@emotion/core'
import { useAppSelector } from '@src/app/hooks'
import {
  TAB_CAROUSEL,
  TAB_DYNAMIC_CAROUSEL,
  TAB_FLOATING_BUTTON,
  TAB_FLOATING_VIDEO,
  TAB_GRID,
  TAB_HERO_UNIT,
  TAB_HORIZONTAL_CAROUSEL,
  TAB_HORIZONTAL_PLAYER,
  TAB_PLAYER_DECK,
  TAB_STORY_BLOCK
} from '@src/modules/channel/constants'
import {
  getOfflineEmbedCode,
  setEmbedType
} from '@src/modules/channel/redux/DynamicContent/embed'
import buildEmbedCode from '@src/modules/channel/utils/buildEmbedCode'
import theme from '@src/styles/theme'
import {
  Tooltip as AntTooltip,
  Checkbox,
  Divider,
  InputNumber,
  Radio,
  Typography
} from 'antd'
import { Box, Flex, Text } from 'fwego'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

//import EditSettings from '@src/pages/channel/ChannelPage/Appearance/EditSettings'

const INFINITE = 'infinite'
const LOAD = 'load'
const BOTTOM_RIGHT = 'bottom-right'
const BOTTOM_LEFT = 'bottom-left'
const TOP_RIGHT = 'top-right'
const TOP_LEFT = 'top-left'
const NEW_TAB = '_blank'
const CURRENT_TAB = '_self'
const MODAL_WINDOW = '_iframe'

const convertEmbedType = {
  [TAB_STORY_BLOCK]: 'story_block',
  [TAB_HERO_UNIT]: 'hero_unit',
  [TAB_GRID]: 'grid',
  [TAB_CAROUSEL]: 'carousel',
  [TAB_FLOATING_BUTTON]: 'floating_button',
  [TAB_FLOATING_VIDEO]: 'floating_video',
  [TAB_HORIZONTAL_PLAYER]: 'horizontal_player',
  [TAB_PLAYER_DECK]: 'player_deck',
  [TAB_HORIZONTAL_CAROUSEL]: 'horizontal_carousel',
  [TAB_DYNAMIC_CAROUSEL]: 'dynamic_carousel'
}

type IProps = {
  onChange: (value: any) => void
}

type LayoutOptions2Props = {
  tab: string
  optionsIndex?: any[]
  dcsParams?: any[]
  embedContext?: string
  playlist_id?: string
}

const CarouselOptions: React.FC<IProps> = ({ onChange }) => {
  const [playerPlacement, setPlayerPlacement] = useState(BOTTOM_RIGHT)
  const { t } = useTranslation()
  const setting = { player_placement: playerPlacement }
  const placementOptions = [
    {
      label: t('Bottom Right'),
      value: BOTTOM_RIGHT
    },
    {
      label: t('Bottom Left'),
      value: BOTTOM_LEFT
    },
    {
      label: t('Top Right'),
      value: TOP_RIGHT
    },
    {
      label: t('Top Left'),
      value: TOP_LEFT
    }
  ]
  const handleSetOptions = (e) => {
    const value = e.target.value
    setting.player_placement = value
    onChange(setting)
    setPlayerPlacement(value)
  }

  return (
    <>
      <Flex>
        <Text
          fontSize="small"
          fontWeight="normal"
          my="medium"
          color={theme.text}
        >
          {t('Minimized video player location')}
        </Text>
        <Box ml="xsmall" my="medium">
          <AntTooltip
            title={
              <Text color="white" size="14">
                {t(
                  'This is where your video player will relocate to when your users minimize it on their screen'
                )}
              </Text>
            }
            placement="topRight"
            arrowPointAtCenter
          >
            <InfoCircleOutlined />
          </AntTooltip>
        </Box>
      </Flex>
      <Radio.Group
        value={playerPlacement}
        options={placementOptions}
        onChange={handleSetOptions}
      />
    </>
  )
}

const GridOptions: React.FC<IProps> = ({ onChange }) => {
  const [options, setOptions] = useState(INFINITE)
  const [videosShow, setVideosShow] = useState(4)
  const { t } = useTranslation()
  const setting = { max_videos: 0 }
  const gridOptions = [
    {
      label: t('Infinite'),
      value: INFINITE
    },
    {
      label: t('Load'),
      value: LOAD
    }
  ]
  const handleSetOptions = (e) => {
    const value = e.target.value
    if (value === LOAD) setting.max_videos = videosShow
    else setting.max_videos = 0
    onChange(setting)
    setOptions(value)
  }
  const handleChangeNumber = (value) => {
    setting.max_videos = value
    onChange(setting)
    setVideosShow(value)
  }

  return (
    <>
      <Text fontSize="small" fontWeight="normal" my="medium" color={theme.text}>
        {t('Video shown in grid')}
      </Text>
      <Radio.Group
        value={options}
        options={gridOptions}
        onChange={handleSetOptions}
      />
      <InputNumber
        size="small"
        value={options === LOAD ? videosShow : null}
        step={1}
        min={1}
        max={1000}
        css={css`
          width: 45px;
          margin-right: 8px;
        `}
        onChange={handleChangeNumber}
      />
      <Text
        display="inline"
        fontSize="small"
        fontWeight="normal"
        color={theme.text}
      >
        {t('videos at a time')}
      </Text>
      <CarouselOptions onChange={onChange} />
    </>
  )
}

const StoryBlockOptions: React.FC<IProps> = ({ onChange }) => {
  const [checked, setChecked] = useState(true)
  const { t } = useTranslation()
  const handleChange = () => {
    onChange({ loop: !checked })
    setChecked(!checked)
  }

  return (
    <Checkbox checked={checked} onChange={handleChange}>
      {t('Loop from the start')}
    </Checkbox>
  )
}

const HeroUnitOptions: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Flex alignItems="center" pt="medium">
      <ExclamationCircleOutlined
        css={css`
          margin-right: 10px;
          color: ${theme.orange};
        `}
      />
      <Typography.Text
        css={css`
          color: ${theme.gray19};
          font-weight: 500;
        `}
      >
        {t(
          'Hero unit works only with livestream playlists. Please make sure your playlist contains a livestream.'
        )}
      </Typography.Text>
    </Flex>
  )
}

const FloatingButtonOptions: React.FC<IProps> = ({ onChange }) => {
  const [placement, setPlacement] = useState(TOP_RIGHT)
  const [open, setOpen] = useState(MODAL_WINDOW)
  const { t } = useTranslation()
  const placementOptions = [
    { label: t('Top Right'), value: TOP_RIGHT },
    { label: t('Bottom Left'), value: BOTTOM_LEFT },
    { label: t('Bottom Right'), value: BOTTOM_RIGHT },
    { label: t('Top Left'), value: TOP_LEFT }
  ]
  const openOptions = [
    { label: t('New tab'), value: NEW_TAB },
    { label: t('Current tab'), value: CURRENT_TAB },
    { label: t('Modal window'), value: MODAL_WINDOW }
  ]

  return (
    <>
      <Text fontSize="small" fontWeight="normal" color={theme.text}>
        {t('Placement:')}
      </Text>
      <Radio.Group
        options={placementOptions}
        value={placement}
        onChange={(e) => {
          const placement = e.target.value
          setPlacement(placement)
          onChange({ placement })
        }}
      />
      <Text fontSize="small" fontWeight="normal" color={theme.text} mt="xlarge">
        {t('Open in:')}
      </Text>
      <Radio.Group
        options={openOptions}
        value={open}
        onChange={(e) => {
          const openIn = e.target.value
          setOpen(openIn)
          onChange({ open_in: openIn })
        }}
      />
      <Box mt="xlarge">
        <Text
          display="inline"
          fontSize="small"
          fontWeight="normal"
          color={theme.text}
        >
          {t('Vertical offset:')}
        </Text>
        <Box display="inline" ml="xsmall">
          <InputNumber
            size="small"
            step={1}
            min={1}
            max={1000}
            css={css`
              width: 45px;
            `}
            onChange={(e) => onChange({ vertical: e })}
          />
        </Box>
      </Box>
      <Box mt="small">
        <Text
          display="inline"
          fontSize="small"
          fontWeight="normal"
          color={theme.text}
        >
          {t('Horizontal offset:')}
        </Text>
        <Box display="inline" ml="xsmall">
          <InputNumber
            size="small"
            step={1}
            min={1}
            max={1000}
            css={css`
              width: 50px;
            `}
            onChange={(e) => onChange({ horizontal: e })}
          />
        </Box>
      </Box>
    </>
  )
}

const LayoutOptions2: React.FC<LayoutOptions2Props> = ({
  tab,
  optionsIndex,
  dcsParams,
  embedContext,
  playlist_id
}) => {
  const { channelId } = useParams()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const channel = useAppSelector(
    (state) => state.channel.channels[channelId] || {}
  ) as any
  const channelName = channel?.username || ''

  const { context, id } = useAppSelector(
    (state) => state.channel.connectWordPress
  )

  const defaultSetting = {
    type: TAB_CAROUSEL,
    // use Carousel as default
    setting: {
      max_videos: 0,
      loop: true,
      open_in: 'default',
      placement: 'middle',
      player_placement: BOTTOM_RIGHT,
      vertical: null,
      horizontal: null,
      channel: channelName,
      context: embedContext || context,
      id: playlist_id || id,
      category:
        dcsParams && dcsParams.includes('category')
          ? '{{category}}'
          : undefined,
      country_code:
        dcsParams && dcsParams.includes('country_code')
          ? '{{country_code}}'
          : undefined,
      customer_segment:
        dcsParams && dcsParams.includes('customer_segment')
          ? '{{customer_segment}}'
          : undefined
    }
  }

  const options = [
    {
      title: t('Story Block'),
      imgSrc: '/storyblock.png',
      animatedImgSrc: '/storyblock.gif',
      key: TAB_STORY_BLOCK
    },
    {
      title: t('Floating Player'),
      imgSrc: '/floatingvideo_embed.png',
      animatedImgSrc: '/floatingvideo_embed.gif',
      key: TAB_FLOATING_VIDEO
    },
    {
      title: t('Grid'),
      imgSrc: '/grid.png',
      animatedImgSrc: '/grid.gif',
      key: TAB_GRID
    },
    {
      title: t('Carousel'),
      imgSrc: '/carousel.png',
      animatedImgSrc: '/carousel.gif',
      key: TAB_CAROUSEL
    },
    {
      title: t('Floating Button'),
      imgSrc: '/floatingbutton.png',
      animatedImgSrc: '/floatingbutton.gif',
      key: TAB_FLOATING_BUTTON
    },
    {
      title: t('Hero Unit'),
      imgSrc: '/hero_unit.png',
      animatedImgSrc: '/hero_unit.gif',
      key: TAB_HERO_UNIT
    },
    {
      title: t('Player Deck'),
      imgSrc: '/player_deck.png',
      animatedImgSrc: '/player_deck.gif',
      key: TAB_PLAYER_DECK
    },
    {
      title: t('Horizontal Player'),
      imgSrc: '/horizontal_player.png',
      animatedImgSrc: '/horizontal_player.gif',
      key: TAB_HORIZONTAL_PLAYER
    },
    {
      title: t('Horizontal Carousel'),
      imgSrc: '/horizontal_carousel.png',
      animatedImgSrc: '/horizontal_carousel.gif',
      key: TAB_HORIZONTAL_CAROUSEL
    },
    {
      title: t('Dynamic Carousel'),
      imgSrc: '/dynamic_carousel.png',
      animatedImgSrc: '/dynamic_carousel.gif',
      key: TAB_DYNAMIC_CAROUSEL
    }
  ]

  const [animateIndex, setAnimateIndex] = useState(null)
  const [currentOption, setCurrentOption] = useState(
    options[optionsIndex[0]].key
  )
  const [settings, setSettings] = useState(defaultSetting)

  useEffect(() => {
    setSettings((prev) => ({
      ...prev,
      setting: {
        ...prev.setting,
        category:
          dcsParams && dcsParams.includes('category')
            ? '{{category}}'
            : undefined,
        country_code:
          dcsParams && dcsParams.includes('country_code')
            ? '{{country_code}}'
            : undefined,
        customer_segment:
          dcsParams && dcsParams.includes('customer_segment')
            ? '{{customer_segment}}'
            : undefined
      }
    }))
  }, [dcsParams])

  const layoutOptions = optionsIndex
    ? options.filter((_, index) => optionsIndex.includes(index))
    : options

  useEffect(() => {
    if (currentOption !== settings.type) {
      handleChangeType(currentOption)
    } else {
      const embedCode = buildEmbedCode(settings)
      dispatch(getOfflineEmbedCode(tab, embedCode))
    }
    // TODO: CMS-300
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, settings, tab, currentOption, channel])

  const handleChangeSettings = useCallback(
    (setting) => {
      const newSetting = {
        ...settings,
        setting: { ...settings.setting, ...setting }
      }
      setSettings(newSetting)
    },
    [settings]
  )

  useEffect(() => {
    dispatch(setEmbedType(convertEmbedType[currentOption]))
  }, [dispatch, currentOption])

  const handleChangeType = (type) => {
    let newSetting = { ...settings, type }
    setCurrentOption(type)
    switch (type) {
      case TAB_CAROUSEL:
      case TAB_GRID:
      case TAB_HERO_UNIT:
      case TAB_STORY_BLOCK:
      case TAB_FLOATING_VIDEO:
      case TAB_PLAYER_DECK:
      case TAB_HORIZONTAL_PLAYER:
      case TAB_HORIZONTAL_CAROUSEL:
      case TAB_DYNAMIC_CAROUSEL: {
        newSetting = { ...newSetting, setting: defaultSetting.setting }
        break
      }
      default:
        newSetting = {
          ...newSetting,
          setting: {
            ...defaultSetting.setting,
            open_in: MODAL_WINDOW,
            placement: TOP_RIGHT,
            vertical: null,
            horizontal: null
          }
        }
    }
    setSettings(newSetting)
  }

  return (
    <Flex flexDirection="column" gap="10" width="100%">
      <Typography.Text strong color={theme.text}>
        {t('Layout Selection')}
      </Typography.Text>
      <Typography.Text
        css={css`
          font-size: 14px;
        `}
        color={theme.text}
      >
        {t(
          'Please choose how you would like your videos to be displayed by picking one of the layout options below.'
        )}
      </Typography.Text>
      <Flex
        gap="16px"
        flexFlow="row wrap"
        justifyContent="flex-start"
        mt="medium"
      >
        {layoutOptions.map((option, index) => (
          <Flex
            p="xxsmall"
            key={index}
            flexDirection="column"
            width="250px"
            cursor="pointer"
            onClick={() => handleChangeType(option.key)}
            mt={['12', '0']}
          >
            <Box>
              <Typography.Text
                strong
                css={css`
                  color: ${currentOption === option.key && theme.primary};
                  font-size: 16px;
                `}
              >
                {option.title}
              </Typography.Text>
            </Box>
            <Box
              mt="10"
              bg="#EAEAEA"
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignContent="center"
              border={
                currentOption === option.key && `solid 2px ${theme.primary}`
              }
              py={currentOption === option.key ? 11 : 10}
              onMouseEnter={() => setAnimateIndex(index)}
              onMouseLeave={() => setAnimateIndex(null)}
              width="100%"
            >
              <Box
                as="img"
                src={
                  animateIndex === index ? option.animatedImgSrc : option.imgSrc
                }
                maxWidth="100%"
                maxHeight="100%"
                objectFit="contain"
              />
            </Box>
          </Flex>
        ))}
      </Flex>
      {currentOption === TAB_CAROUSEL && (
        <CarouselOptions onChange={handleChangeSettings} />
      )}
      {currentOption === TAB_GRID && (
        <GridOptions onChange={handleChangeSettings} />
      )}
      {currentOption === TAB_STORY_BLOCK &&
        !_.isEqual(optionsIndex, [0, 1]) && (
          <StoryBlockOptions onChange={handleChangeSettings} />
        )}
      {currentOption === TAB_FLOATING_BUTTON && (
        <FloatingButtonOptions onChange={handleChangeSettings} />
      )}
      {currentOption === TAB_FLOATING_VIDEO &&
        !_.isEqual(optionsIndex, [0, 1]) && (
          <StoryBlockOptions onChange={handleChangeSettings} />
        )}
      {currentOption === TAB_HERO_UNIT && <HeroUnitOptions />}
      <Divider style={{ width: '100%' }} />
    </Flex>
  )
}

export default LayoutOptions2
