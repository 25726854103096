import React from 'react'

import { css } from '@emotion/core'
import { FWButton, FWModal } from '@src/common/components/BaseComponent'
import { isMdScreen } from '@src/common/hooks/useMediaQuery'
import { Flex, Text } from 'fwego'
import { useTranslation } from 'react-i18next'

type Props = {
  showDiscardModal: boolean
  setShowDiscardModal: React.Dispatch<React.SetStateAction<boolean>>
  onClose: () => void
}

export const DiscardChangeModal = ({
  showDiscardModal,
  setShowDiscardModal,
  onClose
}: Props): JSX.Element => {
  const isMidScreen = isMdScreen()
  const { t } = useTranslation()

  return (
    <FWModal
      open={showDiscardModal}
      footer={null}
      width={500}
      onCancel={() => setShowDiscardModal(false)}
      centered={isMidScreen}
      css={css`
        .ant-modal-content {
          @media (max-width: 500px) {
            width: 100vw;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1002;
          }
        }
      `}
    >
      <Text fontSize="18">{t('Discard Changes?')}</Text>
      <Text fontSize="14" color="gray3" mt="small">
        {t(
          "Are you sure you wish to discard the changes you've made? You haven’t uploaded your video to our Business Portal and will lose your progress."
        )}
      </Text>
      <Flex justifyContent="flex-end" mt="large" gap="16">
        <FWButton
          size="large"
          onClick={() => setShowDiscardModal(false)}
          type="primary"
          css={css`
            width: 165px;
          `}
        >
          {t('Return to Editing')}
        </FWButton>
        <FWButton
          htmlType="submit"
          size="large"
          css={css`
            width: 165px;
          `}
          onClick={() => {
            setShowDiscardModal(false)
            onClose()
          }}
        >
          {t('Discard Changes')}
        </FWButton>
      </Flex>
    </FWModal>
  )
}
