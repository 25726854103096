import React from 'react'

import { Box } from '@src/common/components/BaseComponent'

import theme from '../../../styles/theme'

type IProps = {
  children: React.ReactNode
}

const FormLabel: React.FC<IProps> = ({ children }) => {
  return (
    <Box color={theme.text} fontSize="medium">
      {children}
    </Box>
  )
}

export default FormLabel
