import * as amplitude from '@amplitude/analytics-browser'
import { sessionStorageProvider } from '@src/common/utils/storageProvider'

import { getOS, getBrowser, getLocale } from './tracking'

export const setAmplitudeUser = (userId: string): void => {
  amplitude.setUserId(userId)
}

export const setAmplitudeDevice = (): void => {
  const deviceId = sessionStorageProvider.getItem('amplitude_id')
  if (deviceId) {
    amplitude.setDeviceId(deviceId)
    sessionStorageProvider.removeItem('amplitude_id')
  }
}

export default function sendAmplitudeTrackingEvent({
  event,
  props = {}
}: {
  event: string
  props?: Record<string, unknown>
}): void {
  const eventProperties = {
    ...props,
    browser: getBrowser(),
    language: getLocale(),
    os_name: getOS(),
    platform: 'web'
  }

  amplitude.track(event, eventProperties)
}
