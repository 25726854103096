import React, { ReactNode, useEffect, useState } from 'react'

import { PaperClipOutlined, UploadOutlined } from '@ant-design/icons'
import { css } from '@emotion/core'
import { Flex, FWButton } from '@src/common/components/BaseComponent'
import { useToast } from '@src/common/hooks/useToast'
import { languageRegions } from '@src/common/utils/languages'
import { Checkbox, Typography, Upload } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useTranslation } from 'react-i18next'
import { MdDelete } from 'react-icons/md'

import { uploadSubtitle } from '../../redux/video'
import { LanguageRegionSelect } from './LanguageRegionSelect/LanguageRegionSelect'

interface Props {
  subtitles: globalLib.Subtitle[]
  setSubtitles: (subtitles: globalLib.Subtitle[]) => void
  info?: ReactNode
}

const FormSubtitle: React.FC<Props> = ({
  subtitles,
  setSubtitles,
  info
}): JSX.Element => {
  const { t } = useTranslation()
  const { Text } = Typography
  const { errorToast } = useToast()

  const [language, setLanguage] = useState(null)
  const [isCC, setIsCC] = useState(false)

  useEffect(() => {
    setLanguage(null)
  }, [subtitles])

  const accept = ['.vtt']
  const uploadSubtitleProps = {
    name: 'file',
    accept: accept.join(','),
    beforeUpload: (file) => {
      const fileExtension = `.${file.name.split('.').pop().toLowerCase()}`
      if (!accept.includes(fileExtension)) {
        errorToast(
          t('{{file}} file is in the wrong format', {
            file: file.name
          })
        )

        return false
      }

      return true
    },
    customRequest: async (info) => {
      await uploadSubtitle(info.file, info.onProgress, info.onSuccess)
    },
    itemRender: () => null,
    maxCount: 1,
    async onChange({ file }) {
      const { status, response } = file
      if (status === 'error') {
        errorToast(t('{{file}} file upload failed', { file: file.name }))
      }
      if (response && response.key) {
        if (validateFile(file)) {
          setSubtitles([
            ...subtitles,
            {
              key: response.key,
              language,
              url: '',
              filename: file.name,
              is_cc: isCC
            }
          ])
          setIsCC(false)
        } else {
          errorToast(
            t('{{file}} file is in the wrong format', {
              file: file.name
            })
          )
        }
      }
    }
  }

  function validateFile(file) {
    const isVtt =
      file.type === 'text/vtt' || file.name.toLowerCase().endsWith('.vtt')
    if (!isVtt) {
      errorToast(t('You can only upload a .vtt file!'))
    }
    const isLt10M = file.size < 1000000
    if (!isLt10M) {
      errorToast(t('File must be smaller than 1MB!'))
    }

    return isVtt && isLt10M
  }

  function removeSubtitle(index) {
    const newSubtitles = [...subtitles]
    newSubtitles.splice(index, 1)
    setSubtitles(newSubtitles)
  }

  const onIsCCChange = (e: CheckboxChangeEvent) => {
    setIsCC(e.target.checked)
  }

  return (
    <Flex flexDirection="column" gap="10">
      {!subtitles.length ? info : null}
      <Flex flexDirection="column" gap="10">
        <Flex alignItems="center" gap={10}>
          <PaperClipOutlined
            css={css`
              font-size: 1.5em;
            `}
          />
          <LanguageRegionSelect
            value={language}
            onChange={(value) => setLanguage(value)}
          />

          <Checkbox checked={isCC} onChange={onIsCCChange}>
            {t('Closed Captioning')}
          </Checkbox>
        </Flex>
        {!!language && (
          <Upload {...uploadSubtitleProps}>
            <FWButton icon={<UploadOutlined />}>
              {t('Click to Upload')}
            </FWButton>
          </Upload>
        )}
      </Flex>
      {subtitles.map((s, i) => (
        <div
          key={i}
          css={css`
            display: grid;
            grid-template-columns: 30px 2fr 1fr 50px 45px;
            justify-items: start;
            align-items: center;
            gap: 5px;
            max-width: 390px;
          `}
        >
          <PaperClipOutlined
            css={css`
              font-size: 1.5em;
              margin-right: 10px;
            `}
          />
          <Text ellipsis>
            {languageRegions.find((l) => l.key === s.language)?.value ||
              s.language}
          </Text>
          {s.id ? (
            <FWButton
              type="link"
              href={s.url ?? s.key}
              download={s.filename}
              target="_blank"
            >
              {s.key.split('/').pop()}
            </FWButton>
          ) : (
            <Text ellipsis>{s.filename}</Text>
          )}
          <Checkbox checked={s.is_cc} disabled>
            CC
          </Checkbox>
          <MdDelete
            css={css`
              margin-left: 20px;
              font-size: 1.5em;
              cursor: pointer;
            `}
            onClick={(i) => removeSubtitle(i)}
          />
        </div>
      ))}
    </Flex>
  )
}

export default FormSubtitle
