import React from 'react'

import { Banner } from '@src/common/components/Banner/Banner'
import { FWButton } from '@src/common/components/BaseComponent'
import { usePixelAmpTracking } from '@src/common/hooks'
import { useCustomNavigate } from '@src/common/hooks/useCustomNavigate'
import { TRACKING_EVENTS } from '@src/common/utils/tracking'
import { useTranslation } from 'react-i18next'

interface UploadVideoBannerProps {
  businessId: string
  channelId: string
}

export const FireworkUploadVideoBanner: React.FC<UploadVideoBannerProps> = ({
  businessId,
  channelId
}) => {
  const { t } = useTranslation()
  const { pixelAmpTracking } = usePixelAmpTracking()
  const { navigateToHome } = useCustomNavigate()

  const clickUploadVideo = () => {
    pixelAmpTracking(
      TRACKING_EVENTS.BUSINESS_ACTIVATION_STATUS_EVENTS.CLICK_UPLOAD_VIDEO
    )
    navigateToHome({ businessId, channelId })
  }

  return (
    <>
      <Banner
        title={t('Upload Videos to Your Library')}
        action={
          <FWButton type="primary" onClick={clickUploadVideo}>
            {t('Upload Videos')}
          </FWButton>
        }
      />
    </>
  )
}
