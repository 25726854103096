import React, { useEffect, useRef } from 'react'

import styled from '@emotion/styled'
import { useAppSelector } from '@src/app/hooks'
import { usePixelAmpTracking } from '@src/common/hooks'
import { setDefaultTrackingData } from '@src/common/redux/business'
import { fetchMeteredUsage } from '@src/common/redux/subscriptions'
import { TRACKING_EVENTS } from '@src/common/utils/tracking'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

const Container = styled.div`
  width: 100%;
  overflow: auto;
  flex: 1;
`

const PageContainer: React.FC<{
  children: React.ReactNode
  businessId: string
}> = ({ children, businessId }) => {
  const dispatch = useDispatch()
  const { businesses: businessIds } = useAppSelector((state) => state.business)
  const user = useAppSelector((state) => state.profile.user)
  const subscription = useAppSelector(
    (state) => state.subscriptions.subscriptionsByBusinessId[businessId]
  )
  const paymentPlanId = subscription?.payment_plan_id
  const { pathname } = useLocation()
  const channelId =
    pathname.indexOf('channel') > 0 ? pathname.split('/')[4] : null
  const userId = user.encoded_id

  const loginTracked = useRef(false)
  const { pixelAmpTracking, pixelGuestTracking } = usePixelAmpTracking()
  const subscriptionBasePlan = subscription?.subscription_base_plan?.name

  useEffect(() => {
    if (businessId) {
      dispatch(fetchMeteredUsage(businessId))
    }
    // TODO: CMS-300
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId])

  useEffect(() => {
    if (businessId) {
      const businessType = businessIds[businessId]?.type
      dispatch(
        setDefaultTrackingData({
          _business_id: businessId,
          business_type: businessType
        })
      )
    }
  }, [businessId, businessIds, dispatch])

  useEffect(() => {
    if (channelId) {
      dispatch(
        setDefaultTrackingData({
          _channel_id: channelId
        })
      )
    }
  }, [channelId, dispatch])

  useEffect(() => {
    if (businessId && paymentPlanId) {
      dispatch(
        setDefaultTrackingData({
          subscription_plan: paymentPlanId
        })
      )
    }
  }, [businessId, paymentPlanId, dispatch])

  useEffect(() => {
    if (userId && paymentPlanId && !loginTracked.current) {
      pixelAmpTracking(TRACKING_EVENTS.BUSINESS_CONFIRM_LOGIN, {
        login_source: 'default',
        _business_id: businessId,
        subscription_plan: paymentPlanId,
        subscription_base_plan: subscriptionBasePlan,
        business_type: businessIds[businessId]?.type
      })
      loginTracked.current = true
    }
    // This is intended: we only want to track the first time the user logs in regardless pixelAmpTracking changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, paymentPlanId, subscriptionBasePlan])

  useEffect(() => {
    const script = document.createElement('script')
    script.async = true
    script.type = 'text/javascript'
    if (
      process.env.REACT_APP_ENV === 'production' ||
      process.env.REACT_APP_VERCEL_ENV === 'production'
    ) {
      script.src = 'https://asset.fwbiz1.com/js/analytics.js'
    } else {
      script.src = 'https://asset-staging.fireworktv.com/js/analytics.js'
    }
    script.onload = () => {
      const guestId = window._fwn?.analytics?.guest_id
      pixelGuestTracking(TRACKING_EVENTS.BUSINESS_TRACK_GUEST, {
        guest_id: guestId,
        visitor_id: guestId
      })
    }
    document.head.appendChild(script)
  }, [pixelGuestTracking])

  return <Container>{children}</Container>
}

export default PageContainer
