import React, { useCallback, useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@src/app/hooks'
import { Flex } from '@src/common/components/BaseComponent'
import { FWButton, Title } from '@src/common/components/BaseComponent'
import { useStoreData } from '@src/common/hooks/useStoreData'
import { shopify_fetchScopeValidation } from '@src/modules/channel/redux/YourSite/shopify'
import { useTranslation } from 'react-i18next'

import { Container } from '../Common.styles'

interface IProps {
  businessId: string
}

const FW_APP_NAME = {
  staging: 'firework-app-staging',
  production: 'firework'
}

export const ShopifyAPIScopeBanner: React.FC<IProps> = ({ businessId }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { getSelectedShopifyShopUrl: shopifyStoreUrl } = useStoreData({
    businessId
  })

  const { shopifyAPIScopeValidation } = useAppSelector(
    (state) => state.yourSite
  )

  const checkShopifyAPIScope = useCallback(async () => {
    if (shopifyStoreUrl) {
      dispatch(shopify_fetchScopeValidation({ storeUrl: shopifyStoreUrl }))
    }
  }, [shopifyStoreUrl, dispatch])

  useEffect(() => {
    checkShopifyAPIScope()
  }, [checkShopifyAPIScope])

  const grantAccess = () => {
    const appName =
      FW_APP_NAME[process.env.REACT_APP_ENV as 'staging' | 'production']
    const accessUrl = `https://${shopifyStoreUrl}/admin/apps/${appName}`
    window.open(accessUrl, '_blank')
  }

  if (
    shopifyAPIScopeValidation.status === 'success' ||
    shopifyAPIScopeValidation.loading
  ) {
    return null
  }

  return (
    <Container
      padding="16px 24px"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {shopifyAPIScopeValidation.status === 'missing_required_scopes' && (
        <Flex
          justifyContent="space-between"
          alignContent="flex-start"
          width="100%"
        >
          <Flex flexDirection="column" flex="1">
            <Title weight={600} fontSize={20} noMargin>
              {t(
                'Some required Shopify permissions are missing. Update permissions to enable all features'
              )}
            </Title>
          </Flex>
          <FWButton type="primary" onClick={grantAccess}>
            {t('Grant Access')}
          </FWButton>
        </Flex>
      )}
    </Container>
  )
}
