import React from 'react'

import { css } from '@emotion/core'

import { useSpace } from '../../hooks'

type IProps = {
  children?: React.ReactNode
  space?: string
  className?: string
}

const Stack = ({
  children,
  space = 'medium',
  className = '',
  ...props
}: IProps): JSX.Element => {
  const spacing = useSpace(space)

  return (
    <div
      {...props}
      css={css`
        > *:not(:last-child) {
          margin-bottom: ${spacing};
        }
        ${className}
      `}
    >
      {children}
    </div>
  )
}

export default Stack
