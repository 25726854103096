/** @fileoverview
 *
 *
 * Activation Status step
 *
 * Upload Video
 * Create and add video to playlist
 * Add playlist to site
 */

import React, { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@src/app/hooks'
import { Flex } from '@src/common/components/BaseComponent'
import { usePixelAmpTracking } from '@src/common/hooks'
import { useCustomNavigate } from '@src/common/hooks/useCustomNavigate'
import usePlaylistsAndGroups from '@src/common/hooks/usePlaylistsAndGroups'
import { setCurrentStep } from '@src/common/redux/activationStatus'
import { TRACKING_EVENTS } from '@src/common/utils/tracking'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import { ActivationStepLink, StepList } from './ActivationSteps.styles'

export const ActivationSteps = React.memo(() => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { businessId, channelId } = useParams()
  const { pixelAmpTracking } = usePixelAmpTracking()
  const { firstPlaylistId } = usePlaylistsAndGroups({ businessId, channelId })
  const { navigateToHome } = useCustomNavigate()

  const { currentStep, uploadVideo, createPlaylist } = useAppSelector(
    (state) => state.activationStatus
  )
  const dispatch = useAppDispatch()

  const onStepClick = (step: number) => {
    switch (step) {
      case 0:
        pixelAmpTracking(
          TRACKING_EVENTS.BUSINESS_ACTIVATION_STATUS_EVENTS.CLICK_UPLOAD_VIDEO
        )
        navigateToHome({ businessId, channelId })
        break
      case 1:
        pixelAmpTracking(
          TRACKING_EVENTS.BUSINESS_ACTIVATION_STATUS_EVENTS
            .CLICK_CREATE_PLAYLIST_AND_ADD_VIDEO
        )
        if (firstPlaylistId)
          navigate(
            `/business/${businessId}/channel/${channelId}/videos?activeTab=playlist&activePlaylist=${firstPlaylistId}`
          )
        else
          navigate(
            `/business/${businessId}/channel/${channelId}/videos?activeTab=playlist_overview`
          )
        break
      case 2:
        pixelAmpTracking(
          TRACKING_EVENTS.BUSINESS_ACTIVATION_STATUS_EVENTS
            .CLICK_ADD_PLAYLIST_TO_SITE
        )
        if (firstPlaylistId)
          navigate(
            `/business/${businessId}/channel/${channelId}/videos?activeTab=playlist&activePlaylist=${firstPlaylistId}`
          )
        else
          navigate(
            `/business/${businessId}/channel/${channelId}/videos?activeTab=playlist_overview`
          )
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (createPlaylist === 'complete') {
      dispatch(setCurrentStep(2))
    } else if (uploadVideo === 'complete') {
      dispatch(setCurrentStep(1))
    } else {
      dispatch(setCurrentStep(0))
    }
  }, [createPlaylist, uploadVideo, dispatch])

  return (
    <StepList>
      <li style={{ color: currentStep === 0 ? '#3F4145' : '#B2B6BB' }}>
        <Flex flexDirection="column">
          <Flex gap="3px">
            <Trans t={t}>
              Upload Videos to the
              <ActivationStepLink onClick={() => onStepClick(0)}>
                Video Library
              </ActivationStepLink>
            </Trans>
          </Flex>
          <Flex style={{ fontSize: '12px' }}>
            <Trans t={t} style={{ width: '100%' }}>
              *Tag products to make them shoppable
            </Trans>
          </Flex>
        </Flex>
      </li>
      <li style={{ color: currentStep === 1 ? '#3F4145' : '#B2B6BB' }}>
        <Trans t={t}>
          Add Videos to the{' '}
          <ActivationStepLink onClick={() => onStepClick(1)}>
            Homepage Playlist
          </ActivationStepLink>
        </Trans>
      </li>
      <li style={{ color: currentStep === 2 ? '#3F4145' : '#B2B6BB' }}>
        <Trans t={t}>
          Click the &apos;Add to Site&apos; button in the{' '}
          <ActivationStepLink onClick={() => onStepClick(2)}>
            Homepage Playlist
          </ActivationStepLink>
        </Trans>
      </li>
    </StepList>
  )
})
ActivationSteps.displayName = 'ActivationSteps'
