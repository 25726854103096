export const TAB_STORY_BLOCK = 'TAB_STORY_BLOCK'
export const TAB_HERO_UNIT = 'TAB_HERO_UNIT'
export const TAB_CAROUSEL = 'TAB_CAROUSEL'
export const TAB_PLAYER_DECK = 'TAB_PLAYER_DECK'
export const TAB_FLOATING_BUTTON = 'TAB_FLOATING_BUTTON'
export const TAB_GRID = 'TAB_GRID'
export const TAB_FLOATING_VIDEO = 'TAB_FLOATING_VIDEO'
export const TAB_HORIZONTAL_PLAYER = 'TAB_HORIZONTAL_PLAYER'
export const TAB_HORIZONTAL_CAROUSEL = 'TAB_HORIZONTAL_CAROUSEL'
export const TAB_DYNAMIC_CAROUSEL = 'TAB_DYNAMIC_CAROUSEL'
export const SHOPIFY = 'SHOPIFY'
export const OTHER_HTML = 'OTHER_HTML'
export const SHOPIFY_PRODUCT_PAGES = 'SHOPIFY_PRODUCT_PAGES'
export const DCS = 'dcs'
export const PLAYLIST_COLLECTION = 'playlist_collection'
