import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router'

const SigmaLivestreamInsights = loadable(
  () =>
    import(
      '@src/modules/channel/components/Library/Livestream/Analytics/Analytics'
    )
)

const AssistantMode = loadable(
  () => import('../../pages/Library/Livestream/AssistantMode'),
  {
    resolveComponent: (component) => component.AssistantMode
  }
)
const TabletAssistantMode = loadable(
  () => import('../../pages/Library/Livestream/TabletAssistantMode'),
  {
    resolveComponent: (component) => component.TabletAssistantMode
  }
)
const ViewShowroomEvent = loadable(
  () => import('../../pages/Library/Livestream/ViewShowroomEvent')
)

export const LivestreamRoutes = (
  <>
    <Route
      path="/business/:businessId/channel/:channelId/video/:videoId/showroom/:livestreamId"
      element={<ViewShowroomEvent />}
    />
    <Route
      path="/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId/assistant"
      element={<AssistantMode />}
    />
    <Route
      path="/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId/host_dashboard"
      element={<TabletAssistantMode />}
    />
    <Route
      path="/app/business/:businessId/channel/:channelId/video/:videoId/livestream/:livestreamId/insights"
      element={<SigmaLivestreamInsights />}
    />
  </>
)
