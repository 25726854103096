import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '@src/common/utils/api'
import { getS3Signature, uploadToS3 } from '@src/common/utils/s3'
import _ from 'lodash'

const SLICE_NAME = 'video'

const initialState = {
  interactionResults: {}
}

export const fetchInteractionResults = createAsyncThunk(
  `${SLICE_NAME}/fetchInteractionResult`,
  async (params: { interactionId: string; page?: number }) => {
    const { interactionId, page } = params
    const res = await api.get(`/interactions/${interactionId}/list_responses`, {
      params: {
        page: page ?? undefined
      }
    })

    return {
      interactionId,
      responses: res.data.interaction_results
    }
  }
)

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchInteractionResults.fulfilled, (state, action) => {
      const { interactionId, responses } = action.payload

      let results = responses
      if (state.interactionResults[interactionId]) {
        results = _.uniqBy(
          state.interactionResults[interactionId].concat(responses),
          'id'
        )
      }

      state.interactionResults = {
        ...state.interactionResults,
        [interactionId]: results
      }
    })
  },
  reducers: {}
})

export default slice.reducer

export const uploadSubtitle = async (
  file: File,
  onProgress: (progress: { percent: number }) => void,
  onSuccess: (success: { key: string }) => void
): Promise<void> => {
  try {
    const signature = await getS3Signature(file)
    await uploadToS3(
      file,
      signature,
      (percent) => onProgress({ percent }),
      ({ key }) => {
        onSuccess({ key })
      },
      (err) => {
        throw err
      }
    )
  } catch (error) {
    return error
  }
}

export const createSubtitles = async (
  businessId: string,
  channelId: string,
  videoId: string,
  data: {
    video_subtitles: {
      key: string
      language: string
    }[]
  }
): Promise<void> => {
  try {
    await api.post(
      `bus/${businessId}/channels/${channelId}/videos/${videoId}/video_subtitles`,
      data
    )
  } catch (error) {
    return error
  }
}

export const deleteSubtitle = async (
  businessId: string,
  channelId: string,
  videoId: string,
  subtitleId: string
): Promise<void> => {
  try {
    await api.delete(
      `bus/${businessId}/channels/${channelId}/videos/${videoId}/video_subtitles/${subtitleId}`
    )
  } catch (error) {
    return error
  }
}

export const generateGIF = async (
  businessId: string,
  channelId: string,
  videoId: string,
  data: { start_offset: number }
): Promise<void> => {
  try {
    await api.post(
      `bus/${businessId}/channels/${channelId}/videos/${videoId}/generate_gif`,
      data
    )
  } catch (error) {
    return error
  }
}
