import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { InfoCircleOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from '@src/app/hooks'
import ActivationStatusContainer from '@src/common/components/ActivationStatus'
import {
  Flex,
  FWButton,
  LabelCtrl,
  Title
} from '@src/common/components/BaseComponent'
import { useBusinessData } from '@src/common/hooks/useBusinessData'
import { PLAYLIST } from '@src/constants'
import { EmbedChannelModalInstruction } from '@src/modules/channel/components/DynamicContent/EmbedModal/EmbedChannelModalInstruction/EmbedChannelModalInstruction'
import LayoutOptions2 from '@src/modules/channel/components/DynamicContent/EmbedModal/LayoutOptions2'
import { PlaylistSelect } from '@src/modules/channel/components/Library/Playlist/PlaylistSelect'
import { Container } from '@src/modules/channel/components/YourSite/Common.styles'
import { OTHER_HTML } from '@src/modules/channel/constants'
import { useAutoEmbed } from '@src/modules/channel/hooks/YourSite/useAutoEmbed'
import {
  resetPlaylistVideos,
  setAutoEmbedSelectedPlaylistId
} from '@src/modules/channel/redux/YourSite'
import { autoEmbed_fetchPlaylistVideos } from '@src/modules/channel/redux/YourSite/autoEmbed'
import { Divider, Spin, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

export const HTMLPage = (): JSX.Element => {
  const { t } = useTranslation()
  const { businessId, channelId } = useParams()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [playlistId, setPlaylistId] = useState(null)

  const { isActivationStatusModalOpen } = useAppSelector(
    (state) => state.activationStatus
  )

  const {
    channelPlaylists,
    autoEmbedPlaylistId,
    playlistVideos,
    playlistVideosStatus
  } = useAutoEmbed({
    channelId
  })

  const playlistLoading = playlistVideosStatus === 'loading'
  const selectedPlaylist = autoEmbedPlaylistId || playlistId

  /**
   * Handle navigation to playlist
   */
  const navigateToPlaylist = useCallback(() => {
    navigate(
      `/business/${businessId}/channel/${channelId}/videos?activeTab=playlist&activePlaylist=${selectedPlaylist}`
    )
  }, [navigate, businessId, channelId, selectedPlaylist])

  const fetchPlaylistVideos = useCallback(
    async (playlistId: string) => {
      dispatch(
        autoEmbed_fetchPlaylistVideos({
          playlistId,
          channelId,
          businessId
        })
      )
    },
    [dispatch, channelId, businessId]
  )

  /**
   * Fetch playlist videos on playlist selection
   */
  useEffect(() => {
    if (playlistId) {
      fetchPlaylistVideos(playlistId)
    }
  }, [playlistId, fetchPlaylistVideos])

  useEffect(() => {
    if (selectedPlaylist) {
      const playlistIdInChannelPlaylists = channelPlaylists?.find(
        (playlist) => playlist.id === selectedPlaylist
      )
      if (playlistIdInChannelPlaylists) {
        setPlaylistId(playlistIdInChannelPlaylists.id)
      } else {
        dispatch(setAutoEmbedSelectedPlaylistId(null))
        dispatch(resetPlaylistVideos({}))
      }
    } else if (channelPlaylists?.length === 1) {
      const playlistId = channelPlaylists?.[0].id
      dispatch(setAutoEmbedSelectedPlaylistId(playlistId))
      setPlaylistId(playlistId)
    }
  }, [selectedPlaylist, channelPlaylists, dispatch])

  const handlePlaylistUpdate = useCallback(
    (value: string[]) => {
      dispatch(setAutoEmbedSelectedPlaylistId(value))
      setPlaylistId(value)
    },
    [dispatch]
  )

  const iframeRef = useRef(null)
  const embedCode = useAppSelector(
    (state) => state.embed.offlineEmbedCode[OTHER_HTML]
  )
  const { isPublisher } = useBusinessData({ businessId })
  const embedScript = useMemo(() => {
    if (!embedCode) return ''
    setLoading(true)

    return embedCode
      .replace(
        /HOST_PLACEHOLDER/g,
        isPublisher
          ? process.env.REACT_APP_PUBLISHER_EMBED_CDN_HOST
          : process.env.REACT_APP_EMBED_CDN_HOST
      )
      .replace('//', 'https://')
  }, [embedCode, isPublisher])

  const iframeData = useMemo(() => {
    const iframeDoc = iframeRef.current?.contentWindow?.document
    if (iframeDoc && !embedCode?.includes('pinned')) {
      const fwPlayer = iframeDoc.querySelector('#fw-player')
      if (
        fwPlayer &&
        fwPlayer.shadowRoot &&
        fwPlayer.shadowRoot.querySelector('.cy-back_close')
      ) {
        fwPlayer.shadowRoot.querySelector('.cy-back_close').click()
      }
    }

    return `<html>
      <head>
        <title>Embed</title>
        <style>
          body {
            margin: 0;
            padding: 0;
            overflow: scroll;
          }
          body::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
          }
        </style>
      </head>
      <body>
        ${embedScript}
      </body>
    </html>`
  }, [embedScript, embedCode])

  useEffect(() => {
    return () => {
      const fwPlayer = document.getElementById('fw-player')
      if (fwPlayer) {
        fwPlayer.remove()
      }
    }
  }, [embedCode])

  return (
    <Flex flexDirection="column" gap="16px" flex="1" width="100%">
      {isActivationStatusModalOpen && <ActivationStatusContainer />}
      <Container gap="16px">
        <Title fontSize={24} weight={600} noMargin>
          {t('Create an HTML Embed')}
        </Title>
        <Flex flexDirection="column" minWidth="479px">
          <LabelCtrl label={t('Playlist')} />
          <PlaylistSelect
            playlists={[playlistId]}
            setPlaylists={handlePlaylistUpdate}
            defaultSearchedPlaylists={channelPlaylists}
            notFoundContent={t('No playlists found')}
          />
          {!playlistLoading && playlistVideos.length === 0 && selectedPlaylist && (
            <Flex flexDirection="column" marginTop="20px">
              <p>{t('Add videos to your playlist to display content')}</p>
              <FWButton type="primary" onClick={navigateToPlaylist}>
                {t('Edit Playlist')}
              </FWButton>
            </Flex>
          )}
        </Flex>
        {!!selectedPlaylist && (
          <>
            <LayoutOptions2
              key={selectedPlaylist}
              tab={OTHER_HTML}
              optionsIndex={[0, 1, 2, 3, 5, 6, 7, 8, 9]}
              embedContext={PLAYLIST}
              playlist_id={selectedPlaylist}
            />
            <EmbedChannelModalInstruction
              tab={OTHER_HTML}
              data={{
                steps: [
                  [t('Step 1'), t('Select a layout.')],
                  [t('Step 2'), t('Copy HTML code.')],
                  [
                    t('Step 3'),
                    t('Paste into a custom HTML widget on your desired CMS.')
                  ]
                ]
              }}
            />
            <Divider style={{ width: '100%' }} />
            <Flex
              alignItems="center"
              style={{ marginTop: 10, marginBottom: 20 }}
            >
              <Title level={3} noMargin>
                {t('Preview')}
              </Title>
              <Tooltip
                trigger={['hover', 'click']}
                title={t(
                  'The private or test livestream will not be shown in the list.'
                )}
              >
                <InfoCircleOutlined style={{ marginLeft: 10 }} />
              </Tooltip>
              {loading && <Spin style={{ marginLeft: 10 }} size="small" />}
            </Flex>
            <iframe
              title="embed"
              ref={iframeRef}
              onLoad={() => {
                setLoading(false)
              }}
              key={iframeData.length}
              srcDoc={iframeData}
              width="100%"
              height="800"
              style={{ border: '#000000 1px' }}
            />
          </>
        )}
      </Container>
    </Flex>
  )
}
