import React, { useEffect, useState } from 'react'

import { useAppSelector } from '@src/app/hooks'
import {
  Box,
  Flex,
  FWModal,
  FWButton,
  LoadingIndicator
} from '@src/common/components/BaseComponent'
import { setHasNewNotification } from '@src/common/redux/ui'
import i18next from '@src/localization/i18n'
import { GITHUB_JSON_URL } from '@src/modules/profile/constants'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

interface NotificationType {
  id: number
  title: string
  description: string
  timestamp: string
}

interface NotificationsModalProps {
  visible: boolean
  onClose: () => void
}

const NotificationsModal: React.FC<NotificationsModalProps> = ({
  visible,
  onClose
}) => {
  const [notifications, setNotifications] = useState<NotificationType[]>([])
  const [loading, setLoading] = useState(true)
  const [selectedNotificationId, setSelectedNotificationId] = useState<
    number | null
  >(null)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const user = useAppSelector((state) => state.profile.user)

  const [language, setLanguage] = useState(
    localStorage.getItem('i18nextLng') || navigator.language || 'en'
  )

  useEffect(() => {
    const handleLanguageChange = (lng: string) => {
      setLanguage(lng)
    }

    i18next.on('languageChanged', handleLanguageChange)

    return () => {
      i18next.off('languageChanged', handleLanguageChange)
    }
  }, [])

  const readNotificationsKey = `readNotifications_${user?.id}_${language}`

  useEffect(() => {
    if (!visible) {
      return
    }

    const fetchNotifications = async () => {
      try {
        const response = await fetch(GITHUB_JSON_URL)
        if (!response.ok) {
          throw new Error('Failed to fetch notifications')
        }
        const data = await response.json()
        const selectedLanguageData =
          data[language] ?? data[language?.split('-')?.[0]] ?? data['en']

        setNotifications(
          Array.isArray(selectedLanguageData)
            ? selectedLanguageData.sort((a, b) =>
                moment(b.timestamp).diff(moment(a.timestamp))
              )
            : []
        )
      } finally {
        setLoading(false)
      }
    }

    fetchNotifications()
  }, [visible, language])

  const isNotificationRead = (id: number): boolean => {
    const storedReadNotifications: number[] = JSON.parse(
      localStorage.getItem(readNotificationsKey) || '[]'
    )

    return storedReadNotifications.includes(id)
  }

  const handleNotificationClick = (id: number) => {
    const storedReadNotifications: number[] = JSON.parse(
      localStorage.getItem(readNotificationsKey) || '[]'
    )

    if (!storedReadNotifications.includes(id)) {
      storedReadNotifications.push(id)
      localStorage.setItem(
        readNotificationsKey,
        JSON.stringify(storedReadNotifications)
      )
    }

    const hasUnreadNotifications = notifications.some(
      (notification) => !storedReadNotifications.includes(notification.id)
    )
    dispatch(setHasNewNotification(hasUnreadNotifications))

    setSelectedNotificationId(id)
  }

  const handleModalClose = () => {
    setSelectedNotificationId(null)
    onClose()
  }

  const handleBackToNotifications = () => {
    setSelectedNotificationId(null)
  }

  return (
    <FWModal
      visible={visible}
      title={
        selectedNotificationId ? t('Notification Detail') : t('Notifications')
      }
      onCancel={handleModalClose}
      footer={
        <Flex justifyContent="space-between">
          {selectedNotificationId && (
            <FWButton type="default" onClick={handleBackToNotifications}>
              {t('Back')}
            </FWButton>
          )}
        </Flex>
      }
      width={600}
      maskClosable={false}
    >
      {loading ? (
        <LoadingIndicator />
      ) : selectedNotificationId ? (
        <Box>
          {notifications.map((notification) => {
            if (notification.id === selectedNotificationId) {
              return (
                <Box key={notification.id} padding="12px">
                  <Box fontWeight="bold" fontSize="18px" marginBottom="8px">
                    {notification.title}
                  </Box>
                  <Box fontSize="14px" color="#555" marginBottom="12px">
                    {notification.description}
                  </Box>
                  <Box fontSize="12px" color="#888">
                    {moment(notification.timestamp).format('YYYY/MM/DD')}
                  </Box>
                </Box>
              )
            }

            return null
          })}
        </Box>
      ) : notifications.length > 0 ? (
        notifications.map((notification) => (
          <Box
            key={notification.id}
            padding="12px"
            borderRadius="8px"
            border="1px solid #ddd"
            backgroundColor={
              isNotificationRead(notification.id) ? '#f2f6fc' : '#fff'
            }
            marginBottom="12px"
            onClick={() => handleNotificationClick(notification.id)}
            style={{
              cursor: 'pointer'
            }}
          >
            <Flex display="flex" alignItems="center">
              {!isNotificationRead(notification.id) && (
                <Box
                  height="10px"
                  width="10px"
                  as="span"
                  borderRadius="50%"
                  style={{ backgroundColor: 'red' }}
                  marginRight="6px"
                />
              )}
              <Box
                fontWeight={
                  isNotificationRead(notification.id) ? 'normal' : 'bold'
                }
              >
                {notification.title}
              </Box>
            </Flex>
            <Box fontSize="12px" color="#888">
              {moment(notification.timestamp).format('YYYY/MM/DD')}
            </Box>
          </Box>
        ))
      ) : (
        <Box>{t('No Notifications')}</Box>
      )}
    </FWModal>
  )
}

export default NotificationsModal
