import React from 'react'

import loadable from '@loadable/component'
import { Route } from 'react-router-dom'

const VideoPerformance = loadable(
  () => import('../pages/Insights/VideoPerformance')
)

export const InsightsRoutes = (
  <>
    <Route
      path="/business/:businessId/channel/:channelId/video_performance/:videoId"
      element={<VideoPerformance />}
    />
  </>
)
