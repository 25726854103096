import React, { useCallback, useState } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import { CTA } from '@src/common/components/CTA'
import { CTA_TYPE } from '@src/constants'
import { validationSchema } from '@src/modules/channel/hooks/Library/Video/useVUPlayerActions'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type BatchUploadCTAWrapperProps = {
  addCTA: (value: {
    action_type: string
    action_url: string
    custom_label?: string
  }) => void
  cta: {
    action_type: string
    action_url: string
    custom_label?: string
  }
}

export const BatchUploadCTAWrapper: React.FC<BatchUploadCTAWrapperProps> = ({
  addCTA,
  cta
}) => {
  const { t } = useTranslation()
  const [link, setLink] = useState(cta?.action_url)
  const [type, setType] = useState(cta?.action_type || CTA_TYPE.VIEW)
  const [customLabel, setCustomLabel] = useState(cta?.custom_label)

  const getDefaultValues = () => ({
    action_type: CTA_TYPE.VIEW,
    action_url: ''
  })

  const methods = useForm<Video.VUFormInputs>({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(validationSchema(t))
  })

  const handleAddCTA = useCallback(
    (cta: {
      action_type: string
      action_url: string
      custom_label: string
    }) => {
      addCTA(cta)
    },
    [addCTA]
  )

  const handleTypeChange = (value) => {
    setType(value)
    handleAddCTA({
      action_type: value,
      action_url: link,
      custom_label: customLabel
    })
  }

  const handleLinkChange = (e) => {
    setLink(e.target.value)
    handleAddCTA({
      action_type: type,
      action_url: e.target.value,
      custom_label: customLabel
    })
  }

  const handleLabelChange = (e) => {
    setCustomLabel(e.target.value)
    handleAddCTA({
      action_type: type,
      action_url: link,
      custom_label: e.target.value
    })
  }

  return (
    <FormProvider {...methods}>
      <CTA
        handleLabelChange={handleLabelChange}
        handleLinkChange={handleLinkChange}
        handleTypeChange={handleTypeChange}
        link={link}
        customLabel={customLabel}
        type={type}
      />
    </FormProvider>
  )
}
