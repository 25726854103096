import React from 'react'

import { css } from '@emotion/core'
import { Box, Flex } from '@src/common/components/BaseComponent'
import { BackButton } from '@src/common/components/BaseComponent'
import { useNavigationBackPath } from '@src/common/hooks/useNavigationBackPath'
import theme from '@src/styles/theme'
import { Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router'

interface Props {
  title: string | React.ReactNode
  titleIcon?: React.ReactNode
  titleLevel?: 1 | 2 | 3 | 4 | 5
  rightNode?: React.ReactNode
  onBackButtonClick?: () => void
  backButtonLabel?: string
}

interface CustomLocationState {
  backTo?: string
}

const FullScreenHeader = ({
  title,
  titleLevel = 4,
  titleIcon,
  rightNode,
  onBackButtonClick,
  backButtonLabel
}: Props): JSX.Element => {
  const { businessId, channelId } = useParams()
  const { t } = useTranslation()
  const location = useLocation()
  const state = location.state as CustomLocationState
  const backPath = useNavigationBackPath(state)

  return (
    <Flex
      alignItems="center"
      width="100%"
      bg={theme.white}
      zIndex="102"
      py="medium"
      px="small"
      borderBottom={`1px solid ${theme.gray20}`}
      position="sticky"
    >
      <Box position="absolute" left={10} zIndex="2">
        {(backPath || onBackButtonClick) && (
          <BackButton
            text={backButtonLabel || t('Back')}
            fontSize="14px"
            cb={onBackButtonClick}
            destination={
              backPath || `/business/${businessId}/channel/${channelId}/videos`
            }
          />
        )}
      </Box>
      <Flex width="100%" justifyContent="center" flexDirection="row" gap="10">
        {titleIcon ? titleIcon : null}
        {typeof title === 'string' ? (
          <Typography.Title
            level={titleLevel}
            css={css`
              display: inline-block;
              margin-bottom: 0 !important;
            `}
          >
            {title}
          </Typography.Title>
        ) : (
          title
        )}
      </Flex>
      <Flex position="absolute" right="10">
        {rightNode ? rightNode : null}
      </Flex>
    </Flex>
  )
}

export default FullScreenHeader
