type VideoPostersCompareItem = {
  original?: boolean | null
  format?: string | null
  orientation?: string | null
}

type VideoPostersCompareFn = (
  a: VideoPostersCompareItem,
  b: VideoPostersCompareItem
) => number

const createVideoPostersCompareFn = (): VideoPostersCompareFn => {
  const priorityListMap = {
    original: [false, undefined, null, true],
    format: ['webp', 'gif'],
    orientation: ['vertical', 'square', 'horizontal']
  }

  const getPriority = (
    property: string,
    poster: globalLib.VideoPoster
  ): number => {
    const priorityList = priorityListMap[property]
    const priority: number = priorityList.indexOf(poster[property])

    return priority
  }

  const comparePriority = (
    property: string,
    a: globalLib.VideoPoster,
    b: globalLib.VideoPoster
  ): number => {
    const aPriority = getPriority(property, a)

    if (aPriority === -1) {
      return 0
    }

    const bPriority = getPriority(property, b)
    if (bPriority === -1) {
      return 0
    }

    return aPriority - bPriority
  }

  const videoPostersCompareFn = (
    a: globalLib.VideoPoster,
    b: globalLib.VideoPoster
  ): number => {
    const originalComparingResult = comparePriority('original', a, b)
    if (originalComparingResult !== 0) {
      return originalComparingResult
    }

    const formatComparingResult = comparePriority('format', a, b)

    if (formatComparingResult !== 0) {
      return formatComparingResult
    }

    const orientationComparingResult = comparePriority('orientation', a, b)

    if (orientationComparingResult !== 0) {
      return orientationComparingResult
    }

    return 0
  }

  return videoPostersCompareFn
}

export const getSortedVideoPosters = (
  videoPosters?: globalLib.VideoPoster[] | null
): globalLib.VideoPoster[] | undefined | null => {
  if (!videoPosters) {
    return videoPosters
  }

  const resultVideoPosters = [...videoPosters]
  resultVideoPosters.sort(createVideoPostersCompareFn())

  return resultVideoPosters
}

export const getSortedPendingVideoPosters = (
  pendingVideoPosters?: globalLib.PendingVideoPoster[] | null
): globalLib.PendingVideoPoster[] | undefined | null => {
  if (!pendingVideoPosters) {
    return pendingVideoPosters
  }

  const resultPendignVideoPosters = [...pendingVideoPosters]
  resultPendignVideoPosters.sort(createVideoPostersCompareFn())

  return resultPendignVideoPosters
}

const filterPosterPayloads = (
  videoPosters?: (globalLib.PosterPayload | undefined | null)[] | null
): globalLib.PosterPayload[] | undefined => {
  if (!videoPosters) {
    return undefined
  }

  const resultVideoPosters = videoPosters.filter((p) => {
    if (!p) {
      return false
    }

    if (p.original) {
      return false
    }

    return true
  })

  return resultVideoPosters
}

export const composePosters = (
  newAnimatedPosterPayload?: globalLib.PosterPayload | null,
  customGifAnimatedPosterPayload?: globalLib.PosterPayload | null,
  posterPayload?: globalLib.PosterPayload | null,
  widePosterPayload?: globalLib.PosterPayload | null
): globalLib.PosterPayload[] | undefined => {
  const animatedPosters = [
    newAnimatedPosterPayload,
    customGifAnimatedPosterPayload
  ]
  let staticPosters: (globalLib.PosterPayload | undefined | null)[] = []
  if (
    posterPayload &&
    !posterPayload.id &&
    posterPayload.width &&
    posterPayload.height &&
    posterPayload.width > posterPayload.height
  ) {
    if (
      widePosterPayload &&
      widePosterPayload.format === posterPayload.format
    ) {
      if (!widePosterPayload.id) {
        staticPosters = [widePosterPayload]
      } else {
        staticPosters = [posterPayload]
      }
    } else {
      staticPosters = [posterPayload, widePosterPayload]
    }
  } else {
    staticPosters = [posterPayload, widePosterPayload]
  }

  return filterPosterPayloads(animatedPosters.concat(staticPosters))
}
