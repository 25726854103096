export function convertOptionArray(
  input: globalLib.ProductOptions[]
): {
  product_id: string
  product_unit_id?: string
  business_store_id?: string
}[] {
  return input.map((option) => {
    return {
      product_id: option.id,
      product_unit_id: option.unitId,
      business_store_id: option.businessStoreId
    }
  })
}
