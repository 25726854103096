import { CHANNEL_TABS } from '@src/constants'
import { useNavigate } from 'react-router'

import { useNabooFeatureFlag } from './useFeatureFlag'

export const useCustomNavigate = (): {
  navigateToHome: (args: { businessId: string; channelId: string }) => void
} => {
  const { cms_hide_all_videos_menu } = useNabooFeatureFlag()

  const navigate = useNavigate()

  return {
    navigateToHome: ({ businessId, channelId }) => {
      if (cms_hide_all_videos_menu) {
        navigate(
          `/business/${businessId}/channel/${channelId}/videos?activeTab=${CHANNEL_TABS.ORIGINAL}`
        )
      } else {
        navigate(
          `/business/${businessId}/channel/${channelId}/videos?activeTab=${CHANNEL_TABS.ALL}`
        )
      }
    }
  }
}
