import React, { useContext, useEffect, useMemo, useState } from 'react'

import { useAppSelector } from '@src/app/hooks'
import { useOTOWidgetVisibility } from '@src/app/hooks/useOTOWidgetVisibility'
import { Flex, LoadingIndicator } from '@src/common/components/BaseComponent'
import { DevTools } from '@src/common/components/DevTools'
import { ReviewModalContainer } from '@src/common/components/ReviewModal/ReviewModalContainer'
import Sidebar from '@src/common/components/Sidebar/Sidebar'
import { TopNavigationContext } from '@src/common/components/TopNavigation'
import { BusinessContext } from '@src/common/contexts/BusinessContext'
import { useWindowSize } from '@src/common/hooks'
import { useShopifyFullPage } from '@src/common/hooks/useShopifyFullPage'
import { useToast } from '@src/common/hooks/useToast'
import { makeGetRequest } from '@src/common/redux/selectors/request'
import { setIsSidebarCollapsed } from '@src/common/redux/ui'
import { FloatFlowComponent } from '@src/modules/channel/components/Library/Importer/SourceImporter/AsyncFlow/FloatFlowComponent'
import { Result } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { animated, useSpring } from 'react-spring'

import { ChangelogModal } from './ChangelogModal'
import PageContainer from './PageContainer'
import SidebarMask from './SidebarMask'

const sidebarWidth = 180
const collapsedSidebarWidth = 52
// const collapseBreakpoint = 1200
const mobileSidebarBreakpoint = 768
const sidebarAnimationConfig = { tension: 150, friction: 20, clamp: true }

export default function AppLayout({
  children
}: {
  children: React.ReactNode
}): JSX.Element {
  const { t } = useTranslation()
  const { errorToast } = useToast()

  const dispatch = useDispatch()
  const [mobileSidebarEnabled, setMobileSidebarEnabled] = useState(
    window.innerWidth < mobileSidebarBreakpoint
  )
  const { sidebarCollapsed, disableSidebarAnimation } = useAppSelector(
    (state) => state.ui
  )
  const [businessesDidLoad, setBusinessesDidLoad] = useState(false)

  const [showReviewModal, setShowReviewModal] = useState(false)

  const isFullPage = useShopifyFullPage()

  const businessId = useContext(BusinessContext)
  const errors = useAppSelector((state) => state.errors)
  useOTOWidgetVisibility()

  // Display error notification with a centralized component
  // Specifically used to display errors from axios interceptors in api.js
  useEffect(() => {
    if (errors.message) {
      errorToast(errors.message)
    }
  }, [dispatch, errors, errorToast])

  useWindowSize((windowSize) => {
    if (
      windowSize.innerWidth < mobileSidebarBreakpoint &&
      !mobileSidebarEnabled
    ) {
      setMobileSidebarEnabled(true)
    } else if (
      windowSize.innerWidth >= mobileSidebarBreakpoint &&
      mobileSidebarEnabled
    ) {
      setMobileSidebarEnabled(false)
    }
  })

  const [animatedSidebarWidth, setAnimatedSidebarWidth] = useState(
    collapsedSidebarWidth
  )

  useEffect(() => {
    if (mobileSidebarEnabled) {
      setAnimatedSidebarWidth(collapsedSidebarWidth)
    } else {
      setAnimatedSidebarWidth(
        sidebarCollapsed ? collapsedSidebarWidth : sidebarWidth
      )
    }
  }, [mobileSidebarEnabled, sidebarCollapsed])

  let sidebarTransform
  if (mobileSidebarEnabled) {
    sidebarTransform = sidebarCollapsed
      ? `translateX(${-sidebarWidth}px)`
      : `translateX(0px)`
  } else {
    sidebarTransform = `translateX(0px)`
  }

  const animatedSidebarStyle = useSpring({
    to: {
      width: animatedSidebarWidth,
      transform: sidebarTransform
    },
    config: sidebarAnimationConfig
  })

  const animatedContentMargin =
    mobileSidebarEnabled || !businessId ? 0 : collapsedSidebarWidth

  const contentWrapperStyle = useSpring({
    to: { marginLeft: animatedContentMargin },
    config: sidebarAnimationConfig
  })

  const getRequest = useMemo(makeGetRequest, [])
  const { state: fetchBusinessesRequest } = useAppSelector((state) =>
    getRequest(state, 'business/fetchBusinesses')
  )

  useEffect(() => {
    if (!businessesDidLoad && fetchBusinessesRequest === 'resolved') {
      setBusinessesDidLoad(true)
    }
  }, [businessesDidLoad, fetchBusinessesRequest])

  useEffect(() => {
    if (businessesDidLoad) {
      setTimeout(() => {
        setShowReviewModal(true)
      }, 1000)
    }
  }, [businessesDidLoad])

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    if (mobileSidebarEnabled && !sidebarCollapsed) {
      // Prevent body from scrolling while the mobile sidebar is open
      body.setAttribute('style', 'overflow: hidden')
    }
    if (sidebarCollapsed || !mobileSidebarEnabled) {
      body.style.removeProperty('overflow')
    }
  }, [mobileSidebarEnabled, sidebarCollapsed])

  function renderPageContent() {
    if (!businessesDidLoad) {
      return <LoadingIndicator />
    }

    return children
  }

  if (fetchBusinessesRequest === 'rejected') {
    return (
      <Flex
        width="100%"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Result
          status="500"
          title={t('Hmmmm, something went wrong')}
          subTitle={t('Please contact support for more help')}
        />
      </Flex>
    )
  }

  return (
    <>
      {businessId && !isFullPage && (
        <Sidebar
          style={
            disableSidebarAnimation
              ? { width: animatedSidebarWidth, transform: sidebarTransform }
              : animatedSidebarStyle
          }
          businessId={businessId}
          mobileSidebarEnabled={mobileSidebarEnabled}
          onClickLink={() => {
            if (mobileSidebarEnabled && !sidebarCollapsed) {
              dispatch(setIsSidebarCollapsed(true))
            }
          }}
        />
      )}
      {!isFullPage ? (
        <animated.div
          style={{
            ...contentWrapperStyle,
            ...{ height: '100vh', display: 'flex', flexDirection: 'column' }
          }}
        >
          <TopNavigationContext.Provider
            value={{
              toggleShow: mobileSidebarEnabled,
              onToggleSidebar: () => {
                dispatch(setIsSidebarCollapsed(!sidebarCollapsed))
              }
            }}
          >
            <PageContainer businessId={businessId}>
              {renderPageContent()}
            </PageContainer>
          </TopNavigationContext.Provider>
        </animated.div>
      ) : (
        <PageContainer businessId={businessId}>
          {renderPageContent()}
        </PageContainer>
      )}
      {mobileSidebarEnabled && !isFullPage && (
        <SidebarMask
          opacity={sidebarCollapsed ? 0 : 1}
          height={sidebarCollapsed ? 0 : 100}
          onClick={() => {
            dispatch(setIsSidebarCollapsed(true))
          }}
        />
      )}

      {showReviewModal && <ReviewModalContainer businessId={businessId} />}
      <ChangelogModal />
      <DevTools />
      <FloatFlowComponent />
    </>
  )
}
