import { useEffect, useState, useRef } from 'react'

import { useAppSelector } from '@src/app/hooks'
import {
  fetchBusinessStores,
  fetchProducts
} from '@src/common/redux/businessStore'
import {
  fetchChannelVideoCount,
  fetchPlaylistVideos
} from '@src/common/redux/channel'
import { useDispatch } from 'react-redux'

import useActivationStatus from './Onboarding/useActivationStatus'
import usePlaylistsAndGroups from './usePlaylistsAndGroups'

type Props = {
  businessId: string
  channelId: string
}

const useCurrentActivationStatus = ({ businessId, channelId }: Props): void => {
  const dispatch = useDispatch()
  const {
    setUploadVideoComplete,
    setMatchVideoComplete,
    setCreatePlaylistComplete,
    setUploadVideoNotComplete
  } = useActivationStatus()

  const [firstStoreId, setFirstStoreId] = useState<string>()
  const [productList, setProductList] = useState([])

  const { onboardingContext, openInstructions, embedPlaylist } = useAppSelector(
    (state) => state.activationStatus
  )

  const videos = useAppSelector(
    (state) => state.channel.channelVideoCount[channelId]
  )

  const allVideosCount = videos?.allVideosCount

  const { playlistVideos } = useAppSelector((state) => state.channel)

  const { idsByBusinessId } = useAppSelector((state) => state.businessStore)

  const storeProductList = useAppSelector(
    (state) =>
      state.businessStore.productsByBusinessStoreId?.[firstStoreId]?.products
  )

  const { firstPlaylistId } = usePlaylistsAndGroups({
    businessId,
    channelId
  })

  const hasFetchedProducts = useRef(false)

  // fetch uploaded videos
  useEffect(() => {
    dispatch(fetchChannelVideoCount(businessId, channelId))
  }, [businessId, channelId, dispatch])

  // fetch videos from first playlist
  useEffect(() => {
    if (firstPlaylistId) {
      dispatch(fetchPlaylistVideos(businessId, channelId, firstPlaylistId))
    }
  }, [firstPlaylistId, dispatch, businessId, channelId, videos])

  // fetch first store and products
  useEffect(() => {
    dispatch(fetchBusinessStores(businessId, undefined, 100))
  }, [businessId, dispatch])

  useEffect(() => {
    if (idsByBusinessId[businessId]?.length) {
      setFirstStoreId(idsByBusinessId[businessId][0])
      if (!hasFetchedProducts.current) {
        dispatch(
          fetchProducts({
            businessId,
            channelId,
            storeId: idsByBusinessId[businessId][0]
          })
        )
        hasFetchedProducts.current = true
      }
    }
  }, [businessId, idsByBusinessId, channelId, dispatch, firstStoreId])

  const hasVideosFiled =
    (storeProductList && storeProductList.length === 0) ||
    storeProductList?.find((item) => {
      return !!item.videos
    })

  useEffect(() => {
    if (storeProductList && hasVideosFiled?.video_count > 0) {
      setProductList(storeProductList)
    }
  }, [hasVideosFiled, storeProductList])

  useEffect(() => {
    if (onboardingContext === 'home') {
      if (allVideosCount === 0) {
        // deleted all videos, restart activation steps
        setUploadVideoNotComplete()
      } else if (embedPlaylist === 'complete') {
        return
      } else if (playlistVideos[firstPlaylistId]?.length > 0) {
        setCreatePlaylistComplete()
      } else if (allVideosCount > 0) {
        setUploadVideoComplete()
      }
    }
  }, [
    setUploadVideoComplete,
    playlistVideos,
    setCreatePlaylistComplete,
    onboardingContext,
    firstPlaylistId,
    embedPlaylist,
    allVideosCount,
    setUploadVideoNotComplete
  ])

  useEffect(() => {
    if (onboardingContext === 'pdp') {
      if (allVideosCount === 0) {
        // deleted all videos, restart activation steps
        setUploadVideoNotComplete()
      } else if (openInstructions === 'complete') {
        return
      } else if (productList.length > 0) {
        setMatchVideoComplete()
      } else if (allVideosCount > 0) {
        setUploadVideoComplete()
      }
    }
  }, [
    hasVideosFiled,
    onboardingContext,
    openInstructions,
    productList,
    setMatchVideoComplete,
    setUploadVideoComplete,
    setUploadVideoNotComplete,
    storeProductList,
    allVideosCount
  ])
}
export default useCurrentActivationStatus
