import styled from '@emotion/styled'

export const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StepCircle = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333;
  color: white;
  border-radius: 50%;
  font-weight: bold;
  font-size: 14px;
  margin: 5px 0;
`

export const StepLine = styled.div`
  width: 1px;
  height: 60px;
  background-color: #e1e4e8;
`
