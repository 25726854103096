import { useAppSelector } from '@src/app/hooks'

export const useChannelData = ({
  channelId
}: {
  channelId: string
}): {
  channel: globalLib.Channel
  svCount: number
  lsCount: number
} => {
  const { channels, channelVideoCount } = useAppSelector(
    (state) => state.channel
  )
  const channel = channels[channelId]

  const svCount = channelVideoCount[channelId]?.shortVideosCount
  const lsCount = channelVideoCount[channelId]?.liveStreamsCount

  return {
    channel,
    svCount,
    lsCount
  }
}
