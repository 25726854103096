import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'

import { css } from '@emotion/react'
import { useAppDispatch } from '@src/app/hooks'
import { Flex, Title } from '@src/common/components/BaseComponent'
import { usePixelAmpTracking } from '@src/common/hooks'
import { createChannelPlaylist } from '@src/common/redux/channel'
import { searchPlaylists } from '@src/common/redux/playlist'
import { TRACKING_EVENTS } from '@src/common/utils/tracking'
import { TOP_PLAYLIST } from '@src/constants'
import { Select, SelectProps, Tag } from 'antd'
import { RefSelectProps } from 'antd/lib/select'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

export interface PlaylistSelectWithCreateProps {
  setPlaylists: Dispatch<SetStateAction<string[]>>
  playlists: string[]
  inputRef?: React.Ref<RefSelectProps>
  multiple?: boolean
  defaultSearchedPlaylists?: globalLib.Playlist[]
  maxTagCount?: number | 'responsive'
  videoPlaylists?: {
    id: string
    name: string
  }[]
}

export const PlaylistSelectWithCreate: React.FC<
  PlaylistSelectWithCreateProps & SelectProps
> = ({
  playlists = [],
  setPlaylists,
  inputRef,
  defaultSearchedPlaylists = [],
  maxTagCount = 'responsive',
  videoPlaylists = [],
  ...props
}) => {
  const { businessId, channelId } = useParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { pixelAmpTracking } = usePixelAmpTracking()

  const timer = useRef<ReturnType<typeof setTimeout>>(null)
  const [playlistSearchValue, setPlaylistSearchValue] = useState<string>()
  const [searchedPlaylists, setSearchedPlaylists] = useState<
    globalLib.Playlist[]
  >([])
  const [inputValue, setInputValue] = useState<string[] | string | undefined>(
    undefined
  )
  const [currentPlaylists, setCurrentPlaylists] = useState([])
  const [selectedPlaylists, setSelectedPlaylists] = useState<
    {
      id: string
      name: string
    }[]
  >(videoPlaylists)

  useEffect(() => {
    if (playlists?.length > 0) {
      setCurrentPlaylists(playlists)
    }
    setSearchedPlaylists(defaultSearchedPlaylists)
  }, [playlists, defaultSearchedPlaylists])

  const handlePlaylistSearch = useCallback(
    (value: string) => {
      setPlaylistSearchValue(value)
      const loadPlaylists = async (value) => {
        const playlistResults = ((await searchPlaylists({
          businessId,
          channelId,
          q: value,
          page_size: 100
        })) as unknown) as globalLib.Playlist[]
        setSearchedPlaylists(playlistResults)
      }
      clearTimeout(timer.current)
      timer.current = setTimeout(() => loadPlaylists(value), 150)
    },
    [businessId, channelId]
  )

  const handleChange = useCallback(
    (playlist: string, option) => {
      const value = currentPlaylists.includes(playlist)
        ? currentPlaylists
        : [...currentPlaylists, playlist]
      setPlaylists(value)
      setCurrentPlaylists(value)
      setPlaylistSearchValue('')
      setInputValue('')
      setSearchedPlaylists(defaultSearchedPlaylists)
      setSelectedPlaylists([
        ...selectedPlaylists,
        {
          id: playlist,
          name: option.label
        }
      ])
    },
    [
      currentPlaylists,
      defaultSearchedPlaylists,
      selectedPlaylists,
      setPlaylists
    ]
  )

  const handleRemovePlaylist = useCallback(
    (playlistId: string) => {
      const value = currentPlaylists.filter(
        (playlist) => playlist !== playlistId
      )
      setPlaylists(value)
      setCurrentPlaylists(value)

      setSelectedPlaylists((prop) =>
        prop.filter((playlist) => playlist.id !== playlistId)
      )
    },
    [currentPlaylists, setPlaylists, setSelectedPlaylists]
  )

  /**
   * `createNewPlaylist` is a function that creates a new playlist.
   *
   * This function is memoized using `useCallback` to prevent unnecessary re-renders and improve performance.
   *
   * @callback createNewPlaylist
   * @param {string} playlistName - The name of the new playlist to be created.
   * @returns {Promise<globalLib.Playlist>} The data of the newly created playlist.
   *
   * @example
   * const newPlaylistData = await createNewPlaylist('My New Playlist');
   */
  const createNewPlaylist = useCallback(
    async (playlistName: string) => {
      const newPlaylist = {
        name: playlistName
      }
      const playlistResponse = await dispatch(
        createChannelPlaylist(businessId, channelId, newPlaylist)
      )
      const { data: playlistData } = playlistResponse

      pixelAmpTracking(TRACKING_EVENTS.PLAYLIST_EVENTS.CREATE_PLAYLIST, {
        _playlist_id: playlistData.id,
        trigger_context: 'playlist_select'
      })

      return playlistData
    },
    [businessId, channelId, dispatch, pixelAmpTracking]
  )

  const handleInputKeyDown = useCallback(
    async (e) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        if (playlistSearchValue) {
          // Check if the playlist already exists in the defaultSearchedPlaylists array
          const playlist = defaultSearchedPlaylists.find(
            (playlist) => playlist.name === playlistSearchValue
          )

          // Check if the playlist already exists in the currentPlaylists array
          const existingPlaylist = currentPlaylists.find(
            (currentPlaylist) => currentPlaylist === playlist?.id
          )

          // If the playlist already exists in the currentPlaylists array, return
          if (existingPlaylist) {
            return
          }

          // If the playlist exists in the defaultSearchedPlaylists array, add it to the currentPlaylists array
          if (playlist) {
            handleChange(playlist.id, {
              value: playlist.id,
              label: playlist.name
            })
          } else {
            // If the playlist does not exist in the defaultSearchedPlaylists array, create a new playlist
            const playlistData = await createNewPlaylist(playlistSearchValue)
            setSearchedPlaylists([...searchedPlaylists, playlistData])
            setSelectedPlaylists([
              ...selectedPlaylists,
              {
                id: playlistData?.id,
                name: playlistData?.name
              }
            ])
            handleChange(playlistData?.id, {
              value: playlistData?.id,
              label: playlistData?.name
            })
          }
        }
      }
    },
    [
      createNewPlaylist,
      currentPlaylists,
      defaultSearchedPlaylists,
      handleChange,
      playlistSearchValue,
      searchedPlaylists,
      selectedPlaylists
    ]
  )

  const renderPlaylistTags = useMemo(() => {
    //Loop through currentPlaylist array and use the value to find the
    // playlist in the searchedPlaylists array, then return the name of the
    // playlist in a Tag component
    return (
      <Flex flexWrap="wrap">
        {selectedPlaylists.map((playlist) => {
          return (
            <Tag
              color="#E5F0FF"
              key={playlist.id}
              closable
              css={css`
                svg {
                  color: #000000;
                }
              `}
              onClose={() => handleRemovePlaylist(playlist.id)}
              style={{ marginBottom: '5px', color: 'black' }}
            >
              {playlist.name}
            </Tag>
          )
        })}
      </Flex>
    )
  }, [handleRemovePlaylist, selectedPlaylists])

  return (
    <Flex flexDirection="column">
      {!!currentPlaylists.length && renderPlaylistTags}
      <Select
        ref={inputRef}
        value={inputValue}
        showSearch
        filterOption={false}
        maxTagCount={maxTagCount}
        onChange={handleChange}
        searchValue={playlistSearchValue}
        optionFilterProp="children"
        defaultActiveFirstOption={false}
        placeholder={t(
          'Search for a playlist. Press enter to create playlist.'
        )}
        notFoundContent={
          <Title level={5} weight={600} fontSize={14}>
            {t('{{playlist}} playlist not found, press enter to create it.', {
              playlist: playlistSearchValue
            })}
          </Title>
        }
        onSearch={handlePlaylistSearch}
        onInputKeyDown={handleInputKeyDown}
        style={{ width: '100%' }}
        onBlur={() => handlePlaylistSearch('')}
        options={searchedPlaylists
          .filter(
            (playlist) =>
              !currentPlaylists.includes(playlist.id) &&
              playlist.description !== TOP_PLAYLIST.UNIQUE_DESCRIPTION
          )
          .map((playlist) => ({
            value: playlist.id,
            label: playlist.name
          }))}
        {...props}
      />
    </Flex>
  )
}
