import React, { FC } from 'react'

import { css } from '@emotion/core'
import { Flex } from '@src/common/components/BaseComponent'
import { Segmented } from 'antd'
import { AiFillAppstore, AiOutlineUnorderedList } from 'react-icons/ai'

export type GridListTabLayout = 'grid' | 'list'

type IProps = {
  layout?: GridListTabLayout
  onChangeLayout?: (value: GridListTabLayout) => void
}

export const GridListTabButton: FC<IProps> = React.memo(
  ({ layout, onChangeLayout }): JSX.Element => {
    return (
      <Flex
        css={css`
          .ant-segmented {
            padding: 2px !important;
          }
          .ant-segmented.ant-segmented-lg .ant-segmented-item-label {
            min-height: 32px !important;
            line-height: 32px !important;
            display: flex;
            align-items: center;
            justify-content: center;

            .ant-segmented-item-icon {
              font-size: 20px;
              display: flex;
            }
          }
        `}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Segmented
          size="large"
          onChange={onChangeLayout}
          value={layout}
          options={[
            {
              value: 'grid',
              icon: <AiFillAppstore />
            },
            {
              value: 'list',
              icon: <AiOutlineUnorderedList />
            }
          ]}
        />
      </Flex>
    )
  }
)

GridListTabButton.displayName = 'GridListTabButton'
