import React from 'react'

import { css } from '@emotion/core'
import { Box, Flex } from '@src/common/components/BaseComponent'
import { Progress } from 'antd'

interface PosterProps {
  imgUrl: string
  posterUploading?: boolean
  posterProgress?: number
  width?: number
  height?: number
  borderRadius?: string
}

const Poster: React.FC<PosterProps> = ({
  imgUrl = null,
  posterProgress,
  posterUploading,
  width,
  height,
  borderRadius = '4px'
}) => {
  return (
    <Flex position="relative">
      <Box
        // purposedly a bit smaller to fit within the Dragger border
        width={width - 2}
        height={height - 2}
        borderRadius={borderRadius}
        backgroundImage={`url("${imgUrl}")`}
        backgroundSize="cover"
        backgroundPosition="center center"
      >
        <Progress
          percent={posterProgress}
          showInfo={false}
          css={css`
            margin-left: auto;
            width: 80%;
            opacity: ${posterUploading ? '100%' : '0%'};
            transition: all 0.2s;
          `}
        />
      </Box>
    </Flex>
  )
}

export default Poster
